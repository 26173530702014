// @flow
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Loading, Rates, RatesUtil } from "react-components";
import { connect } from "react-redux";
import {
  AgentJoin,
  SERVICE_ID_ELECTRICITY,
  USAGE_TYPE_LOW,
  USAGE_TYPE_STANDARD,
} from "../../Config/Constants";

import "../../Containers/Rates/Rates.scss";

import { getRates } from "../../Redux/Actions/ApiCalls";
import { priceFetching } from "../../Redux/Actions/CSRAgent/PriceAction";
import { withUserType } from "./RatesUtils";

type Props = {
  className?: string,
  constants: Object,
  icp: string,
  planId: string,
  hasNoIcp: boolean,
  isNarrow?: boolean,
  isSummary?: boolean,
  isIcon?: boolean,
  isLowUser: boolean,
  gasOnly?: boolean,
  gasRate?: Array,
  loaderCustomStyles?: Object,
  managedContent?: Object,
  setIsStandard: () => {},
  gasUsageType: string,
  setGasUsageType: (consumptionBand: string) => void,
  errorClassName?: string,
  trackingRequest?: (icp: string, rates: []) => {},
};

export const RateDetails = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [rates, setRates] = useState([]);
  const {
    icp,
    hasNoIcp,
    setIsStandard,
    constants,
    gasOnly,
    trackingRequest,
    planId,
    setGasUsageType,
    gasUsageType,
  } = props;

  const gasUsageTypeMap = useMemo(() => {
    return {
      low: AgentJoin.planServiceDetails.consumptionBands[0],
      standard: AgentJoin.planServiceDetails.consumptionBands[1],
    };
  }, []);

  const handleGasUsageToggle = useCallback(() => {
    setGasUsageType(
      gasUsageType === gasUsageTypeMap.low
        ? gasUsageTypeMap.standard
        : gasUsageTypeMap.low
    );
  }, [gasUsageTypeMap, gasUsageType, setGasUsageType]);

  useEffect(() => {
    if (icp && planId) {
      setRates([]);
      setIsLoading(true);
      props.togglePriceFetching(true);
      getRates(icp, planId)
        .then(([standardRatesResponse, lowRatesResponse]) => [
          ...withUserType(
            standardRatesResponse.data.priceList.filter(
              (rate) => rate.Charge > 0
            ),
            USAGE_TYPE_STANDARD
          ),
          ...withUserType(
            lowRatesResponse.data.priceList.filter((rate) => rate.Charge > 0),
            USAGE_TYPE_LOW
          ),
        ])
        .then((rates) => {
          if (trackingRequest) {
            trackingRequest(icp, rates);
          }
          setRates([
            {
              Rates: rates,
              Service: SERVICE_ID_ELECTRICITY,
            },
          ]);
        })
        .finally(() => {
          setIsLoading(false);
          props.togglePriceFetching(false);
        });
    } else if (hasNoIcp)
      setRates([{ Rates: [], Service: SERVICE_ID_ELECTRICITY }]);
    else setRates([]);
    // eslint-disable-next-line
  }, [icp, hasNoIcp, trackingRequest, planId]);

  if (isLoading) {
    return (
      <Loading
        customstyle={{
          minWidth: "100%",
          minHeight: "150px",
          marginBottom: "-15px",
        }}
      />
    );
  }

  return rates.length ? (
    <Rates
      className={props.className}
      id={constants.id}
      rates={RatesUtil.getRates(rates, constants.rateLabels)}
      gasRate={props.gasRate}
      messages={constants.messages}
      managedContent={props.managedContent}
      isOpenByDefault={props.isOpenByDefault}
      setIsStandard={setIsStandard}
      onGasUsageToggle={handleGasUsageToggle} /* TODO: Wire up after CSRT-599 */
      gasUsageType={gasUsageType}
      isNarrow={props.isNarrow}
      isSummary={props.isSummary}
      isLowUser={props.isLowUser}
      isIcon={props.isIcon}
      gasOnly={gasOnly}
      errorClassName={props.errorClassName}
    />
  ) : null;
};

const mapDispatchToProps = (dispatch) => ({
  togglePriceFetching: (fetchingStatus) =>
    dispatch(priceFetching(fetchingStatus)),
});

export default connect(null, mapDispatchToProps)(RateDetails);
