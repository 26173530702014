import * as actionTypes from "../../Actions/actionTypes";

const initialState = {
  isSubmitting: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SUBMITTING_JOURNEY:
      return { ...state, isSubmitting: action.isSubmitting };
    default:
      return state;
  }
};

export default reducer;
