// @flow
import * as actionTypes from "../../Actions/actionTypes";
import { reducerPropUpdater } from "../../utils";

const initialState: State = {
  gasRate: [],
};

const updateProp = reducerPropUpdater("PipedGasInfo");

const updatePipedGas = (state, gas) => {
  return { ...state, ...gas };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PROP:
      return updateProp(state, action.propName, action.value);

    case actionTypes.UPDATE_PIPED_GAS:
      return updatePipedGas(state, action.gas);

    default:
      return state;
  }
};

export default reducer;
