// @flow
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { compose } from "recompose";

import { withReduxProvider } from "../Utils/enhancers";
import { triggerSummaryJourney } from "../Utils/analytics";

import iJoinStore from "../Redux/Stores/iJoinStore";
import { fetchMyDetailsData } from "../Redux/Actions/iJoin/MyDetailsActions";

import MyDetails from "./MyDetails";
// import FindPlan from "./FindPlan";
// import Summary from "./Summary";
import Success from "./Success";
import withLayout from "../Components/Layout";

import { injectGtm } from "../Utils/gtm";
import { Gtm } from "../Config/Constants";

type Props = {
  fetchMyDetailsData?: () => void,
};

const JoinApp = (props: Props) => {
  useEffect(() => {
    props.fetchMyDetailsData();
    triggerSummaryJourney();
    injectGtm(Gtm.reactAppId);
    // eslint-disable-next-line
  }, []);

  return (
    <Switch>
      <Route
        path="/residential/my-details/join-success"
        component={withLayout(Success)}
      />
      <Route path="/residential/my-details" component={withLayout(MyDetails)} />
      {/* These routes are currently managed by sitecore hence it as been commented out */}
      {/* <Route path="/residential/find-a-plan" component={withLayout(FindPlan)} />
      <Route path="/residential/summary" component={withLayout(Summary)} /> */}
      <Route
        path="/"
        component={() => {
          console.log("NOT FOUND");
          window.location.href =
            process.env.REACT_APP_EXPRESS_BASE_URL + "/404-not-found";
          return null;
        }}
      />
    </Switch>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchMyDetailsData: () => dispatch(fetchMyDetailsData()),
});

export default compose(
  withReduxProvider(iJoinStore),
  connect(null, mapDispatchToProps)
)(JoinApp);
