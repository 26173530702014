// @flow
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { SelectedTick, Cross, Bar, Checkbox } from "react-components";
import { CreditCheckResult as Constants } from "../../../../Config/Constants";
import "./CreditCheckResult.scss";

export type CreditCheckResultType = "Green" | "Amber" | "Red" | "None";

type Props = {
  resultType: CreditCheckResultType,
  prepayEligible: boolean,
  onCheckboxChange?: () => void,
};

const resultText = {
  Green: Constants.passResultText,
  Amber: Constants.amberResultText,
  Red: Constants.failResultText,
  None: Constants.exceptionResultText,
};

const icons = {
  Green: <SelectedTick className="creditCheckResult_icon--green" />,
  Amber: <Bar />,
  Red: <Cross />,
  None: <SelectedTick className="creditCheckResult_icon--green" />,
};

const checkboxText = {
  Amber: Constants.amberCheckboxText,
  Red: Constants.failCheckboxText,
};

const CreditCheckResult = (props: Props) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    if (props.checked) {
      setIsChecked(true);
    }
  }, [props.checked]);

  return (
    <div className="creditCheckResult">
      {!isEmpty(props.resultType) && (
        <div>
          <h1 className="creditCheckResult_title">{Constants.title}</h1>
          <div className="creditCheckResult_section">
            {Constants.resultLabel}
            <div
              className={classNames("creditCheckResult_result", {
                "creditCheckResult_result--green":
                  props.resultType.result === "Green",
                "creditCheckResult_result--amber":
                  props.resultType.result === "Amber",
                "creditCheckResult_result--red":
                  props.resultType.result === "Red",
                "creditCheckResult_result--none":
                  props.resultType.result === "None",
              })}
            >
              {resultText[props.resultType.result]}
              {icons[props.resultType.result]}
            </div>
          </div>
          {props.resultType.message && (
            <div className="creditCheckResult_section">
              {Constants.nextStepsLabel}
              <div className="creditCheckResult_nextSteps">
                {props.resultType.message}
              </div>
            </div>
          )}
          {props.resultType.result === "Red" && props.prepayEligible && (
            <div className="creditCheckResult_textContainer">
              <div className="creditCheckResult_textContainer_text">
                {Constants.prepayEligible}
              </div>
            </div>
          )}
          {props.resultType.result === "Amber" && props.onCheckboxChange && (
            <div
              className={classNames(
                "creditCheckResult_section",
                "creditCheckResult_section--checkbox"
              )}
            >
              <div className="creditCheckResult_checkbox">
                <Checkbox
                  label={checkboxText[props.resultType.result]}
                  checked={isChecked}
                  onChange={() => {
                    props.onCheckboxChange(!isChecked);
                    setIsChecked(!isChecked);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CreditCheckResult;
