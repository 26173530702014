// @flow
import React, { useState, useEffect } from "react";
import _uniqBy from "lodash/uniqBy";
import { Rates, RatesUtil, Loading } from "react-components";
import Decimal from "decimal.js";

import { getRates } from "../../Redux/Actions/ApiCalls";
import {
  ExpressRates as Constants,
  SERVICE_ID_ELECTRICITY,
  USAGE_TYPE_STANDARD,
  USAGE_TYPE_LOW,
} from "../../Config/Constants";

import "./Rates.scss";

type Props = {
  icp: string,
  hasNoIcp: boolean,
  setIsStandard: () => {},
};

const getUnit = (currencyType, time) => {
  const timeDisplay = time.toLowerCase().replace(/kwh/, "kWh");

  return `${currencyType.toLowerCase()}${timeDisplay}`;
};

const getDisplayRate = (currencyType, charge) =>
  currencyType.toLowerCase().includes("dollars") ? `$${charge}` : `${charge}c`;

const withUserType = (rates, UserType) =>
  _uniqBy(rates, "PriceKeyDesc").map((rate) => ({
    Name: rate.PriceKeyDesc,
    UserType,
    Rate: getDisplayRate(rate.CurrType1, new Decimal(rate.Charge).toDP(2)),
    Unit: getUnit(rate.CurrType1, rate.UomTimeCat),
  }));

export default (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [rates, setRates] = useState([]);
  const { icp, hasNoIcp, setIsStandard } = props;

  useEffect(() => {
    if (icp) {
      setRates([]);
      setIsLoading(true);
      getRates(icp)
        .then(([standardRatesResponse, lowRatesResponse]) => [
          ...withUserType(
            standardRatesResponse.data.priceList.filter(
              (rate) => rate.Charge > 0
            ),
            USAGE_TYPE_STANDARD
          ),
          ...withUserType(
            lowRatesResponse.data.priceList.filter((rate) => rate.Charge > 0),
            USAGE_TYPE_LOW
          ),
        ])
        .then((rates) =>
          setRates([
            {
              Rates: rates,
              Service: SERVICE_ID_ELECTRICITY,
            },
          ])
        )
        .finally(() => setIsLoading(false));
    } else if (hasNoIcp)
      setRates([{ Rates: [], Service: SERVICE_ID_ELECTRICITY }]);
    else setRates([]);
  }, [icp, hasNoIcp]);

  if (isLoading) {
    return (
      <div className="expressRates_loader">
        <Loading />
      </div>
    );
  }

  return rates.length ? (
    <Rates
      id={Constants.id}
      rates={RatesUtil.getRates(rates, Constants.rateLabels)}
      messages={Constants.messages}
      isOpenByDefault
      setIsStandard={setIsStandard}
    />
  ) : null;
};
