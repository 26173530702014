// @flow
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import {
  AaSmartFuelInput,
  ArrowDown,
  BankAccountInput,
  Checkbox,
  ExpandableCard,
  Input,
  RadioButtonList,
  HTML,
  ExpansionPanel,
} from "react-components";

import { validatePhone } from "../../../../Utils/validateFields";

import "../../../../Containers/Payment/Payment.scss";

import {
  Payment as Constants,
  AboutYou as AboutYouConstants,
} from "../../../../Config/Constants";
import { useStateFromProp } from "../../../../Utils/hooks";
import {
  getIsFuelRewards,
  getIsElectricityOnly,
} from "../../../../Utils/selectors";
import { saveCustomerData } from "../../../../Redux/Actions/CSRAgent/JoinAction";
import { updateCreateBP } from "../../../../Redux/Actions/ApiCalls";
import {
  isReceiveBill,
  isPrepay,
  paymentMethodLabel,
  isBillingMonthly,
  isBillingFortnightly,
  billingCycleLabel,
  generateBillDates,
} from "../../../../Containers/Payment/utils.js";
import VerificationFailedModal from "../../../../Containers/Payment/VerificationFailedModal";
import { triggerAgentPageView } from "../../../../Utils/analytics";

type Props = {
  mobileNumberFromAboutYou?: string,
  PaymentMethod?: string,
  PrePayMobileNumber?: string,
  BillingCycle?: string,
  BillStartDate?: string,
  PaperlessDiscount?: boolean,
  JoinDirectDebit?: boolean,
  BankAccountNumber?: string,
  AgreeWithTermsAndConditions?: boolean,
  AaSmartFuel?: string,
  HasAaSmartFuel?: boolean,
  isFuelRewards?: boolean,
  isElectricityOnly?: boolean,
  isPrepayEligible?: boolean,
  today?: Date,
  isCurrentCardOpen?: boolean,
  isCardDisabled?: boolean,
  validate?: boolean,
  setValid: () => {},
  onClick: () => void,
  onCardClick?: () => void,
};

export const Payment = (props: Props) => {
  const billDates = generateBillDates(props.today);

  const [PaymentMethod, setPaymentMethod] = useStateFromProp(
    props.PaymentMethod,
    Constants.paymentMethodDefault
  );
  const [BillingCycle, setBillingCycle] = useStateFromProp(
    props.isElectricityOnly
      ? props.BillingCycle
      : Constants.billingCycleDefault,
    Constants.billingCycleDefault
  );
  const [BillStartDate, setBillStartDate] = useStateFromProp(
    props.isElectricityOnly ? props.BillStartDate : billDates[1],
    billDates[1]
  );
  const [PaperlessDiscount, setPaperlessDiscount] = useStateFromProp(
    props.PaperlessDiscount,
    Constants.paperlessDiscountDefault
  );
  const [JoinDirectDebit, setJoinDirectDebit] = useStateFromProp(
    props.JoinDirectDebit,
    Constants.joinDirectDebitDefault
  );
  const [BankAccountNumber, setBankAccountNumber] = useStateFromProp(
    props.BankAccountNumber,
    ""
  );
  const [bankAccountHasError, setBankAccountHasError] = useState(false);
  const [bankAccountNumberVerified, setBankAccountNumberVerified] = useState(
    false
  );
  const [
    bankAccountNumberVerificationFailed,
    setBandAccountNumberVerificationFailed,
  ] = useState(false);
  const [
    AgreeWithTermsAndConditions,
    setAgreeWithTermsAndConditions,
  ] = useStateFromProp(props.AgreeWithTermsAndConditions, false);
  const [AaSmartFuel, setAaSmartFuel] = useStateFromProp(props.AaSmartFuel, "");
  const [aaSmartFuelHasError, setAaSmartFuelHasError] = useState(false);
  const [aaSmartFuelVerified, setAaSmartFuelVerified] = useState(false);
  const [
    aaSmartFuelVerificationFailed,
    setAASmartFuelVerificationFailed,
  ] = useState(false);
  const [HasAaSmartFuel, setHasAaSmartFuel] = useStateFromProp(
    props.HasAaSmartFuel,
    props.isFuelRewards
  );
  const [PrePayMobileNumber, setPrePayMobileNumber] = useStateFromProp(
    props.PrePayMobileNumber,
    props.mobileNumberFromAboutYou
  );
  const [prePayMobileNumberHasError, setPrePayMobileNumberHasError] = useState(
    false
  );
  const [prePayMobileNumberAgree, setPrePayMobileNumberAgree] = useState(false);
  // eslint-disable-next-line
  const [validateItself, setValidateItself] = useState(false);

  const [changing, setChanging] = useState(false);

  const [cardOpen, setCardOpen] = useState(true);

  const [pageViewed, setPageViewed] = useState(false);

  const isDisable =
    (isReceiveBill(PaymentMethod) &&
      JoinDirectDebit &&
      (bankAccountHasError ||
        !BankAccountNumber ||
        !bankAccountNumberVerified ||
        !AgreeWithTermsAndConditions)) ||
    (props.isElectricityOnly &&
      props.isPrepayEligible &&
      isPrepay(PaymentMethod) &&
      (!prePayMobileNumberAgree ||
        !PrePayMobileNumber ||
        prePayMobileNumberHasError)) ||
    (props.isFuelRewards &&
      HasAaSmartFuel &&
      AaSmartFuel &&
      (aaSmartFuelHasError || !aaSmartFuelVerified));

  useEffect(() => {
    if (changing && !pageViewed) {
      triggerAgentPageView("/payment-setup");
      setPageViewed(true);
    }
  }, [changing, pageViewed, setPageViewed]);

  useEffect(() => {
    if (props.validate) {
      setValidateItself(true);
      setCardOpen(true);
    }
  }, [props.validate]);

  useEffect(() => {
    if (BillingCycle && props.sessionId && changing) {
      saveFields();
    }
    // eslint-disable-next-line
  }, [BillingCycle, props.sessionId]);

  useEffect(() => {
    if (props.BankAccountNumber) {
      createUpdateCustomer();
    }
    // eslint-disable-next-line
  }, [props.BankAccountNumber]);

  useEffect(() => {
    if (isDisable) {
      props.setValid("Payment", false);
    } else {
      props.setValid("Payment", true);
    }
    // eslint-disable-next-line
  }, [isDisable]);

  useEffect(() => {
    // const validFields = (!BankAccountNumber ||
    // 	(BankAccountNumber && BankAccountNumber.length > Constants.accountNumberMinLength)) &&
    // 	(!AaSmartFuel || AaSmartFuel.length === Constants.smartFuelFieldLength);

    if (!props.isCallingAPI && changing) {
      saveFields();
    }
    // eslint-disable-next-line
  }, [
    PaymentMethod,
    PrePayMobileNumber,
    PaperlessDiscount,
    BankAccountNumber,
    AgreeWithTermsAndConditions,
    AaSmartFuel,
    HasAaSmartFuel,
    BillStartDate,
  ]);

  const saveFields = (checkbox, value) => {
    let prepayMobile = null;
    if (PrePayMobileNumber && validatePhone(PrePayMobileNumber)) {
      prepayMobile = isPrepay(PaymentMethod) ? PrePayMobileNumber : null;
    }
    const directDebitJoin =
      checkbox === "JoinDirectDebit" ? value : JoinDirectDebit;
    props.saveCustomerData({
      PaymentMethod,
      PrePayMobileNumber: prepayMobile,
      BillingCycle,
      BillStartDate: isBillingFortnightly(BillingCycle) ? BillStartDate : null,
      PaperlessDiscount: isPrepay(PaymentMethod) ? false : PaperlessDiscount,
      JoinDirectDebit: !isPrepay(PaymentMethod) && directDebitJoin,
      BankAccountNumber,
      AgreeWithTermsAndConditions,
      AaSmartFuel:
        props.isFuelRewards && HasAaSmartFuel && AaSmartFuel
          ? AaSmartFuel
          : null,
      HasAaSmartFuel: props.isFuelRewards && HasAaSmartFuel,
    });
  };

  const createUpdateCustomer = () => {
    const a = props.addressDetails;
    const cust = props.customer;
    if (cust.DateOfBirth) {
      const dob = cust.DateOfBirth.replace(/\//g, "");
      const formattedDob =
        dob.substr(4) + "/" + dob.substr(2, 2) + "/" + dob.substr(0, 2);
      const newAcc = props.bankAccountNumber.replaceAll("-", "");
      if (a && props.token && props.bankAccountNumber) {
        props.createBP({
          title: cust.Title ? cust.Title : AboutYouConstants.unknownTitle,
          firstName: cust.FirstName,
          lastName: cust.LastName,
          middleName: cust.MiddleName,
          phoneNumber: {
            type: "mobile",
            number: cust.PhoneNumber,
          },
          address: {
            number: a.number,
            street: a.street,
            postCode: a.postcode,
            city: a.city,
            country: "NZ",
          },
          email: cust.EmailAddress,
          isAllowedToSendMarketingEmails: cust.ReceiveNewsAndOffers || false,
          dateOfBirth: formattedDob,
          sex: "X",
          businessPartner: cust.BusinessPartnerNumber,
          bankDetails: {
            key: newAcc.substr(0, 6),
            account: newAcc.substr(6),
            name: cust.FirstName + " " + cust.LastName,
          },
        });
      }
    }
  };

  const getContent = (
    <div className="payment">
      {props.isElectricityOnly && props.isPrepayEligible && (
        <div className="field">
          <RadioButtonList
            name="paymentVariant"
            title={Constants.cardIntro}
            description={Constants.cardIntroDisclaimer}
            items={Constants.paymentMethods}
            value={PaymentMethod}
            onChange={setPaymentMethod}
            onKeyDown={() => setChanging(true)}
          />
        </div>
      )}
      <ExpansionPanel isExpanded={isReceiveBill(PaymentMethod)}>
        {props.isElectricityOnly && props.isWeeklyFortnightlyEligible && (
          <ExpansionPanel isExpanded={PaperlessDiscount && JoinDirectDebit}>
            <div className="row subItem">
              <div className="field">
                <RadioButtonList
                  name="paymentPeriod"
                  title={Constants.billingCycle}
                  description={Constants.billingCycleText}
                  items={Constants.billingCycleOptions}
                  value={BillingCycle}
                  onChange={setBillingCycle}
                  onKeyDown={() => setChanging(true)}
                />
              </div>
            </div>

            <ExpansionPanel isExpanded={isBillingFortnightly(BillingCycle)}>
              <div className="payment_subSection payment_billingCycle">
                <div className="row subItem">
                  <div className="field">
                    <RadioButtonList
                      name="paymentStartDate"
                      title={Constants.billStartDate}
                      items={billDates}
                      value={BillStartDate}
                      onChange={setBillStartDate}
                      onKeyDown={() => setChanging(true)}
                    />
                  </div>
                </div>
              </div>
            </ExpansionPanel>

            {!isBillingMonthly(BillingCycle) && (
              <div className="payment_subSection">
                <div className="row subItem">
                  <div className="payment_disclaimer payment_stand-alone">
                    <HTML html={Constants.billingCycleDisclaimer} />
                  </div>
                </div>
              </div>
            )}
          </ExpansionPanel>
        )}

        <div className="row payment_disclaimer payment_stand-alone">
          {Constants.sectionDisclaimer}
        </div>

        <div className="payment_checkbox ebill">
          <Checkbox
            name="ebill"
            className="ebill-check-checkbox"
            label={Constants.paperlessDiscount}
            checked={PaperlessDiscount}
            onChange={() => {
              setPaperlessDiscount(!PaperlessDiscount);
              setChanging(true);
            }}
          />
          <div className="subItem payment_disclaimer">
            <HTML html={Constants.paperlessDiscountText} />
          </div>
        </div>

        <div className="payment_checkbox direct-debit">
          <Checkbox
            name="directDebit"
            className="dd-check-checkbox"
            label={Constants.joinDirectDebit}
            checked={JoinDirectDebit}
            onChange={() => {
              setJoinDirectDebit(!JoinDirectDebit);
              saveFields("JoinDirectDebit", !JoinDirectDebit);
              setChanging(true);
            }}
          />
          <div className="subItem payment_disclaimer">
            <HTML html={Constants.joinDirectDebitText} />
          </div>

          <ExpansionPanel isExpanded={JoinDirectDebit}>
            <div className="row subItem">
              <div className="payment_bankAccount">
                <div className="field">
                  <BankAccountInput
                    api={{
                      baseUrl: process.env.REACT_APP_BASE_URL,
                      key: process.env.REACT_APP_X_API_KEY,
                    }}
                    name="bankAccount"
                    handleChange={setBankAccountNumber}
                    handleVerified={setBankAccountNumberVerified}
                    handleVerificationError={() =>
                      setBandAccountNumberVerificationFailed(true)
                    }
                    value={BankAccountNumber}
                    isVerified={bankAccountNumberVerified}
                    labelText={Constants.bankAccountNumber}
                    handleError={setBankAccountHasError}
                    errorMessage={Constants.bankAccountNumberErrorMessage}
                    invalidMessage={Constants.bankAccountNumberInvalidMessage}
                    hasError={bankAccountHasError}
                    required
                    validateItself={validateItself}
                  />
                </div>
              </div>
            </div>

            <div className="row subItem">
              <div className="payment_checkbox bank-account">
                <Checkbox
                  name="bankAccountCheckbox"
                  className="bankAccount-check-checkbox"
                  label={<HTML html={Constants.agreeWithTermsAndConditions} />}
                  checked={AgreeWithTermsAndConditions}
                  onChange={() => {
                    setAgreeWithTermsAndConditions(
                      !AgreeWithTermsAndConditions
                    );
                    setChanging(true);
                  }}
                  required={JoinDirectDebit}
                  validateItself={validateItself}
                />
              </div>
            </div>
          </ExpansionPanel>
        </div>
      </ExpansionPanel>

      <ExpansionPanel
        isExpanded={
          props.isElectricityOnly &&
          props.isPrepayEligible &&
          isPrepay(PaymentMethod)
        }
      >
        <div className="row subItem">
          <div className="payment_disclaimer payment_stand-alone">
            {Constants.prePayMobileNumberDisclaimer}
          </div>
        </div>

        <div className="row subItem">
          <div className="payment_mobile">
            <div className="field">
              <Input
                name="mobileNumber"
                handleChange={(value) => setPrePayMobileNumber(value)}
                value={PrePayMobileNumber}
                labelText={Constants.prePayMobileNumber}
                maxLength={Constants.prePayMobileNumberMaxLength}
                handleError={setPrePayMobileNumberHasError}
                errorMessage={Constants.prePayMobileNumberErrorMessage}
                invalidMessage={Constants.prePayMobileNumberInvalidMessage}
                hasError={prePayMobileNumberHasError}
                validationType="phoneNumber"
                required
                validateItself={validateItself}
              />
            </div>
          </div>
        </div>

        <div className="row subItem payment_checkbox mobile">
          <Checkbox
            name="mobile"
            className="mobile-check-checkbox"
            label={<HTML html={Constants.prePayMobileNumberAgree} />}
            checked={prePayMobileNumberAgree}
            onChange={() => {
              setPrePayMobileNumberAgree(!prePayMobileNumberAgree);
              setChanging(true);
            }}
            required
            validateItself={validateItself}
          />
        </div>
      </ExpansionPanel>

      {props.isFuelRewards && (
        <div className="row">
          <div className="payment_checkbox aasf">
            <Checkbox
              name="aasf"
              className="aasf-check-checkbox"
              label={Constants.hasAaSmartFuel}
              subLabel={Constants.hasAaSmartFuelText}
              checked={HasAaSmartFuel}
              onChange={() => {
                setHasAaSmartFuel(!HasAaSmartFuel);
                setChanging(true);
              }}
            />
          </div>
        </div>
      )}

      <ExpansionPanel isExpanded={HasAaSmartFuel}>
        <div className="row subItem">
          <div className="payment_aasf">
            <div className="field">
              <AaSmartFuelInput
                api={{
                  baseUrl: process.env.REACT_APP_BASE_URL,
                  key: process.env.REACT_APP_X_API_KEY,
                }}
                name="aasfCard"
                handleChange={(value) => setAaSmartFuel(value)}
                handleVerificationError={() =>
                  setAASmartFuelVerificationFailed(true)
                }
                value={AaSmartFuel}
                labelText={Constants.aaSmartFuel}
                handleError={setAaSmartFuelHasError}
                handleVerified={setAaSmartFuelVerified}
                invalidMessage={
                  AaSmartFuel ? Constants.aaSmartFuelInvalidMessage : ""
                }
                hasError={aaSmartFuelHasError}
              />
            </div>
          </div>
        </div>
      </ExpansionPanel>
    </div>
  );

  const getPreview = (
    <div>
      {isReceiveBill(PaymentMethod) && (
        <Fragment>
          <div className="field">
            {paymentMethodLabel(PaymentMethod) + ": "}
            <span className="bold">{billingCycleLabel(BillingCycle)}</span>
          </div>
          {props.isElectricityOnly && isBillingFortnightly(BillingCycle) && (
            <div className="field">
              {Constants.billStartDatePreview + ": "}
              <span className="bold">{BillStartDate}</span>
            </div>
          )}
          {PaperlessDiscount && (
            <div className="field">
              <span className="bold">&#10004; </span>
              {Constants.paperlessDiscountPreview}
            </div>
          )}
          {JoinDirectDebit && (
            <div className="field">
              <span className="bold">&#10004; </span>
              {Constants.joinDirectDebitPreview}
            </div>
          )}
          {JoinDirectDebit && BankAccountNumber && (
            <div className="field">
              {Constants.bankAccountNumber + ": "}
              <span className="bold">{BankAccountNumber}</span>
            </div>
          )}
          {JoinDirectDebit && AgreeWithTermsAndConditions && (
            <div className="field">
              <HTML html={Constants.agreeWithTermsAndConditions} />
            </div>
          )}
        </Fragment>
      )}

      {props.isElectricityOnly &&
        props.isPrepayEligible &&
        isPrepay(PaymentMethod) && (
          <>
            {PrePayMobileNumber && !prePayMobileNumberHasError && (
              <>
                <div className="field">
                  {paymentMethodLabel(PaymentMethod) + ": "}
                  <span className="bold">{PrePayMobileNumber}</span>
                </div>
                {prePayMobileNumberAgree && (
                  <div className="field">
                    <HTML html={Constants.prePayMobileNumberAgree} />
                  </div>
                )}
              </>
            )}
            {PaperlessDiscount && (
              <div className="field">
                <span className="bold">&#10004; </span>
                {Constants.paperlessDiscountPreview}
              </div>
            )}
          </>
        )}

      {props.isFuelRewards && HasAaSmartFuel && AaSmartFuel && (
        <div className="field">
          {Constants.aaSmartFuelPreview + ": "}
          <span className="bold">{AaSmartFuel}</span>
        </div>
      )}
    </div>
  );

  const getHeader = {
    cardTitle: Constants.cardTitle,
    cardContent: getPreview,
    cardIcon: <ArrowDown />,
    cardIsSelected: false,
    cardLink: true,
  };

  return (
    <>
      <div id="Payment" className="cardId" />
      <ExpandableCard
        name="paymentCard"
        headerContent={getHeader}
        content={getContent}
        isOpen={cardOpen}
        disabled={props.isCardDisabled}
        handleClick={() => setCardOpen(!cardOpen)}
      />
      <VerificationFailedModal
        type="bankAccountNumber"
        isActive={bankAccountNumberVerificationFailed}
        onClose={() => {
          setJoinDirectDebit(false);
          setBankAccountNumber("");
          setBandAccountNumberVerificationFailed(false);
        }}
      />
      <VerificationFailedModal
        type="aaSmartFuel"
        isActive={aaSmartFuelVerificationFailed}
        onClose={() => {
          setHasAaSmartFuel(false);
          setAaSmartFuel("");
          setAASmartFuelVerificationFailed(false);
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  mobileNumberFromAboutYou: state.Customer.CustomerInfo.PhoneNumber,
  PaymentMethod: state.Payment.PaymentMethod || undefined,
  PrePayMobileNumber: state.Payment.PrePayMobileNumber || undefined,
  BillingCycle: state.Payment.BillingCycle || undefined,
  BillStartDate: state.Payment.BillStartDate || undefined,
  PaperlessDiscount: Boolean(state.Payment.PaperlessDiscount),
  JoinDirectDebit: Boolean(state.Payment.JoinDirectDebit),
  BankAccountNumber:
    state.Payment.DirectDebitDetails.BankAccountNumber || undefined,
  AgreeWithTermsAndConditions: Boolean(
    state.Payment.DirectDebitDetails.IsSoleSignatory &&
      state.Payment.DirectDebitDetails.AgreeWithTermsAndConditions
  ),
  AaSmartFuel: state.Payment.AaSmartFuel || undefined,
  HasAaSmartFuel: Boolean(state.Payment.HasAaSmartFuel),
  isFuelRewards: getIsFuelRewards(state),
  isElectricityOnly: getIsElectricityOnly(state),
  isPrepayEligible: state.Customer.CustomerInfo.PrepayEligible,
  isWeeklyFortnightlyEligible: state.Customer.CustomerInfo.WfEligible,
  isCallingAPI: state.Auth.isCallingAPI,
  addressDetails: state.Property.PropertyInfo?.AddressDetails,
  customer: state.Customer.CustomerInfo,
  bankAccountNumber: state.Payment.DirectDebitDetails.BankAccountNumber,
  sessionId: state.Auth.sessionID,
});

const mapDispatchToProps = (dispatch) => {
  return {
    createBP: (data) => {
      dispatch(updateCreateBP(data));
    },
    saveCustomerData: (values) => {
      const joinDirectDebit = Boolean(values.JoinDirectDebit);
      const directDebitDetails = joinDirectDebit
        ? {
            BankAccountNumber: values.BankAccountNumber,
            IsSoleSignatory: values.AgreeWithTermsAndConditions,
            AgreeWithTermsAndConditions: values.AgreeWithTermsAndConditions,
          }
        : {
            BankAccountNumber: "",
            IsSoleSignatory: "",
            AgreeWithTermsAndConditions: "",
          };
      const details = {
        Payment: {
          PaymentMethod: values.PaymentMethod || null,
          PrePayMobileNumber: values.PrePayMobileNumber || null,
          BillingCycle: values.BillingCycle || null,
          BillStartDate: values.BillStartDate || null,
          PaperlessDiscount: values.PaperlessDiscount,
          JoinDirectDebit: joinDirectDebit,
          AaSmartFuel: values.AaSmartFuel || null,
          HasAaSmartFuel: Boolean(values.HasAaSmartFuel),
          DirectDebitDetails: directDebitDetails,
        },
      };
      dispatch(saveCustomerData(details));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
