import Decimal from "decimal.js";
import _uniqBy from "lodash/uniqBy";

export const getUnit = (currencyType, time) => {
  const timeDisplay = time.toLowerCase().replace(/kwh/, "kWh");

  return `${currencyType.toLowerCase()}${timeDisplay}`;
};
export const getDisplayRate = (currencyType, charge) =>
  currencyType.toLowerCase().includes("dollars") ? `$${charge}` : `${charge}c`;

export const withUserType = (rates, UserType) =>
  _uniqBy(rates, "PriceKeyDesc").map((rate) => ({
    Name: rate.PriceKeyDesc,
    UserType,
    Rate: getDisplayRate(rate.CurrType1, new Decimal(rate.Charge).toDP(3)),
    Unit: getUnit(rate.CurrType1, rate.UomTimeCat),
  }));
