export const PriceFixture = {
  pricingAvailable: "Y",
  total: 5,
  priceList: [
    {
      BillingClass: "RESI",
      Brand: "01",
      CampaignEnd: "99991231",
      CampaignId: "R5BASO00",
      CampaignStart: "20190101",
      Charge: "27.80000000",
      ChargeGst: "31.97000000",
      ChargePpd: "27.80000000",
      ChargePpdGst: "31.97000000",
      CurrType1: "Cents",
      CurrType2: "Cents",
      CurrType3: "Cents",
      CurrType4: "Cents",
      Division: "01",
      Metercodedesc: "ANYTIME",
      OrderNo: "",
      PriceChngSch: "N",
      PriceKey: "C17_HYANN4",
      PriceKeyDesc: "Anytime Less than 15kVA (Low User)",
      PriceValidFrom: "20190101",
      PriceValidTo: "99991231",
      Priceplandes: "ANYTIME:",
      RateFactGroup: "17HAWRPERM",
      RateType: "ERANYTIM",
      RateTypeDesc: "Anytime",
      RegPriceCateg: "H-M11",
      Registercode1: "UN-24",
      RtFctgrpTxt: "1) Residential Permanent",
      UomTimeCat: "/KWH",
    },
    {
      BillingClass: "RESI",
      Brand: "01",
      CampaignEnd: "99991231",
      CampaignId: "R5BASO00",
      CampaignStart: "20190101",
      Charge: "22.30000000",
      ChargeGst: "25.64500000",
      ChargePpd: "22.30000000",
      ChargePpdGst: "25.64500000",
      CurrType1: "Cents",
      CurrType2: "Cents",
      CurrType3: "Cents",
      CurrType4: "Cents",
      Division: "01",
      Metercodedesc: "ANYTIME AND ECONOMY",
      OrderNo: "",
      PriceChngSch: "N",
      PriceKey: "C17_HYECN4",
      PriceKeyDesc: "Economy Less than 15kVA (Low User)",
      PriceValidFrom: "20190101",
      PriceValidTo: "99991231",
      Priceplandes: "ANYTIME AND ECONOMY:",
      RateFactGroup: "17HAWRPERM",
      RateType: "ERECNOMY",
      RateTypeDesc: "Economy",
      RegPriceCateg: "H-M11",
      Registercode1: "CN-17",
      RtFctgrpTxt: "1) Residential Permanent",
      UomTimeCat: "/KWH",
    },
    {
      BillingClass: "RESI",
      Brand: "01",
      CampaignEnd: "99991231",
      CampaignId: "R5BASO00",
      CampaignStart: "20190101",
      Charge: "27.80000000",
      ChargeGst: "31.97000000",
      ChargePpd: "27.80000000",
      ChargePpdGst: "31.97000000",
      CurrType1: "Cents",
      CurrType2: "Cents",
      CurrType3: "Cents",
      CurrType4: "Cents",
      Division: "01",
      Metercodedesc: "ANYTIME AND ECONOMY",
      OrderNo: "",
      PriceChngSch: "N",
      PriceKey: "C17_HYANN4",
      PriceKeyDesc: "Anytime Less than 15kVA (Low User)",
      PriceValidFrom: "20190101",
      PriceValidTo: "99991231",
      Priceplandes: "ANYTIME AND ECONOMY:",
      RateFactGroup: "17HAWRPERM",
      RateType: "ERANYTIM",
      RateTypeDesc: "Anytime",
      RegPriceCateg: "H-M11",
      Registercode1: "UN-24",
      RtFctgrpTxt: "1) Residential Permanent",
      UomTimeCat: "/KWH",
    },
    {
      BillingClass: "RESI",
      Brand: "01",
      CampaignEnd: "99991231",
      CampaignId: "R5BASO00",
      CampaignStart: "20190101",
      Charge: "30.00000000",
      ChargeGst: "34.50000000",
      ChargePpd: "30.00000000",
      ChargePpdGst: "34.50000000",
      CurrType1: "Cents",
      CurrType2: "Cents",
      CurrType3: "Cents",
      CurrType4: "Cents",
      Division: "01",
      Metercodedesc: "Fixed Daily Charge",
      OrderNo: "",
      PriceChngSch: "N",
      PriceKey: "C17_HYFDN4",
      PriceKeyDesc: "Daily Charge Less than 15kVA (Low User)",
      PriceValidFrom: "20190101",
      PriceValidTo: "99991231",
      Priceplandes: "Fixed Daily Charge:",
      RateFactGroup: "17HAWRPERM",
      RateType: "EDAILYCH",
      RateTypeDesc: "Elec. Daily Charge",
      RegPriceCateg: "H-M11",
      Registercode1: "",
      RtFctgrpTxt: "1) Residential Permanent",
      UomTimeCat: "/Day",
    },
    {
      BillingClass: "RESI",
      Brand: "01",
      CampaignEnd: "99991231",
      CampaignId: "R5BASO00",
      CampaignStart: "20190101",
      Charge: "0.13000000",
      ChargeGst: "0.14950000",
      ChargePpd: "0.13000000",
      ChargePpdGst: "0.14950000",
      CurrType1: "Cents",
      CurrType2: "Cents",
      CurrType3: "Cents",
      CurrType4: "Cents",
      Division: "01",
      Metercodedesc: "Levy Charge",
      OrderNo: "",
      PriceChngSch: "N",
      PriceKey: "RESILEVY",
      PriceKeyDesc: "Electricity Authority Levy",
      PriceValidFrom: "20170901",
      PriceValidTo: "99991231",
      Priceplandes: "Levy Charge:",
      RateFactGroup: "17HAWRPERM",
      RateType: "LEVY",
      RateTypeDesc: "Levy Charge",
      RegPriceCateg: "LEVY",
      Registercode1: "",
      RtFctgrpTxt: "1) Residential Permanent",
      UomTimeCat: "/KWH",
    },
  ],
};
