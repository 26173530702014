// @flow
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import {
  ArrowDown,
  Button,
  Checkbox,
  ExpandableCard,
  Input,
  Address,
  CheckboxList,
  AutoCompleteInput,
} from "react-components";

import "./BroadbandOrder.scss";

import { Broadband as Constants } from "../../Config/Constants";
import { saveBroadbandData } from "../../Redux/Actions/iJoin/BroadbandActions";
import { useStateFromProp } from "../../Utils/hooks";
import { getPropertyAddress } from "../../Utils/selectors";

type Props = {
  hasBroadbandProvider?: boolean,
  broadbandProvider?: string,
  accountHolderName?: string,
  accountNumber?: string,
  modemDeliveredToDifferentAddress?: boolean,
  deliveryAddress?: string,
  addPhoneLine?: boolean,
  homePhoneNumber?: string,
  selectedPhoneLineOptions?: array,
  moniker?: string,
  isCurrentCardOpen?: boolean,
  isCardDisabled?: boolean,
  saveBroadbandData?: () => void,
  onCardClick?: () => void,
  onClick: () => void,
};

export const BroadbandOrder = (props: Props) => {
  const [hasBroadbandProvider, setHasBroadbandProvider] = useStateFromProp(
    props.hasBroadbandProvider,
    false
  );
  const [broadbandProvider, setBroadbandProvider] = useStateFromProp(
    props.broadbandProvider,
    ""
  );
  const [broadbandProviderHasError, setBroadbandProviderHasError] = useState(
    false
  );
  const [accountHolderName, setAccountHolderName] = useStateFromProp(
    props.accountHolderName,
    ""
  );
  const [accountHolderNameHasError, setAccountHolderNameHasError] = useState(
    false
  );
  const [accountNumber, setAccountNumber] = useStateFromProp(
    props.accountNumber,
    ""
  );
  const [accountNumberHasError, setAccountNumberHasError] = useState(false);
  const [
    modemDeliveredToDifferentAddress,
    setModemDeliveredToDifferentAddress,
  ] = useStateFromProp(props.modemDeliveredToDifferentAddress, false);
  const [deliveryAddress, setDeliveryAddress] = useStateFromProp(
    props.deliveryAddress,
    ""
  );
  const [deliveryAddressHasError, setDeliveryAddressHasError] = useState(false);
  const [moniker, setMoniker] = useStateFromProp(props.moniker, "");
  const [addPhoneLine, setAddPhoneLine] = useStateFromProp(
    props.addPhoneLine,
    false
  );
  const [homePhoneNumber, setHomePhoneNumber] = useStateFromProp(
    props.homePhoneNumber,
    ""
  );
  const [homePhoneNumberHasError, setHomePhoneNumberHasError] = useState(false);
  const [phoneLineOptions, setPhoneLineOptions] = useState(
    Constants.addonsOptions.map((o) => ({ ...o, checked: false }))
  );
  const [validateItself, setValidateItself] = useState(false);

  const providerOptions = Constants.providerList.map((provider) => ({
    name: provider,
  }));

  const isValidDeliveryAddress = deliveryAddress && !deliveryAddressHasError;
  const invalidBroadbandProvider =
    hasBroadbandProvider &&
    (!broadbandProvider ||
      broadbandProviderHasError ||
      !accountHolderName ||
      accountHolderNameHasError ||
      !accountNumber ||
      accountNumberHasError);
  const invalidModemDelivery =
    modemDeliveredToDifferentAddress && !isValidDeliveryAddress;
  const invalidPhoneLine =
    addPhoneLine && (!homePhoneNumber || homePhoneNumberHasError);
  const isContinueBtnDisable =
    invalidBroadbandProvider || invalidModemDelivery || invalidPhoneLine;

  useEffect(() => {
    const newPhoneLineOptions = Constants.addonsOptions.map((addon) => {
      return {
        ...addon,
        checked: props.selectedPhoneLineOptions.includes(addon.code),
      };
    });
    setPhoneLineOptions(newPhoneLineOptions);
  }, [props.selectedPhoneLineOptions]);

  const continueButtonClickedHandler = () => {
    const broadbandId = props.broadbandId;
    const broadbandFullName = props.broadbandFullName;
    props.saveBroadbandData({
      hasBroadbandProvider,
      broadbandProvider,
      accountHolderName,
      accountNumber,
      modemDeliveredToDifferentAddress,
      deliveryAddress,
      moniker,
      addPhoneLine,
      homePhoneNumber,
      phoneLineOptions,
      broadbandId,
      broadbandFullName,
    });
    props.onClick();
  };

  // The following 2 checkboxs are mutually exclusive. (Landline to Mobile call 100 minutes, Landline to Mobile call 200 minutes)
  const phoneLineOptionsChangedHandler = (index) => {
    if (index === 0) {
      const newPhoneLineOptions = [...phoneLineOptions];
      newPhoneLineOptions[0].checked = !newPhoneLineOptions[0].checked;
      setPhoneLineOptions(newPhoneLineOptions);
    } else {
      setPhoneLineOptions(
        phoneLineOptions.map((opt, idx) =>
          idx === 0
            ? opt
            : idx === index
            ? { ...opt, checked: !opt.checked }
            : { ...opt, checked: false }
        )
      );
    }
  };

  const hasBroadbandProviderCheckboxChangeHandler = () => {
    setHasBroadbandProvider(!hasBroadbandProvider);
    setBroadbandProvider("");
    setBroadbandProviderHasError(false);
    setAccountHolderName("");
    setAccountHolderNameHasError(false);
    setAccountNumber("");
    setAccountNumberHasError(false);
  };

  const modemDeliveredToDifferentAddressCheckboxChangeHandler = () => {
    setModemDeliveredToDifferentAddress(!modemDeliveredToDifferentAddress);
    setDeliveryAddress("");
    setDeliveryAddressHasError(false);
    setMoniker(null);
  };

  const addPhoneLineCheckboxChangeHandler = () => {
    setAddPhoneLine(!addPhoneLine);
    setHomePhoneNumber("");
    setPhoneLineOptions(
      phoneLineOptions.map((opt) => ({ ...opt, checked: false }))
    );
  };

  const getContent = (
    <div className="broadbandOrder">
      <div className="field">
        <Checkbox
          name="hasBroadbandProvider"
          label={Constants.hasBroadbandProviderLabel}
          checked={hasBroadbandProvider}
          onChange={hasBroadbandProviderCheckboxChangeHandler}
        />
      </div>
      {hasBroadbandProvider && (
        <Fragment>
          <div className="subItem">
            <div className="field">
              <AutoCompleteInput
                name="broadbandProvider"
                handleChange={(value) => setBroadbandProvider(value)}
                value={broadbandProvider}
                options={providerOptions}
                labelText={Constants.broadbandProviderLabel}
                maxLength={Constants.broadbandProviderMaxLength}
                handleError={setBroadbandProviderHasError}
                errorMessage={Constants.broadbandProviderRequiredMsg}
                hasError={broadbandProviderHasError}
                required
                validateItself={validateItself}
              />
            </div>
          </div>
          <div className="row subItem">
            <div className="field">
              <Input
                name="accountHolderName"
                handleChange={(value) => setAccountHolderName(value)}
                value={accountHolderName}
                labelText={Constants.accountHolderNameLabel}
                maxLength={Constants.accountHolderNameLabelMaxLength}
                handleError={setAccountHolderNameHasError}
                errorMessage={Constants.accountHolderNameRequiredMsg}
                hasError={accountHolderNameHasError}
                required
                validateItself={validateItself}
              />
            </div>
            <div className="field">
              <Input
                name="accountNumber"
                handleChange={(value) => setAccountNumber(value)}
                value={accountNumber}
                labelText={Constants.accountNumberLabel}
                handleError={setAccountNumberHasError}
                errorMessage={Constants.accountNumberRequiredMsg}
                invalidMessage={Constants.accountNumberInvalidMsg}
                hasError={accountNumberHasError}
                validationType="broadbandAccountNumber"
                required
                validateItself={validateItself}
                maxLength={Constants.accountNumberMaxLength}
              />
            </div>
          </div>
        </Fragment>
      )}

      <div className="field">
        <Checkbox
          name="modemDeliveredToDifferentAddress"
          label={Constants.modemDeliveredToDifferentAddressLabel}
          subLabel={Constants.modemDeliveredToDifferentAddressSubLabel}
          checked={modemDeliveredToDifferentAddress}
          onChange={modemDeliveredToDifferentAddressCheckboxChangeHandler}
        />
      </div>

      {modemDeliveredToDifferentAddress && (
        <div className="subItem">
          <div className="field">
            <Address
              api={{
                baseUrl: process.env.REACT_APP_BASE_URL,
                key: process.env.REACT_APP_X_API_KEY,
              }}
              name="deliveryAddress"
              handleChange={setDeliveryAddress}
              value={deliveryAddress}
              handleMonikerChange={setMoniker}
              labelText={Constants.deliveryAddressLabel}
              handleError={setDeliveryAddressHasError}
              errorMessage={Constants.deliveryAddressRequiredMsg}
              hasError={deliveryAddressHasError}
              required
              validateItself={validateItself}
            />
          </div>
        </div>
      )}

      <div className="field">
        <Checkbox
          name="addPhoneLine"
          label={Constants.addPhoneLineLabel}
          subLabel={Constants.addPhoneLineSubLabel}
          checked={addPhoneLine}
          onChange={addPhoneLineCheckboxChangeHandler}
        />
      </div>
      {addPhoneLine && (
        <Fragment>
          <div className="subItem">
            <div className="field">
              <Input
                name="homePhoneNumber"
                handleChange={(value) => setHomePhoneNumber(value)}
                value={homePhoneNumber}
                placeholder={Constants.homePhoneNumberPlaceholder}
                labelText={Constants.homePhoneNumberLabel}
                handleError={setHomePhoneNumberHasError}
                invalidMessage={Constants.homePhoneNumberInvalidMsg}
                errorMessage={Constants.homePhoneNumberRequiredMsg}
                hasError={homePhoneNumberHasError}
                validationType="phoneNumber"
                required
                validateItself={validateItself}
              />
            </div>
          </div>
          <div className="subItem">
            <div className="broadbandOrder_checkbox">
              <CheckboxList
                name="phoneLineOptions"
                title={Constants.phoneLineOptionsLabel}
                onChange={phoneLineOptionsChangedHandler}
                options={phoneLineOptions}
              />
            </div>
          </div>
        </Fragment>
      )}

      <div className="commonButton">
        <Button
          name="broadbandContinueButton"
          type="button"
          className="continue-button"
          text={Constants.buttonLabel}
          handleClick={continueButtonClickedHandler}
          primaryOnLight
          disabled={isContinueBtnDisable}
          handleValidationClick={() => setValidateItself(true)}
        />
      </div>
    </div>
  );

  const getPreview = (
    <div>
      {broadbandProvider && (
        <div className="field">
          {Constants.previewBroadbandProvider}{" "}
          <span className="bold">{broadbandProvider}</span>
        </div>
      )}

      {accountHolderName && (
        <div className="field">
          {Constants.previewAccountHolderName}{" "}
          <span className="bold">{accountHolderName}</span>
        </div>
      )}

      {accountNumber && (
        <div className="field">
          {Constants.previewAccountNumber}{" "}
          <span className="bold">{accountNumber}</span>
        </div>
      )}

      <div className="field">
        {Constants.previewDeliveryAddress}
        <span className="bold">
          {isValidDeliveryAddress ? deliveryAddress : props.customerAddress}
        </span>
      </div>

      {homePhoneNumber && (
        <div className="field">
          {Constants.previewHomePhoneNumber}{" "}
          <span className="bold">{homePhoneNumber}</span>
        </div>
      )}

      {phoneLineOptions &&
        phoneLineOptions.map(
          (opt, index) =>
            opt.checked && (
              <div className="field" key={index}>
                {Constants.previewPhoneLineOptions}{" "}
                <span className="bold">{opt.label}</span>
              </div>
            )
        )}
    </div>
  );

  const getHeader = {
    cardTitle: Constants.cardTitle,
    cardContent: getPreview,
    cardIcon: <ArrowDown />,
    cardIsSelected: false,
    cardLink: true,
  };

  return (
    <Fragment>
      <div id="BroadbandOrder" className="cardId" />
      <ExpandableCard
        name="broadbandOrderCard"
        headerContent={getHeader}
        content={getContent}
        isOpen={props.isCurrentCardOpen}
        disabled={props.isCardDisabled}
        handleClick={props.onCardClick}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  hasBroadbandProvider: state.BroadbandInfo.HasBroadbandProvider,
  broadbandProvider: state.BroadbandInfo.BroadbandProvider
    ? state.BroadbandInfo.BroadbandProvider
    : state.BroadbandInfo.BroadbandProviderOther,
  accountHolderName: state.BroadbandInfo.AccountHolderName,
  accountNumber: state.BroadbandInfo.AccountNumber,
  modemDeliveredToSameAddress: !state.BroadbandInfo
    .ModemDeliveredToDifferentAddress,
  deliveryAddress: state.BroadbandInfo.DeliveryAddress,
  addPhoneLine: state.BroadbandInfo.AddPhoneLine,
  homePhoneNumber: state.BroadbandInfo.HomePhoneNumber,
  selectedPhoneLineOptions: state.BroadbandInfo.PhoneLineOptions,
  customerAddress: getPropertyAddress(state),
  broadbandId: state.BroadbandInfo.Id,
  broadbandFullName: state.BroadbandInfo.Name,
});

const mapDispatchToProps = (dispatch) => {
  return {
    saveBroadbandData: (values) => {
      const broadband = {
        BroadbandId: values.broadbandId,
        BroadbandFullName: values.broadbandFullName,
        HasBroadbandProvider: values.hasBroadbandProvider,
        BroadbandProvider: Constants.providerList.includes(
          values.broadbandProvider
        )
          ? values.broadbandProvider
          : "",
        BroadbandProviderOther: Constants.providerList.includes(
          values.broadbandProvider
        )
          ? ""
          : values.broadbandProvider,
        AccountHolderName: values.accountHolderName,
        AccountNumber: values.accountNumber,
        ModemDeliveredToSameAddress: !values.modemDeliveredToDifferentAddress,
        DeliveryAddress: values.deliveryAddress,
        Moniker: values.moniker,
        AddPhoneLine: values.addPhoneLine,
        HomePhoneNumber: values.homePhoneNumber,
        PhoneLineOptions: values.phoneLineOptions
          .filter((option) => option.checked)
          .map((option) => option.code),
        PhoneLineOptionsFullName: values.phoneLineOptions
          .filter((option) => option.checked)
          .map((option) => option.label),
      };
      dispatch(saveBroadbandData(broadband));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BroadbandOrder);
