import * as actionTypes from "../../Actions/actionTypes";

const initialState = {
  isFetching: false,
  isError: false,
  errorMessage: "",
  errorDetails: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_BP_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
        isError: false,
        errorMessage: "",
      };
    case actionTypes.UPDATE_BP_FETCHED:
      return {
        ...state,
        isFetched: action.isFetched,
        isError: false,
        errorMessage: "",
      };
    case actionTypes.UPDATE_BP_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        isError: true,
        errorMessage: action.errorMessage,
        errorDetails: action.errorDetails,
      };
    default:
      return state;
  }
};

export default reducer;
