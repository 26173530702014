import axios from "axios";
import _now from "lodash/now";
import { RatesUtil } from "react-components";
import uuidv4 from "uuid/v4";
import { withUserType } from "../../Containers/CSRJouney/RatesUtils";
import * as actionTypes from "./actionTypes";
import { rehydratedCustomerData } from "./iJoin/CustomerActions";
import { clearSessionId } from "./PersistActions";
import { updateMyDetails } from "./iJoin/MyDetailsActions";
import {
  updateBP,
  createBA,
  updateCreditCheck,
  saveForLaterFailure,
  saveForLaterSuccess,
  saveForLaterRequest,
} from "./CSRAgent/JoinAction";
import {
  updateCreditCheckResult,
  updateCreditCheckFetching,
  updateCreditCheckError,
} from "./CSRAgent/CreditCheckAction";
import {
  updateBPFetching,
  updateBPFetched,
  updateBPError,
} from "./CSRAgent/AboutYouAction";
import { updateSubmittingJourney } from "./CSRAgent/JoinDetailsAction";
import * as Analytics from "../../Utils/analytics";
import {
  USAGE_TYPE_STANDARD,
  USAGE_TYPE_LOW,
  BASIC_PLAN,
  SERVICE_ID_PIPED_GAS,
  CUSTOMER_TYPE,
} from "../../Config/Constants";
import { getBrowserAndVersion } from "../../Utils/browserVersion";
import { LoginFixture } from "../Fixtures/express/LoginResponse";
import { AddressDetailsFixture } from "../Fixtures/express/AddressDetailsResponse";
import { IdentityCheckFixture } from "../Fixtures/express/IdentityCheckResponse";
import { ElectricityLookupFixture } from "../Fixtures/express/ElectricityLookupResponse";
import { ElectricityManualLookupFixture } from "../Fixtures/express/ElectricityManualLookupResponse";
import { IcpLookupFixture } from "../Fixtures/express/IcpLookupResponse";
import { PriceFixture } from "../Fixtures/express/PriceResponse";
import {
  Express as Constants,
  AgentJoin,
  SaveForLater as SaveForLaterConstants,
} from "../../Config/Constants";
import { apiResolveError } from "../../Utils/ApiUtils";
import { saveForLaterCustomerList } from "../../Transformers/SaveForLaterTransformation";

const form_key_nps = "nps.generic.feedback";

const stateUrl = process.env.REACT_APP_BASE_URL + "/state/";
const submitUrl = process.env.REACT_APP_BASE_URL + "/submit-journey/";
const npsURL = process.env.REACT_APP_BASE_URL + "/forms/generic/";
const loginUrl = process.env.REACT_APP_BASE_URL + "/login/";
const userProfileUrl = process.env.REACT_APP_BASE_URL + "/mcfu/profile";
const addressDetailsUrl =
  process.env.REACT_APP_BASE_URL + "/address-finder/details/";
const electricityLookupUrl =
  process.env.REACT_APP_BASE_URL + "/electricity-registry/electricity-lookup/";
const icpLookupUrl =
  process.env.REACT_APP_BASE_URL + "/electricity-registry/icp-lookup/";
const electricityManualSearchUrl =
  process.env.REACT_APP_BASE_URL + "/electricity-registry/manual-search";
const priceUrl = process.env.REACT_APP_BASE_URL + "/price";
const plansUrl = process.env.REACT_APP_BASE_URL + "/plans";
const identityCheckUrl = process.env.REACT_APP_BASE_URL + "/identity-check/";
const broadbandUrl = process.env.REACT_APP_BASE_URL + "/devoli/broadband";
const promoCodeUrl = process.env.REACT_APP_BASE_URL + "/crm/validate-promo";
const addressSearchUrl = process.env.REACT_APP_BASE_URL + "/address-cached/";
const sitcoContentUrl = process.env.REACT_APP_BASE_URL + "/crm/content/";
const priceEstimationUrl = process.env.REACT_APP_BASE_URL + "/price-estimation";
// const identifyUrl = process.env.REACT_APP_BASE_URL + '/identify';
const createUpdateAccountUrl =
  process.env.REACT_APP_BASE_URL + "/create-account/business-partner";
const createUpdateBaUrl =
  process.env.REACT_APP_BASE_URL + "/create-account/business-agreement";
const creditCheckUrl =
  process.env.REACT_APP_BASE_URL + "/create-account/credit-check";
const csrLoginUrl = process.env.REACT_APP_BASE_URL + "/csr-login";
const redirectAuthUrl =
  process.env.REACT_APP_SIGN_UP_URL + "/auth/cognito/callback";
const billingEligibilityUrl =
  process.env.REACT_APP_BASE_URL + "/billing-eligibility";
const gasAddressSearchUrl =
  process.env.REACT_APP_BASE_URL + "/gas-registry/address-search";
const gasIcpSearchUrl =
  process.env.REACT_APP_BASE_URL + "/gas-registry/icp-search";
const broadbandAddressSearchUrl =
  process.env.REACT_APP_BASE_URL + "/devoli/search-address";
const broadbandOfferingsUrl =
  process.env.REACT_APP_BASE_URL + "/devoli/provide-address";

const config = {
  headers: {
    "x-api-key": process.env.REACT_APP_X_API_KEY,
    "Content-Type": "application/json",
  },
};

const basicPlanParams = {
  division: "01",
  billingClass: "RESI",
};

export const redirectToAgentLogin = (redirectUrl) => {
  return (dispatch) => {
    const url = `${csrLoginUrl}?redirect=${redirectAuthUrl}`;
    return axios.get(url, config).then((response) => {
      window.location.href = response.data.url;
    });
  };
};

export const getAgentToken = (grantToken, redirectUrl, userAuth) =>
  axios.get(
    `${csrLoginUrl}/${grantToken}?redirect=${redirectAuthUrl}&sessionToken=${userAuth}`,
    config
  ); //TODO change to test 1 url

export const apiError = () => ({
  type: actionTypes.API_ERROR,
});

export const updateMyDetailsFail = (error, is404 = false) => {
  if (is404) {
    return [clearSessionId(), apiError()];
  }
  return apiError();
};

export const updateMyDetailsSuccess = () => {
  return { type: actionTypes.API_SUCCESS };
};

export const submitSuccess = () => ({
  type: actionTypes.API_SUBMIT_SUCCESS,
});

export const callAPI = () => {
  return { type: actionTypes.CALL_API };
};

export const getApiState = () => (dispatch, getState) => {
  const sessionId = getState().Auth.sessionID;
  if (sessionId) {
    dispatch(rehydratedCustomerData(false));
    dispatch(callAPI());
    axios
      .get(stateUrl + sessionId, config)
      .then((response) => response.data)
      .then((data) => {
        dispatch(updateMyDetails(data));
        dispatch(updateMyDetailsSuccess());
        dispatch(rehydratedCustomerData(true));
      })
      .catch((error) => {
        dispatch(
          updateMyDetailsFail(
            error,
            error.response && error.response.status === 404
          )
        );
      });
  } else {
    dispatch(updateMyDetailsFail());
  }
};

export const saveApiState = (apiData, submit = false) => {
  return (dispatch, getState) => {
    const state = getState();
    const data = {
      ...apiData,
      sessionID: state.Auth.sessionID,
      deleteAfter: state.Auth.deleteAfter,
    };
    dispatch(callAPI());
    return axios
      .put(stateUrl, data, config)
      .then(() => {
        if (submit) {
          return dispatch(submitToJourneyApi());
        } else {
          dispatch(updateMyDetailsSuccess());
        }
      })
      .catch((error) => {
        dispatch(
          updateMyDetailsFail(
            error,
            error.response && error.response.status === 404
          )
        );
      });
  };
};

export const createApiState = (apiData) => {
  return (dispatch, getState) => {
    const state = getState();
    const data = {
      ...apiData,
      sessionID: state.Auth.sessionID,
      deleteAfter: state.Auth.deleteAfter,
    };
    dispatch(callAPI());
    return axios
      .post(stateUrl, data, config)
      .then(() => {
        return dispatch(submitToJourneyApi(apiData));
      })
      .catch((error) => {
        dispatch(
          updateMyDetailsFail(
            error,
            error.response && error.response.status === 404
          )
        );
      });
  };
};

const withFixture = (fixture, apiCall) => (requestPayload) =>
  process.env.REACT_APP_USE_FIXTURES
    ? Promise.resolve(fixture)
    : apiCall(requestPayload);

export const loginUser = withFixture(LoginFixture, (requestPayload) =>
  axios.post(loginUrl, requestPayload, config)
);

export const getAddressDetails = withFixture(AddressDetailsFixture, (id) =>
  axios.get(addressDetailsUrl + id, config)
);

export const getElectricityDetailsManualSearch = withFixture(
  ElectricityManualLookupFixture,
  (queryParams) =>
    axios.get(electricityManualSearchUrl, { ...config, params: queryParams })
);

export const getElectricityDetails = withFixture(
  ElectricityLookupFixture,
  (requestPayload) =>
    axios.post(
      electricityLookupUrl,
      {
        ...requestPayload,
        format: "websphere",
      },
      config
    )
);

const formatDateToAPIString = (date: string) => {
  const d = new Date(date);
  return `${d.getDate()}${d.getMonth() + 1}${d.getFullYear()}`;
};
export const getGasRates = (gasIcp, plan) => {
  return getGasIcp({ icp: gasIcp }).then(({ data }) => {
    const payload = {
      packCode: plan.PackCode,
      icpNumber: data.icp,
      startDate: formatDateToAPIString(plan.StartDate), // NOTE: format is a bit strange: DDMMYYYY, no separators
      billingClass: "RESI", // NOTE: Hardcoded by design
      division: "02", // NOTE: Hardcoded, 02 = GAS, 01 = ELEC
      campaignId: plan.CampaignId,
      priceCategoryCode: data.priceCategoryCode,
      gxpNumber: data.gxpNumber,
      networkCode: data.networkCode,
    };

    return Promise.all([
      axios.post(
        priceUrl,
        { ...payload, electricityUserType: USAGE_TYPE_LOW },
        config
      ),
      axios.post(
        priceUrl,
        { ...payload, electricityUserType: USAGE_TYPE_STANDARD },
        config
      ),
    ]).then(([{ data: lowPricing }, { data: standardPricing }]) => {
      const rates = RatesUtil.getRates([
        {
          Rates: [
            ...withUserType(
              lowPricing.priceList.filter((rate) => rate.Charge > 0),
              USAGE_TYPE_LOW
            ),
            ...withUserType(
              standardPricing.priceList.filter((rate) => rate.Charge > 0),
              USAGE_TYPE_STANDARD
            ),
          ],
          Service: SERVICE_ID_PIPED_GAS,
        },
      ]);

      return rates;
    });
  });
  // TODO: This needs to get rates via POST /price endpoint
  // return axios.post(priceEstimationUrl, requestPayload, config)
  //   .then((response) => {
  //     console.log("API :: getGasRates :: response", response.data.ServiceRates.find(item => item.Service === 'GAS'))
  //     return response.data.ServiceRates.find(item => item.Service === 'GAS');
  //   })
};

export const updateCreateBP = (stateData) => (dispatch, getState) => {
  const state = getState();
  const apiData = {
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      "access-token": state.Login.token,
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(createUpdateAccountUrl, stateData, apiData)
    .then((response) => {
      dispatch(updateBP(response.data));
      dispatch(updateBPFetching(false));
      dispatch(updateBPFetched(true));
    })
    .catch((error) => {
      dispatch(
        updateBPError(
          apiResolveError(
            {
              statusCode: error?.response?.status,
              serviceResponse: error?.response,
            },
            "bp",
            state
          ),
          error.response?.data?.error
        )
      );
    });
};

export const createBusinessAccountNumber = (stateData) => (
  dispatch,
  getState
) => {
  const state = getState();
  const apiData = {
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      "access-token": state.Login.token,
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(createUpdateBaUrl, stateData, apiData)
    .then((response) => {
      dispatch(createBA(response.data.ba));
      dispatch(updateBP(response.data.bp));
    })
    .catch((error) => {
      dispatch(
        updateMyDetailsFail(
          error,
          error.response && error.response.status === 404
        )
      );
    });
};

export const checkCredit = (requestPayload, token) => (dispatch) => {
  if (requestPayload && token) {
    const apiData = {
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        "access-token": token,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(creditCheckUrl, requestPayload, apiData)
      .then((response) => {
        dispatch(updateCreditCheckResult(response.data));
        dispatch(updateCreditCheckFetching(false));
        dispatch(updateCreditCheck());
      })
      .catch((error) => {
        dispatch(
          updateCreditCheckError(
            apiResolveError(
              {
                statusCode: error.response ? error.response.status : null,
                serviceResponse: error.response ? error.response : null,
              },
              "cc"
            )
          )
        );
      });
  }
};

export const getElecRegion = (requestPayload) =>
  axios
    .post(priceEstimationUrl, requestPayload, config)
    .then((response) => response.data.Region);

export const getIcpDetails = withFixture(IcpLookupFixture, (requestPayload) =>
  axios.post(icpLookupUrl, requestPayload, config)
);

export const getPrice = withFixture(PriceFixture, (requestPayload) =>
  axios.post(priceUrl, requestPayload, config)
);

export const getRates = (icp, planId) =>
  getIcpDetails({ icp })
    .then((response) =>
      Promise.all([
        {
          icpNumber: icp,
          gxpNumber: response.data.icp.gxpNumber,
          networkCode: response.data.icp.networkCode,
          priceCategoryCode: response.data.icp.priceCategoryCode,
          registerContentCodes: response.data.icp.registerContentCodes,
          startDate: _now(),
          ...basicPlanParams,
        },
        axios.get(plansUrl, config),
      ])
    )
    .then(([request, response]) => {
      if (response.data && response.data.Plans) {
        const plan = response.data.Plans.find((plan) => plan.Id === planId);
        const basicPlan = response.data.Plans.find(
          (plan) => plan.Id === BASIC_PLAN
        );
        const currentPlan = plan ?? basicPlan;
        return {
          ...request,
          campaignId: currentPlan.CampaignId,
          packCode: currentPlan.PackCode,
        };
      }
      return request;
    })
    .then((request) =>
      Promise.all([
        getPrice({ ...request, electricityUserType: USAGE_TYPE_STANDARD }),
        getPrice({ ...request, electricityUserType: USAGE_TYPE_LOW }),
      ])
    );

export const getDefaultPlans = () => {
  return axios.get(plansUrl, config).then((response) => {
    return response.data.Plans;
  });
};

export const getBroadbandAddress = (params?: {
  pxid?: string,
  address?: string,
}) => {
  if (!params) {
    console.error(
      "Cannot call getBroadbandAddress without pxid or address params."
    );
    return Promise.resolve(null);
  }

  return axios
    .get(broadbandAddressSearchUrl, { ...config, params })
    .then((response) => response.data);
};

export const getBroadbandInfo = (
  params: { planId: string, selectedServices: string[] },
  payload
) => {
  if (!payload || !params) {
    console.error(
      "Cannot call getBroadbandInfo without an params and payload."
    );
    return Promise.resolve(null);
  }
  const { planId, selectedServices } = params;
  return axios
    .post(broadbandOfferingsUrl, payload, {
      ...config,
      params: { planId, services: selectedServices.join() },
    })
    .then((response) => response.data);
};

// Note: No longer used in CSR Join
export const getBroadbandOffers = (
  services,
  planId,
  isManualAddress,
  address,
  pxid
) => {
  let addressPxidParams = "";
  if (isManualAddress) {
    addressPxidParams = `&address=${address}`;
  } else {
    addressPxidParams = `&pxid=${pxid}`;
  }
  const url =
    broadbandUrl + `?services=${services}&planId=${planId}` + addressPxidParams;
  return axios.get(url, config);
};

export const validatePromoCode = (promoCode) => {
  const url = promoCodeUrl + `?promoCode=${promoCode}`;
  return axios.get(url, config).then((response) => {
    return response.data;
  });
};

export const getAddressIcp = (address) => {
  const url = addressSearchUrl + `search?Query=${address}`;
  return axios.get(url, config);
};

export const getGasAddress = (queryParams) => {
  return axios.get(gasAddressSearchUrl, { ...config, params: queryParams });
};

export const getGasIcp = (queryParams) => {
  return axios.get(gasIcpSearchUrl, { ...config, params: queryParams });
};

export const getSitcorePlans = (agent) => {
  const urlCustomer =
    sitcoContentUrl +
    "ijoin-react-app%2Fhome%2Fresidential%2Ffind-a-plan-react";
  const urlAgent =
    sitcoContentUrl +
    "ijoin-react-app%2Fhome%2Fresidential%2Ffind-a-plan-csr-react";
  const url = agent ? urlAgent : urlCustomer;

  return axios.get(url, config);
};
export const getUserProfile = (token) => {
  const browser = getBrowserAndVersion();
  const profileConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: token,
      "Content-Type": "application/json",
      "X-Contact-Source": "Journey",
      "X-Contact-Platform": browser.name,
      "X-Contact-Platform-Version": browser.version,
    },
  };
  return axios.post(userProfileUrl, null, profileConfig);
};

export const getUserAccount = (accountId, token) => {
  const browser = getBrowserAndVersion();
  const url = userProfileUrl + "?ContractAccountID=" + accountId;
  const profileConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: token,
      "Content-Type": "application/json",
      "X-Contact-Source": "Journey",
      "X-Contact-Platform": browser.name,
      "X-Contact-Platform-Version": browser.version,
    },
  };
  return axios.post(url, null, profileConfig);
};

export const getBillinEligibility = (data) => {
  return axios.post(billingEligibilityUrl, data, config);
};

const submitToJourneyApi = (stateData) => {
  return (dispatch, getState) => {
    const state = getState();
    const apiData = {
      sessionID: state.Auth.sessionID,
      customerType: "Residential",
      journeyType: state.Confirmation.JourneyType,
    };
    return axios
      .post(submitUrl, apiData, config)
      .then(() => {
        dispatch(updateSubmittingJourney(false));
        if (state.Confirmation.JourneyType === Constants.expressJourneyType) {
          Analytics.triggerExpressMoveSubmitted(
            stateData.MedicalInfo.HasVulnerablePerson,
            stateData.MedicalInfo.HasMedicalDependant,
            stateData.PropertyInfo.Hazards
          );
        }
        dispatch(submitSuccess());
      })
      .catch((error) => {
        dispatch(updateSubmittingJourney(false));
        dispatch(
          updateMyDetailsFail(
            error,
            error.response && error.response.status === 404
          )
        );
      });
  };
};
export const checkIdentity = withFixture(IdentityCheckFixture, (stateData) =>
  axios.post(identityCheckUrl, stateData, config).catch((error) => {
    // return error?.response;
  })
);

export const deleteStateApiSessionId = () => (dispatch, getState) => {
  const sessionId = getState().Auth.sessionID;
  if (sessionId) {
    return axios.delete(stateUrl + sessionId, config);
  }
};

export const submitToNPSApi = (apiData, formKey = form_key_nps) => {
  return () => {
    const data = {
      formsKey: formKey,
      retainToken: "",
      enterPrizeDraw: "false",
      ...apiData,
    };

    axios
      .post(npsURL + formKey, data, config)
      .then(() => {
        if (apiData.preferredChannel) {
          Analytics.triggerAsyncNPSSubmit(apiData);
        } else {
          Analytics.triggerNPSSubmit(apiData.score, apiData.scoreComments);
        }
      })
      .catch((error) => {
        // go silent and do not notify customer.
      });
  };
};

export const saveCustomerInfo = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    const apiData = {
      ...state,
      sessionID: uuidv4(),
      deleteAfter: new Date().setDate(new Date().getDate() + 1),
      customerType: CUSTOMER_TYPE,
      journeyType: AgentJoin.journeyType,
      type: SaveForLaterConstants.type,
    };
    return axios
      .post(stateUrl, apiData, config)
      .then(() => {
        dispatch(saveForLaterSuccess());
      })
      .catch((error) => {
        dispatch(
          saveForLaterFailure(
            apiResolveError({
              statusCode: error.response ? error.response.status : null,
              serviceResponse: error.response ? error.response : null,
            })
          )
        );
      });
  };
};

export const getCustomerInfo = (customerData) => (dispatch, getState) => {
  dispatch(saveForLaterRequest());
  const state = getState();

  const requestPayload = {
    ...customerData,
    access_token: state.Login.token,
  };

  axios
    .post(stateUrl + "search", requestPayload, config)
    .then((data) => {
      dispatch(
        saveForLaterSuccess(
          saveForLaterCustomerList(data?.data),
          data?.data?.message,
          data?.data?.result?.state
        )
      );
    })
    .catch((error) => {
      dispatch(
        saveForLaterFailure(
          apiResolveError({
            statusCode: error.response ? error.response.status : null,
            serviceResponse: error.response ? error.response : null,
          })
        )
      );
    });
};
