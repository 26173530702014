// @flow
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";

import {
  ArrowDown,
  Button,
  Checkbox,
  ExpandableCard,
  Input,
  Address,
  CheckboxList,
  Modal,
  RadioButton,
  DateUtil,
  DatePicker,
} from "react-components";

import "./Property.scss";

import {
  Property as Constants,
  SITUATION_MOVING,
  SITUATION_NOT_MOVING,
  SITUATION_CHANGE_OWNERSHIP,
} from "../../Config/Constants";
import { useStateFromProp } from "../../Utils/hooks";
import {
  getHasElectricity,
  getHasBottledGas,
  getPropertyAddress,
  getPostalAddress,
  getServicesStartDate,
  getIsBottleGasOnly,
} from "../../Utils/selectors";
import { savePropertyData } from "../../Redux/Actions/iJoin/PropertyActions";

type Props = {
  address: string,
  addressMoniker?: string,
  postalAddress?: string,
  postalAddressMoniker?: string,
  addressSameAsPostalAddress?: boolean,
  hazards?: Array<string>,
  hazardsOther?: string,
  hasMedicalDependant?: boolean,
  criticalMedicalEquipment?: string,
  medicalDependantFirstName?: string,
  medicalDependantLastName?: string,
  medicalDependantEMailAddress?: string,
  medicalDependantPhone?: string,
  medicalDependantSameAsAccountHolder?: boolean,
  contactHealthPractitioner?: string,
  practiceName?: string,
  practicePhone?: string,
  practitionerName?: string,
  hasVulnerablePerson?: boolean,
  accountStart?: string,
  situation?: string,
  situationOtherDetails?: string,
  hasElectricity: boolean,
  hasBottledGas: boolean,
  isCurrentCardOpen?: boolean,
  isCardDisabled?: boolean,
  savePropertyData?: () => void,
  onClick?: () => void,
};

export const Property = (props: Props) => {
  // Address
  const [address, setAddress] = useStateFromProp(props.address, null);
  const [addressMoniker, setAddressMoniker] = useStateFromProp(
    props.addressMoniker,
    null
  );
  const [addressHasError, setAddressHasError] = useState(false);
  const [
    addressSameAsPostalAddress,
    setAddressSameAsPostalAddress,
  ] = useStateFromProp(props.addressSameAsPostalAddress, false);
  const [postalAddress, setPostalAddress] = useStateFromProp(
    props.postalAddress,
    null
  );
  const [postalAddressMoniker, setPostalAddressMoniker] = useStateFromProp(
    props.postalAddressMoniker,
    null
  );
  const [postalAddressHasError, setPostalAddressHasError] = useState(false);

  // Situation
  const [situation, setSituation] = useState("");
  const [serviceSwitchDate, setServiceSwitchDate] = useStateFromProp(
    props.accountStart,
    DateUtil.getCurrentDate(Constants.dateFormat)
  );
  const [serviceSwitchDateHasError, setServiceSwitchDateHasError] = useState(
    false
  );
  const [situationOtherDetails, setSituationOtherDetails] = useStateFromProp(
    props.situationOtherDetails,
    ""
  );
  const [
    situationOtherDetailsHasError,
    setSituationOtherDetailsHasError,
  ] = useState(false);

  // Vulnerable Person
  const [hasVulnerablePerson, setHasVulnerablePerson] = useStateFromProp(
    props.hasVulnerablePerson,
    true
  );
  const [
    hasVulnerablePersonModalActive,
    setHasVulnerablePersonModalActive,
  ] = useState(false);

  // Medical Dependant
  const [hasMedicalDependant, setHasMedicalDependant] = useStateFromProp(
    props.hasMedicalDependant,
    false
  );
  const [
    medicalDependantDifferentAsAccountHolder,
    setMedicalDependantDifferentAsAccountHolder,
  ] = useStateFromProp(!props.medicalDependantSameAsAccountHolder, false);
  const [
    medicalDependantFirstName,
    setMedicalDependantFirstName,
  ] = useStateFromProp(props.medicalDependantFirstName, "");
  const [
    medicalDependantFirstNameHasError,
    setMedicalDependantFirstNameHasError,
  ] = useState(false);
  const [
    medicalDependantLastName,
    setMedicalDependantLastName,
  ] = useStateFromProp(props.medicalDependantLastName, "");
  const [
    medicalDependantLastNameHasError,
    setMedicalDependantLastNameHasError,
  ] = useState(false);
  const [medicalDependantPhone, setMedicalDependantPhone] = useStateFromProp(
    props.medicalDependantPhone,
    ""
  );
  const [
    medicalDependantPhoneHasError,
    setMedicalDependantPhoneHasError,
  ] = useState(false);
  const [
    medicalDependantEMailAddress,
    setMedicalDependantEMailAddress,
  ] = useStateFromProp(props.medicalDependantEMailAddress, "");
  const [
    medicalDependantEMailAddressHasError,
    setMedicalDependantEMailAddressHasError,
  ] = useState(false);
  const [
    criticalMedicalEquipment,
    setCriticalMedicalEquipment,
  ] = useStateFromProp(props.criticalMedicalEquipment, "");
  const [
    criticalMedicalEquipmentHasError,
    setCriticalMedicalEquipmentHasError,
  ] = useState(false);
  const [
    contactHealthPractitioner,
    setContactHealthPractitioner,
  ] = useStateFromProp(props.contactHealthPractitioner, false);
  const [practiceName, setPracticeName] = useStateFromProp(
    props.practiceName,
    ""
  );
  const [practiceNameHasError, setPracticeNameHasError] = useState(false);
  const [practitionerName, setPractitionerName] = useStateFromProp(
    props.practitionerName,
    ""
  );
  const [practitionerNameHasError, setPractitionerNameHasError] = useState(
    false
  );
  const [practicePhone, setPracticePhone] = useStateFromProp(
    props.practicePhone,
    ""
  );
  const [practicePhoneHasError, setPracticePhoneHasError] = useState(false);
  const [
    hasMedicalDependantModalActive,
    setHasMedicalDependantModalActive,
  ] = useState(false);

  // Hazards
  const [hazards, setHazards] = useState([]);
  const [hazardsOther, setHazardsOther] = useStateFromProp(
    props.hazardsOther,
    ""
  );
  const [hazardsOtherSelected, setHazardsOtherSelected] = useState(false);
  const [hazardsConstants, setHazardsConstants] = useState({});
  const [hazardsCheckboxOptions, setHazardsCheckboxOptions] = useState([]);
  const [hazardsOtherHasError, setHazardsOtherHasError] = useState(false);
  const [previewHazardsContent, setPreviewHazardsContent] = useState("");

  //validation
  const [validateItself, setValidateItself] = useState(false);

  const invalidAddressPart =
    !address ||
    addressHasError ||
    (!addressSameAsPostalAddress && (!postalAddress || postalAddressHasError));
  const invalidSituationPart =
    !props.isBottledGasOnly &&
    (!situation ||
      (situation !== SITUATION_NOT_MOVING &&
        (!serviceSwitchDate || serviceSwitchDateHasError)) ||
      (situation === SITUATION_CHANGE_OWNERSHIP &&
        (!situationOtherDetails || situationOtherDetailsHasError)));
  const invalidMedicalDependantPart =
    hasMedicalDependant &&
    ((medicalDependantDifferentAsAccountHolder &&
      (!medicalDependantFirstName ||
        medicalDependantFirstNameHasError ||
        !medicalDependantLastName ||
        medicalDependantLastNameHasError ||
        !medicalDependantEMailAddress ||
        medicalDependantEMailAddressHasError ||
        !medicalDependantPhone ||
        medicalDependantPhoneHasError)) ||
      !criticalMedicalEquipment ||
      criticalMedicalEquipmentHasError ||
      (contactHealthPractitioner &&
        (!practiceName ||
          practiceNameHasError ||
          !practitionerName ||
          practitionerNameHasError ||
          !practicePhone ||
          practicePhoneHasError)));
  const invalidHazardPart =
    !hazards ||
    hazards.length < 1 ||
    (hazardsOtherSelected && hazardsOther === "");

  const isDisable =
    invalidAddressPart ||
    invalidSituationPart ||
    invalidMedicalDependantPart ||
    invalidHazardPart;

  useEffect(() => {
    const hazards = hazardsCheckboxOptions
      .filter((option) => option.checked)
      .map((option) => option.label);
    setHazards(hazards);
  }, [hazardsCheckboxOptions]);

  useEffect(() => {
    setPreviewHazardsContent(getPreviewHazardsContent(hazards, hazardsOther));
    // eslint-disable-next-line
  }, [hazards, hazardsOther]);

  useEffect(() => {
    setHazards(props.hazards);
  }, [props.hazards]);

  useEffect(() => {
    const mappedHazard = props.hasBottledGas
      ? props.hasElectricity
        ? Constants.hazards.bottleGasWithElectricity
        : Constants.hazards.bottleGasOnly
      : Constants.hazards.electricityOrPipeGasOnly;

    setHazardsConstants(mappedHazard);

    const hazardsOptions = mappedHazard.options.map((option) => {
      const checked = props.hazards.includes(option);
      return {
        checked: checked,
        label: option,
      };
    });

    setHazardsCheckboxOptions(hazardsOptions);

    setHazardsOtherSelected(
      props.hazards.includes(hazardsConstants.optionsOther)
    );
    // eslint-disable-next-line
  }, [props.hasElectricity, props.hasBottledGas, props.hazards]);

  useEffect(() => {
    setSituation(props.situation);
  }, [props.situation]);

  useEffect(() => {
    if (!postalAddress) {
      setPostalAddress("");
      setAddressSameAsPostalAddress(true);
    } else {
      setAddressSameAsPostalAddress(false);
    }
    if (postalAddress && !address) {
      setPostalAddress("");
    }
    // eslint-disable-next-line
  }, [postalAddress]);

  const hazardsCheckboxListChangedHandler = (index) => {
    if (hazardsCheckboxOptions[index].label === hazardsConstants.optionsOther) {
      if (hazardsCheckboxOptions[index].checked) {
        setHazardsOther("");
      }
      setHazardsOtherSelected(!hazardsCheckboxOptions[index].checked);
    }

    let updatedHazardsCheckboxOptions;
    if (
      hazardsCheckboxOptions[index].label === hazardsConstants.optionsNoHazards
    ) {
      updatedHazardsCheckboxOptions = hazardsCheckboxOptions.map((opt) => {
        return opt.label === hazardsConstants.optionsNoHazards
          ? { ...opt, checked: !opt.checked }
          : { ...opt, checked: false };
      });
      setHazardsOther("");
      setHazardsOtherSelected(false);
    } else {
      updatedHazardsCheckboxOptions = hazardsCheckboxOptions.map((opt, idx) => {
        return idx === index
          ? { ...opt, checked: !opt.checked }
          : opt.label === hazardsConstants.optionsNoHazards
          ? { ...opt, checked: false }
          : opt;
      });
    }

    setHazardsCheckboxOptions(updatedHazardsCheckboxOptions);
  };

  const getPreviewHazardsContent = (selectedHazards, value) => {
    let newSelectedHazards = [...selectedHazards];
    if (value && value.trim()) {
      newSelectedHazards.push(value.trim());
    }
    return newSelectedHazards
      .filter((hazard) => hazard !== hazardsConstants.optionsOther)
      .join(", ");
  };

  const addressDifferentAsPostalAddressCheckboxChangedHandler = () => {
    if (!addressSameAsPostalAddress) {
      setPostalAddress("");
      setPostalAddressHasError(false);
    }
    if (!postalAddress) {
      setPostalAddress("");
    }
    setAddressSameAsPostalAddress(!addressSameAsPostalAddress);
  };

  const hasMedicalDependantCheckboxChangedHandler = (value) => {
    if (hasMedicalDependant) {
      clearMedicalDependant();
      setMedicalDependantDifferentAsAccountHolder(false);
      clearCriticalMedicalEquipment();
      clearContactHealthPractitioner();
      setContactHealthPractitioner(false);
    }

    setHasMedicalDependant(!hasMedicalDependant);
  };

  const medicalDependantDifferentAsAccountHolderCheckboxChangedHandler = () => {
    if (medicalDependantDifferentAsAccountHolder) {
      clearMedicalDependant();
    }
    setMedicalDependantDifferentAsAccountHolder(
      !medicalDependantDifferentAsAccountHolder
    );
  };

  const contactHealthPractitionerCheckboxChangedHandler = () => {
    if (contactHealthPractitioner) {
      clearContactHealthPractitioner();
    }
    setContactHealthPractitioner(!contactHealthPractitioner);
  };

  const clearMedicalDependant = () => {
    setMedicalDependantFirstName("");
    setMedicalDependantFirstNameHasError("");
    setMedicalDependantLastName("");
    setMedicalDependantLastNameHasError("");
    setMedicalDependantEMailAddress("");
    setMedicalDependantEMailAddressHasError("");
    setMedicalDependantPhone("");
    setMedicalDependantPhoneHasError("");
  };

  const clearCriticalMedicalEquipment = () => {
    setCriticalMedicalEquipmentHasError("");
    setCriticalMedicalEquipment("");
  };

  const clearContactHealthPractitioner = () => {
    setPractitionerName("");
    setPractitionerNameHasError("");
    setPracticeName("");
    setPracticeNameHasError("");
    setPracticePhone("");
    setPracticePhoneHasError("");
  };

  const setSelectedSituation = (value) => {
    if (value !== situation) {
      setSituation(value);
      setServiceSwitchDateHasError(false);
      setSituationOtherDetails("");
      setSituationOtherDetailsHasError(false);
    }
  };

  const continueButtonClickedHandler = () => {
    const withAnotherSupplier = situation === SITUATION_NOT_MOVING;
    const isMovingHouse = situation === SITUATION_MOVING;

    props.savePropertyData({
      address,
      addressMoniker,
      addressSameAsPostalAddress,
      postalAddress,
      postalAddressMoniker,
      hazards,
      hazardsOther,
      withAnotherSupplier,

      hasMedicalDependant,
      criticalMedicalEquipment,
      medicalDependantFirstName,
      medicalDependantLastName,
      medicalDependantEMailAddress,
      medicalDependantPhone,
      medicalDependantDifferentAsAccountHolder,
      contactHealthPractitioner,
      practiceName,
      practicePhone,
      practitionerName,
      hasVulnerablePerson,

      isMovingHouse,
      serviceSwitchDate,
      situation,
      situationOtherDetails,
    });
    props.onClick();
  };

  const getContent = (
    <div className="property">
      <div className="field">
        <Address
          api={{
            baseUrl: process.env.REACT_APP_BASE_URL,
            key: process.env.REACT_APP_X_API_KEY,
          }}
          name="address"
          labelText={Constants.addressLabel}
          handleChange={setAddress}
          handleMonikerChange={setAddressMoniker}
          value={address}
          maxLength={Constants.addressMaxLength}
          handleError={setAddressHasError}
          hasError={addressHasError}
          errorMessage={Constants.addressRequiredMsg}
          disabled
        />
      </div>
      <div className="property_checkbox">
        <Checkbox
          name="addressDifferentAsPostalAddress"
          label={Constants.addressDifferentAsPostalAddressLabel}
          checked={!addressSameAsPostalAddress}
          onChange={addressDifferentAsPostalAddressCheckboxChangedHandler}
        />
      </div>
      {!addressSameAsPostalAddress && (
        <div className="field subItem">
          <Address
            api={{
              baseUrl: process.env.REACT_APP_BASE_URL,
              key: process.env.REACT_APP_X_API_KEY,
            }}
            name="postalAddress"
            labelText={Constants.postalAddress}
            placeholder={Constants.postalAddressPlaceHolder}
            maxLength={Constants.postalAddressMaxLength}
            handleChange={setPostalAddress}
            handleMonikerChange={setPostalAddressMoniker}
            value={postalAddress}
            handleError={setPostalAddressHasError}
            hasError={postalAddressHasError}
            errorMessage={Constants.postalAddressRequiredMsg}
            required={!addressSameAsPostalAddress}
            validateItself={validateItself}
          />
        </div>
      )}
      {!props.isBottledGasOnly && (
        <Fragment>
          <div className="field">
            <div className="property_situation_title">
              {Constants.situationLabel}
            </div>
            {Constants.situationRadioButton.map((situationEl, index) => (
              <div className="property_situation_container subItem" key={index}>
                <RadioButton
                  name={situationEl.radioButtonId}
                  checked={situation === situationEl.radioButtonCode}
                  value={situationEl.radioButtonCode}
                  label={situationEl.radioButtonLabel}
                  onChange={(value) => setSelectedSituation(value)}
                  required={!situation}
                  validateItself={validateItself}
                />
                {situation === situationEl.radioButtonCode && (
                  <Fragment>
                    {situationEl.situationOtherDetails && (
                      <div className="subItem field">
                        <Input
                          name="situationOtherDetails"
                          handleChange={(value) =>
                            setSituationOtherDetails(value)
                          }
                          value={situationOtherDetails}
                          maxLength={situationEl.situationOtherDetailsMaxLength}
                          labelText={situationEl.situationOtherDetails}
                          placeholder={
                            situationEl.situationOtherDetailsPlaceHolder
                          }
                          handleError={setSituationOtherDetailsHasError}
                          errorMessage={
                            situationEl.situationOtherDetailsRequiredMsg
                          }
                          hasError={situationOtherDetailsHasError}
                          required
                          validateItself={validateItself}
                        />
                      </div>
                    )}
                    {situationEl.radioButtonCode !== SITUATION_NOT_MOVING && (
                      <div className="subItem field">
                        {index !== 0 ? (
                          <DatePicker
                            name="serviceSwitchDate"
                            handleChange={(value) => {
                              setServiceSwitchDate(
                                value
                                  ? DateUtil.convertDateInstanceToFormat(
                                      value,
                                      Constants.dateFormat
                                    )
                                  : value
                              );
                            }}
                            value={
                              serviceSwitchDate &&
                              DateUtil.getJavascriptDateFormat(
                                serviceSwitchDate,
                                Constants.dateFormat
                              )
                            }
                            labelText={situationEl.serviceSwitchDateLabel}
                            placeholder={Constants.datePlaceholder}
                            minDate={
                              new Date(
                                new Date(new Date().setMonth(-1)).setHours(
                                  0,
                                  0,
                                  0,
                                  0
                                )
                              )
                            }
                            handleError={setServiceSwitchDateHasError}
                            hasError={serviceSwitchDateHasError}
                            errorMessage={
                              situationEl.serviceSwitchDateRequiredMsg
                            }
                            required
                            validateItself={validateItself}
                          />
                        ) : (
                          <DatePicker
                            name="serviceSwitchDate"
                            value={
                              serviceSwitchDate &&
                              DateUtil.getJavascriptDateFormat(
                                serviceSwitchDate,
                                Constants.dateFormat
                              )
                            }
                            labelText={situationEl.serviceSwitchDateLabel}
                            disabled
                          />
                        )}
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
            ))}
          </div>
          <div className="field property_checkbox">
            <Checkbox
              name="hasVulnerablePerson"
              label={Constants.hasVulnerablePersonLabel}
              subLabel={Constants.hasVulnerablePersonSubLabel}
              checked={hasVulnerablePerson}
              onChange={() => setHasVulnerablePerson(!hasVulnerablePerson)}
              hasHelpIcon
              iconClicked={() =>
                setHasVulnerablePersonModalActive(
                  !hasVulnerablePersonModalActive
                )
              }
            />
          </div>
          <div className="field property_checkbox">
            <Checkbox
              name="hasMedicalDependant"
              label={Constants.hasMedicalDependantLabel}
              subLabel={Constants.hasMedicalDependantSubLabel}
              checked={hasMedicalDependant}
              onChange={(value) =>
                hasMedicalDependantCheckboxChangedHandler(value)
              }
              hasHelpIcon
              iconClicked={() =>
                setHasMedicalDependantModalActive(
                  !hasMedicalDependantModalActive
                )
              }
            />
          </div>
          {hasMedicalDependant && (
            <Fragment>
              <div className="field subItem">
                <Checkbox
                  name="medicalDependantDifferentAsAccountHolder"
                  label={
                    Constants.medicalDependantDifferentAsAccountHolderLabel
                  }
                  checked={medicalDependantDifferentAsAccountHolder}
                  onChange={
                    medicalDependantDifferentAsAccountHolderCheckboxChangedHandler
                  }
                />

                {medicalDependantDifferentAsAccountHolder && (
                  <Fragment>
                    <div className="row subItem">
                      <div className="field">
                        <Input
                          name="medicalDependantFirstName"
                          handleChange={(value) =>
                            setMedicalDependantFirstName(value)
                          }
                          value={medicalDependantFirstName}
                          labelText={Constants.medicalDependantFirstNameLabel}
                          maxLength={
                            Constants.medicalDependantFirstNameMaxLength
                          }
                          handleError={setMedicalDependantFirstNameHasError}
                          errorMessage={
                            Constants.medicalDependantFirstNameRequiredMsg
                          }
                          hasError={medicalDependantFirstNameHasError}
                          validationType="name"
                          required={hasMedicalDependant}
                          validateItself={validateItself}
                        />
                      </div>
                      <div className="field">
                        <Input
                          name="medicalDependantLastName"
                          handleChange={(value) =>
                            setMedicalDependantLastName(value)
                          }
                          value={medicalDependantLastName}
                          labelText={Constants.medicalDependantLastNameLabel}
                          maxLength={
                            Constants.medicalDependantLastNameMaxLength
                          }
                          handleError={setMedicalDependantLastNameHasError}
                          errorMessage={
                            Constants.medicalDependantLastNameRequiredMsg
                          }
                          hasError={medicalDependantLastNameHasError}
                          validationType="name"
                          required={hasMedicalDependant}
                          validateItself={validateItself}
                        />
                      </div>
                    </div>
                    <div className="row subItem">
                      <div className="field">
                        <Input
                          name="medicalDependantPhone"
                          handleChange={(value) =>
                            setMedicalDependantPhone(value)
                          }
                          value={medicalDependantPhone}
                          labelText={Constants.medicalDependantPhoneLabel}
                          placeholder={
                            Constants.medicalDependantPhonePlaceholder
                          }
                          maxLength={Constants.medicalDependantPhoneMaxLength}
                          handleError={setMedicalDependantPhoneHasError}
                          errorMessage={
                            Constants.medicalDependantPhoneRequiredMsg
                          }
                          invalidMessage={
                            Constants.medicalDependantPhoneInvalidMsg
                          }
                          hasError={medicalDependantPhoneHasError}
                          validationType="phoneNumber"
                          required
                          validateItself={validateItself}
                        />
                      </div>
                      <div className="field">
                        <Input
                          name="medicalDependantEMailAddress"
                          handleChange={(value) =>
                            setMedicalDependantEMailAddress(value)
                          }
                          value={medicalDependantEMailAddress}
                          labelText={
                            Constants.medicalDependantEMailAddressLabel
                          }
                          maxLength={
                            Constants.medicalDependantEMailAddressMaxLength
                          }
                          handleError={setMedicalDependantEMailAddressHasError}
                          errorMessage={
                            Constants.medicalDependantEMailAddressRequiredMsg
                          }
                          invalidMessage={
                            Constants.medicalDependantEMailAddressInvalidMsg
                          }
                          hasError={medicalDependantEMailAddressHasError}
                          validationType="email"
                          required
                          validateItself={validateItself}
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>

              <div className="field subItem">
                <Input
                  name="criticalMedicalEquipment"
                  handleChange={(value) => setCriticalMedicalEquipment(value)}
                  value={criticalMedicalEquipment}
                  labelText={Constants.criticalMedicalEquipmentLabel}
                  handleError={setCriticalMedicalEquipmentHasError}
                  errorMessage={Constants.criticalMedicalEquipmentRequiredMsg}
                  hasError={criticalMedicalEquipmentHasError}
                  required={hasMedicalDependant}
                  maxLength={Constants.criticalMedicalEquipmentMaxLength}
                  validateItself={validateItself}
                />
              </div>

              <div className="field subItem">
                <Checkbox
                  name="contactHealthPractitioner"
                  label={Constants.contactHealthPractitionerLabel}
                  subLabel={Constants.contactHealthPractitionerSubLabel}
                  checked={contactHealthPractitioner}
                  onChange={contactHealthPractitionerCheckboxChangedHandler}
                />
                {contactHealthPractitioner && (
                  <Fragment>
                    <div className="row subItem">
                      <div className="field">
                        <Input
                          name="practiceName"
                          handleChange={(value) => setPracticeName(value)}
                          value={practiceName}
                          labelText={Constants.practiceNameLabel}
                          maxLength={Constants.practiceNameMaxLength}
                          handleError={setPracticeNameHasError}
                          errorMessage={Constants.practiceNameRequiredMsg}
                          hasError={practiceNameHasError}
                          required={
                            hasMedicalDependant && contactHealthPractitioner
                          }
                          validateItself={validateItself}
                        />
                      </div>
                    </div>
                    <div className="row subItem">
                      <div className="field">
                        <Input
                          name="practitionerName"
                          handleChange={(value) => setPractitionerName(value)}
                          value={practitionerName}
                          labelText={Constants.practitionerNameLabel}
                          maxLength={Constants.practitionerNameMaxLength}
                          handleError={setPractitionerNameHasError}
                          errorMessage={Constants.practitionerNameRequiredMsg}
                          hasError={practitionerNameHasError}
                          validationType="name"
                          required={
                            hasMedicalDependant && contactHealthPractitioner
                          }
                          validateItself={validateItself}
                        />
                      </div>
                      <div className="field">
                        <Input
                          name="practicePhone"
                          handleChange={(value) => setPracticePhone(value)}
                          value={practicePhone}
                          labelText={Constants.practicePhoneLabel}
                          placeholder={Constants.practicePhonePlaceholder}
                          maxLength={Constants.practicePhoneMaxLength}
                          handleError={setPracticePhoneHasError}
                          errorMessage={Constants.practicePhoneRequiredMsg}
                          invalidMessage={Constants.practicePhoneInvalidMsg}
                          hasError={practicePhoneHasError}
                          validationType="phoneNumber"
                          required={
                            hasMedicalDependant && contactHealthPractitioner
                          }
                          validateItself={validateItself}
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
            </Fragment>
          )}
        </Fragment>
      )}

      <div className="field">
        <CheckboxList
          name="hazardsCheckboxOptions"
          title={hazardsConstants.label}
          description={hazardsConstants.helpText}
          onChange={hazardsCheckboxListChangedHandler}
          options={hazardsCheckboxOptions}
          columns={3}
          required
          validateItself={validateItself}
        />
      </div>

      {hazardsOtherSelected && (
        <div className="field">
          <Input
            name="hazardsOther"
            handleChange={setHazardsOther}
            value={hazardsOther}
            labelText={hazardsConstants.otherHazards}
            placeholder={hazardsConstants.otherHazardsPlaceholder}
            handleError={setHazardsOtherHasError}
            errorMessage={hazardsConstants.otherHazardsRequiredMsg}
            hasError={hazardsOtherHasError}
            required
            validateItself={validateItself}
          />
        </div>
      )}
      <div className="commonButton">
        <Button
          name="propertyContinueButton"
          className="continue-button"
          type="button"
          text={Constants.buttonLabel}
          handleClick={continueButtonClickedHandler}
          primaryOnLight
          disabled={isDisable}
          handleValidationClick={() => setValidateItself(true)}
        />
      </div>
    </div>
  );

  const getPreview = (
    <div>
      {address && (
        <div className="field">
          {Constants.previewAddress}
          <span className="bold">{address}</span>
        </div>
      )}

      {postalAddress && (
        <div className="field">
          {Constants.previewPostalAddress}
          <span className="bold">
            {!addressSameAsPostalAddress
              ? postalAddress
              : Constants.previewPostalAddressSameAsPropertyAddress}
          </span>
        </div>
      )}

      {situation && (
        <div className="field">
          {Constants.previewMySituation}{" "}
          <span className="bold">
            {situation === SITUATION_CHANGE_OWNERSHIP
              ? situationOtherDetails
              : situation}
          </span>
        </div>
      )}

      {situation !== SITUATION_NOT_MOVING && serviceSwitchDate && (
        <div className="field">
          {Constants.previewServiceSwitchDate}{" "}
          <span className="bold">{serviceSwitchDate}</span>
        </div>
      )}

      {hasVulnerablePerson && (
        <div className="field">
          <span className="bold">{Constants.previewHasVulnerablePerson}</span>
        </div>
      )}

      {hasMedicalDependant && (
        <div className="field">
          <span className="bold">{Constants.previewHasMedicalDependant}</span>
        </div>
      )}

      {hasMedicalDependant && (
        <div className="field">
          <span className="bold">
            {medicalDependantDifferentAsAccountHolder
              ? Constants.previewMedicalDependantDifferentAsAccountHolder
              : Constants.previewMedicalDependantSameAsAccountHolder}
          </span>
        </div>
      )}

      {hasMedicalDependant && criticalMedicalEquipment && (
        <div className="field">
          {Constants.previewCriticalMedicalEquipment}{" "}
          <span className="bold">{criticalMedicalEquipment}</span>
        </div>
      )}

      {medicalDependantDifferentAsAccountHolder &&
        (medicalDependantFirstName || medicalDependantLastName) && (
          <div className="field">
            {Constants.previewMedicalDependantName}{" "}
            <span className="bold">
              {medicalDependantFirstName} {medicalDependantLastName}
            </span>
          </div>
        )}

      {medicalDependantDifferentAsAccountHolder && medicalDependantPhone && (
        <div className="field">
          {Constants.previewMedicalDependantPhone}{" "}
          <span className="bold">{medicalDependantPhone}</span>
        </div>
      )}

      {medicalDependantDifferentAsAccountHolder &&
        medicalDependantEMailAddress && (
          <div className="field">
            {Constants.previewMedicalDependantEmail}{" "}
            <span className="bold">{medicalDependantEMailAddress}</span>
          </div>
        )}

      {contactHealthPractitioner && (
        <div className="field">
          <span className="bold">
            {Constants.previewContactHealthPractitioner}
          </span>
        </div>
      )}

      {contactHealthPractitioner && practiceName && (
        <div className="field">
          {Constants.previewPracticeName}{" "}
          <span className="bold">{practiceName}</span>
        </div>
      )}

      {contactHealthPractitioner && practitionerName && (
        <div className="field">
          {Constants.previewPractitionerName}{" "}
          <span className="bold">{practitionerName}</span>
        </div>
      )}

      {contactHealthPractitioner && practicePhone && (
        <div className="field">
          {Constants.previewPracticePhone}{" "}
          <span className="bold">{practicePhone}</span>
        </div>
      )}

      {previewHazardsContent && (
        <div className="field">
          {Constants.previewHazards}
          <span className="bold">{previewHazardsContent}</span>
        </div>
      )}
    </div>
  );

  const getHeader = {
    cardTitle: Constants.cardTitle,
    cardContent: getPreview,
    cardIcon: <ArrowDown />,
    cardIsSelected: false,
    cardLink: true,
  };

  return (
    <Fragment>
      <div id="Property" className="cardId" />
      <Modal
        isActive={hasVulnerablePersonModalActive}
        className="property_modal_container"
        handlePrimaryClick={() =>
          setHasVulnerablePersonModalActive(!hasVulnerablePersonModalActive)
        }
        content={
          <div className="property_modal_content">
            <div className="property_modal_title">
              {Constants.vulnerableQuestionModalContent.title}
            </div>
            <p>{Constants.vulnerableQuestionModalContent.consider}</p>
            <ul className="property_modal_bullet">
              {Constants.vulnerableQuestionModalContent.bulletPoints.map(
                (item, index) => (
                  <li key={index}>{item}</li>
                )
              )}
            </ul>
            <p>{Constants.vulnerableQuestionModalContent.content1}</p>
            <p>{Constants.vulnerableQuestionModalContent.content2}</p>
          </div>
        }
        buttonTextPrimary={Constants.vulnerableQuestionModalContent.buttonLabel}
        journeyStyle={true}
      />
      <Modal
        name="hasMedicalDependantModal"
        isActive={hasMedicalDependantModalActive}
        className="property_modal_container"
        handlePrimaryClick={() =>
          setHasMedicalDependantModalActive(!hasMedicalDependantModalActive)
        }
        content={
          <div className="property_modal_content">
            <div className="property_modal_title">
              {Constants.medicalQuestionModalContent.title1}
            </div>
            <p>{Constants.medicalQuestionModalContent.para1}</p>
            <div className="property_modal_title">
              {Constants.medicalQuestionModalContent.title2}
            </div>
            <p>{Constants.medicalQuestionModalContent.para2}</p>
            <p>{Constants.medicalQuestionModalContent.bulletPointsTitle}</p>
            <ul className="property_modal_bullet">
              {Constants.medicalQuestionModalContent.bulletPoints.map(
                (item, index) => (
                  <li key={index}>{item}</li>
                )
              )}
            </ul>
          </div>
        }
        buttonTextPrimary={Constants.medicalQuestionModalContent.buttonLabel}
        journeyStyle={true}
      />
      <ExpandableCard
        name="propertyCard"
        headerContent={getHeader}
        content={getContent}
        isOpen={props.isCurrentCardOpen}
        disabled={props.isCardDisabled}
        handleClick={props.onCardClick}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  address: getPropertyAddress(state),
  addressMoniker: state.Property.PropertyInfo.Moniker,
  postalAddress: state.Property.PropertyInfo.AddressSameAsPostalAddress
    ? ""
    : getPostalAddress(state),
  postalAddressMoniker: state.Customer.CustomerInfo.PostalAddressMoniker,
  addressSameAsPostalAddress:
    state.Property.PropertyInfo.AddressSameAsPostalAddress,
  hazards: [...state.Property.PropertyInfo.Hazards],
  hazardsOther: state.Property.PropertyInfo.HazardsOther,

  hasMedicalDependant: state.Property.MedicalInfo.HasMedicalDependant,
  criticalMedicalEquipment: state.Property.MedicalInfo.CriticalMedicalEquipment,
  medicalDependantFirstName:
    state.Property.MedicalInfo.MedicalDependantFirstName,
  medicalDependantLastName: state.Property.MedicalInfo.MedicalDependantLastName,
  medicalDependantEMailAddress:
    state.Property.MedicalInfo.MedicalDependantEMailAddress,
  medicalDependantPhone: state.Property.MedicalInfo.MedicalDependantPhone,
  medicalDependantSameAsAccountHolder:
    state.Property.MedicalInfo.MedicalDependantSameAsAccountHolder,
  contactHealthPractitioner:
    state.Property.MedicalInfo.ContactHealthPractitioner,
  practiceName: state.Property.MedicalInfo.PracticeName,
  practicePhone: state.Property.MedicalInfo.PracticePhone,
  practitionerName: state.Property.MedicalInfo.PractitionerName,
  hasVulnerablePerson: state.Property.MedicalInfo.HasVulnerablePerson,

  accountStart: getServicesStartDate(state),
  situation: state.Property.MoveInfo.Situation,
  situationOtherDetails: state.Property.MoveInfo.SituationOtherDetails,

  hasElectricity: getHasElectricity(state),
  hasBottledGas: getHasBottledGas(state),
  isBottledGasOnly: getIsBottleGasOnly(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    savePropertyData: (values) => {
      const customer = {
        CustomerInfo: {
          CurrentAddress: values.address,
          PostalAddress: values.postalAddress,
          PostalAddressMoniker: values.postalAddressMoniker,
        },
      };

      const property = {
        PropertyInfo: {
          Address: values.address,
          Moniker: values.addressMoniker,
          AddressSameAsPostalAddress: values.addressSameAsPostalAddress,
          Hazards: values.hazards,
          HazardsOther: values.hazardsOther,
          WithAnotherSupplier: values.withAnotherSupplier,
        },

        MedicalInfo: {
          HasMedicalDependant: values.hasMedicalDependant,
          CriticalMedicalEquipment: values.criticalMedicalEquipment,
          MedicalDependantFirstName: values.medicalDependantFirstName,
          MedicalDependantLastName: values.medicalDependantLastName,
          MedicalDependantEMailAddress: values.medicalDependantEMailAddress,
          MedicalDependantPhone: values.medicalDependantPhone,
          MedicalDependantSameAsAccountHolder: values.hasMedicalDependant
            ? !values.medicalDependantDifferentAsAccountHolder
            : "",
          ContactHealthPractitioner: values.hasMedicalDependant
            ? values.contactHealthPractitioner
            : "",
          PracticeName: values.practiceName,
          PracticePhone: values.practicePhone,
          PractitionerName: values.practitionerName,
          HasVulnerablePerson: values.hasVulnerablePerson,
        },

        MoveInfo: {
          IsMovingHouse: values.isMovingHouse,
          AccountStart:
            !values.situation || values.situation === SITUATION_NOT_MOVING
              ? null
              : values.serviceSwitchDate,
          Situation: values.situation ? values.situation : SITUATION_NOT_MOVING,
          SituationOtherDetails: values.situationOtherDetails,
        },
      };
      dispatch(savePropertyData(customer, property));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Property);
