import React from "react";
import {
  AppBank,
  AppBottledGas,
  AppBroadbandClock,
  AppBroadbandHome,
  BroadbandHomeBold,
  AppCalendar,
  AppElectricity,
  Gas,
  AppEnvelope,
  AppHotMug,
  BottledGas as BottledGasIcon,
  // AppMeter,
  AppPrepay,
  AppUserUnknown,
} from "react-components";

import electricityImage from "../Assets/Images/electricity.svg";
import pricintgImage from "../Assets/Images/pricing.svg";
import smileImage from "../Assets/Images/smilie.svg";
import calentarImage from "../Assets/Images/calendar.svg";

const generalTermsAndConditions =
  "/-/media/contact/pdfs/terms-and-conditions/join/contact-general-terms-and-conditions.pdf";
const planTermsAndConditions =
  "/-/media/contact/pdfs/terms-and-conditions/join/contact-plan-terms-and-conditions.pdf";
const broadbandTermsAndConditions =
  "/-/media/contact/pdfs/terms-and-conditions/join/contact-broadband-terms-and-conditions.pdf";
const bottleGasTermsAndConditions =
  "/-/media/contact/pdfs/terms-and-conditions/join/rockgas-lpg-tcs-for-cylinder-and-bulk-supply.pdf";
const pipedGasTermsAndConditions =
  "/-/media/contact/pdfs/terms-and-conditions/join/reticulated-lpg-terms-and-conditions.pdf";
const planTermsAndConditionsURL =
  process.env.REACT_APP_EXPRESS_BASE_URL +
  planTermsAndConditions +
  "?t=" +
  new Date().getTime();
const ddTermsAndConditions =
  process.env.REACT_APP_EXPRESS_BASE_URL +
  "/-/media/contact/pdfs/terms-and-conditions/join/direct-debit-terms.pdf?t=" +
  new Date().getTime();
const periodTermsAndConditions =
  process.env.REACT_APP_EXPRESS_BASE_URL +
  "/-/media/contact/pdfs/terms-and-conditions/join/weekly-billing-special-terms.pdf?t=" +
  new Date().getTime();
const prepayTermsAndConditions =
  process.env.REACT_APP_EXPRESS_BASE_URL +
  "/-/media/contact/pdfs/terms-and-conditions/join/contact-prepay-special-terms.pdf?t=" +
  new Date().getTime();
const customerHelpTool = "https://contactenergy.sharepoint.com/sites/csc";
const quotingTool = "https://contact.webtools-eqt.co.nz/site/login";
const fando = "http://fando.contact-energy.co.nz/fando/outages-preload.do";
const googleMapLink =
  "https://www.google.co.nz/maps/@-40.8953267,173.6040693,6.06z";
const postCodeFinderLink =
  "https://www.nzpost.co.nz/tools/address-postcode-finder";
const electricityRegistry =
  "https://www.electricityregistry.co.nz/bin_public/jadehttp.dll?MariaWebR";
const gasRegistry =
  "https://www.gasregistry.co.nz/bin_public/jadehttp.dll?Registry";
const broadbandPortal = "https://vumeda.devoli.com/main/login";
const devoliSupport = "https://support.devoli.com/";
const broadbandMap = "https://broadbandmap.nz/home";
const bottleGasMap =
  "https://www.google.com/maps/d/u/0/viewer?mid=1-oYP_FMNMiSTovJAwv2NO1pi7-8&ll=-38.86931631439009%2C170.70533096219003&z=4";
const bottleGasPricing =
  "https://firstgasnz.sharepoint.com/sites/CONT/CustomerPricingandRunSchedule/Forms/AllItems.aspx";

export const SERVICE_ID_ELECTRICITY = "ELEC";
export const SERVICE_ID_PIPED_GAS = "GAS";
export const SERVICE_ID_LPG = "LPG";
export const SERVICE_ID_BROADBAND = "BB";

export const SMART_METER = "SMART METER";

export const USAGE_TYPE_STANDARD = "S";
export const USAGE_TYPE_LOW = "L";
export const BASIC_PLAN = "BASIC";

export const STANDARD_USER = "Standard";

export const SERVICE_ELEC = "Electricity";

export const CUSTOMER_TYPE = "Residential";

export const SERVICES_NAMES = {
  [SERVICE_ID_ELECTRICITY]: "electricity",
  [SERVICE_ID_PIPED_GAS]: "piped gas",
  [SERVICE_ID_LPG]: "bottled gas",
  [SERVICE_ID_BROADBAND]: "broadband",
};

export const SERVICES_SHORT_NAMES = {
  elec: "ELEC",
};

export const Header = {
  logo: {
    redirectURL: process.env.REACT_APP_EXPRESS_BASE_URL + "/residential/",
    alt: "Contact Energy Logo",
  },
  support: {
    label: "Support",
    url: process.env.REACT_APP_EXPRESS_BASE_URL + "/support",
  },
};

export const AboutYou = {
  cardTitle: "About You",
  cardTitleAgent: "Personal details",
  cardTitleAddPerson: "Add someone to your account",
  salutationTitle: "Title/Salutation",
  selectTitle: "Select one",
  unknownTitle: null,
  labelTitle: "Title",
  errorTitle: "Title required",
  salutationDescription:
    "Please provide your full legal name as per your NZ Driver Licence or Passport",
  salutationLabels: ["Mr", "Mrs", "Miss", "Ms", "Mx"],
  addPersonCheckbox: "Add a joint account holder",
  addAuthPersonCheckbox: "Add an authorised person",
  addAnotherPersonCheckbox: "Add another joint account holder",
  firstNameLabel: "First Name",
  firstNamePlaceholder: "",
  firstNameMaxLength: 30,
  firstNameRequiredMsg: "First name is required",
  nameNotMatchMsg: "Please check name matches your ID",
  dobNotMatchMsg: "Please check DOB matches your ID",
  licenceNotMatchMsg:
    "Drivers license or version number is incorrect.  Please check and try again",
  middleNameLabel: "Middle Name",
  middleNamePlaceholder: "",
  middleNameMaxLength: 30,
  lastNameLabel: "Last Name",
  lastNamePlaceholder: "",
  lastNameMaxLength: 30,
  lastNameRequiredMsg: "Last Name is required",
  dateLabel: "Date of birth",
  datePlaceholder: "DD / MM / YYYY",
  dateFormat: "DD/MM/YYYY",
  dateMinAge: 18,
  dateMinAgeErrorMsg: "You must be over 18 to signup",
  dateMaxAge: 100,
  dateMaxAgeErrorMsg: "Please check your date of birth",
  numberYearOptions: 82,
  dateRequiredMsg: "Date of birth is required",
  invalidDateMsg: "Invalid date",
  phoneLabel: "Phone no.",
  phonePlaceholder: "e.g. 091234567",
  phoneMaxLength: 11,
  phoneRequiredMsg: "Phone number is required",
  phoneInvalidMsg: "Invalid phone number",
  emailLabel: "Email",
  emailPlaceholder: "",
  emailMaxLength: 50,
  emailRequiredMsg: "Email is required",
  emailInvalidMsg: "Please enter a valid email address",
  emailNotMatchMsg: "Email address doesn't match",
  creditCheckLabel:
    "I give permission to use the personal information for the purposes of conducting a credit check",
  creditCheckSubLabel:
    "Giving us your driver licence number will help speed up the credit check process.",
  driverLabel: "Driver Licence no.",
  driverPlaceholder: "AA000000",
  driverMaxLength: 8,
  driverRequiredMsg: "Driver Licence no. is required",
  driverInvalidMsg: "Licence number format is incorrect",
  cardNumberLabel: "Card version no.",
  cardNumberPlaceholder: "000",
  cardNumberMaxLength: 3,
  cardNumberRequiredMsg: "Card version no. is required",
  cardNumberInvalidMsg: "Card version number format is incorrect",
  buttonLabel: "Continue",
  buttonCreateAgent: "Create customer",
  buttonUpdateAgent: "Update customer",
  previewDate: "Date of Birth:",
  previewPhone: "Phone Number:",
  previewEmail: "Email:",
  previewDriver: "Driver licence number:",
  previewCardNumber: "Card version number:",
  newsCheckboxLabel:
    "I would like to receive emails about special offers, products and news from Contact.",
  newsCheckboxLabelAgent:
    "Would you like to receive occasional marketing and special offer emails from Contact?",
  checkIdentity: true,
  hasDriverLicenceAgent: "Do you have a current NZ driver’s licence?",
  customerCreated: "Customer created successfully.",
  customerUpdated: "Customer updated successfully.",
  customerChanged: "You have unsaved changes.",
  makeChanges: "Make changes.",
  pressUpdate: "Press update to commit.",
  updateCustomer: "Update customer",
  customerNumberTitle: "Customer number",
  customerNumberLabel: "BP number",
  driverLicenceTitle: "NZ Driver’s Licence",
  sapContinue: "Please continue sign up in SAP-CRM.",
};

export const SITUATION_NOT_MOVING = "Changing energy supplier";
export const SITUATION_MOVING = "Moving house";
export const SITUATION_CHANGE_OWNERSHIP = "Changing of ownership";

export const Property = {
  cardTitle: "About your property",
  cardTitleAgent: "Medical dependency & credit check",
  addressLabel: "Address you are signing up",
  addressMaxLength: 250,
  addressRequiredMsg: "Street address is required",
  addressDifferentAsPostalAddressLabel: "This isn't my postal address",
  postalAddress: "Postal address",
  postalAddressPlaceHolder: "",
  postalAddressMaxLength: 250,
  postalAddressRequiredMsg: "Address is required",
  datePlaceholder: "DD / MM / YYYY",
  dateFormat: "DD/MM/YYYY",
  situationLabel: "What is your situation?",
  creditCheckConsent:
    "Yes, customer has given consent to perform a credit check?",
  buttonCreditCheck: "Run credit check",
  amber: "Amber",
  red: "Red",
  defaultBond: "N/A",
  amberBond: "150",
  prepayName: "PREPAY",
  paymentCycleWF: "WEEKLY/FORTNIGHTLY",
  ccServiceElec: "ELEC",
  ccServiceGasNI: "NGAS",
  ccServiceGasSI: "RLPG",
  situationRadioButton: [
    {
      radioButtonId: "NOTMOVING",
      radioButtonCode: SITUATION_NOT_MOVING,
      radioButtonLabel: "I am changing energy supplier (not moving house)",
      serviceSwitchDateLabel: "The date you want your services switched on",
      serviceSwitchDateRequiredMsg: "Please pick a later date",
    },
    {
      radioButtonId: "MOVING",
      radioButtonCode: SITUATION_MOVING,
      radioButtonLabel: "I am moving, or have already moved house",
      serviceSwitchDateLabel: "The date you want your services switched on",
      serviceSwitchDateRequiredMsg: "Please pick a later date",
    },
    {
      radioButtonId: "OWNERSHIP",
      radioButtonCode: SITUATION_CHANGE_OWNERSHIP,
      radioButtonLabel: "I am taking over ownership of the account",
      serviceSwitchDateLabel: "The date you want account ownership changed",
      serviceSwitchDateRequiredMsg: "Please pick a later date",
      situationOtherDetails: "Please tell us about your situation",
      situationOtherDetailsMaxLength: 200,
      situationOtherDetailsRequiredMsg:
        "Please explain your situation so we can help you",
      situationOtherDetailsPlaceHolder:
        "e.g. Looking to take over the flat electricity account as the flatmate moving out is the primary account holder.",
    },
  ],
  hasVulnerablePersonLabel: "Someone at this property is vulnerable",
  hasVulnerablePersonLabelAgent:
    "Someone at this property is medically dependent on electricity",
  hasVulnerablePersonSubLabel:
    "We can discuss options to help customers in vulnerable circumstances.",
  hasMedicalDependantLabel:
    "Someone at this property is medically dependant on electricity",
  hasMedicalDependantSubLabel:
    "This means any situation where a loss of electricity may result in loss of life or serious harm.",
  medicalDependantDifferentAsAccountHolderLabel:
    "The medically dependant person isn't the account holder",
  medicalDependantFirstNameLabel: "First name",
  medicalDependantFirstNamePlaceholder: "",
  medicalDependantFirstNameMaxLength: 30,
  medicalDependantFirstNameRequiredMsg:
    "Medical dependant First Name is required",
  medicalDependantLastNameLabel: "Last name",
  medicalDependantLastNamePlaceholder: "",
  medicalDependantLastNameMaxLength: 30,
  medicalDependantLastNameRequiredMsg:
    "Medical Dependant Last Name is required.",
  medicalDependantPhoneLabel: "Phone no.",
  medicalDependantPhonePlaceholder: "e.g. 091234567",
  medicalDependantPhoneMaxLength: 11,
  medicalDependantPhoneRequiredMsg:
    "Medical Dependant Phone Number is required.",
  medicalDependantPhoneInvalidMsg: "Invalid phone number",
  medicalDependantEMailAddressLabel: "Email",
  medicalDependantEMailAddressPlaceholder: "",
  medicalDependantEMailAddressMaxLength: 50,
  medicalDependantEMailAddressRequiredMsg:
    "Medical Dependant Email is required.",
  medicalDependantEMailAddressInvalidMsg: "Invalid Email",
  criticalMedicalEquipmentLabel: "Details of the critical medical equipment",
  criticalMedicalEquipmentPlaceholder: "",
  criticalMedicalEquipmentRequiredMsg:
    "Critical Medical Equipment is required.",
  criticalMedicalEquipmentMaxLength: 50,
  contactHealthPractitionerLabel:
    "I authorise Contact Energy to contact my health practitioner to collect information about me for this purpose",
  contactHealthPractitionerLabelAgent:
    "Do you consent to let Contact Energy contact your health practitioner to collect information related to this?",
  contactHealthPractitionerSubLabel:
    "We require your permission to contact your health practitioner directly, so we can verify your medically dependant status. We'll need to re-confirm this status every year.",
  practiceNameLabel: "Practice name",
  practiceNamePlaceholder: "",
  practiceNameMaxLength: 40,
  practiceNameInvalidMsg: "Invalid Name",
  practiceNameRequiredMsg: "Practice name is required.",
  practitionerNameLabel: "Practitioner name",
  practitionerNamePlaceholder: "",
  practitionerNameMaxLength: 40,
  practitionerNameInvalidMsg: "Invalid Name",
  practitionerNameRequiredMsg: "Practitioner name is required.",
  practicePhoneLabel: "Practise phone no.",
  practicePhonePlaceholder: "e.g. 091234567",
  practicePhoneMaxLength: 20,
  practicePhoneInvalidMsg: "Invalid phone number",
  practicePhoneRequiredMsg: "Practice phone number is required",
  buttonLabel: "Continue",
  vulnerableQuestionModalContent: {
    title:
      "Contact takes its obligation to assist customers in vulnerable circumstances seriously.",
    consider: "You can be considered vulnerable if you either: ",
    bulletPoints: [
      "rely on electricity due to age, health or disability and a power cut may threaten your health or well-being.",
      "have genuine difficulty paying bills due to financial hardship.",
    ],
    content1:
      "If you think you or your family fits this description or you are finding it difficult to pay your energy bills, please contact us immediately on 0800 80 9000. " +
      "We’ll discuss options such as setting up a payment arrangement, applying to Work and Income or another social agency for budgeting or other advice or assistance.",
    content2:
      "Also, if you believe that at some time in the future you may have difficulties with your payments or with communicating with us, you can appoint one or more alternate contacts who agree to assist if a disconnection or payment issue is pending. " +
      "An alternate contact could be a family member or friend, or a social agency support person.",
    buttonLabel: "Close",
  },
  medicalQuestionModalContent: {
    title1:
      "Do you have medical equipment at home that depends on electricity?",
    para1:
      "If you, or someone in your household, depends on mains electricity for critical medical support, " +
      "and the loss of electricity could result in loss of life or serious harm, then let us know when you sign up. " +
      "Medical dependence on electricity relates to both medical equipment and other electrical equipment needed " +
      "to support a treatment regime such as a microwave to heat fluids for renal dialysis.",
    title2: "Your emergency back-up plan during an electricity outage",
    para2:
      "If you're medically dependant, it's important that you have emergency back-up plan during a power cut. " +
      "Power cuts can occur suddenly during bad weather or unplanned emergency repairs.",
    bulletPointsTitle: "Your back-up plan could include:",
    bulletPoints: [
      "Having a back-up battery that is always charged up.",
      "Relocating to a friend or family members house.",
      "If it's really serious, then calling the ambulance to be taken to the hospital.",
    ],
    buttonLabel: "Close",
  },
  hazards: {
    electricityOrPipeGasOnly: {
      label: "Potential hazards or access issues at my property",
      helpText:
        "We require this information just in case a meter reader needs to visit.",
      optionsOther: "Other",
      optionsNoHazards: "No hazards",
      options: ["No hazards", "Dog", "Other"],
      otherHazards: "Other hazards not mentioned above",
      otherHazardsPlaceholder: "",
      otherHazardsRequiredMsg: "Hazard details are required.",
    },
    bottleGasWithElectricity: {
      label: "Potential hazards or access issues at my property",
      helpText:
        "We require this information just in case a meter reader needs to visit and when we deliver your gas bottles.",
      optionsOther: "Other",
      optionsNoHazards: "No hazards",
      options: [
        "No hazards",
        "Dog",
        "Steps",
        "Locked gate",
        "Steep driveway",
        "Slippery path",
        "Difficult street parking",
        "Other",
      ],
      otherHazards: "Other hazards not mentioned above",
      otherHazardsPlaceholder:
        "E.g.: Dog is running free or meter is inside the house",
      otherHazardsRequiredMsg: "Hazard details are required.",
    },
    bottleGasOnly: {
      label: "Potential hazards or access issues at my property",
      helpText:
        "We need this information for when we deliver your gas bottles.",
      optionsOther: "Other",
      optionsNoHazards: "No hazards",
      options: [
        "No hazards",
        "Dog",
        "Steps",
        "Locked gate",
        "Steep driveway",
        "Slippery path",
        "Difficult street parking",
        "Other",
      ],
      otherHazards: "Other hazards not mentioned above",
      otherHazardsPlaceholder: "",
      otherHazardsRequiredMsg: "Hazard details are required.",
    },
  },
  previewAddress: "Address: ",
  previewPostalAddressSameAsPropertyAddress: "Same as supply address",
  previewPostalAddress: "Postal Address: ",
  previewMySituation: "My situation: ",
  previewServiceSwitchDate: "Services switched on: ",
  previewHasVulnerablePerson: "Someone is vulnerable at this property",
  previewHasMedicalDependant:
    "Someone at this property is medically dependant on electricity",
  previewMedicalDependantDifferentAsAccountHolder:
    "The medically dependant person isn't the account holder",
  previewMedicalDependantSameAsAccountHolder:
    "The medically dependant person is the account holder",
  previewCriticalMedicalEquipment:
    "Details of the critical medical equipment: ",
  previewMedicalDependantName: "Medically dependant person: ",
  previewMedicalDependantPhone: "Medically dependant person phone: ",
  previewMedicalDependantEmail: "Medically dependant person email: ",
  previewContactHealthPractitioner:
    "I authorise Contact Energy to contact my health practitioner to collect information about you for this purpose",
  previewPracticeName: "Practice name: ",
  previewPractitionerName: "Practitioner: ",
  previewPracticePhone: "Practice phone number: ",
  previewHazards: "Hazards: ",
};

export const BottledGas = {
  cardTitle: "About your bottled gas order",
  sectionResidentialDisclaimer:
    "Contact and Rockgas will both be looking after your LPG supply. Rockgas will deliver your replacement " +
    "bottles and we're responsible for ordering, billing and customer help.",
  numberOfBottles: "How many 45kg bottles would you like to order?",
  numberOfBottlesPreview: "Number of LPG bottles",
  numberOfBottlesDescription:
    "A minimum of 2 bottles is required for your first order and delivery is subject to a site inspection.",
  numberOfBottlesOptions: [
    { value: "0", label: "None" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5+", label: "5+" },
  ],
  numberOfBottlesDefault: "0",
  amountDisclaimer:
    "If you store more than 100kgs of bottled gas, we can only deliver to you if your site meets " +
    '<a href="https://contact.co.nz/support/guides-staying-safe#Staying-safe-with-gas" target="_blank" rel="noopener noreferrer">certain criteria</a>.',
  differentPostalAddress:
    "The delivery address is different to my postal address above",
  deliveryAddress: "Delivery address",
  deliveryAddressPlaceholder: "Enter your address",
  deliveryAddressErrorMessage: "Address is required",
  deliveryAddressInvalidMessage: "Address is invalid",
  paperlessStatements:
    "I would like to receive my bottled gas bills and correspondence by email (where available)",
  paperlessStatementsDescription:
    "If your bottled gas is supplied by one of our Franchises, then email may not be available at this time. " +
    "Your franchise will confirm that.",
  paperlessStatementsDefault: false,
  deliveryInstructions: "Special delivery instructions",
  deliveryInstructionsMaxLength: 200,
  previewYourPostalAddress: "Your Postal address",
};

export const Broadband = {
  cardTitle: "About your broadband order",
  hasBroadbandProviderLabel: "I currently have a broadband provider",
  broadbandProviderLabel: "Current broadband provider name",
  broadbandProviderRequiredMsg: "Broadband provider name is required",
  broadbandProviderMaxLength: 50,
  accountHolderNameLabel: "Account holder's name",
  accountHolderNameRequiredMsg: "Account holder name is required",
  accountHolderNameLabelMaxLength: 30,
  accountNumberLabel: "Broadband account no.",
  accountNumberRequiredMsg: "Broadband account number is required",
  accountNumberInvalidMsg: "Invalid broadband account number",
  accountNumberMaxLength: 150,
  modemDeliveredToDifferentAddressLabel:
    "Modem delivery is not to my service address above",
  modemDeliveredToDifferentAddressSubLabel:
    "Modem included ($14.99 postage and handling applies)",
  deliveryAddressLabel: "Delivery address",
  deliveryAddressRequiredMsg: "Modem delivery address is required",
  addPhoneLineLabel:
    "Add a telephone line to my Broadband plan for an additional $10 per month",
  addPhoneLineSubLabel: "Would you like to add a telephone to your Broadband?",
  homePhoneNumberLabel: "Current home no.",
  homePhoneNumberPlaceholder: "e.g. 091234567",
  homePhoneNumberInvalidMsg: "Invalid home number",
  homePhoneNumberRequiredMsg: "Current home number is required",
  phoneLineOptionsLabel: "Optional add-ons for your phone line",
  addonsOptions: [
    {
      code: "INTERNATIONAL",
      label:
        "100 hours calling to top 10 countries: Australia, UK, USA, Canada, Ireland, South Africa, South Korea, India, Hong Kong and China",
    },
    {
      code: "MOBILE100",
      label: "Landline to Mobile call 100 minutes",
    },
    {
      code: "MOBILE200",
      label: "Landline to Mobile call 200 minutes",
    },
  ],
  buttonLabel: "Continue",
  previewBroadbandProvider: "Broadband provider: ",
  previewAccountHolderName: "Account holder name: ",
  previewAccountNumber: "Broadband account number: ",
  previewDeliveryAddress: "Modem delivery address: ",
  previewHomePhoneNumber: "Telephone line number: ",
  previewPhoneLineOptions: "Add-ons: ",
  providerList: [
    "2degrees",
    "Actrix",
    "Bigpipe",
    "Blue Door",
    "CallPlus",
    "Clearnet",
    "Compass",
    "Farmside",
    "Flip",
    "ICONZ",
    "Inspire.net",
    "Kiwilink",
    "MyRepublic",
    "NetSpeed",
    "Nova",
    "Now",
    "NZFarming",
    "Orcon",
    "Skinny",
    "Slingshot",
    "Spark",
    "Stuff Fibre",
    "Terrible Talk",
    "Trustpower",
    "Ultrafast Fibre Ltd",
    "Unlimited Internet",
    "Vodafone",
    "Voyager",
    "Wireless Nation",
    "Woosh",
    "Worldnet",
  ],
};

export const PAYMENT_METHOD_BILL = "PostPay";
export const PAYMENT_METHOD_PREPAY = "PrePay";
export const BILLING_CYCLE_MONTHLY = "Monthly";
export const BILLING_CYCLE_FORTNIGHTLY = "Fortnightly";
export const BILLING_CYCLE_WEEKLY = "Weekly";
export const ELIGIBLE_PERIODIC = "WEEKLY/FORTNIGHTLY BILLING";
export const ELIGIBLE_PREPAY = "CONTACT PREPAY";

export const Payment = {
  cardTitle: "Payment setup",
  cardIntro: "Choose to be billed regularly, or go onto Contact PrePay",
  cardIntroDisclaimer:
    "When you select Contact PrePay, you will receive correspondence and bills by email.",
  paymentMethods: [
    { value: PAYMENT_METHOD_BILL, label: "Receive a bill" },
    { value: PAYMENT_METHOD_PREPAY, label: "Contact PrePay" },
  ],
  accountNumberMinLength: 15,
  smartFuelFieldLength: 19,
  paymentMethodDefault: PAYMENT_METHOD_BILL,
  billingCycle: "I would like to be billed",
  billingCycleText: "You can revise this later if you change your mind",
  billingCycleOptions: [
    { value: BILLING_CYCLE_MONTHLY, label: "Monthly" },
    { value: BILLING_CYCLE_FORTNIGHTLY, label: "Fortnightly" },
    { value: BILLING_CYCLE_WEEKLY, label: "Weekly" },
  ],
  billingCycleDefault: BILLING_CYCLE_MONTHLY,
  billStartDate: "When would you like to start receiving your bills from?",
  billStartDatePreview: "Start receiving bills on",
  billingCycleDisclaimer:
    `By selecting weekly or fortnightly billing you agree to these ` +
    `<a href="${periodTermsAndConditions}" download rel="noopener noreferrer">terms and conditions</a>`,
  sectionDisclaimer:
    "NOTE: Any discounts only apply towards our electricity and natural gas usage.",
  paperlessDiscountDefault: true,
  paperlessDiscount:
    "I would like to receive my bills and correspondence by email",
  paperlessDiscountText:
    "Choose to receive your bills by email and avoid the $1.10 paper bill fee. Find out about service fees " +
    '<a href="https://contact.co.nz/support/prices-and-fees#Service-fees" target="_blank" rel="noopener noreferrer">here</a>.',
  paperlessDiscountPreview: "Email billing",
  noPaperlessDiscountMessage:
    "Choose YES, if you would like weekly or fortnightly billing options on this plan.",
  joinDirectDebitDefault: false,
  joinDirectDebit: "I would like to set up a Direct Debit",
  noJoinDirectDebitMessage:
    "Choose YES, if you would like weekly or fortnightly billing options on this plan. You can set up your " +
    "preferred payment method once we've activated your My Account login.",
  joinDirectDebitText:
    "Paying by direct debit from a bank account means avoiding over the counter or credit/debit card fees. " +
    'Find out about service fees <a href="https://contact.co.nz/support/prices-and-fees#Service-fees" target="_blank" rel="noopener noreferrer">here</a>.',
  joinDirectDebitPreview: "Set up direct debit",
  bankAccountNumber: "Bank account no.",
  bankAccountNumberErrorMessage: "Your bank account is required",
  bankAccountNumberInvalidMessage: "Please check bank account you have entered",
  agreeWithTermsAndConditions:
    "Just confirming you are able to act as the sole signatory for the bank account provided and agree to the direct debit " +
    `<a href="${ddTermsAndConditions}" download rel="noopener noreferrer">terms and conditions.</a>`,
  prePayMobileNumber: "Your mobile no.",
  prePayMobileNumberErrorMessage: "Your mobile number is required",
  prePayMobileNumberInvalidMessage:
    "Please check mobile number you have entered",
  prePayMobileNumberMaxLength: 20,
  prePayMobileNumberDisclaimer:
    "We will use this mobile no. to alert you when your balance is low. There are no SMS charges for notifications.",
  prePayMobileNumberAgree:
    "I agree to receive all notifications by text to the mobile number provided and agree to the Contact PrePay " +
    `<a href="${prepayTermsAndConditions}" download rel="noopener noreferrer">terms and conditions</a>.`,
  hasAaSmartFuel: "I have an existing AA Membership or AA Smartfuel card",
  hasAaSmartFuelText:
    "Don't worry if you don't have the number on hand, you can always register later in My Account",
  aaSmartFuel: "Membership or card no.",
  aaSmartFuelErrorMessage: "AASF card number is required",
  aaSmartFuelInvalidMessage: "Please check AASF card number you have entered",
  aaSmartFuelPreview: "AA Smartfuel card number",
  buttonLabel: "Continue",
  verificationFailedModal: {
    bankAccountNumber:
      "Apologies, we’re unable to process your Bank account number details. You can join Direct Debit " +
      "after your signup is complete.",
    aaSmartFuel:
      "Apologies, we’re unable to process your AA Smartfuel details. You can provide this after your " +
      "signup is complete.",
    buttonLabel: "Close",
  },
};

export const FinalStep = {
  cardTitle: "Final step",
  termsAndConditionsPrefix:
    "Before I confirm your new signup, are you happy to accept ",
  termsAndConditionsSuffix:
    "and give me permission to sign you up to receive the products and services you have requested.",
  generalTermsAndConditions: {
    label: "the general",
    labelWithLink: "terms and conditions",
    file: generalTermsAndConditions,
  },
  planTermsAndConditions: {
    label: "the plan",
    labelWithLink: "terms and conditions",
    file: planTermsAndConditions,
  },
  broadbandTermsAndConditions: {
    label: "the broadband",
    labelWithLink: "terms and conditions",
    file: broadbandTermsAndConditions,
  },
  bottledGasTermsAndConditions: {
    label: "the Rockgas",
    labelWithLink: "terms and conditions",
    file: bottleGasTermsAndConditions,
  },
  pipedGasTermsAndConditions: {
    label: "the Rockgas",
    labelWithLink: "terms and conditions",
    file: pipedGasTermsAndConditions,
  },
  buttonLabel: "JOIN CONTACT",
  privacyStatementTitle: "<b>Privacy Statement</b>",
  privacyStatementContent:
    "<span>We collect the information requested in order to supply you with the products and services you have asked for and if the information isn’t provided, we may not be able to supply you. You have the right to ask for a copy of any personal information we hold about you and to ask for it to be corrected if you think it is wrong.</span>",
  termsAndConditionsTitle: "<b>Terms and Conditions</b>",
  welcomePackContent:
    "<span>We will send all Tc&Cs with your welcome pack and you can also view these on our website.</span>",
};

export const MyDetails = {
  header: "My Details",
  pageTitle: "Contact Energy | My Details",
  title: "What you might need to complete",
  intro: "Average time to complete: 3 mins",
  redirectOnErrorUrl: process.env.REACT_APP_IJOIN_START_URL,
  backToJourneyText: "EDIT MY ORDER",
  backToJourneyUrl: process.env.REACT_APP_IJOIN_START_URL,
  steps: [
    {
      title: "Proof of identity ",
      icon: <AppUserUnknown />,
      html:
        "<p>If you hold a NZ drivers license, keep it handy as it's helpful for credit checking.</p>",
    },
    {
      title: "Bank account details",
      icon: <AppBank />,
      html:
        "<p>If you choose to setup Direct Debit payment, keep your bank account details handy. " +
        "Paying by direct debit from a bank account is one way to avoid over the counter or credit/debit card fees.</p>",
    },
    {
      title: "Important dates",
      icon: <AppCalendar />,
      html:
        "<p>If you're on the move, let us know your new address, your move dates and when you want our services to start.</p>",
    },
    {
      title: "Broadband information",
      icon: <AppBroadbandClock />,
      html:
        "<p>If you signed up to Broadband and currently have a Broadband provider, we'll need to " +
        "know the company name and account no. to begin the switching process.</p>",
    },
  ],
  joinErrorModalContent: {
    buttonLabel: "Close",
    content:
      "<p>Apologies, we’re currently experiencing technical issues with your request . To complete your order, " +
      'please call us on <a href="tel:0800 494 847">0800 494 847</a> or contact us ' +
      '<a href="https://contact.co.nz/support/online-enquiries" rel="noopener noreferrer" target="_blank" rel="noopener noreferrer"> here</a></p>',
  },
};

export const FindPlan = {
  journeyType: "Join",
  newBuildUrl: "https://contact.co.nz/support/building-a-new-home",
  yourAddressTitle: "What’s your street address?",
  serviceTitle: "Which services would you like?",
  planTitle: "Choose your new plan",
  gasTitle: "What will you be using bottled gas for?",
  bbTitle: "Which broadband type would you like?",
  elecNeededNote:
    "To sign up to our Broadband service you must have your Electricity account at the same address.",
  serviceEmptyNote: "One of the service selection is mandatory.",
  onlyLPGNote:
    "You do not need to select a plan to order bottled gas, please continue to the next question.",
  onlyGasSANote:
    "You don't need to select any plan with Piped Gas as only service for South Island addresses.",
  bothGasNote: "You cannot have Bottled Gas and Piped Gas at the same time.",
  cookingGasNote:
    "Unfortunately, we are unable to supply bottled gas if gas will be used for cooking only.",
  bbAddNote:
    "We have added BroadBand service into your selection since is required on this plan.",
  bbRemoveNote:
    "We have removed your selected plan since BroadBand is required on this plan.",
  gasAddNote:
    "We have added Piped gas service into your selection since is required on this plan.",
  simplicityRemoveNote:
    "We have removed your selected plan since Piped Gas is required on this plan.",
  southRemoveNote:
    "We have removed your selected plan since it is not available in South Island.",
  ppRemoved:
    "We have removed your selected plan since Piped Gas is required on this plan.",
  basicPlan: "BASIC",
  simplicityPlan: "SIMPLICITYBUNDLE",
  gasRatesDayUnit: "day",
  lpgOnlyPlan: "LPG-ONLY",
  bbBundle: "BB_BUNDLE_AGENT",
  rockGas: "ROCKGAS",
  addressRequired:
    "Your street address and services are required to calculate your rates",
  serviceAddress: {
    addressRequiredMsg: "Address is required",
    addressInputLabel: "Enter your street address",
    broadbandAddressInputLabel: "Enter address details here:",
  },
  serviceButtons: [
    {
      name: "electricity",
      code: "ELEC",
      icon: <AppElectricity />,
      bg: "#fff",
      bgSelected: "#32122f",
      bgHover: "rgba(110, 13, 131, 0.05)",
      bgSelectedHover: "#32122f",
    },
    {
      name: "broadband",
      code: "BB",
      icon: <AppBroadbandHome />,
      bbg: "#fff",
      bgSelected: "#32122f",
      bgHover: "rgba(110, 13, 131, 0.05)",
      bgSelectedHover: "#32122f",
    },
    {
      name: "piped gas",
      code: "GAS",
      icon: <Gas />,
      bg: "#fff",
      bgSelected: "#32122f",
      bgHover: "rgba(110, 13, 131, 0.05)",
      bgSelectedHover: "#32122f",
    },
    {
      name: "bottled gas",
      code: "LPG",
      icon: <AppBottledGas />,
      bg: "#fff",
      bgSelected: "#32122f",
      bgHover: "rgba(110, 13, 131, 0.05)",
      bgSelectedHover: "#32122f",
    },
  ],
  gasButtons: [
    {
      name: "heating",
      code: "heating",
      bg: "#fff",
      bgSelected: "#32122f",
      bgHover: "rgba(110, 13, 131, 0.05)",
      bgSelectedHover: "#32122f",
    },
    {
      name: "hot water",
      code: "hot water",
      bg: "#fff",
      bgSelected: "#32122f",
      bgHover: "rgba(110, 13, 131, 0.05)",
      bgSelectedHover: "#32122f",
    },
    {
      name: "cooking",
      code: "cooking",
      bg: "#fff",
      bgSelected: "#32122f",
      bgHover: "rgba(110, 13, 131, 0.05)",
      bgSelectedHover: "#32122f",
    },
  ],
  bbDefault: [
    {
      BundlePrice: "$89.99/mth",
      DownloadSpeed: "10",
      Id: "ADSL",
      Label: "",
      Name: "ADSL",
      OriginalPrice: "$89.99/mth",
      UploadSpeed: "2",
    },
    {
      BundlePrice: "$89.99/mth",
      DownloadSpeed: "70",
      Id: "VDSL",
      Label: "",
      Name: "VDSL",
      OriginalPrice: "$89.99/mth",
      UploadSpeed: "15",
    },
    {
      BundlePrice: "$89.99/mth",
      DownloadSpeed: "100",
      Id: "FASTFIBRE",
      Label: "Best for families",
      Name: "Fast Fibre",
      OriginalPrice: "$89.99/mth",
      UploadSpeed: "20",
    },
    {
      BundlePrice: "$114.99/mth",
      DownloadSpeed: "200",
      Id: "FASTERFIBER",
      Label: "Best for renters",
      Name: "Faster Fibre",
      OriginalPrice: "$114.99/mth",
      UploadSpeed: "20",
    },
    {
      BundlePrice: "$129.99/mth",
      DownloadSpeed: "1000",
      Id: "MAXFIBRE",
      Label: "Best for streamers",
      Name: "Max Fibre",
      OriginalPrice: "$129.99/mth",
      UploadSpeed: "500",
    },
  ],
  bbCarouselConstants: {
    download: "Mbps download",
    upload: "Mbps upload",
    button: "Choose",
  },
  planCarouselConstants: {
    buttonBBColor: "#000000",
  },
  bbPlanId: "BB_BUNDLE_AGENT",
  joinRates: {
    id: "expressRates",
    rateLabels: {
      [SERVICE_ID_ELECTRICITY]: ["Your electricity rates", "Electricity rates"],
      [SERVICE_ID_PIPED_GAS]: ["Your piped gas rates", "Gas rates"],
      [SERVICE_ID_BROADBAND]: ["Your broadband rates", "Broadband rates"],
    },
    messages: {
      noAddress:
        "Your street address and services are required to calculate your rates",
      error:
        "Unfortunately we’re unable to provide you with an estimate for this address. You can still complete " +
        "your order, or you can call us on 0800 80 9000 and we can provide you with an estimate.",
      multipleRates: "There are multiple rates for your Property",
      noElectricityRates:
        "Unfortunately we’re unable to provide you with Electricity Rates estimate for this address. You can still " +
        "complete your order, or you can call us on 0800 494 847 and we can provide you with an estimate.",
      noGasRates:
        "Unfortunately we’re unable to provide you with Gas Rates estimate for this address. You can still " +
        "complete your order, or you can call us on 0800 494 847 and we can provide you with an estimate. ",
      expand: "View details",
      collapse: "Hide details",
      electricityLabel: "Electricity",
      gasLabel: "Piped gas",
      standardUserRates: "standard user rates",
      lowUserRates: "low user rates",
      disclaimer:
        "Energy rates are before GST and any applicable discounts and are based on the plan you selected (or " +
        "our Basic plan if you haven’t chosen one yet). Electricity rates are based on the information available " +
        "for your property, and natural gas rates are the most common in your region. If you require LPG (i.e. " +
        "piped gas in selected South Island areas / bottled gas) or your property has unmetered load, rates will " +
        "be confirmed in your welcome letter. We do our best to provide accurate rates, but there are rare " +
        "occasions where your actual prices may differ and we will confirm these once we have worked through all " +
        "the details.",
      usageTypeSwitch: "Switch to %usageType% user",
    },
  },
  noIcp:
    "Unfortunately we`re unable to provide you with an estimate for this address. You can still complete your order, or you can call us on 0800 494 847 and we can provide you with an estimate.",
  detailsTitle: "Basic",
  description:
    "Move house faster with this simple, hassle-free plan. Get transparent pricing plus the flexibility of no fixed term and no break fees.",
  includeTitle: "Express move with the Basic plan includes:",
  gasModule: {
    icons: [
      {
        image: electricityImage,
        text: "Electricity only",
      },
      {
        image: smileImage,
        text: "No fixed term, no break fees, no hassle",
      },
      {
        image: pricintgImage,
        text: "Simple, transparent pricing",
      },
      {
        image: calentarImage,
        text: "Change your plan or add services anytime after your move",
      },
    ],
    planCard: {
      banner: "Change plan anytime",
      tag: "Popular",
      content: {
        plan: "Basic",
        header: "Simple",
        title: " energy rates",
        description: "No fixed term",
      },
    },
  },
  excludedBBType: "ADSL",
  primaryBBType: "FIBRE",
  joinErrorModalContent: {
    buttonLabel: "Close",
    content:
      "<p>Apologies, we’re currently experiencing technical issues with your request . To complete your order, " +
      'please call us on <a href="tel:0800 494 847">0800 494 847</a> or contact us ' +
      '<a href="https://contact.co.nz/support/online-enquiries" rel="noopener noreferrer" target="_blank" rel="noopener noreferrer"> here</a></p>',
  },
};

export const Summary = {
  header: "Summary",
  sideBarHeader: "Why Contact",
  sideBarList: [
    "No need to talk to your old supplier, we'll let them know",
    "Extended New Zealand based call centre hours. From 7am to 9pm, Monday to Friday, and 8am to 5pm on Saturdays.",
    "Over 80% of our generation is from renewable sources",
    "Track your usage and pay bills with our simple, easy-to-use app.",
  ],
  redirectOnErrorUrl: process.env.REACT_APP_IJOIN_START_URL,
  goBackText: "back to plan select",
  backToUrl: process.env.REACT_APP_IJOIN_START_URL,
  addressTitle: "Your street address",
  planTitle: "Your plan details",
  serviceTitle: "Your service and rates",
  broadbandTitle: "Broadband ",
  electricityTitle: "Electricity ",
  bottledGasTitle: "Bottled gas ",
  bottledGasDescription: "Delivery service",
  pipedGasTitle: "Piped gas ",
  pipedGasDescription: "Reticulated LPG",
  priceLabel: "Price per month",
  standardUser: "standard user",
  lowUser: "low user",
  promoCodeTitle: "Got a promo code?",
  promoCodePlaceholder: "Promo code",
  promoCodeButtonText: "APPLY",
  promoCodeButtonTextHover: "VALIDATE",
  promoCodeButtonTextApplied: "APPLIED",
  promoCodeNotApplicable:
    "Sorry, promo code does not apply to this service or plan.",
  emailTitle: "Please enter your email address",
  emailSubTitle:
    "We may contact you to ensure you complete your signup successfully",
  emailPlaceholder: "Email address",
  emailValidError: "Not valid email",
  emailEmptyError: "Email required",
  buttonText: "Continue to my details",
  buttonSubText: "3 - 4 minutes to complete",
  joinRates: {
    id: "joinRates",
    rateLabels: {
      ELEC: ["Your electricity rates", "Electricity rates"],
      GAS: ["Your piped gas rates", "Gas rates"],
      BB: ["Your broadband rates", "Broadband rates"],
    },
    messages: {
      noAddress:
        "Your street address and services are required to calculate your rates",
      error:
        "Unfortunately we’re unable to provide you with an estimate for this address. You can still complete " +
        "your order, or you can call us on 0800 80 9000 and we can provide you with an estimate.",
      multipleRates: "",
      noElectricityRates:
        "Unfortunately we’re unable to provide you with Electricity Rates estimate for this address. You can still " +
        "complete your order, or you can call us on 0800 494 847 and we can provide you with an estimate.",
      noGasRates:
        "Unfortunately we’re unable to provide you with Gas Rates estimate for this address. You can still " +
        "complete your order, or you can call us on 0800 494 847 and we can provide you with an estimate. ",
      expand: "View rates",
      collapse: "Close rates",
      electricityLabel: "Electricity",
      gasLabel: "Piped gas",
      standardUserRates: "standard user rates",
      lowUserRates: "low user rates",
      disclaimer: "",
      yourCurrentType: "We think you are a %currentUsageType% user. ",
      usageTypeSwitch: "Switch to %usageType% user rates",
      fixedRates: "fixed rates until ",
      notFixedRates: "No fixed term / No break-up fees",
    },
  },
  ratesDisclaimer:
    "Rates are based on the plan you selected and exclude GST. Electricity rates are based on the information" +
    "available for your property, and gas rates are the most common in your region. (If your property has unmetered load, additional charges will apply.) Broadband prices include GST.",
  emailDomainsList: [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "xtra.co.nz",
    "live.com",
    "mail.com",
    "msn.com",
    "aol.com",
  ],
  excludedPromo: {
    services: ["GAS", "LPG"],
    plansId: ["MONTHOFF", "EDBONUSFIXED"],
  },
  joinErrorModalContent: {
    buttonLabel: "Close",
    content:
      "<p>Apologies, we’re currently experiencing technical issues with your request . To complete your order, " +
      'please call us on <a href="tel:0800 494 847">0800 494 847</a> or contact us ' +
      '<a href="https://contact.co.nz/support/online-enquiries" rel="noopener noreferrer" target="_blank" rel="noopener noreferrer"> here</a></p>',
  },
};

export const AgentJoin = {
  journeyChannel: "AGENT",
  journeyType: "Join",
  continueButtonLabel: "Continue",
  title: "Join Us",
  newSessionAgentButton: "START NEW SESSION",
  category1: "Registries",
  category2: "Broadband",
  category3: "Bottle Gas",
  linksTitle: "Helpful links",
  linkHelp: { title: "Customer Help Tool", url: customerHelpTool },
  linkQuote: { title: "Quoting tool", url: quotingTool },
  linkFaults: { title: "Faults & Outages", url: fando },
  linkGoogleMap: { title: "Google Map", url: googleMapLink },
  linkPostcodeFinder: { title: "Postcode finder", url: postCodeFinderLink },
  linkElec: { title: "Electricity Registry", url: electricityRegistry },
  linkPG: { title: "Natural Gas Registry", url: gasRegistry },
  linkVumedaPortal: { title: "Vumeda Portal", url: broadbandPortal },
  linkDevoliSupport: { title: "Devoli Support", url: devoliSupport },
  linkMap: { title: "Broadband Map", url: broadbandMap },
  linkBottleGasMap: { title: "Bottle Gas Map", url: bottleGasMap },
  linkBottleGasPricing: {
    title: "Bottle Gas Pricing",
    url: bottleGasPricing,
  },
  noteMaxLength: 512,
  mailPaperless: "EMAL",
  mailPaper: "PAPR",
  clearSessionRedirectUrl: process.env.REACT_APP_SIGN_UP_START_URL,
  clearSession: "Clear and start over",
  newBuildUrl: "https://contact.co.nz/support/building-a-new-home",
  clearSessionModal: {
    primaryButtonContent: "Keep Info",
    secondaryButtonContent: "Delete & start over",
    html:
      '<strong style="font-size:20px;">Are you sure you want to delete this session and start over?</strong>',
  },
  questionsTitle: "Open questions",
  summaryTitle: "Summary",
  customerDetailsQuestions: [
    {
      title: "<b>Wrapping up the call</b>",
      content:
        "<ul><li>Replay address, services and plan</li><li>Replay move in/ start dates (if applicable)</li></ul>",
    },
    {
      title: "<b>If Broadband in order:</b>",
      content:
        "<ul><li>We unfortunately cannot guarantee our services will be available to you until it is successfully connected</li><li>There is a minimum 10 working days for connections to be completed</li><li>Modem postage and handling fee is $14.99 and will show on your next bill</li><li>Broadband is billed 1 month in advance, therefore you may receive a pro rata charge plus a month in advance on your first bill</li></ul>",
    },
    {
      title: "<b>If Bottle Gas in order:</b>",
      content: "<ul><li>LPG delivery can take 7 days+ </li></ul>",
    },
    {
      title: "<b>If fixed term plan:</b>",
      content:
        "<ul><li>Fixed term termination fee may apply if you choose to end your plan prior to the plan end date </li></ul>",
    },
    {
      title: "<b>If new to property:</b>",
      content:
        "<ul><li>The property is currently showing as connected however if a disconnection is being scheduled in the last 48 hours then the power may be off when you arrive. If this is the case, please call us to arrange a re connection.</li><li>There may be a power outage in your area that we haven't be able to notify you of.</li></ul>",
    },
  ],
  findAPlanQuestions: [
    "So I can recommend the correct plan, tell me a bit more about your household.",
    "Lets chat through through your energy needs.",
    "Have you heard about Contact Broadband?",
  ],
  accountNumberMinLength: 15,
  smartFuelFieldLength: 19,
  gasRatesDayUnit: "day",
  bbBundle: "BB_BUNDLE_AGENT",
  rockGas: "ROCKGAS",
  notesTitle: "Notes",
  notesButton: "Save note",
  sendSummary: "Send customer summary",
  customerDetails: {
    title: "Who am I speaking with today?",
    firstNameLabel: "First Name",
    lastNameLabel: "Last Name",
    firstNamePlaceholder: "",
    lastNamePlaceholder: "",
    firstNameMaxLength: 30,
    lastNameMaxLength: 30,
    spokenToUsBeforeLabel: "Spoken to us before?",
    phoneLabel: "Phone no.",
    phonePlaceholder: "e.g. 091234567",
    phoneMaxLength: 11,
    phoneRequiredMsg: "Phone number is required",
    phoneInvalidMsg: "Invalid phone number",
    dateLabel: "Date of birth",
    datePlaceholder: "DD / MM / YYYY",
    dateFormat: "DD/MM/YYYY",
    dateMinAge: 18,
    dateMinAgeErrorMsg: "You must be over 18 to signup",
    dateMaxAge: 100,
    dateMaxAgeErrorMsg: "Please check your date of birth",
    numberYearOptions: 82,
    dateRequiredMsg: "Date of birth is required",
    invalidDateMsg: "Invalid date",
    emailLabel: "Email",
    emailPlaceholder: "",
    emailMaxLength: 50,
    emailRequiredMsg: "Email is required",
    emailInvalidMsg: "Please enter a valid email address",
    customerNumberLabel: "BP number",
    findButton: "Find",
  },
  planServiceDetails: {
    title: "Tell me about your property",
    titlePlans: "Plans and rates",
    bbBundle: "BB_BUNDLE_AGENT",
    rockGas: "ROCKGAS",
    basicPlan: "BASIC",
    titleS: "Find address and services",
    simplicityPlan: "SIMPLICITYBUNDLE",
    lpgOnlyPlan: "LPG-ONLY",
    elecNeededNote:
      "To sign up to our Broadband service you must have your Electricity account at the same address.",
    serviceEmptyNote: "One of the service selection is mandatory.",
    onlyLPGNote:
      "You do not need to select a plan to order bottled gas, please continue to the next question.",
    onlyGasSANote:
      "You don't need to select any plan with Piped Gas as only service for South Island addresses.",
    bothGasNote: "You cannot have Bottled Gas and Piped Gas at the same time.",
    cookingGasNote:
      "Unfortunately, we are unable to supply bottled gas if gas will be used for cooking only.",
    bbAddNote:
      "We have added BroadBand service into your selection since is required on this plan.",
    bbRemoveNote:
      "We have removed your selected plan since BroadBand is required on this plan.",
    gasAddNote:
      "We have added Piped gas service into your selection since is required on this plan.",
    simplicityRemoveNote:
      "We have removed your selected plan since Piped Gas is required on this plan.",
    southRemoveNote:
      "We have removed your selected plan since it is not available in South Island.",
    ppRemoved:
      "We have removed your selected plan since Piped Gas is required on this plan.",
    gasStatus: [
      {
        connectionStatus: "GAS",
        description: "Gas able to flow",
        status: "ACTC",
      },
      {
        connectionStatus: "GAS",
        description: "Gas able to flow",
        status: "ACTV",
      },
      {
        connectionStatus: "GDE",
        description:
          "Service disconnected from network outside property and service abandoned",
        status: "DECR",
      },
      {
        connectionStatus: "GIR",
        description: "Gas ready to flow",
        status: "READY",
      },
      {
        connectionStatus: "GMM",
        description:
          "Gas maintenance disconnect  GMS removed supply capped or plugged",
        status: "INACT",
      },
      {
        connectionStatus: "GMC",
        description:
          "Gas maintenance disconnect  GMS removed supply capped or plugged",
        status: "INACT",
      },
      {
        connectionStatus: "GMU",
        description:
          "Gas maintenance disconnect  GMS remains service disconnected upstream of service valve by network operator",
        status: "INACT",
      },
      {
        connectionStatus: "GNC",
        description:
          "Gas currently not required  GMS remains supply capped or plugged",
        status: "INACT",
      },
      {
        connectionStatus: "GNM",
        description:
          "Gas currently not required  GMS removed supply capped or plugged",
        status: "INACT",
      },
      {
        connectionStatus: "GPC",
        description:
          "Gas permanent disconnect ready for GMS removal  GMS remains supply capped or plugged",
        status: "INACP",
      },
      {
        connectionStatus: "GPM",
        description:
          "Gas permanent disconnect ready for decommissioning  GMS removed supply capped or plugged",
        status: "INACP",
      },
      {
        connectionStatus: "GSC",
        description:
          "Gas safety disconnect  GMS remains supply capped or plugged",
        status: "INACT",
      },
      {
        connectionStatus: "GSM",
        description:
          "Gas safety disconnect  GMS remains supply capped or plugged",
        status: "INACT",
      },
      {
        connectionStatus: "GSU",
        description:
          "Gas safety disconnect  GMS remains service disconnected upstream of service valve by distributor",
        status: "INACT",
      },
      {
        connectionStatus: "GTD",
        description:
          "Gas temporary disconnect - GMS remains service turned off at service valve or supply capped or plugged",
        status: "ACTC",
      },
      {
        connectionStatus: "GVC",
        description:
          "Gas vacant disconnect  GMS remains supply capped or plugged",
        status: "INACT",
      },
      {
        connectionStatus: "GVL",
        description:
          "Historic gas transitional disconnect -- GMS removed service turned off and locked at service valve",
        status: "INACT",
      },
      {
        connectionStatus: "GVM",
        description:
          "Gas vacant disconnect  GMS removed supply capped or plugged",
        status: "INACT",
      },
      {
        connectionStatus: "GVT",
        description:
          "Historic gas transitional disconnect -- GMS remains service turned off at service valve",
        status: "INACT",
      },
      {
        connectionStatus: "NEW",
        description: "Pre-activation service has not yet been installed",
        status: "NEW",
      },
    ],

    newBuildUrl: "https://contact.co.nz/support/building-a-new-home",
    serviceTitle: "Which services would you like?",
    services: {
      broadband: {
        networkLinkURL: "https://broadbandmap.nz/",
        networkLinkLabel: "Go to site",
      },
    },
    serviceButtons: [
      {
        name: "broadband",
        code: "BB",
        icon: <BroadbandHomeBold />,
        bbg: "#fff",
        bgSelected: "#32122f",
        bgHover: "rgba(110, 13, 131, 0.05)",
        bgSelectedHover: "#32122f",
      },
      // { name: 'piped gas', code: "GAS", icon: <Gas />, bg: '#fff', bgSelected: '#32122f', bgHover: 'rgba(110, 13, 131, 0.05)', bgSelectedHover: '#32122f' },
      {
        name: "bottled gas",
        code: "LPG",
        icon: <BottledGasIcon />,
        bg: "#fff",
        bgSelected: "#32122f",
        bgHover: "rgba(110, 13, 131, 0.05)",
        bgSelectedHover: "#32122f",
      },
    ],
    gasButtons: [
      {
        name: "heating",
        code: "heating",
        bg: "#fff",
        bgSelected: "#32122f",
        bgHover: "rgba(110, 13, 131, 0.05)",
        bgSelectedHover: "#32122f",
      },
      {
        name: "hot water",
        code: "hot water",
        bg: "#fff",
        bgSelected: "#32122f",
        bgHover: "rgba(110, 13, 131, 0.05)",
        bgSelectedHover: "#32122f",
      },
      {
        name: "cooking",
        code: "cooking",
        bg: "#fff",
        bgSelected: "#32122f",
        bgHover: "rgba(110, 13, 131, 0.05)",
        bgSelectedHover: "#32122f",
      },
    ],
    consumptionBands: ["Low - LCR_01", "Standard - LCR_02", "High - LCR_03"],
    gasUsageTitle: "Gas Usage",
    pipedGasDescription:
      "This property is in a <strong>reticulated LPG</strong> area.",
    joinRates: {
      id: "joinRates",
      rateLabels: {
        ELEC: ["Your electricity rates", "Electricity rates"],
        GAS: ["Your piped gas rates", "Gas rates"],
        BB: ["Your broadband rates", "Broadband rates"],
      },
      messages: {
        noAddress:
          "Your street address and services are required to calculate your rates",
        error:
          "Unfortunately we’re unable to provide you with an estimate for this address. You can still complete " +
          "your order, or you can call us on 0800 80 9000 and we can provide you with an estimate.",
        multipleRates: "There are multiple rates for your Property",
        noElectricityRates:
          "Unfortunately we’re unable to provide you with Electricity Rates estimate for this address. You can still " +
          "complete your order, or you can call us on 0800 494 847 and we can provide you with an estimate.",
        noGasRates:
          "Unfortunately we’re unable to provide you with Gas Rates estimate for this address. You can still " +
          "complete your order, or you can call us on 0800 494 847 and we can provide you with an estimate. ",
        expand: "View rates",
        collapse: "Close rates",
        electricityLabel: "Electricity",
        gasLabel: "Piped gas",
        standardUserRates: "standard user rates",
        lowUserRates: "low user rates",
        disclaimer: "",
        yourCurrentType: "We think you are a %currentUsageType% user. ",
        usageTypeSwitch: "Switch to %usageType% user rates",
        fixedRates: "fixed rates until ",
        notFixedRates: "No fixed term / No break-up fees",
      },
    },
    bbCarouselConstants: {
      download: "Mbps download",
      upload: "Mbps upload",
      button: "Choose",
    },
    bbPlanId: "BB_BUNDLE_AGENT",
    planCarouselConstants: {
      buttonBBColor: "#000000",
    },
    promoCodeTitle: "Promo code",
    promoCodePlaceholder: "Promo code",
    promoCodeButtonText: "APPLY",
    promoCodeButtonTextHover: "VALIDATE",
    promoCodeButtonTextApplied: "APPLIED",
    promoCodeNotApplicable:
      "Sorry, promo code does not apply to this service or plan.",
    promoCodeDisclaimer:
      "Discounts only apply towards electricity and natural gas usage.",
    excludedPromo: {
      services: ["GAS", "LPG"],
      plansId: ["MONTHOFF", "EDBONUSFIXED"],
    },
  },
};

export const SaveForLater = {
  type: "SAVE_FOR_LATER",
  title: "Send customer summary by email",
  primaryButtonContent: "Cancel",
  secondaryButtonContent: "Send summary",
  secondaryButtonLoadContent: "Load this profile",
  marketingOffersCheckboxLabel:
    "Would you like to receive occasional marketing and special offer emails from Contact?",
  emailLabel: "Email",
  emailPlaceholder: "",
  emailMaxLength: 50,
  emailRequiredMsg: "Email is required",
  emailInvalidMsg: "Please enter a valid email address",
  information:
    "If you'd like to take your time and explore other options, we can send you a summary of our conversation today, including all the plan and rate details. If you decide you'd like to proceed with the order, just pick up straight from the email or call us.",
  resultsModalSubtext:
    "We've found a match based on the information you provided.",
  resultsModalFooter1: "Not right? ",
  resultsModalFooter2: "Search again",
  errorMessageBlurb1: "No matches",
  errorMessageBlurb2: "Continue with signup as usual.",
  errorMessageMultipleResults:
    "Multiple match found, please try again with unique identifier.",
  errorMessageButton: "CONTINUE with signup",
};

export const Login = {
  pageTitle: "Contact Energy | Express Move Login",
  cardTitle: "Let's get started",
  radioButtonTypes: ["EXISTING", "NONE", "NEW"],
  existingAccountLabel: "I have an online account (Contact 'My Account')",
  noAccountLabel: "I don't have an online account",
  newToContactLabel: "I'm new to contact",
  usernamePlaceholder: "Email or username",
  passwordPlaceholder: "Password",
  passwordHint: "Forgot password?",
  loginButtonText: "LOG IN",
  forgotPasswordLink: `${process.env.REACT_APP_EXPRESS_BASE_URL}/account/forgotten-password`,
  makeChangesLink: `${process.env.REACT_APP_EXPRESS_BASE_URL}/residential/make-changes#MoveHouse`,
  continueButtonText: "CONTINUE WITHOUT A LOG IN",
  registerText1: "Or, ",
  registerText2: "register for My account",
  registerLink: `${process.env.REACT_APP_EXPRESS_BASE_URL}/account/register`,
  findAPlanButtonText: "LET'S GET STARTED",
  findAPlanButtonLink: `${process.env.REACT_APP_EXPRESS_BASE_URL}/residential/find-a-plan`,
  sidebarTitle: "Why express move?*",
  sideBarItems: [
    "Faster checkout giving you more time to plan your move",
    "You'll be placed on the 'Basic plan' with no fixed term or break fees",
    "Feel free to change your plan after you've moved into your new home",
  ],
  sideBarDescription:
    "*Available for current Contact customers that only need electricity at their new property. You can add services after you've moved into your new property.",
  automationIds: {
    existingAccount: "Login-existing-account",
    noAccount: "Login-no-account",
    newToContact: "Login-new-to-contact",
    loginButton: "Login-login-button",
    usernameInput: "Login-username-input",
    passwordInput: "Login-password-input",
  },
  redirectToExpressUrl: "/residential/express-move",
  redirectToLoginUrl: "/residential/express",
  errorMessage: "Try another username or password",
  usernameRequiredErrorMessage: "Email or Username is required",
  passwordRequiredErrorMessage: "Password is required",
  invalidPasswordErrorMessage: "Invalid Password",
};

export const Success = {
  pageTitle: "Contact Energy | Success",
  greeting: "Thanks %firstName%",
  greetingSubtitle: "We’re glad you’ve decided to join us.",
  greetingExpressSubtitle: "We’re glad you’ve decided to move with us.",
  redirectOnErrorUrl: process.env.REACT_APP_EXPRESS_BASE_URL + "/residential",
  nextStepsTitle: "What happens next?",
  steps: [
    {
      title: "Services",
      icon: <AppElectricity />,
      html:
        "<p>You requested %services% to start on <b>%startDate%</b> at <b>%address%</b>.</p>",
      required: ["services", "startDate", "address"],
    },
    {
      title: "Services",
      icon: <AppElectricity />,
      html: "<p>You requested %services%.</p>",
      required: ["services"],
      restricted: ["startDate"],
    },
    {
      title: "Confirmation email",
      icon: <AppEnvelope />,
      html: "<p>Shortly, you’ll receive an email confirming your details.</p>",
      successHtml:
        "<p>This will contain your account number " +
        "which will allow you to sign up for My Account and log into the Contact app.</p>",
    },
    {
      title: "Switching",
      icon: <AppHotMug />,
      html:
        "<p>No need to get in touch with your old supplier; we’ll do the switching for you.</p>",
      required: ["isSwitching"],
    },
    // {
    //   title: 'You have a standard meter',
    //   icon: <AppMeter/>,
    //   html: (
    //     '<p>You may need to provide a meter read. ' +
    //     '<a href="https://contact.co.nz/support/meters-and-connections#Standard-meters" target="_blank" rel="noopener noreferrer">View FAQs</a></p>'
    //   ),
    //   required: ['hasElectricity'],
    //   restricted: ['hasSmartMeter'],
    // },
    // {
    //   title: 'You have a smart meter',
    //   icon: <AppMeter/>,
    //   html: (
    //     '<p>No need to supply a meter read as we\'ll read your meter remotely ' +
    //     '<a href="https://contact.co.nz/support/meters-and-connections#Smart-meters" target="_blank" rel="noopener noreferrer">View FAQs</a></p>'
    //   ),
    //   required: ['hasElectricity', 'hasSmartMeter'],
    // },
    {
      title: "PrePay",
      icon: <AppPrepay />,
      html:
        '<p>Top-up now through <a href="https://contact.co.nz/account/sign-in" target="_blank" rel="noopener noreferrer">My Account</a>, on our ' +
        '<a href="https://contact.co.nz/residential/billing-and-payments/prepay" target="_blank" rel="noopener noreferrer">website</a>, on the ' +
        '<a href="https://contact.co.nz/support/our-apps" target="_blank" rel="noopener noreferrer">Contact app</a>, or by text.</p>',
      required: ["isPaymentPrepay"],
    },
    {
      title: "Bottled gas",
      icon: <AppBottledGas />,
      html:
        "<p>Our team is now processing your request, and will get back to you soon with more information " +
        "on pricing, bottled gas rental fees and delivery date (if applicable).</p>",
      required: ["hasBottledGas", "hasOrderBottledGas"],
    },
    {
      title: "Bottled gas",
      icon: <AppBottledGas />,
      html:
        "<p>Our team is now processing your request, and will get back to you soon with more information " +
        "on pricing, bottled gas rental fees and delivery date (if applicable).</p>" +
        "<p>When you’re ready to order bottled gas:</p>" +
        '<p><a href="https://contact.co.nz/support/contact-us" target="_blank" rel="noopener noreferrer">Give us a call</a> or once your new account is confirmed, order online ' +
        'via our <a href="https://contact.co.nz/support/our-apps" target="_blank" rel="noopener noreferrer">Bottled Gas App.</a></p>',
      required: ["hasBottledGas"],
      restricted: ["hasOrderBottledGas"],
    },
    {
      title: "Broadband",
      icon: <AppBroadbandHome />,
      html:
        "Your new broadband connection may take 10 working days or longer to complete depending on the " +
        "connection type and where you live. You’ll receive updates about it in the coming days. " +
        '<a href="https://contact.co.nz/support/broadband" target="_blank" rel="noopener noreferrer">See more info here.</a>',
      required: ["hasBroadband"],
    },
  ],
  belowNextStepsNote:
    'In the mean time, if you have any further questions please <a href="https://contact.co.nz/support/contact-us" target="_blank" rel="noopener noreferrer">get in touch</a>.',
  tipsTitle: "Helpful tips",
  tipsLabel1: "Need help? Get support",
  tipsLabel2: "Rewards with AA Smartfuel",
  tipsLabel3: "Flexible payment options",
  tipsLink1: process.env.REACT_APP_EXPRESS_BASE_URL + "/support",
  tipsLink2: process.env.REACT_APP_EXPRESS_BASE_URL + "/residential/rewards",
  tipsLink3:
    process.env.REACT_APP_EXPRESS_BASE_URL +
    "/residential/billing-and-payments/weekly-fortnightly",

  nps: {
    title: "Rate your experience with us",
    thanksMsg: "Thanks for messaging with us",
    backButton: "Back",
    continueButton: "Continue",
    asyncFormKey: "nps.async.feedback",

    score: {
      subtitle:
        "How likely are you to recommend Contact Energy to a colleague or a friend?",
      continueButton: "Continue",
    },
    feedback: {
      subtitle: "Why did you score %score%?",
      feedbackLabel: "Type your feedback here",
      backButton: "Back",
      continueButton: "Continue",
    },
    contactYou: {
      subtitle:
        "Would you like someone to contact you to discuss your comments?",
      yesButton: "Yes",
      noButton: "No",
      backButton: "Back",
      continueButton: "Continue",
    },
    contactDetails: {
      subtitle:
        "Please enter your details and one of our contact centre team will be in touch",
      nameLabel: "Name",
      nameRequiredMsg: "Name is required",
      phoneNumberLabel: "Phone Number",
      phoneNumberPlaceholder: "e.g. 091234567",
      phoneNumberRequiredMsg: "Phone account number is required",
      phoneNumberInvalidMsg: "Invalid home number",
      emailAddressLabel: "Email address",
      emailRequiredMsg: "Email address is required",
      emailInvalidMsg: "Please enter a valid email address",
      backButton: "Back",
      continueButton: "Continue",
    },
    promotionalPurposes: {
      subtitle:
        "Can we use your comment for our promotional purposes? In doing so, we’ll only publish your comment, first name and town",
      yesButton: "Yes",
      noButton: "No",
      backButton: "Back",
      continueButton: "Continue",
    },
    preferredChannel: {
      title: "If you couldn’t message us today, what would you have done?",
      subtitle: "Select one",
      channelLabels: [
        "call you",
        "email you",
        "call and email you",
        "try to figure it out myself",
        "nothing",
      ],
      call: "call you",
      email: "email you",
      callEmail: "call and email you",
      myself: "tried to figure it out myself",
      nothing: "nothing",
      yesButton: "Yes",
      noButton: "No",
      backButton: "Back",
      continueButton: "Continue",
    },
    asyncAgain: {
      title:
        "Would you use WhatsApp or Facebook Messenger to speak with us again?",
      yesButton: "Yes",
      noButton: "No",
      backButton: "Back",
      continueButton: "Continue",
    },
    thankYou: {
      title: "Thanks, we appreciate your feedback.",
      subtitle: "Join our Customer Panel",
      content:
        "Want to help shape the future of Contact Energy? We're launching an online customer panel and we'd love you to be a part of it. You'll get the chance to have your say and we'll reward your feedback with credits on your energy bill.",
      joinButton: "Join the panel",
      redirectURL: "http://surveys.perceptive.co.nz/s3/Energy-panel-customer",
    },
  },
  displayDateFormat: "dddd D MMM",
  apiDateFormat: "DD/MM/YYYY",
};
export const NPS = Success.nps;

export const Footer = {
  links: [
    {
      title: "Legal",
      href: process.env.REACT_APP_EXPRESS_BASE_URL + "/legal",
    },
    {
      title: "Terms and Conditions",
      href:
        process.env.REACT_APP_EXPRESS_BASE_URL + "/legal/terms-and-conditions",
    },
    {
      title: "Privacy",
      href: process.env.REACT_APP_EXPRESS_BASE_URL + "/legal/privacy",
    },
  ],
  copyright: "© Contact Energy Ltd, All rights reserved 2019",
  contactLogoAlt: "Contact Energy Logo",
};

export const Express = {
  pageTitle: "Contact Energy | Express Move",
  title: "Express move your electricity with the Basic plan",
  detailsTitle: "Basic",
  description:
    "Move house faster with this simple, hassle-free plan. Get transparent pricing plus the flexibility of no fixed term and no break fees.",
  includeTitle: "Express move with the Basic plan includes:",
  icons: [
    {
      image: electricityImage,
      text: "Electricity only",
    },
    {
      image: smileImage,
      text: "No fixed term, no break fees, no hassle",
    },
    {
      image: pricintgImage,
      text: "Simple, transparent pricing",
    },
    {
      image: calentarImage,
      text: "Change your plan or add services anytime after your move",
    },
  ],
  planCard: {
    banner: "Change plan anytime",
    tag: "Popular",
    content: {
      plan: "Basic",
      header: "Simple",
      title: " energy rates",
      description: "No fixed term",
    },
  },
  promoCode: {
    title: "Promo code",
    buttonText: "Apply",
    buttonImage: "",
    error: "This promocode is invalid",
  },
  tc: {
    title: "Important things to know",
    bulletPoints: [
      "The plan is available with SmoothPay, Control Pay and Contact PrePay.",
      "The plan is not available to PrePower customers and is not available for any LPG supply.",
      "Our standard billing and payment fees are charged if you receive a paper bill or pay by credit card. You can avoid these fees by choosing to get your correspondence by email or choosing a different payment method.",
      "Overdue payments may incur our standard $25 debt management fee.",
      "Subject to a satisfactory credit check.",
    ],
    file: planTermsAndConditionsURL,
    termsButtonLabel: "Terms & Conditions",
    moduleButtonLabel: "View full terms and conditions",
  },
  property: {
    title: "About your new property",
  },
  moveOutAddress: {
    addressInputLabel: "Address you're moving from",
    addressRequiredMsg: "Address is required",
    dateInputLabel: "Move out date",
    datePlaceholder: "DD / MM / YYYY",
    dateRequiredMsg: "Move out date is required",
    automationIds: {
      autocomplete: "moveOutAddressAutocomplete",
      datepicker: "moveOutAddressDatepicker",
    },
  },
  moveInAddress: {
    addressInputLabel: "Where are you moving to?",
    addressRequiredMsg: "Address is required",
    addressInvalidMsg:
      "Moving to address can not be same as moving from address",
    dateInputLabel: "Move in date",
    datePlaceholder: "DD / MM / YYYY",
    dateMaxMonths: 2,
    dateRequiredMsg: "Move in date is required",
    dateMaxMonthsErrorMsg: "Please check the date entered",
  },
  accountNumber: "Account number #",
  hazards: {
    label: "Potential hazards or access issues at my property",
    optionsOther: "Other",
    helpText:
      "We require this information just in case a meter reader needs to visit.",
    optionsNoHazards: "No hazards",
    options: ["No hazards", "Dog", "Other"],
    otherHazards: "Other hazards not mentioned above",
    otherHazardsPlaceholder: "",
    otherHazardsRequiredMsg: "Hazard details are required.",
  },
  tcCheckbox: {
    label: `I agree to the <a href="${planTermsAndConditionsURL}" download rel="noopener noreferrer">terms and conditions</a> of the Basic Plan`,
    subLabel:
      "We will keep your account preferences the same, for example, contact details and billing and payment methods. Please note, if " +
      "you’re on weekly / fortnightly billing or Contact PrePay we may not be able to transfer these services over to your new property due to " +
      "eligibility criteria, though we will be in touch if there are any issues. If you would like to update your account preferences or sign " +
      "up for other services at your new property, such as gas, please log-in to My Account or the Contact App to do this after your new connection " +
      "has been set up. As you’re an existing customer, any applicable terms and conditions already accepted by you will continue to apply.",
  },
  submitButtonText: "Express move",
  submitButtonSecondaryText: "with the Basic plan",
  expressJourneyType: "EXPRESS-MOVE",
  planInfo: {
    planName: "Basic plan",
    planId: "BASIC",
    campaignId: "R5BASO00",
  },
  dateFormat: "DD/MM/YYYY",
  redirectOnErrorUrl: process.env.REACT_APP_EXPRESS_BASE_URL,
  errorModalContent: {
    buttonLabel: "Close",
    content:
      "<p>Apologies, we’re currently experiencing technical issues with your request . To complete your order, " +
      'please call us on <a href="tel:0800 494 847">0800 494 847</a> or contact us ' +
      '<a href="https://contact.co.nz/support/online-enquiries" rel="noopener noreferrer" target="_blank" rel="noopener noreferrer"> here</a></p>',
  },
  dualCustomerMessage:
    "Please note - Express Move is for customers that only require electricity at their new address. Existing services (e.g. Gas or Broadband) will not carry over to the new property.",
};

export const AppError = {
  message:
    "An unexpected error has occurred. We are working on this issue. Please try again later.",
  buttonLabel: "Close",
};

export const ExpressRates = {
  id: "expressRates",
  rateLabels: {
    [SERVICE_ID_ELECTRICITY]: ["Your electricity rates", "Electricity rates"],
    [SERVICE_ID_PIPED_GAS]: ["Your piped gas rates", "Gas rates"],
    [SERVICE_ID_BROADBAND]: ["Your broadband rates", "Broadband rates"],
  },
  messages: {
    noAddress:
      "Your street address and services are required to calculate your rates",
    error:
      "Unfortunately we’re unable to provide you with an estimate for this address. You can still complete " +
      "your order, or you can call us on 0800 80 9000 and we can provide you with an estimate.",
    multipleRates: "There are multiple rates for your Property",
    noElectricityRates:
      "Unfortunately we’re unable to provide you with Electricity Rates estimate for this address. You can still " +
      "complete your order, or you can call us on 0800 494 847 and we can provide you with an estimate.",
    noGasRates:
      "Unfortunately we’re unable to provide you with Gas Rates estimate for this address. You can still " +
      "complete your order, or you can call us on 0800 494 847 and we can provide you with an estimate. ",
    expand: "View rates",
    collapse: "Hide rates",
    electricityLabel: "Electricity",
    gasLabel: "Piped gas",
    standardUserRates: "standard user rates",
    lowUserRates: "low user rates",
    disclaimer:
      "Energy rates are before GST and any applicable discounts and are based on the plan you selected (or " +
      "our Basic plan if you haven’t chosen one yet). Electricity rates are based on the information available " +
      "for your property, and natural gas rates are the most common in your region. If you require LPG (i.e. " +
      "piped gas in selected South Island areas / bottled gas) or your property has unmetered load, rates will " +
      "be confirmed in your welcome letter. We do our best to provide accurate rates, but there are rare " +
      "occasions where your actual prices may differ and we will confirm these once we have worked through all " +
      "the details.",
    usageTypeSwitch: "Switch to %usageType% user rates",
  },
};

export const CreditCheckResult = {
  title: "Credit check result",
  resultLabel: "Result",
  passResultText: "Pass",
  amberResultText: "Amber",
  failResultText: "We can’t take you on as a customer.",
  exceptionResultText: "Exception",
  nextStepsLabel: "Next steps",
  amberNextStepsText:
    "You’ll need to pay a $150 bond to sign up as a customer with us. Or we can sign you up for Prepay.",
  failNextStepsText:
    "We’re unable to take you on as a customer. You may be eligible for prepay.",
  exceptionNextStepsText: "Proceed with sign-up.",
  amberCheckboxText: "Pay $150 bond",
  failCheckboxText: "Sign up for Prepay",
  prepayEligible:
    "Eligible for PrePay - Please ensure only ‘Electricity’ is selected. PrePay option is under ‘Payment Setup'",
};

export const Gtm = {
  reactAppId: process.env.REACT_APP_GTM_ID,
  reactAgentAppId: process.env.REACT_APP_AGENT_GTM_ID,
};

export const ApiError = {
  errorTitle: "Something went wrong.",
  errorMessage: "Please try again.",
  serverErrorMessage: "Please try again or proceed with journey.",
  bpServerErrorMessage:
    "BP creation failed, please try again or proceed with journey.",
  ccServerErrorMessage:
    "Credit check operation failed, please try again or proceed with journey.",
};

export const Tracking = {
  ELEC: "electricity",
  GAS: "piped gas",
  LPG: "bottled gas",
  BB: "broadband",
  valid: "valid",
  invalid: "invalid",
  categoryNew: "New/Join",
  fail: "failed",
  pass: "passed",
  noUse: "not used",
};
export const Titles = [
  "Mr",
  "Mrs",
  "Miss",
  "Ms",
  "Doctor",
  "Reverend",
  "Professor",
  "Lady",
  "Sir",
  "Captain",
  "Senior",
  "Sister",
  "Dame",
  "Judge",
  "MP",
  "Pastor",
  "Commander",
  "Junior",
  "MDM",
  "Master",
  "Bishop",
  "Estate of",
  "Mx",
];

export const servicesFullName = {
  ELEC: "Electricity",
  GAS: "Piped Gas",
  LPG: "Bottled Gas",
  BB: "Broadband",
};
