// @flow
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";

import {
  ArrowDown,
  Checkbox,
  ExpandableCard,
  Input,
  Address,
  CheckboxList,
  RadioButton,
  DateUtil,
  DatePicker,
} from "react-components";
import { triggerAgentPageView } from "../../../../Utils/analytics";

import "../../../../Containers/Property/Property.scss";

import ServiceAddress from "../../../../Containers/FindPlan/Address";

import {
  Property as Constants,
  SITUATION_MOVING,
  SITUATION_NOT_MOVING,
  SITUATION_CHANGE_OWNERSHIP,
} from "../../../../Config/Constants";
import { useStateFromProp } from "../../../../Utils/hooks";
import {
  getHasElectricity,
  getHasBottledGas,
  getPropertyAddress,
  getServicesStartDate,
  getIsBottleGasOnly,
} from "../../../../Utils/selectors.js";
import { saveCustomerData } from "../../../../Redux/Actions/CSRAgent/JoinAction";

type Props = {
  address: string,
  addressMoniker?: string,
  postalAddress?: string,
  postalAddressMoniker?: string,
  addressSameAsPostalAddress?: boolean,
  hazards?: Array<string>,
  hazardsOther?: string,
  accountStart?: string,
  situation?: string,
  situationOtherDetails?: string,
  hasElectricity: boolean,
  hasBottledGas: boolean,
  isCurrentCardOpen?: boolean,
  isCardDisabled?: boolean,
  validate?: boolean,
  savePropertyData?: () => void,
  onClick?: () => void,
  setValid: () => {},
};

export const Property = (props: Props) => {
  // Address
  const [address, setAddress] = useStateFromProp(props.address, "");
  const [addressMoniker, setAddressMoniker] = useStateFromProp(
    props.addressMoniker,
    ""
  );
  const [addressHasError, setAddressHasError] = useState(false);
  const [
    addressSameAsPostalAddress,
    setAddressSameAsPostalAddress,
  ] = useStateFromProp(props.addressSameAsPostalAddress, true);
  const [postalAddress, setPostalAddress] = useState(props.postalAddress, "");
  const [postalAddressMoniker, setPostalAddressMoniker] = useStateFromProp(
    props.postalAddressMoniker,
    ""
  );
  const [postalAddressHasError, setPostalAddressHasError] = useState(false);

  // Situation
  const [situation, setSituation] = useStateFromProp(props.situation, "");
  const [serviceSwitchDate, setServiceSwitchDate] = useStateFromProp(
    props.accountStart,
    DateUtil.getCurrentDate(Constants.dateFormat)
  );
  const [serviceSwitchDateHasError, setServiceSwitchDateHasError] = useState(
    false
  );
  const [situationOtherDetails, setSituationOtherDetails] = useStateFromProp(
    props.situationOtherDetails,
    ""
  );
  const [
    situationOtherDetailsHasError,
    setSituationOtherDetailsHasError,
  ] = useState(false);

  // Hazards
  const [hazards, setHazards] = useStateFromProp(props.hazards, []);
  const [hazardsOther, setHazardsOther] = useStateFromProp(
    props.hazardsOther,
    ""
  );
  const [hazardsOtherSelected, setHazardsOtherSelected] = useState(false);
  const [hazardsConstants, setHazardsConstants] = useState({});
  const [hazardsCheckboxOptions, setHazardsCheckboxOptions] = useState([]);
  const [hazardsOtherHasError, setHazardsOtherHasError] = useState(false);
  const [previewHazardsContent, setPreviewHazardsContent] = useState("");

  const [cardOpen, setCardOpen] = useState(true);

  const [focusedInput, setFocusedInput] = useState("");

  const [pageViewed, setPageViewed] = useState(false);

  //validation
  // eslint-disable-next-line
  const [validateItself, setValidateItself] = useState(false);

  const invalidAddressPart =
    !address ||
    addressHasError ||
    (!addressSameAsPostalAddress && (!postalAddress || postalAddressHasError));

  const invalidSituationPart =
    !props.isBottledGasOnly &&
    (!situation ||
      (situation === SITUATION_MOVING &&
        (!serviceSwitchDate || serviceSwitchDateHasError)));

  const invalidHazardPart =
    !hazards ||
    hazards?.length < 1 ||
    (hazardsOtherSelected && hazardsOther === "");

  useEffect(() => {
    if (props.isBottledGasOnly) {
      setSituation(SITUATION_NOT_MOVING);
    }
  }, [props.isBottledGasOnly, setSituation]);

  useEffect(() => {
    if (invalidAddressPart || invalidSituationPart || invalidHazardPart) {
      props.setValid("Property", false);
    } else {
      props.setValid("Property", true);
    }
    // eslint-disable-next-line
  }, [invalidAddressPart, invalidSituationPart, invalidHazardPart]);

  useEffect(() => {
    if (props.validate) {
      setValidateItself(true);
      setCardOpen(true);
    }
  }, [props.validate]);

  useEffect(() => {
    setPreviewHazardsContent(getPreviewHazardsContent(hazards, hazardsOther));
    // eslint-disable-next-line
  }, [hazards, hazardsOther]);

  useEffect(() => {
    if (postalAddress && postalAddressMoniker && !props.isCallingAPI) {
      handleSaveData();
    }
    // eslint-disable-next-line
  }, [postalAddress, postalAddressMoniker]);

  useEffect(() => {
    const mappedHazard = props.hasBottledGas
      ? props.hasElectricity
        ? Constants.hazards.bottleGasWithElectricity
        : Constants.hazards.bottleGasOnly
      : Constants.hazards.electricityOrPipeGasOnly;

    setHazardsConstants(mappedHazard);

    const hazardsOptions = mappedHazard.options.map((option) => {
      const checked = hazards.includes(option);
      return {
        checked: checked,
        label: option,
      };
    });

    setHazardsCheckboxOptions(hazardsOptions);
    setHazardsOtherSelected(hazards.includes(hazardsConstants.optionsOther));
    // eslint-disable-next-line
  }, [props.hasElectricity, props.hasBottledGas]);

  useEffect(() => {
    if (props.hazards?.length && hazardsCheckboxOptions?.length) {
      const hazardsOptions = hazardsCheckboxOptions.map((option) => {
        const checked = props.hazards.includes(option.label);
        return {
          checked: checked,
          label: option.label,
        };
      });
      setHazardsCheckboxOptions(hazardsOptions);
      setHazardsOtherSelected(
        props.hazards.includes(hazardsConstants.optionsOther)
      );
    }
    // eslint-disable-next-line
  }, [props.hazards?.length, hazardsCheckboxOptions?.length]);

  useEffect(() => {
    if (hazards.length > 0 && !props.isCallingAPI) {
      handleSaveData();
    }
    // eslint-disable-next-line
  }, [hazards]);

  useEffect(() => {
    if (!postalAddress) {
      setPostalAddress("");
      setAddressSameAsPostalAddress(true);
    } else {
      setAddressSameAsPostalAddress(false);
    }
    if (postalAddress && !address) {
      setPostalAddress("");
    }
    // eslint-disable-next-line
  }, [postalAddress]);

  useEffect(() => {
    const today = DateUtil.convertDateInstanceToFormat(
      new Date(),
      Constants.dateFormat
    );
    if (
      (serviceSwitchDate !== today || situation || hazardsOtherSelected) &&
      !props.isCallingAPI
    ) {
      handleSaveData();
    }
    // eslint-disable-next-line
  }, [
    addressSameAsPostalAddress,
    serviceSwitchDate,
    situation,
    hazardsOtherSelected,
  ]);

  const hazardsCheckboxListChangedHandler = (index) => {
    if (hazardsCheckboxOptions[index].label === hazardsConstants.optionsOther) {
      if (hazardsCheckboxOptions[index].checked) {
        setHazardsOther("");
      }
      setHazardsOtherSelected(!hazardsCheckboxOptions[index].checked);
    }

    let updatedHazardsCheckboxOptions;
    if (
      hazardsCheckboxOptions[index].label === hazardsConstants.optionsNoHazards
    ) {
      updatedHazardsCheckboxOptions = hazardsCheckboxOptions.map((opt) => {
        return opt.label === hazardsConstants.optionsNoHazards
          ? { ...opt, checked: !opt.checked }
          : { ...opt, checked: false };
      });
      setHazardsOther("");
      setHazardsOtherSelected(false);
    } else {
      updatedHazardsCheckboxOptions = hazardsCheckboxOptions.map((opt, idx) => {
        return idx === index
          ? { ...opt, checked: !opt.checked }
          : opt.label === hazardsConstants.optionsNoHazards
          ? { ...opt, checked: false }
          : opt;
      });
    }
    setHazardsCheckboxOptions(updatedHazardsCheckboxOptions);
    const newHazards = updatedHazardsCheckboxOptions
      .filter((option) => option.checked)
      .map((option) => option.label);
    setHazards(newHazards);
  };

  const getPreviewHazardsContent = (selectedHazards, value) => {
    let newSelectedHazards = [...selectedHazards];
    if (value && value.trim()) {
      newSelectedHazards.push(value.trim());
    }
    return newSelectedHazards
      .filter((hazard) => hazard !== hazardsConstants.optionsOther)
      .join(", ");
  };

  const addressDifferentAsPostalAddressCheckboxChangedHandler = () => {
    if (!addressSameAsPostalAddress) {
      setPostalAddress("");
      setPostalAddressHasError(false);
    }
    if (!postalAddress) {
      setPostalAddress("");
    }
    setAddressSameAsPostalAddress(!addressSameAsPostalAddress);
  };

  const setSelectedSituation = (value) => {
    if (value !== situation) {
      setSituation(value);
      setServiceSwitchDateHasError(false);
      setSituationOtherDetails("");
      setSituationOtherDetailsHasError(false);
    }
  };

  const handleSaveData = (input, value) => {
    if (!pageViewed) {
      triggerAgentPageView("/about-property");
    }
    setPageViewed(true);

    const withAnotherSupplier = situation === SITUATION_NOT_MOVING;
    const isMovingHouse = situation === SITUATION_MOVING;

    if (!input || (input && input === focusedInput)) {
      props.saveCustomerData({
        address,
        addressMoniker,
        addressSameAsPostalAddress,
        postalAddress,
        postalAddressMoniker,
        hazards,
        hazardsOther,
        withAnotherSupplier,

        isMovingHouse,
        serviceSwitchDate,
        situation,
        situationOtherDetails,
      });
      // props.onClick();
    }
  };

  const getContent = (
    <div className="property">
      <div className="field">
        <Address
          api={{
            baseUrl: process.env.REACT_APP_BASE_URL,
            key: process.env.REACT_APP_X_API_KEY,
          }}
          name="address"
          labelText={Constants.addressLabel}
          handleChange={setAddress}
          handleMonikerChange={setAddressMoniker}
          value={address}
          maxLength={Constants.addressMaxLength}
          handleError={setAddressHasError}
          hasError={addressHasError}
          errorMessage={Constants.addressRequiredMsg}
          disabled
        />
      </div>
      <div className="property_checkbox">
        <Checkbox
          name="addressDifferentAsPostalAddress"
          label={Constants.addressDifferentAsPostalAddressLabel}
          checked={!addressSameAsPostalAddress}
          onChange={addressDifferentAsPostalAddressCheckboxChangedHandler}
        />
      </div>
      {!addressSameAsPostalAddress && (
        <div className="field subItem">
          <ServiceAddress
            serviceAddress={postalAddress}
            handleAddressChange={setPostalAddress}
            handleManualChange={() => {}}
            onIcpSelect={() => {}}
            icp=""
            setHasNoIcp={() => {}}
            hasError={postalAddressHasError}
            handleError={setPostalAddressHasError}
            validateItself={validateItself}
            suburbRequired
            addressId=""
            setAddressDetails={() => {}}
            loaderCustomStyle={{
              background: "#fff",
            }}
            setMoniker={setPostalAddressMoniker}
            newBuildUrl={Constants.newBuildUrl}
            noIcpNeeded
          />
        </div>
      )}
      {!props.isBottledGasOnly && (
        <Fragment>
          <div className="field">
            <div className="property_situation_title">
              {Constants.situationLabel}
            </div>
            {Constants.situationRadioButton.map((situationEl, index) => (
              <div className="property_situation_container subItem" key={index}>
                <RadioButton
                  name={situationEl.radioButtonId}
                  checked={situation === situationEl.radioButtonCode}
                  value={situationEl.radioButtonCode}
                  label={situationEl.radioButtonLabel}
                  onChange={(value) => setSelectedSituation(value)}
                  required={!situation}
                  validateItself={validateItself}
                />
                {situation === situationEl.radioButtonCode && (
                  <Fragment>
                    {situationEl.situationOtherDetails && (
                      <div className="subItem field">
                        <Input
                          name="situationOtherDetails"
                          handleChange={(value) =>
                            setSituationOtherDetails(value)
                          }
                          value={situationOtherDetails}
                          maxLength={situationEl.situationOtherDetailsMaxLength}
                          labelText={situationEl.situationOtherDetails}
                          placeholder={
                            situationEl.situationOtherDetailsPlaceHolder
                          }
                          handleError={setSituationOtherDetailsHasError}
                          errorMessage={
                            situationEl.situationOtherDetailsRequiredMsg
                          }
                          hasError={situationOtherDetailsHasError}
                          validateItself={validateItself}
                          onBlur={() => handleSaveData("situationOtherDetails")}
                          inputProps={{
                            onFocus: () =>
                              setFocusedInput("situationOtherDetails"),
                          }}
                        />
                      </div>
                    )}
                    {situationEl.radioButtonCode !== SITUATION_NOT_MOVING && (
                      <div className="subItem field">
                        {index !== 0 ? (
                          <DatePicker
                            name="serviceSwitchDate"
                            handleChange={(value) => {
                              setServiceSwitchDate(
                                value
                                  ? DateUtil.convertDateInstanceToFormat(
                                      value,
                                      Constants.dateFormat
                                    )
                                  : value
                              );
                            }}
                            value={
                              serviceSwitchDate &&
                              DateUtil.getJavascriptDateFormat(
                                serviceSwitchDate,
                                Constants.dateFormat
                              )
                            }
                            labelText={situationEl.serviceSwitchDateLabel}
                            placeholder={Constants.datePlaceholder}
                            minDate={
                              new Date(
                                new Date(new Date().setMonth(-1)).setHours(
                                  0,
                                  0,
                                  0,
                                  0
                                )
                              )
                            }
                            handleError={setServiceSwitchDateHasError}
                            hasError={serviceSwitchDateHasError}
                            errorMessage={
                              situationEl.serviceSwitchDateRequiredMsg
                            }
                            required
                            validateItself={validateItself}
                          />
                        ) : (
                          <DatePicker
                            name="serviceSwitchDate"
                            value={
                              serviceSwitchDate &&
                              DateUtil.getJavascriptDateFormat(
                                serviceSwitchDate,
                                Constants.dateFormat
                              )
                            }
                            labelText={situationEl.serviceSwitchDateLabel}
                            disabled
                          />
                        )}
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
            ))}
          </div>
        </Fragment>
      )}

      <div className="field">
        <CheckboxList
          name="hazardsCheckboxOptions"
          title={hazardsConstants.label}
          description={hazardsConstants.helpText}
          onChange={hazardsCheckboxListChangedHandler}
          options={hazardsCheckboxOptions}
          columns={3}
          required
          validateItself={validateItself}
        />
      </div>

      {hazardsOtherSelected && (
        <div className="field">
          <Input
            name="hazardsOther"
            handleChange={setHazardsOther}
            value={hazardsOther}
            labelText={hazardsConstants.otherHazards}
            placeholder={hazardsConstants.otherHazardsPlaceholder}
            handleError={setHazardsOtherHasError}
            errorMessage={hazardsConstants.otherHazardsRequiredMsg}
            hasError={hazardsOtherHasError}
            required
            validateItself={validateItself}
            inputProps={{
              onFocus: () => setFocusedInput("hazardsOther"),
            }}
            onBlur={() => handleSaveData("hazardsOther")}
          />
        </div>
      )}
    </div>
  );

  const getPreview = (
    <div>
      {address && (
        <div className="field">
          {Constants.previewAddress}
          <span className="bold">{address}</span>
        </div>
      )}

      {postalAddress && (
        <div className="field">
          {Constants.previewPostalAddress}
          <span className="bold">
            {!addressSameAsPostalAddress
              ? postalAddress
              : Constants.previewPostalAddressSameAsPropertyAddress}
          </span>
        </div>
      )}

      {situation && (
        <div className="field">
          {Constants.previewMySituation}{" "}
          <span className="bold">
            {situation === SITUATION_CHANGE_OWNERSHIP
              ? situationOtherDetails
              : situation}
          </span>
        </div>
      )}

      {situation !== SITUATION_NOT_MOVING && serviceSwitchDate && (
        <div className="field">
          {Constants.previewServiceSwitchDate}{" "}
          <span className="bold">{serviceSwitchDate}</span>
        </div>
      )}

      {previewHazardsContent && (
        <div className="field">
          {Constants.previewHazards}
          <span className="bold">{previewHazardsContent}</span>
        </div>
      )}
    </div>
  );

  const getHeader = {
    cardTitle: Constants.cardTitle,
    cardContent: getPreview,
    cardIcon: <ArrowDown />,
    cardIsSelected: false,
    cardLink: true,
  };

  return (
    <Fragment>
      <div id="Property" className="cardId" />
      <ExpandableCard
        name="propertyCard"
        headerContent={getHeader}
        content={getContent}
        isOpen={cardOpen}
        disabled={props.isCardDisabled}
        handleClick={() => setCardOpen(!cardOpen)}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  address:
    getPropertyAddress(state) || state.Customer.CustomerInfo.CurrentAddress,
  addressMoniker: state.Property?.PropertyInfo.Moniker,
  postalAddress: state.Customer?.CustomerInfo.PostalAddress,
  postalAddressMoniker: state.Customer?.CustomerInfo.PostalAddressMoniker,
  addressSameAsPostalAddress:
    state.Property?.PropertyInfo.AddressSameAsPostalAddress,
  hazards: state.Property ? state.Property?.PropertyInfo.Hazards : [],
  hazardsOther: state.Property?.PropertyInfo.HazardsOther,

  accountStart: getServicesStartDate(state),
  situation: state.Property?.MoveInfo.Situation,
  situationOtherDetails: state.Property?.MoveInfo.SituationOtherDetails,

  hasElectricity: getHasElectricity(state),
  hasBottledGas: getHasBottledGas(state),
  isBottledGasOnly: getIsBottleGasOnly(state),

  isCallingAPI: state.Auth.isCallingAPI,
});

const mapDispatchToProps = (dispatch) => {
  return {
    saveCustomerData: (values) => {
      const details = {
        Customer: {
          CustomerInfo: {
            CurrentAddress: values.address,
            PostalAddress: values.postalAddress,
            PostalAddressMoniker: values.postalAddressMoniker,
            Moniker: values.postalAddressMoniker,
          },
        },
        Property: {
          PropertyInfo: {
            Address: values.address,
            Moniker: values.addressMoniker,
            AddressSameAsPostalAddress: values.addressSameAsPostalAddress,
            Hazards: values.hazards,
            HazardsOther: values.hazardsOther,
            WithAnotherSupplier: values.withAnotherSupplier,
          },

          MoveInfo: {
            IsMovingHouse: values.isMovingHouse,
            AccountStart:
              !values.situation || values.situation === SITUATION_NOT_MOVING
                ? null
                : values.serviceSwitchDate,
            Situation: values.situation ? values.situation : "",
            SituationOtherDetails: values.situationOtherDetails,
          },
        },
      };
      dispatch(saveCustomerData(details));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Property);
