import * as actionTypes from "../actionTypes";
import { updateServices } from "../iJoin/ServicesActions";
import { updateProperty } from "../iJoin/PropertyActions";
import { saveApiState, createApiState } from "../ApiCalls";
import { updateConfirmation } from "../iJoin/ConfirmationActions";
import { updateCustomer } from "../iJoin/CustomerActions";
import { updatePipedGas } from "../iJoin/GasActions";
import { updateBottledGas } from "../iJoin/BottledGasActions";
import { updatePayment } from "./PaymentActions";

export const updatePlan = (plan) => {
  return {
    type: actionTypes.UPDATE_PLAN,
    plan: plan,
  };
};

export const updateAuth = (auth) => {
  return {
    type: actionTypes.UPDATE_AUTH,
    auth: auth,
  };
};

export const createState = () => {
  return (dispatch) => {
    if (!process.env.REACT_APP_USE_FIXTURES) {
      dispatch(createApiState({}));
    }
  };
};

export const saveAuthData = (auth, state) => {
  return (dispatch, getState) => {
    const state = getState();
    const authData = {
      ...state.Auth,
      ...auth,
    };
    dispatch(updateAuth(authData));
  };
};

export const savePlanData = (
  customer,
  services,
  plan,
  property,
  gas,
  lpg,
  confirmation,
  planInfo,
  payment
) => {
  return (dispatch, getState) => {
    const state = getState();
    const customerData = {
      ...state.Customer,
      CustomerInfo: {
        ...state.Customer.CustomerInfo,
        ...customer.CustomerInfo,
      },
    };
    const planData = {
      ...state.Plan,
      ...plan,
    };
    const newProperty = {
      PropertyInfo: {
        ...state.PropertyInfo,
        ...property.PropertyInfo,
      },
    };
    const gasData = {
      gasRate: gas,
    };
    const lpgData = {
      BottledGasUsage: [
        ...state.LpgInfo.BottledGasUsage,
        ...lpg.BottledGasUsage,
      ],
    };
    const confirmationData = {
      ...confirmation,
    };
    const paymentData = {
      ...state.Payment,
      ...payment,
    };
    dispatch(updatePlan(planData));
    dispatch(updateServices(services));
    dispatch(updateProperty(newProperty));
    dispatch(updateConfirmation(confirmation));
    dispatch(updateCustomer(customerData));
    dispatch(updatePipedGas(gasData));
    dispatch(updateBottledGas(lpgData));
    dispatch(updatePayment(paymentData));
    if (!process.env.REACT_APP_USE_FIXTURES) {
      dispatch(
        saveApiState({
          PipedGasInfo: {
            ...gasData,
          },
          LpgInfo: {
            ...lpgData,
          },
          Customer: {
            ...customerData,
          },
          Plan: {
            ...planData,
          },
          ...newProperty,
          Services: services,
          PropertyInfo: newProperty.PropertyInfo,
          PlanInfo: {
            ...planInfo,
          },
          Confirmation: {
            ...confirmationData,
          },
          Payment: {
            ...paymentData,
          },
        })
      );
    }
  };
};
