import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { compose } from "recompose";

import { withReduxProvider } from "../Utils/enhancers";
import expressStore from "../Redux/Stores/expressStore";

import Express from "./Express/Express";
import Login from "./Login/Login";
import Success from "./Success/Success";
import WithRedirectToLogin from "./WithRedirectToLogin/WithRedirectToLogin";
import withLayout from "../Components/Layout";

import { injectGtm } from "../Utils/gtm";
import { Gtm } from "../Config/Constants";

const ExpressApp = () => {
  useEffect(() => {
    injectGtm(Gtm.reactAppId);
  }, []);

  return (
    <Switch>
      <Route path="/residential/express-move/success" component={Success} />
      <Route path="/residential/express" component={Login} />
      <Route
        path="/residential/express-move"
        component={WithRedirectToLogin(Express)}
      />
      <Route
        path="/"
        component={() => {
          window.location.href =
            process.env.REACT_APP_EXPRESS_BASE_URL + "/404-not-found";
          return null;
        }}
      />
    </Switch>
  );
};

export default compose(withReduxProvider(expressStore), withLayout)(ExpressApp);
