import * as actionTypes from "../actionTypes";
import { saveApiState } from "../ApiCalls";
import { getIsBottleGasOnly } from "../../../Utils/selectors";

export const updateConfirmation = (confirmation) => {
  return {
    type: actionTypes.UPDATE_CONFIRMATION,
    confirmation: confirmation,
  };
};

export const saveConfirmationData = (confirmation) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(updateConfirmation(confirmation));
    if (!process.env.REACT_APP_USE_FIXTURES) {
      const isBottledGasOnly = getIsBottleGasOnly(state);
      const apiObject = {
        Confirmation: {
          ...state.Confirmation,
          ...confirmation,
        },
      };
      if (isBottledGasOnly) {
        apiObject.Payment = {
          PaperlessDiscount: false,
          JoinDirectDebit: false,
        };
      }
      return dispatch(saveApiState(apiObject, true));
    }
  };
};
