// @flow
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import {
  ArrowDown,
  Checkbox,
  ExpandableCard,
  Input,
  RadioButtonList,
  HTML,
} from "react-components";

import ServiceAddress from "../../../../Containers/FindPlan/Address";

import { BottledGas as Constants } from "../../../../Config/Constants";
import { useStateFromProp } from "../../../../Utils/hooks";
import {
  getPostalAddress,
  getIsResidential,
} from "../../../../Utils/selectors";
import { saveCustomerData } from "../../../../Redux/Actions/CSRAgent/JoinAction";
import {
  isOrderBottledGas,
  numberOfBottlesLabel,
} from "../../../../Containers/BottledGas/utils";
import { triggerAgentPageView } from "../../../../Utils/analytics";

import "../../../../Containers/BroadbandOrder/BroadbandOrder.scss";

type Props = {
  NumberOfBottles?: string,
  DeliveryAddress?: string,
  DeliveryInstructions?: string,
  PaperlessStatements?: boolean,
  PostalAddress?: string,
  isResidential?: boolean,
  isCurrentCardOpen?: boolean,
  isCardDisabled?: boolean,
  validate?: boolean,
  onCardClick?: () => void,
  onClick?: () => void,
  setValid: () => {},
};

export const BottledGas = (props: Props) => {
  const [NumberOfBottles, setNumberOfBottles] = useStateFromProp(
    props.NumberOfBottles,
    Constants.numberOfBottlesDefault
  );
  const [validateItself, setValidateItself] = useState(false);
  const [DeliveryAddress, setDeliveryAddress] = useStateFromProp(
    props.DeliveryAddress,
    ""
  );
  const [DeliveryInstructions, setDeliveryInstructions] = useStateFromProp(
    props.DeliveryInstructions,
    ""
  );
  const [PaperlessStatements, setPaperlessStatements] = useStateFromProp(
    props.PaperlessStatements,
    Constants.paperlessStatementsDefault
  );

  const [differentDeliveryAddress, setDifferentDeliveryAddress] = useState(
    Boolean(
      props.DeliveryAddress && props.DeliveryAddress !== props.PostalAddress
    )
  );
  const [deliveryAddressError, setDeliveryAddressError] = useState(false);

  const isValidDeliveryAddress = DeliveryAddress && !deliveryAddressError;
  const isDisable =
    !NumberOfBottles || (differentDeliveryAddress && !isValidDeliveryAddress);

  const [changeMade, setChangeMade] = useState(false);
  const [pageViewed, setPageViewed] = useState(false);

  const [cardOpen, setCardOpen] = useState(true);

  useEffect(() => {
    setDifferentDeliveryAddress(
      Boolean(
        props.DeliveryAddress && props.DeliveryAddress !== props.PostalAddress
      )
    );
  }, [props.PostalAddress, props.DeliveryAddress]);

  useEffect(() => {
    if (props.validate) {
      setValidateItself(true);
      setCardOpen(true);
    }
  }, [props.validate]);

  useEffect(() => {
    if (changeMade && !pageViewed) {
      triggerAgentPageView("/about-your-bottled-gas-order");
      setPageViewed(true);
    }
  }, [changeMade, pageViewed, setPageViewed]);

  useEffect(() => {
    if (isDisable) {
      props.setValid("BottledGas", false);
    } else {
      props.setValid("BottledGas", true);
    }
    // eslint-disable-next-line
  }, [
    NumberOfBottles,
    differentDeliveryAddress,
    isValidDeliveryAddress,
    isDisable,
  ]);

  useEffect(() => {
    if (
      (NumberOfBottles ||
        DeliveryAddress ||
        PaperlessStatements ||
        differentDeliveryAddress) &&
      changeMade
    ) {
      saveData();
    }
    // eslint-disable-next-line
  }, [
    NumberOfBottles,
    DeliveryAddress,
    PaperlessStatements,
    differentDeliveryAddress,
  ]);

  const saveData = () => {
    props.saveCustomerData({
      NumberOfBottles,
      DeliveryAddress: differentDeliveryAddress ? DeliveryAddress : null,
      DeliveryInstructions,
      PaperlessStatements,
    });
  };

  const setBottles = (value) => {
    setNumberOfBottles(value);
    setChangeMade(true);
  };

  const handleAddressChange = (address) => {
    setDeliveryAddress(address);
    setChangeMade(true);
  };

  const getContent = (
    <div className="bottledGas">
      {props.isResidential && (
        <div className="row bottledGas_disclaimer">
          {Constants.sectionResidentialDisclaimer}
        </div>
      )}
      <div className="field">
        <RadioButtonList
          name="numberOfBottles"
          title={Constants.numberOfBottles}
          description={Constants.numberOfBottlesDescription}
          items={Constants.numberOfBottlesOptions}
          value={NumberOfBottles}
          onChange={(value) => setBottles(value)}
        />
      </div>
      {parseInt(NumberOfBottles, 10) > 2 && (
        <div className="field bottledGas_disclaimer bottledGas_disclaimer--important">
          <HTML html={Constants.amountDisclaimer} />
        </div>
      )}
      {NumberOfBottles !== "0" && (
        <div className="bottledGas_checkbox">
          <Checkbox
            name="differentPostalAddress"
            className="different-postal-address-checkbox"
            label={Constants.differentPostalAddress}
            checked={differentDeliveryAddress}
            onChange={() => {
              setDifferentDeliveryAddress(!differentDeliveryAddress);
              setChangeMade(true);
            }}
          />
        </div>
      )}
      {differentDeliveryAddress && (
        <div className="field subItem">
          <ServiceAddress
            serviceAddress={DeliveryAddress}
            handleAddressChange={handleAddressChange}
            handleManualChange={handleAddressChange}
            hasError={deliveryAddressError}
            handleError={setDeliveryAddressError}
            loaderCustomStyle={{
              background: "#fff",
            }}
            required={differentDeliveryAddress}
            onIcpSelect={() => {}}
            icp=""
            setHasNoIcp={() => {}}
            setAddressDetails={() => {}}
            validateItself={validateItself}
            noIcpNeeded
          />
        </div>
      )}
      {parseInt(NumberOfBottles, 10) > 0 && (
        <div className="field">
          <Input
            name="deliveryInstructions"
            handleChange={setDeliveryInstructions}
            value={DeliveryInstructions}
            labelText={Constants.deliveryInstructions}
            maxLength={Constants.deliveryInstructionsMaxLength}
            onBlur={() => setChangeMade(true)}
          />
        </div>
      )}
      <div className="bottledGas_checkbox">
        <Checkbox
          name="paperlessStatements"
          label={Constants.paperlessStatements}
          subLabel={Constants.paperlessStatementsDescription}
          checked={PaperlessStatements}
          onChange={() => {
            setPaperlessStatements(!PaperlessStatements);
            setChangeMade(true);
          }}
        />
      </div>
    </div>
  );

  const getPreview = (
    <div>
      {NumberOfBottles && (
        <div className="field">
          {Constants.numberOfBottlesPreview}:{" "}
          <span className="bold">{numberOfBottlesLabel(NumberOfBottles)}</span>
        </div>
      )}
      {differentDeliveryAddress && isValidDeliveryAddress ? (
        <div className="field">
          {Constants.deliveryAddress}:{" "}
          <span className="bold">{DeliveryAddress}</span>
        </div>
      ) : (
        <div className="field">
          {Constants.deliveryAddress}:{" "}
          <span className="bold">{Constants.previewYourPostalAddress}</span>
        </div>
      )}
      {DeliveryInstructions && (
        <div className="field">
          {Constants.deliveryInstructions}:{" "}
          <span className="bold">{DeliveryInstructions}</span>
        </div>
      )}
      {PaperlessStatements && (
        <div className="field">{Constants.paperlessStatements}</div>
      )}
    </div>
  );

  const getHeader = {
    cardTitle: Constants.cardTitle,
    cardContent: getPreview,
    cardIcon: <ArrowDown />,
    cardIsSelected: false,
    cardLink: true,
  };

  return (
    <Fragment>
      <div id="BottledGas" className="cardId" />
      <ExpandableCard
        name="bottleGasCard"
        headerContent={getHeader}
        content={getContent}
        isOpen={cardOpen}
        disabled={props.isCardDisabled}
        handleClick={() => setCardOpen(!cardOpen)}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  const postalAddress = getPostalAddress(state);
  return {
    NumberOfBottles: state.LpgInfo.NumberOfBottles,
    DeliveryAddress:
      state.LpgInfo.DeliveryAddress &&
      state.LpgInfo.DeliveryAddress !== postalAddress
        ? state.LpgInfo.DeliveryAddress
        : "",
    DeliveryInstructions: state.LpgInfo.DeliveryInstructions,
    PaperlessStatements: state.LpgInfo.PaperlessStatements,
    PostalAddress: postalAddress,
    isResidential: getIsResidential(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveCustomerData: (values) => {
      const details = {
        LpgInfo: {
          OrderBottledGas: isOrderBottledGas(values.NumberOfBottles),
          NumberOfBottles: values.NumberOfBottles,
          DeliveryAddress: values.DeliveryAddress,
          DeliveryInstructions: values.DeliveryInstructions,
          PaperlessStatements: values.PaperlessStatements,
        },
      };
      dispatch(saveCustomerData(details));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BottledGas);
