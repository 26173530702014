import React, { useState, useEffect } from "react";
import { Address, IcpSelect } from "react-components";
import type { AddressDetails } from "react-components/src/Components/Address/Address";
import { FindPlan } from "../../../Config/Constants";
import {
  getAddressDetails,
  getElectricityDetails,
} from "../../../Redux/Actions/ApiCalls";

import "../FindPlan.scss";

const constants = FindPlan.serviceAddress;

type Props = {
  date?: Date,
  serviceAddress: String,
  suburbRequired?: boolean,
  addressId?: String,
  newBuildUrl?: String,
  serviceAddressIcp: string | null,
  setServiceAddressIcp: (icp: string) => void,
  loaderCustomStyle?: Object,
  noIcpNeeded?: boolean,
  setIsManualAddress: () => void,
  handleAddressChange: () => void,
  handleManualChange: () => void,
  onIcpSelect: () => void,
  validateItself: boolean,
  handleError: () => void,
  setHasNoIcp: () => void,
  setAddressDetails: () => void,
  setMoniker: () => void,
};

export const ServiceAddress = (props: Props) => {
  // const [addressId, setAddressId] = useState('');
  const [addressDetails, setAddressDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [addressHasError, setAddressHasError] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState(
    constants.addressRequiredMsg
  );
  const [suggestions, setSuggestions] = useState([]);
  const [moniker, setMoniker] = useState("");
  const { handleError, serviceAddress } = props;

  useEffect(() => {
    handleError && handleError(addressHasError);
  }, [addressHasError, handleError]);

  useEffect(() => {
    props.setAddressDetails(addressDetails);
    // eslint-disable-next-line
  }, [addressDetails]);

  useEffect(() => {
    if (props.addressId) {
      setMoniker(props.addressId);
    }
  }, [props.addressId]);

  useEffect(() => {
    if (moniker) {
      setIsLoading(true);
      getAddressDetails(moniker).then((response) => {
        setAddressDetails(response.data);
        setIsLoading(false);
      });
    } else {
      setAddressDetails(null);
    }
  }, [moniker]);

  useEffect(() => {
    if (serviceAddress) {
      validateServiceAddress();
    }
    // eslint-disable-next-line
  }, [serviceAddress]);

  const validateServiceAddress = () => {
    if (!serviceAddress) {
      setAddressHasError(true);
      setInvalidMessage(constants.addressRequiredMsg);
    } else {
      setAddressHasError(false);
    }
  };

  const handleChange = (address) => {
    if (!address) {
      props.onIcpSelect("");
    } else {
      if (address !== serviceAddress) {
        let newMonicker = "";
        suggestions.filter((suggestion) => {
          if (suggestion.value?.partialAddress === address) {
            newMonicker = suggestion.value.moniker;
          }
          return newMonicker;
        });
        setMoniker(newMonicker);
        if (props.setMoniker) {
          props.setMoniker(newMonicker);
        }
        props.setHasNoIcp(false);
        props.handleAddressChange(address);
      }
    }
  };

  const handleManualChange = (address, details: AddressDetails) => {
    props.onIcpSelect("");
    props.setHasNoIcp(true);
    if (address) {
      props.handleManualChange(address);
    }
    if (details) {
      setAddressDetails({
        a: address, // NOTE: This is the formatted address string which is used in some API calls
        number: details.streetNumber,
        street: details.streetName,
        postcode: details.postcode,
        city: details.city,
        suburb: details.suburb,
      });
    }
  };

  return (
    <div className="find-pan_address">
      <Address
        api={{
          baseUrl: process.env.REACT_APP_BASE_URL,
          key: process.env.REACT_APP_X_API_KEY,
        }}
        labelText={constants.addressInputLabel}
        value={props.serviceAddress}
        handleChange={(address) => handleChange(address)}
        hasError={addressHasError}
        handleError={setAddressHasError}
        errorMessage={constants.addressRequiredMsg}
        invalidMessage={invalidMessage}
        classes={{ container: "find-plan_address_input" }}
        isLoading={isLoading}
        validateItself={props.validateItself}
        required
        onInputBlur={validateServiceAddress}
        setManualAddress={handleManualChange}
        suburbRequired={props.suburbRequired}
        loaderCustomStyle={props.loaderCustomStyle}
        newBuildUrl={props.newBuildUrl}
        hasEnterButton
        setSuggestions={setSuggestions}
      >
        {!props.noIcpNeeded && (
          <IcpSelect
            addressDetails={addressDetails}
            request={getElectricityDetails}
            onSelect={props.onIcpSelect}
            onSelectLater={() => props.setHasNoIcp(true)}
            onSuccess={(addresses) => {
              props.setHasNoIcp(addresses.length === 0);
              setIsLoading(false);
            }}
          />
        )}
      </Address>
    </div>
  );
};

export default ServiceAddress;
