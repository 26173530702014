import * as actionTypes from "../../Actions/actionTypes";
import { reducerPropUpdater } from "../../utils";

const initialState = {
  CustomerInfo: {
    Title: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    EmailAddress: "",
    PhoneNumber: "",
    DateOfBirth: null,
    AccountNumber: "",
    PostalAddress: "",
    CurrentAddress: "",
  },
  HasDriversLicense: false,
  DriversLicense: {
    Number: "",
    Version: "",
  },
  Action: 0,
  IsExistingCustomer: false,
  Note: "",
  isRehydrated: false,
};

const updateProp = reducerPropUpdater("Customer");

const updateCustomer = (state, data) => {
  const newCustomer = { ...state, ...data };
  return newCustomer;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PROP:
      return updateProp(state, action.propName, action.value);

    case actionTypes.UPDATE_CUSTOMER:
      return updateCustomer(state, action.customer);

    case actionTypes.REHYDRATED_CUSTOMER:
      return { ...state, isRehydrated: action.payload };

    default:
      return state;
  }
};

export default reducer;
