// @flow
import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";

import "./Layout.scss";

type State = {};
type Props = {};

export default function withLayout(WrappedComponent: any) {
  return class Layout extends Component<Props, State> {
    wrappedComponent: any;
    render() {
      return (
        <div className="App">
          <Header />
          <div className="layout">
            <WrappedComponent navigation={this.props.navigation} />
          </div>
          <Footer />
        </div>
      );
    }
  };
}
