import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import JoinApp from "./JoinApp";
import ExpressApp from "./ExpressApp";
import AgentApp from "./AgentApp";
import AppError from "./AppError";
import NpsApp from "./NpsApp";

import { withErrorBoundary } from "../Utils/enhancers";

const App = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/residential/my-details/join-success"
          component={JoinApp}
        />
        <Route path="/residential/my-details" component={JoinApp} />
        <Route path="/residential/find-a-plan" component={JoinApp} />
        <Route path="/residential/summary" component={JoinApp} />
        <Route
          path="/residential/express-move/success"
          component={ExpressApp}
        />
        <Route path="/residential/express" component={ExpressApp} />
        <Route path="/residential/express-move" component={ExpressApp} />
        <Route path="/residential/agent" component={AgentApp} />
        <Route path="/auth" component={AgentApp} />
        <Route path="/asyncsurvey" component={NpsApp} />
        <Route
          path="/"
          component={() => {
            window.location.href =
              process.env.REACT_APP_EXPRESS_BASE_URL + "/404-not-found";
            return null;
          }}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default withErrorBoundary(() => <AppError />)(App);
