// @flow
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { redirectToAgentLogin } from "../../../Redux/Actions/ApiCalls";
import { getToken } from "../../../Redux/Actions/CSRAgent/LoginActions";

type Props = {
  isLoggedIn?: boolean,
  getToken: () => void,
};

const getPathnameWithoutParam = (pathname) =>
  pathname.slice(0, pathname.lastIndexOf("/"));

const WithLogin = (WrappedComponent: any) => (props: Props) => {
  const grantToken = props.match.params.grantToken;
  let token = "";
  let userAuth = "";
  let userAuthUrl = "";
  let userAuthFromLocal = "";
  if (props.location.search.indexOf("code=") > -1) {
    token = props.location.search.split("code=").pop();
  }
  if (localStorage.getItem("userAuth")) {
    userAuthFromLocal = localStorage.getItem("userAuth");
  }
  if (props.location.search.indexOf("userAuth=") > -1) {
    userAuthUrl = props.location.search.split("userAuth=").pop();
    localStorage.setItem("userAuth", userAuthUrl);
  }

  userAuth = userAuthUrl || userAuthFromLocal;

  const { isLoggedIn, redirectUrl } = props;
  if (!isLoggedIn && !token) {
    props.redirectToAgentLogin(props.location.pathname);
  } else if (token && userAuth) {
    props.getToken(
      token,
      getPathnameWithoutParam(props.location.pathname),
      userAuth
    );
  } else if (redirectUrl && grantToken) {
    props.redirect(`${redirectUrl}/:${grantToken}`);
  }

  return <WrappedComponent {...props} />;
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.Login.isLoggedIn,
  redirectUrl: state.Login.redirectUrl,
  token: state.Login.token,
});
const mapDispatchToProps = {
  getToken,
  redirectToAgentLogin,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), WithLogin);
