// @flow
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { compose } from "recompose";

import { Login as Constants } from "../../Config/Constants";
import { getIsLoggedIn } from "../../Redux/Reducers/express/LoginSelectors";

type Props = {
  isLoggedIn: boolean,
};

export const WithRedirectToLogin = (Component: any) => (props: Props) =>
  props.isLoggedIn ? (
    <Component />
  ) : (
    <Redirect to={Constants.redirectToLoginUrl} />
  );

export default compose(
  connect((state) => ({ isLoggedIn: getIsLoggedIn(state) })),
  WithRedirectToLogin
);
