import React, { useState, useEffect } from "react";
import { Address, DatePicker, Dropdown } from "react-components";

import { Express } from "../../../Config/Constants";

import "../Express.scss";

const constants = Express.moveOutAddress;

type AddressProps = {
  name: string,
};

type Props = {
  addressValue: string,
  addressIndex: string,
  addresses: Array<AddressProps>,
  date?: Date,
  handleAddressChange: () => void,
  handleAddressIndexChange: () => void,
  handleDateChange: () => void,
  validateItself: boolean,
  handleError: () => void,
};

export const MoveOutAddress = (props: Props) => {
  const [dropdownHasError, setDropdownHasError] = useState(false);
  const [dateHasError, setDateHasError] = useState(false);

  const handleError = props.handleError;
  useEffect(() => {
    handleError && handleError(dateHasError || dropdownHasError);
  }, [dateHasError, dropdownHasError, handleError]);

  return (
    <div className="express_address express_moveOutAddress">
      <div
        className="express_address_input"
        id={constants.automationIds.autocomplete}
      >
        {props.addresses.length < 2 ? (
          <Address
            disabled
            value={props.addressValue}
            labelText={constants.addressInputLabel}
            handleChange={() => {}}
            handleError={() => {}}
          />
        ) : (
          <Dropdown
            items={props.addresses}
            selectedItem={props.addressValue}
            selectedItemIndex={props.addressIndex}
            handleSelectedItem={props.handleAddressChange}
            handleSelectedItemIndex={props.handleAddressIndexChange}
            labelText={constants.addressInputLabel}
            handleError={setDropdownHasError}
            errorMessage={constants.addressRequiredMsg}
            hasError={dropdownHasError}
            validateItself={props.validateItself}
            required
          />
        )}
      </div>
      <div
        className="express_address_datepicker"
        id={constants.automationIds.datepicker}
      >
        <DatePicker
          value={props.date}
          handleChange={props.handleDateChange}
          labelText={constants.dateInputLabel}
          placeholder={constants.datePlaceholder}
          hasError={dateHasError}
          handleError={setDateHasError}
          errorMessage={constants.dateRequiredMsg}
          validateItself={props.validateItself}
          required
        />
      </div>
    </div>
  );
};

export default MoveOutAddress;
