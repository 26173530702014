export const PaymentFixture = {
  PaymentMethod: null,
  PrePayMobileNumber: null,
  PaperlessDiscount: true,
  JoinDirectDebit: true,
  DirectDebitDetails: {
    BankName: null,
    BankAccountNumber: "38-1234-5678930-00",
    IsSoleSignatory: true,
    AgreeWithTermsAndConditions: false,
  },
  AaSmartFuel: null,
  HasAaSmartFuel: false,
  PromoCode: "TEST PROMO1",
  BillingCycle: null,
  BillStartDate: null,
};
