import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { NPS as Constants } from "../../../Config/Constants";
import { replaceKeys } from "../../../Utils";
import { Button, Input, NPSBar, YesNoButtonGroup } from "react-components";
import { submitNPSData } from "../../../Redux/Actions/NPSActions";

import "./NPSSurvey.scss";

const SCORE = "SCORE";
const FEEDBACK = "FEEDBACK";
// const CONTACTYOU = 'CONTACTYOU';
// const CONTACTDETAILS = 'CONTACTDETAILS';
const PROMOTIONALPURPOSE = "PROMOTIONALPURPOSE";
const THANKYOU = "THANKYOU";

type Props = {
  submitNPSData?: () => void,
};

export const NPSSurvey = (props: Props) => {
  const [historySections, setHistorySections] = useState([]);
  const [currentSection, setCurrentSection] = useState(SCORE);

  const [score, setScore] = useState(null);
  const [scoreComments, setScoreComments] = useState("");
  // const [requestCallback, setRequestCallback] = useState(false);
  const requestCallback = false;
  // const [firstName, setFirstName] = useState('');
  const firstName = "";
  // const [firstNameHasError, setFirstNameHasError] = useState(false);
  // const [phoneNumber, setPhoneNumber] = useState('');
  const phoneNumber = "";
  // const [phoneNumberHasError, setPhoneNumberHasError] = useState(false);
  // const [emailAddress, setEmailAddress] = useState('');
  const emailAddress = "";
  // const [emailAddressHasError, setEmailAddressHasError] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(null);

  const [buttonDisabled, setButtonDisable] = useState(false);

  useEffect(() => {
    switch (currentSection) {
      case SCORE:
        setButtonDisable(!score);
        break;
      case FEEDBACK:
        setButtonDisable(false);
        break;
      // case CONTACTYOU:
      //   setButtonDisable(requestCallback === null);
      //   break;
      // case CONTACTDETAILS:
      //   setButtonDisable(!(firstName && phoneNumber && emailAddress && !firstNameHasError && !phoneNumberHasError && !emailAddressHasError));
      //   break;
      case PROMOTIONALPURPOSE:
        setButtonDisable(marketingConsent === null);
        break;
      default:
    }
  }, [currentSection, score, scoreComments, marketingConsent]);

  const handleContinueButtonClick = () => {
    if (historySections[historySections.length - 1] !== currentSection) {
      const newHistorySection = [...historySections];
      newHistorySection.push(currentSection);
      setHistorySections(newHistorySection);
    }

    switch (currentSection) {
      case SCORE:
        setCurrentSection(FEEDBACK);
        break;
      case FEEDBACK:
        if (scoreComments) {
          setCurrentSection(PROMOTIONALPURPOSE);
        } else {
          props.submitNPSData({
            score,
            scoreComments,
            firstName,
            emailAddress,
            phoneNumber,
            requestCallback,
            marketingConsent,
          });
          setCurrentSection(THANKYOU);
        }
        break;
      // case CONTACTYOU:
      //   setCurrentSection(requestCallback ? CONTACTDETAILS : PROMOTIONALPURPOSE);
      //   break;
      // case CONTACTDETAILS:
      //   setCurrentSection(PROMOTIONALPURPOSE);
      //   break;
      case PROMOTIONALPURPOSE:
        props.submitNPSData({
          score,
          scoreComments,
          firstName,
          emailAddress,
          phoneNumber,
          requestCallback,
          marketingConsent,
        });
        setCurrentSection(THANKYOU);
        break;
      default:
    }
  };

  const handleBackButtonClick = () => {
    const newHistorySections = [...historySections];
    setCurrentSection(newHistorySections.pop());
    setHistorySections(newHistorySections);
  };

  const handleJoinButtonClick = () => {
    window.location.href = Constants.thankYou.redirectURL;
  };

  const ScoreSection = (
    <div>
      <p className="npsSurvey_intro">{Constants.score.subtitle}</p>
      <NPSBar score={score} onChange={setScore} />
    </div>
  );

  const FeedbackSection = (
    <div>
      <p className="npsSurvey_intro">
        {replaceKeys(Constants.feedback.subtitle, { score: score })}
      </p>
      <Input
        name="feedback"
        handleChange={setScoreComments}
        value={scoreComments}
        labelText={Constants.feedback.feedbackLabel}
      />
    </div>
  );

  // const ContactYouSection = (
  //   <div>
  //     <p className='npsSurvey_intro' name='contactYouSubtitle'>{Constants.contactYou.subtitle}</p>
  //     <YesNoButtonGroup name='contactYou' onChange={setRequestCallback}/>
  //   </div>
  // );

  const PromotionalPurposeSection = (
    <div>
      <p className="npsSurvey_intro">
        {Constants.promotionalPurposes.subtitle}
      </p>
      <YesNoButtonGroup
        name="promotionalPurpose"
        onChange={setMarketingConsent}
      />
    </div>
  );

  // const ContactDetailsSection = (
  //   <div>
  //     <p className='npsSurvey_intro'>{Constants.contactDetails.subtitle}</p>
  //     <div className='row'>
  //       <div className='field'>
  //         <Input
  //           name='name'
  //           handleChange={setFirstName}
  //           value={firstName}
  //           labelText={Constants.contactDetails.nameLabel}
  //           handleError={setFirstNameHasError}
  //           errorMessage={Constants.contactDetails.nameRequiredMsg}
  //           hasError={firstNameHasError}
  //           validationType='name'
  //           required
  //         />
  //       </div>
  //       <div className='field'>
  //         <Input
  //           name='phoneNumber'
  //           handleChange={setPhoneNumber}
  //           value={phoneNumber}
  //           labelText={Constants.contactDetails.phoneNumberLabel}
  //           handleError={setPhoneNumberHasError}
  //           errorMessage={Constants.contactDetails.phoneNumberRequiredMsg}
  //           invalidMessage={Constants.contactDetails.phoneNumberInvalidMsg}
  //           hasError={phoneNumberHasError}
  //           validationType='phoneNumber'
  //           required
  //         />
  //       </div>
  //     </div>
  //     <Input
  //       name='emailAddress'
  //       handleChange={setEmailAddress}
  //       value={emailAddress}
  //       labelText={Constants.contactDetails.emailAddressLabel}
  //       handleError={setEmailAddressHasError}
  //       errorMessage={Constants.contactDetails.emailRequiredMsg}
  //       invalidMessage={Constants.contactDetails.emailInvalidMsg}
  //       hasError={emailAddressHasError}
  //       validationType='email'
  //       required
  //     />
  //   </div>
  // );

  const ThankYouSection = (
    <div>
      <h2 name="thankYouTitle" className="npsSurvey_title">
        {Constants.thankYou.title}
      </h2>
      <h3>{Constants.thankYou.subtitle}</h3>

      <div>
        <p className="npsSurvey_intro">{Constants.thankYou.content}</p>
      </div>

      <div className="npsSurvey_buttongroup">
        <Button
          name="joinButton"
          type="button"
          primaryOnLight
          text={Constants.thankYou.joinButton}
          handleClick={handleJoinButtonClick}
        />
      </div>
    </div>
  );

  const ButtonGroupSection = (
    <div className="npsSurvey_buttongroup">
      {currentSection !== SCORE && (
        <Button
          name="backButton"
          type="button"
          primaryOnDark
          text={Constants.backButton}
          handleClick={handleBackButtonClick}
        />
      )}
      <Button
        name="continueButton"
        type="button"
        primaryOnLight
        text={Constants.continueButton}
        handleClick={handleContinueButtonClick}
        disabled={buttonDisabled}
      />
    </div>
  );

  return (
    <div className="npsSurvey">
      {currentSection !== THANKYOU ? (
        <div className="npsSurvey_container">
          <h2 name="npsTitle" className="npsSurvey_title">
            {Constants.title}
          </h2>
          <div>
            {currentSection === SCORE && ScoreSection}
            {currentSection === FEEDBACK && FeedbackSection}
            {/* {currentSection === CONTACTYOU && ContactYouSection}
            {currentSection === CONTACTDETAILS && ContactDetailsSection} */}
            {currentSection === PROMOTIONALPURPOSE && PromotionalPurposeSection}
          </div>
          {ButtonGroupSection}
        </div>
      ) : (
        <div>{ThankYouSection}</div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitNPSData: (values) => {
      const npsInfo = {
        score: values.score,
        scoreComments: values.scoreComments,
        firstName: values.firstName,
        emailAddress: values.emailAddress,
        phoneNumber: values.phoneNumber,
        requestCallback: values.requestCallback,
        marketingConscent: values.marketingConsent,
      };
      dispatch(submitNPSData(npsInfo));
    },
  };
};

export default connect(null, mapDispatchToProps)(NPSSurvey);
