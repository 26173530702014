import sha256 from "crypto-js/sha256";
import uuidv4 from "uuid/v4";
import { Express as Constants } from "../Config/Constants";
import { Gtm, Tracking } from "../Config/Constants";
import { useCallback, useRef } from "react";
import isEqual from "lodash/isEqual";

const MOVE_EXPRESS = "Existing/MoveExpress-account";

const servicesFullName = {
  ELEC: "Electricity",
  GAS: "Piped Gas",
  LPG: "Bottled Gas",
  BB: "Broadband",
};

const loginTypes = {
  EXISTING: "online-account",
  NONE: "no-online-account",
  NEW: "new",
};

const sectionInfo = {
  AboutYou: { name: "about-you", step: "1" },
  Property: { name: "about-property", step: "2" },
  BottledGas: { name: "extras", step: "3" },
  BroadbandOrder: { name: "broadband", step: "4" },
  Payment: { name: "payment-setup", step: "5" },
  FinalStep: { name: "final-steps", step: "6" },
};

export const useTracking = (
  callback,
  dependencies: any[],
  options = { limit: 1 }
) => {
  const counter = useRef(0);
  const cb = useRef(callback);
  cb.current = callback;
  const deps = useRef(dependencies);
  const track = useCallback(() => {
    if (
      counter.current < options.limit &&
      !isEqual(deps.current, dependencies)
    ) {
      cb.current(...dependencies);
      counter.current++;
    }
  }, [counter, cb, deps, dependencies, options]);
  return track;
};

const getProducts = (apiPlan, services) => {
  return services && apiPlan
    ? services.map((service) => ({
        name: apiPlan.Name,
        id: apiPlan.PlanId,
        brand: apiPlan.Extras,
        variant: servicesFullName[service],
        category: "New/Join",
        quantity: 1,
        price: "",
      }))
    : [];
};

const getExpressProducts = () => [
  {
    name: Constants.planInfo.planName,
    id: Constants.planInfo.planId,
    price: "",
    brand: "No fixed term",
    category: MOVE_EXPRESS,
    variant: "Electricity",
    quantity: 1,
  },
];

const getAddress = (address) => {
  if (address) {
    const lastIndex = address.lastIndexOf(", ");
    const streetSuburb = address.substring(0, lastIndex);
    const [city, zip] = address.substring(lastIndex + 2).split(" ");
    return { streetSuburb, city, zip };
  }
  return {};
};

const hashString = (message) => {
  return message ? sha256(message.toLowerCase()).toString() : null;
};

export const triggerSitecoreContractId = (id) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    SitecoreContactId: id ? id : uuidv4(),
  });
};

export const triggerMeasurementProtocolLive = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    measurementProtocolLive: true,
  });
};

export const triggerMyDetailsJourney = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "MyDetailsJourney",
    journey: "New/Join",
  });
};

export const triggerAddToCart = (apiPlan, services, email, propertyAddress) => {
  const address = getAddress(propertyAddress);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "addToCart",
    hashedEmail: hashString(email),
    hashedAddress: hashString(address.streetSuburb),
    hashedCity: hashString(address.city),
    hashedZip: hashString(address.zip),
    ecommerce: {
      add: {
        products: getProducts(apiPlan, services),
      },
    },
  });
};

export const triggerCheckoutStep = (apiPlan, services, section) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "checkoutStep",
    virtualUrl: "/residential/my-details/" + sectionInfo[section].name,
    ecommerce: {
      checkout: {
        products: getProducts(apiPlan, services),
        actionField: { step: sectionInfo[section].step },
      },
    },
  });
};

export const triggerPurchased = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "purchased",
  });
};

export const triggerPurchase = (apiPlan, services, icp, promoCode) => {
  if (process.env.NODE_ENV !== "development") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        purchase: {
          products: getProducts(apiPlan, services),
          transaction_id: "",
          affiliation: "web",
          coupon: promoCode,
        },
      },
    });
  }
};

export const triggerMyDetailsPageview = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "My-Details-Pageview",
    virtualUrl: "/residential/my-details/join-success",
  });
};

export const triggerNPSSubmit = (rate, text) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "NpsQuestionnaireSubmit",
    rate: rate,
    text: text,
  });
};

export const triggerAsyncNPSSubmit = (data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "NpsQuestionnaireSubmit",
    rate: data.score,
    text: data.scoreComments,
    preferredChannel: data.preferredChannel,
    useAsyncAgain: data.useAsyncAgain,
  });
};

export const triggerCustomerInfo = (email, firstName, lastName, phone) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "customerInfo",
    hashedEmail: hashString(email),
    hashedFN: hashString(firstName),
    hashedLN: hashString(lastName),
    hashedPnum: hashString(phone),
  });
};

export const triggerPropertyInfo = (propertyAddress) => {
  const address = getAddress(propertyAddress);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "propertyInfo",
    hashedAddress: hashString(address.streetSuburb),
    hashedCity: hashString(address.city),
    hashedZip: hashString(address.zip),
  });
};

export const triggerPageView = (type) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "MoveExpress-Pageview",
    virtualUrl: "/residential/express-move/" + loginTypes[type],
  });
};

export const triggerSuccessLogin = (accountId, businessPartnerId) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "loginEvent",
    login: "successfulLogin",
    baid: accountId,
    bpid: businessPartnerId,
  });
};

export const triggerGuestCheckout = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "guestCheckout",
  });
};

export const triggerClickToRegister = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "clickToRegister",
  });
};

export const triggerClicktostartNew = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "clicktostartNew",
  });
};

export const triggerMoveExpressJourney = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "MoveExpressJourney",
    journey: MOVE_EXPRESS,
  });
};

export const triggerAddToCartExpressJourney = (email, propertyAddress) => {
  const address = getAddress(propertyAddress);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "addToCart",
    hashedEmail: hashString(email),
    hashedAddress: hashString(address.streetSuburb),
    hashedCity: hashString(address.city),
    hashedZip: hashString(address.zip),
    ecommerce: {
      add: {
        products: getExpressProducts(),
      },
    },
  });
};

export const triggerCheckoutStepExpressJourney = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "checkoutStep",
    ecommerce: {
      checkout: {
        actionField: { step: "1" },
        products: getExpressProducts(),
      },
    },
  });
};

export const triggerExpressMoveSubmitted = (
  vulnerability,
  medicalDependence,
  hazards
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "expressMoveSubmitted",
    vulnerability: vulnerability ? "vulnerable" : "non-vulnerable",
    medicalDependence: medicalDependence ? "dependent" : "non-dependent",
    hazards: hazards.join("|"),
  });
};

export const triggerPurchaseExpress = (icp, promoCode) => {
  if (process.env.NODE_ENV !== "development") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        purchase: {
          transaction_id: "",
          affiliation: "web",
          coupon: promoCode,
          products: getExpressProducts(),
        },
      },
    });
  }
};

export const triggerIdCheck = (status, failedFields) => {
  window.dataLayer = window.dataLayer || [];
  if (failedFields) {
    window.dataLayer.push({
      event: "IdChecked",
      status: status,
      errorMsg: failedFields.join("|"), //form field name (id) separated by a pipe
    });
  } else {
    window.dataLayer.push({
      event: "IdChecked",
      status: status,
    });
  }
};

export const triggerSummaryJourney = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "SummaryJourney",
    journey: "New/Not Selected",
  });
};

export const triggerEPValues = (icp, available, region) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "EPValues",
    available,
    icp,
    pricingCalc: "Pricing Calculator",
    provider: "",
    region,
  });
};

export const triggerProductImpressions = (products) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "productImpression",
    ecommerce: {
      impressions: products.map((product, index) => ({
        brand: "",
        category: "New/Not Selected",
        id: product.Id,
        list:
          "contentpage standard-template basepage residential font-trial ijoin",
        name: product.Name,
        position: index + 1,
        price: "",
        variant: "",
      })),
    },
  });
};

export const triggerProductClick = (product, position) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "productClick",
    ecommerce: {
      click: {
        actionField: {
          list:
            "contentpage standard-template basepage residential font-trial ijoin",
        },
        products: [
          {
            name: product.Name,
            id: product.Id,
            price: "",
            brand: "",
            category: "New/Join",
            variant: "",
            position,
          },
        ],
      },
    },
  });
  window.dataLayer.push({
    event: "detailView",
    ecommerce: {
      detail: {
        products: [
          {
            name: product.Name,
            id: product.Id,
            price: "",
            brand: "",
            category: "New/Join",
            variant: "",
          },
        ],
      },
    },
  });
};

export const triggerPlanBuilderPageSubmitted = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "planBuilderPageSubmitted",
    baid: undefined,
    journey: "New/Join",
  });
};

export const triggerJoinPromoCodeSubmitted = (promoCode, isValid, message) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "promoCodesubmitted",
    promoCode: promoCode,
    promoCodeStatus: isValid ? "valid" : "invalid",
    promoCodeApplicationStatus: message,
    journey: "New/Not Selected",
  });
};

export const triggerSummaryPageSubmitted = (email, propertyAddress) => {
  const address = getAddress(propertyAddress);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "summaryPageSubmitted",
    optIn: undefined,
    hashedEmail: hashString(email),
    hashedAddress: hashString(address.streetSuburb),
    hashedCity: hashString(address.city),
    hashedZip: hashString(address.zip),
    journey: "New/Not Selected",
  });
};

export const triggerCsrEvent = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "csr",
    csrId: Gtm.reactAgentAppId,
  });
};

export const triggerAgentPageView = (page) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "page_view",
    page_location: page ? window.location.href + page : window.location.href,
  });
};

export const triggerServiceToggle = (isOpen, service) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: isOpen ? "show_service_details" : "hide_service_details",
    service: service,
  });
};

export const triggerServiceSelect = (service) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "select_service",
    service: Tracking[service],
  });
};

export const triggerServiceEnter = (serviceType, icp, data) => {
  window.dataLayer = window.dataLayer || [];
  const electricity = {
    event: "service_result",
    service: serviceType,
    icp: icp,
    status: data.status,
    meter_type: data.meterType,
    provider: data.trader,
    switch_in_progress: data.switchInProgress,
  };
  const pipedGas = {
    event: "service_result",
    service: serviceType,
    icp: icp,
    status: data.statusDescription,
  };
  const broadband = {
    event: "service_result",
    service: serviceType,
    status: data.status || "unknown",
    provider: data.Trader || "unknown",
    address: data.Address,
    fibre: data.fibre || "unknown",
  };
  const currentService =
    serviceType === "electricity"
      ? electricity
      : serviceType === "piped gas"
      ? pipedGas
      : broadband;
  window.dataLayer.push(currentService);
};

export const triggerPlanSelect = (data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "select_item",
    items: [
      {
        item_name: data.Name,
        item_id: data.Id,
        item_brand: data.Description,
        item_category: Tracking.categoryNew,
        item_variant: Tracking.ELEC,
        quantity: 1,
      },
    ],
  });
};

export const triggerPromoCodeSubmitted = (data, promoCode) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "promoCodesubmitted",
    promoCode: promoCode,
    promoCodeStatus: data.IsValid ? Tracking.valid : Tracking.invalid,
    promoCodeApplicationStatus: data.Description,
  });
};

export const triggerBeginCheckout = (data, promoCode) => {
  if (data) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "begin_checkout",
      items: [
        {
          item_name: data.Name,
          item_id: data.Id,
          item_brand: data.Description,
          item_category: Tracking.categoryNew,
          item_variant: Tracking.ELEC,
          quantity: 1,
          item_coupon: promoCode,
        },
      ],
    });
  }
};

export const triggerDriverLicense = (data) => {
  window.dataLayer = window.dataLayer || [];
  const dlEmptyOrValid = {
    event: "IdChecked",
    status: data?.verified ? Tracking.pass : Tracking.noUse,
  };
  const dlNotValid = {
    event: "IdChecked",
    status: Tracking.fail,
    errorMsg: data?.invalidFields?.join("|"),
  };
  const dlData = data?.verified || !data ? dlEmptyOrValid : dlNotValid;
  window.dataLayer.push(dlData);
};

export const triggerBPCreate = (bp) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "sign_up",
    bpid: bp,
  });
};

export const triggerCCResult = (result) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "credit_check_result",
    result: result,
  });
};

export const triggerPersonAdded = (
  jointAccountAmount,
  authorisedPersonAmount
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: jointAccountAmount
      ? "joint_account_holder_added"
      : "authorised_person_added",
  });
};

export const triggerAgentPurchase = (data, coupon, icp) => {
  window.dataLayer = window.dataLayer || [];
  const transactionId = icp + "_" + new Date().getTime();
  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      purchase: {
        transaction_id: transactionId,
        affiliation: "CSR",
        coupon: coupon,
        items: [
          {
            item_name: data.Name,
            item_id: data.Id,
            item_brand: data.Description,
            item_category: Tracking.categoryNew,
            item_variant: Tracking.ELEC,
            quantity: 1,
            item_coupon: coupon,
          },
        ],
      },
    },
  });
};
