// @flow
import React from "react";
import { HTML, Modal } from "react-components";
import { Payment as Constants } from "../../Config/Constants";

type Props = {
  isActive: boolean,
  onClose: () => void,
  type: "bankAccountNumber" | "aaSmartFuel",
};

const VerificationFailedModal = (props: Props) => (
  <Modal
    isActive={props.isActive}
    className="payment_verificationFailedModal"
    handlePrimaryClick={props.onClose}
    content={<HTML html={Constants.verificationFailedModal[props.type]} />}
    buttonTextPrimary={Constants.verificationFailedModal.buttonLabel}
  />
);

export default VerificationFailedModal;
