import { useCallback, useReducer } from "react";

type Field = "driverLicence" | "cardNumber";
type FieldState = {
  value: string,
  error: boolean,
};
type State = {
  driverLicence: FieldState,
  cardNumber: FieldState,
  hasDriverLicence: boolean,
};
type Action =
  | { type: "set", payload: null | string, meta: { field: Field } }
  | { type: "error", payload: undefined | boolean, meta: { field: Field } }
  | { type: "clear", payload: Field }
  | { type: "toggle", payload?: boolean }
  | { type: "reset" };

const init = (values: $Shape<State> = {}): State => ({
  hasDriverLicence: false,
  driverLicence: {
    value: "",
    error: false,
  },
  cardNumber: {
    value: "",
    error: false,
  },
  ...values,
});
const driverLicenseReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "set":
      return {
        ...state,
        [action.meta.field]: {
          ...state[action.meta.field],
          value: action.payload ?? "",
        },
      };
    case "error":
      return {
        ...state,
        [action.meta.field]: {
          ...state[action.meta.field],
          error: action.payload ?? false,
        },
      };
    case "toggle":
      const nextToggleState = action.payload ?? !state.hasDriverLicence;
      return {
        ...state,
        hasDriverLicence: nextToggleState,
      };
    case "clear":
      return {
        ...state,
        [action.payload]: {
          value: "",
          error: false,
        },
      };
    case "reset":
      return init();
    default:
      throw Error("Action not found.");
  }
};
/**
 * Manages:
 *  - hasDriverLicence checkbox
 *  - driverLicence value + error
 *  - cardNumber value + error
 */
export const useDriverLicense = (initialState: $Shape<State> = {}) => {
  const [state, dispatch] = useReducer(
    driverLicenseReducer,
    initialState,
    init
  );

  const setDriverLicence = useCallback(
    (value: string) =>
      dispatch({
        type: "set",
        payload: value,
        meta: { field: "driverLicence" },
      }),
    []
  );
  const setCardNumber = useCallback(
    (value: string) =>
      dispatch({
        type: "set",
        payload: value,
        meta: { field: "cardNumber" },
      }),
    []
  );

  const setDriverLicenceHasError = useCallback(
    (state: boolean) =>
      dispatch({
        type: "error",
        payload: state,
        meta: { field: "driverLicence" },
      }),
    []
  );
  const setCardNumberHasError = useCallback(
    (state: boolean) =>
      dispatch({
        type: "error",
        payload: state,
        meta: { field: "cardNumber" },
      }),
    []
  );

  const setHasDriverLicence = useCallback((state: boolean) => {
    if (state) {
      dispatch({ type: "toggle", payload: state });
    } else {
      // We don't want to persist the details in the UI layer when the box is
      // unchecked, this ensures that if the "next" state is to uncheck the box
      // that we not only set the toggle to `false` but also reset all internal
      // fields state to have no values and no errors.
      dispatch({ type: "reset" });
    }
  }, []);

  // This interface is written this way to ensure backwards compatibility
  // with the existing, poorly structured, code.
  return {
    hasDriverLicence: state.hasDriverLicence,
    setHasDriverLicence: setHasDriverLicence,

    driverLicence: state.driverLicence.value,
    setDriverLicence,
    driverLicenceHasError: state.driverLicence.error,
    setDriverLicenceHasError,

    cardNumber: state.cardNumber.value,
    setCardNumber,
    cardNumberHasError: state.cardNumber.error,
    setCardNumberHasError,
  };
};
