import * as actionTypes from "../actionTypes";

export const updateBPFetching = (isFetching) => {
  return {
    type: actionTypes.UPDATE_BP_FETCHING,
    isFetching,
  };
};

export const updateBPFetched = (isFetched) => {
  return {
    type: actionTypes.UPDATE_BP_FETCHED,
    isFetched,
  };
};

export const updateBPError = (errorMessage, errorDetails) => {
  return {
    type: actionTypes.UPDATE_BP_ERROR,
    isError: true,
    errorMessage,
    errorDetails,
  };
};
