// @flow
import * as actionTypes from "../../Actions/actionTypes";

type Eligibility = {
  name: string,
  eligible: boolean,
};

type ApiPlan = {
  Filters: Array<string>,
  PlanId: string,
  Name: string,
  Extras: string,
};

type Discounts = {
  JoinDirectDebit: boolean,
  PromoCode: string,
  PaperlessDiscount: boolean,
};

type PlanInfo = {
  PlanFixedEndDate: string,
  PlanId: string,
  SitecorePlanItemId: string,
};

type ElectricityICP = {
  IcpNumber: string,
};

type PriceChecker = {
  ElectricityICP: ElectricityICP,
};

type State = {
  +SitecoreContactId: string,
  +Eligibilities: Array<Eligibility>,
  +ApiPlan: ApiPlan,
  +Discounts: Discounts,
  +PlanInfo: PlanInfo,
  +PriceChecker: PriceChecker,
};

const initialState: State = {
  SitecoreContactId: null,
  BroadbandFullName: null,
  BroadbandId: null,
  Eligibilities: [],
  ApiPlan: null,
  Discounts: null,
  PriceChecker: { ElectricityICP: { IcpNumber: null } },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_READONLY:
      return { ...action.data };

    default:
      return state;
  }
};

export default reducer;
