// @flow
import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import {
  ArrowDown,
  ExpandableCard,
  Gas,
  ServiceModal,
  ServiceCardField,
  RadioButtonList,
  HTML,
} from "react-components";
import { triggerServiceToggle } from "../../../Utils/analytics";
import { Tracking } from "../../../Config/Constants";

type State = {
  activeModal: null | string,
  icp: null | string,
  icpDetails: null | {}, // not sure if this card needs this?
  results: [],
  cardOpen: boolean,
  pipedGasUsage: string,
};
type Actions =
  | { type: "setActiveModal", payload: "ADDRESS" | "ICP" }
  | { type: "closeModals" }
  | { type: "selectICP", payload: string }
  | { type: "invalidICP" }
  | { type: "reset" }
  | { type: "setSearchResults" }
  | { type: "toggleCard", payload: boolean }
  | { type: "setUsage", payload: string };
const initialState: State = {
  activeModal: null,
  icp: null,
  icpDetails: null,
  results: [],
  cardOpen: false,
  pipedGasUsage: "Standard",
  changedPipedGasUsage: false,
};
const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case "setActiveModal":
      return {
        ...state,
        activeModal: action.payload,
      };
    case "closeModals":
      return {
        ...state,
        activeModal: null,
      };
    case "setSearchResults":
      return {
        ...state,
        activeModal: null,
        results: action.payload,
      };
    case "selectICP":
      return {
        ...state,
        activeModal: null,
        icp: action.payload, // setting this should trigger an API call in a useEffect
        icpDetails: null, // clear previous icp details
        results: [], // clear results list
      };
    case "invalidICP":
      return {
        ...state,
        icp: null,
        icpDetails: null,
      };
    case "toggleCard":
      return {
        ...state,
        cardOpen: action.payload ?? !state.cardOpen,
      };
    case "setAddressesList":
      return {
        ...state,
        results: action.payload,
      };
    case "setUsage":
      return {
        ...state,
        pipedGasUsage: action.payload,
        changedPipedGasUsage: true,
      };
    case "reset":
      return initialState;
    default:
      throw new Error(
        `PipedGasServiceCard, could not find reducer key for action of type "${action.type}"`
      );
  }
};

type Address = {
  streetNumber: string,
  streetName: string,
  suburbOrTown: string,
  region: string,
};

type Props = {
  gasIcp: null | string,
  setGasIcp: (icp: string) => void,
  list: Array,
  addressesList: Array,
  serviceType: string,
  address: string,
  icpDetails: Object,
  island: null | string,
  onCheckboxChange: Function,
  constants: Object,
  consumptionBand: null | string,
  setConsumptionBand: Function,
  getGasData: Function,
  setModalOpened: Function,
};
export const PipedGasServiceCard = ({
  onCheckboxChange,
  setGasIcp,
  consumptionBand,
  setConsumptionBand,
  gasIcp,
  icpDetails,
  constants,
  serviceType,
  list,
  isInList,
  address,
  getGasData,
  addressesList,
  island,
  ...props
}: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Pre-bound reducer actions
  const actions = useMemo(
    () => ({
      setActiveModal: (modal: string) =>
        dispatch({ type: "setActiveModal", payload: modal }),
      closeModals: () => dispatch({ type: "closeModals" }),
      selectICP: (icp: string) => dispatch({ type: "selectICP", payload: icp }),
      invalidICP: () => dispatch({ type: "invalidICP" }),
      reset: () => dispatch({ type: "reset" }),
      setSearchResults: () => dispatch({ type: "setSearchResults" }),
      toggleCard: (isOpen) => dispatch({ type: "toggleCard", payload: isOpen }),
      setAddressesList: (gasList) =>
        dispatch({ type: "setAddressesList", payload: gasList }),
      setUsage: (usage) => dispatch({ type: "setUsage", payload: usage }),
    }),
    [dispatch]
  );

  const consumptionBandIndex = useMemo(() => {
    return state.pipedGasUsage === "Standard" ? 1 : 0;
  }, [state.pipedGasUsage]);

  // API Calls

  // State Sync
  useEffect(() => {
    // When the parent gets a state update after a rehydration, sync the gasIcp into this component
    if (gasIcp) {
      actions.selectICP(gasIcp);
    }
    // eslint-disable-next-line
  }, [gasIcp]);

  useEffect(() => {
    props.setModalOpened(state.activeModal);
    // eslint-disable-next-line
  }, [state.activeModal]);

  useEffect(() => {
    if (state.cardOpen) {
      triggerServiceToggle(state.cardOpen, Tracking.GAS);
    }
  }, [state.cardOpen]);

  useEffect(() => {
    if (state.icp) {
      setGasIcp(state.icp);
    }
    // eslint-disable-next-line
  }, [state.icp]);

  useEffect(() => {
    if (addressesList) {
      actions.setAddressesList(addressesList);
    }
    // eslint-disable-next-line
  }, [addressesList]);

  useEffect(() => {
    if (serviceType && list) {
      actions.toggleCard(isInList(serviceType, list));
    }
    // eslint-disable-next-line
  }, [serviceType, list]);

  useEffect(() => {
    // We will have to populate the usage dropdown or consumption band radio button everytime there is a ICP change.
    if (consumptionBand && !state.changedPipedGasUsage) {
      setConsumptionBand(consumptionBand);
      actions.setUsage(consumptionBand.split(" - ")[0]);
    } else {
      setConsumptionBand(constants.consumptionBands[consumptionBandIndex]);
    }
    // eslint-disable-next-line
  }, [
    state.icp,
    state.changedPipedGasUsage,
    consumptionBand,
    consumptionBandIndex,
    setConsumptionBand,
  ]);

  useEffect(() => {
    // Unfortunately we have usage bands dropdown and consumption band radio buttons
    // linked so as a fix we can stick to this. Can be refactored later.
    if (island === "North" && state.changedPipedGasUsage) {
      setConsumptionBand(constants.consumptionBands[consumptionBandIndex]);
    }

    if (island === "North" && !state.changedPipedGasUsage) {
      setConsumptionBand(consumptionBand);
    }

    if (island === "South") {
      setConsumptionBand(
        consumptionBand || constants.consumptionBands[consumptionBandIndex]
      );
    }

    // eslint-disable-next-line
  }, [
    state.changedPipedGasUsage,
    island,
    consumptionBand,
    consumptionBandIndex,
    setConsumptionBand,
  ]);

  // Local Handlers
  const handleAddressSearch = useCallback(
    (address: Address) => {
      getGasData(address);

      /**
       * TODO: Handle address search, this will probably be an API call and
       *       results in a dispatch for either "selectICP" or "setSearchResults"
       * */
      actions.closeModals(); // remove this when done and close modals within the reducer
      // eslint-disable-next-line
    },
    [actions, getGasData]
  );

  const getStatus = (details) => {
    if (details) {
      let description = "-";
      constants.gasStatus.forEach((item) => {
        if (
          item.connectionStatus === details.connectionStatus &&
          item.status === details.status
        ) {
          description = item.description;
        }
      });
      return description;
    }
    return "";
  };

  const buildGasServiceContent = () => {
    if (island === "North" || !island) {
      return (
        <div className="serviceCard_content">
          <ServiceCardField
            fieldType="button"
            value={gasIcp ? gasIcp : "ICP not found"}
            title="Gas ICP"
            buttonTitle="Copy"
            hasError={!gasIcp}
          />
          <ServiceCardField
            fieldType="address"
            title="Registry Address"
            setShowAddressModal={() => actions.setActiveModal("ADDRESS")}
            setShowIcpModal={() => actions.setActiveModal("ICP")}
            value={
              icpDetails?.fullAddress
                ? icpDetails?.fullAddress
                : props.address
                ? props.address
                : ""
            }
            addressList={state.results}
            onIcpSelect={(icp) => dispatch({ type: "selectICP", payload: icp })}
          />
          <ServiceCardField
            fieldType="standard"
            value={getStatus(icpDetails)} //if icpDetails is empty it removes status from ui
            title="Status"
            isValid
            showSuccessIcon={icpDetails?.status}
          />
          <ServiceCardField
            fieldType="standard"
            value={icpDetails?.serial || "-"}
            title="Serial number"
          />
          <ServiceCardField
            fieldType="standard"
            value={
              icpDetails?.meterPressure
                ? `${icpDetails?.meterPressure} units`
                : "-"
            }
            title="Meter pressure"
          />
          <ServiceCardField
            fieldType="standard"
            value={icpDetails?.trader || "-"}
            title="Current retailer"
          />
          <ServiceCardField
            fieldType="dropdown"
            value={state.pipedGasUsage}
            title="User type"
            dropdownItems={["Standard", "Low"]}
            style={{ margin: "10px 0 20px 0" }}
            setSelectedItem={actions.setUsage}
            selectedItem={state.pipedGasUsage}
          />
        </div>
      );
    }

    if (island === "South") {
      return (
        <div className="serviceDetails_service-buttons">
          <div className="serviceDetails_description">
            <HTML html={constants.pipedGasDescription} />
          </div>
          <div
            className="serviceDetails_description"
            style={{ marginBottom: 20 }}
          >
            {constants.gasUsageTitle}
          </div>
          <RadioButtonList
            name="consumptionBand"
            items={constants.consumptionBands}
            value={consumptionBand}
            onChange={(value) => setConsumptionBand(value)}
            required
          />
        </div>
      );
    }
  };

  return (
    <>
      {/* Main Content */}
      <ExpandableCard
        name="piped gas"
        isOpen={state.cardOpen}
        checked={isInList(serviceType, list)}
        onCheckboxChange={onCheckboxChange}
        headerContent={{
          cardTitle: "Piped Gas",
          cardIcon: <ArrowDown />,
          mainIcon: <Gas />,
          withCheckbox: true,
        }}
        classes={{
          infoContainer: {
            "expandableCard_info-bar": true,
          },
        }}
        handleClick={actions.toggleCard}
        content={<>{buildGasServiceContent()}</>}
      />
      {/* Modals */}
      <ServiceModal
        modalType="address"
        isActive={state.activeModal === "ADDRESS"}
        handlePrimaryClick={() => actions.setActiveModal(null)}
        handleManualAddressSubmission={handleAddressSearch}
        serviceType="piped gas"
      />
      <ServiceModal
        modalType="icp"
        isActive={state.activeModal === "ICP"}
        handlePrimaryClick={() => actions.setActiveModal(null)}
        handleIcpChange={actions.selectICP}
        serviceType="piped gas"
      />
    </>
  );
};
