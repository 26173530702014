import * as actionTypes from "../actionTypes";
import {
  saveApiState,
  createApiState,
  getApiState,
  saveCustomerInfo,
} from "../ApiCalls";
import { updateServices } from "../iJoin/ServicesActions";
import { updateProperty } from "../iJoin/PropertyActions";
import { updateConfirmation } from "../iJoin/ConfirmationActions";
import { updateCustomer } from "../iJoin/CustomerActions";
import { updatePipedGas } from "../iJoin/GasActions";
import { updateBottledGas } from "../iJoin/BottledGasActions";
import { updatePayment } from "../iJoin/PaymentActions";
import { updateBroadband } from "../iJoin/BroadbandActions";
import {
  saveCreditCheckResult,
  updateCreditCheckResult,
} from "../CSRAgent/CreditCheckAction";
import {
  updateJointAccountHolder,
  updateAuthorizedPerson,
} from "../CSRAgent/JointAccountAction";

export const updatePlan = (plan) => {
  return {
    type: actionTypes.UPDATE_PLAN,
    plan: plan,
  };
};

export const createState = () => {
  return (dispatch) => {
    if (!process.env.REACT_APP_USE_FIXTURES) {
      dispatch(createApiState({}));
    }
  };
};

export const getState = () => {
  return (dispatch) => {
    if (!process.env.REACT_APP_USE_FIXTURES) {
      dispatch(getApiState({}));
    }
  };
};

export const updateAuth = (auth) => {
  return {
    type: actionTypes.UPDATE_AUTH,
    auth: auth,
  };
};

export const saveAuthData = (auth, state) => {
  return (dispatch, getState) => {
    const state = getState();
    const authData = {
      ...state.Auth,
      ...auth,
    };
    dispatch(updateAuth(authData));
  };
};

export const updateBP = (apiData) => {
  return (dispatch) => {
    const customer = {
      Customer: {
        CustomerInfo: {
          BusinessPartnerNumber: apiData.bp,
          BankId: apiData.bankDetails?.BankId,
        },
      },
    };
    dispatch(saveCustomerData(customer));
  };
};

export const createBA = (apiData) => {
  return (dispatch) => {
    const customer = {
      Customer: {
        CustomerInfo: {
          AccountNumber: apiData,
        },
      },
    };
    dispatch(saveCustomerData(customer));
  };
};

export const updateCreditCheck = () => {
  return (dispatch) => {
    const customer = {
      Customer: {
        CustomerInfo: {
          BPUpdated: false,
        },
      },
    };
    dispatch(updateCustomer(customer));
  };
};

export const saveCustomerData = (data, submit) => {
  return (dispatch, getState) => {
    const state = getState();
    if (state.Auth.sessionID) {
      const customerData = {
        ...state.Customer,
        ...data.Customer,
        CustomerInfo: {
          ...state.Customer?.CustomerInfo,
          ...data.Customer?.CustomerInfo,
        },
      };
      const plan = {
        PlanInfo: {
          ...state.PlanInfo,
          ...data.PlanInfo,
        },
      };
      const newProperty = {
        PropertyInfo: {
          ...state.Property?.PropertyInfo,
          ...data.Property?.PropertyInfo,
        },
        MedicalInfo: {
          ...state.Property?.MedicalInfo,
          ...data.Property?.MedicalInfo,
        },
        MoveInfo: {
          ...state.Property?.MoveInfo,
          ...data.Property?.MoveInfo,
        },
      };
      const bb = {
        ...state.BroadbandInfo,
        ...data.BroadbandInfo,
      };
      const gasData = {
        ...state.PipedGasInfo,
        ...data.PipedGasInfo,
      };
      const services = data.Services ? [...data.Services] : state.Services;
      const lpgData = {
        ...state.LpgInfo,
        ...data.LpgInfo,
      };
      const paymentData = {
        ...state.Payment,
        ...data.Payment,
      };

      let credit = {
        ...state.CreditCheck.data,
        ...data.CreditCheck,
      };
      if (credit.paymentAmount) {
        credit = {
          Message: credit.message,
          Result: credit.ragStatus,
          Bond: credit.paymentAmount,
        };
      }
      const confirmation = {
        ...state.Confirmation,
        ...data.Confirmation,
      };
      const stateData = state.JointAccountHolderInfo || [];
      const accountData = data.JointAccountHolderInfo || [];
      let jointAccountHolder = {
        ...stateData[0],
        ...accountData,
      };
      if (!jointAccountHolder.IsJointAccount) {
        jointAccountHolder = [];
      } else {
        jointAccountHolder = [jointAccountHolder];
      }
      const authPersonData = {
        ...state.AuthorizedPersonInfo,
        ...data.AuthorizedPersonInfo,
      };

      data.CreditCheck && dispatch(saveCreditCheckResult(credit));
      data.PlanInfo && dispatch(updatePlan(plan));
      data.Services && dispatch(updateServices(services));
      data.Property && dispatch(updateProperty(newProperty));
      data.Customer && dispatch(updateCustomer(customerData));
      data.PipedGasInfo && dispatch(updatePipedGas(gasData));
      lpgData.BottledGasUsage && dispatch(updateBottledGas(lpgData));
      data.Payment && dispatch(updatePayment(paymentData));
      data.BroadbandInfo && dispatch(updateBroadband(bb));
      data.Confirmation && dispatch(updateConfirmation(confirmation));
      data.JointAccountHolderInfo &&
        dispatch(updateJointAccountHolder(jointAccountHolder));
      data.AuthorizedPersonInfo &&
        dispatch(updateAuthorizedPerson(authPersonData));

      if (!process.env.REACT_APP_USE_FIXTURES) {
        dispatch(
          saveApiState(
            {
              LpgInfo: {
                ...lpgData,
              },
              PipedGasInfo: {
                ...gasData,
              },
              Customer: {
                ...customerData,
              },
              BroadbandInfo: {
                ...bb,
              },
              Property: {
                ...newProperty,
              },
              Services: services,
              PropertyInfo: newProperty.PropertyInfo,
              MedicalInfo: newProperty.MedicalInfo,
              MoveInfo: newProperty.MoveInfo,
              PlanInfo: {
                ...plan.PlanInfo,
              },
              Payment: {
                ...paymentData,
              },
              JointAccountHolderInfo: jointAccountHolder,
              AuthorizedPersonInfo: authPersonData,
              Confirmation: confirmation,
              CreditCheckInfo: credit,
              JourneyChannel: data.JourneyChannel,
            },
            submit
          )
        );
      }
    }
  };
};

export const saveCustomerInfoForSaveForLater = (data, submit) => {
  return (dispatch, getState) => {
    const state = getState();
    if (state.Auth.sessionID) {
      const customerData = {
        ...state.Customer,
        ...data.Customer,
        CustomerInfo: {
          ...state.Customer?.CustomerInfo,
          ...data.Customer?.CustomerInfo,
        },
      };
      const plan = {
        PlanInfo: {
          ...state.PlanInfo,
          ...data.PlanInfo,
        },
      };
      const newProperty = {
        PropertyInfo: {
          ...state.Property?.PropertyInfo,
          ...data.Property?.PropertyInfo,
        },
        MedicalInfo: {
          ...state.Property?.MedicalInfo,
          ...data.Property?.MedicalInfo,
        },
        MoveInfo: {
          ...state.Property?.MoveInfo,
          ...data.Property?.MoveInfo,
        },
      };
      const bb = {
        ...state.BroadbandInfo,
        ...data.BroadbandInfo,
      };
      const gasData = {
        ...state.PipedGasInfo,
        ...data.PipedGasInfo,
      };
      const services = data.Services ? [...data.Services] : state.Services;
      const lpgData = {
        ...state.LpgInfo,
        ...data.LpgInfo,
      };
      const paymentData = {
        ...state.Payment,
        ...data.Payment,
      };

      let credit = {
        ...state.CreditCheck.data,
        ...data.CreditCheck,
      };
      if (credit.paymentAmount) {
        credit = {
          Message: credit.message,
          Result: credit.ragStatus,
          Bond: credit.paymentAmount,
        };
      }
      const confirmation = {
        ...state.Confirmation,
        ...data.Confirmation,
      };
      const stateData = state.JointAccountHolderInfo || [];
      const accountData = data.JointAccountHolderInfo || [];
      let jointAccountHolder = [
        {
          ...stateData[0],
          ...accountData,
        },
      ];
      if (!jointAccountHolder[0].IsJointAccount) {
        jointAccountHolder = [];
      }
      const authPersonData = {
        ...state.AuthorizedPersonInfo,
        ...data.AuthorizedPersonInfo,
      };
      data.CreditCheck && dispatch(updateCreditCheckResult(credit));
      data.PlanInfo && dispatch(updatePlan(plan));
      data.Services && dispatch(updateServices(services));
      data.Property && dispatch(updateProperty(newProperty));
      data.Customer && dispatch(updateCustomer(customerData));
      data.PipedGasInfo && dispatch(updatePipedGas(gasData));
      lpgData.BottledGasUsage && dispatch(updateBottledGas(lpgData));
      data.Payment && dispatch(updatePayment(paymentData));
      data.BroadbandInfo && dispatch(updateBroadband(bb));
      data.Confirmation && dispatch(updateConfirmation(confirmation));
      data.JointAccountHolderInfo &&
        dispatch(updateJointAccountHolder(jointAccountHolder));
      data.AuthorizedPersonInfo &&
        dispatch(updateAuthorizedPerson(authPersonData));

      if (!process.env.REACT_APP_USE_FIXTURES) {
        dispatch(
          saveCustomerInfo(
            {
              LpgInfo: {
                ...lpgData,
              },
              PipedGasInfo: {
                ...gasData,
              },
              Customer: {
                ...customerData,
              },
              BroadbandInfo: {
                ...bb,
              },
              Property: {
                ...newProperty,
              },
              Services: services,
              PropertyInfo: newProperty.PropertyInfo,
              MedicalInfo: newProperty.MedicalInfo,
              MoveInfo: newProperty.MoveInfo,
              PlanInfo: {
                ...plan.PlanInfo,
              },
              Payment: {
                ...paymentData,
              },
              JointAccountHolderInfo: jointAccountHolder,
              AuthorizedPersonInfo: authPersonData,
              Confirmation: confirmation,
              CreditCheckInfo: credit,
              JourneyChannel: data.JourneyChannel,
            },
            submit
          )
        );
      }
    }
  };
};

export const saveForLaterRequest = () => {
  return {
    type: actionTypes.SAVE_FOR_LATER_SUBMITTING,
  };
};

export const saveForLaterSuccess = (successResponse, successMessage, state) => {
  return {
    type: actionTypes.SAVE_FOR_LATER_SUCCESS,
    successResponse,
    successMessage,
    state,
  };
};

export const saveForLaterFailure = (error) => {
  return {
    type: actionTypes.SAVE_FOR_LATER_ERROR,
    error,
  };
};

export const saveForLaterReset = () => {
  return {
    type: actionTypes.SAVE_FOR_LATER_INITIAL_STATE,
  };
};
