import * as actionTypes from "../../Actions/actionTypes";

const initialState = [
  {
    IsJointAccount: false,
    Title: "",
    FirstName: "",
    LastName: "",
    DateOfBirth: "",
    PhoneNumber: "",
    EmailAddress: "",
  },
];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_JOINT_ACCOUNT_HOLDER:
      return action.JointAccountHolderInfo;
    default:
      return state;
  }
};

export default reducer;
