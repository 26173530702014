// @flow
import React from "react";
import { connect, Provider } from "react-redux";
import { compose, branch, renderNothing } from "recompose";
import {
  getHasBottledGas,
  getHasBroadband,
  getHasReticulatedGas,
} from "./selectors";

/**
 * HOC
 * Wraps component into Redux provider with specified store.
 * @param store
 */
export const withReduxProvider = (store) => (Component) => (props) => (
  <Provider store={store}>
    <Component {...props} />
  </Provider>
);

export const withErrorBoundary = (renderError) => {
  class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
      return { hasError: true };
    }

    componentDidCatch(error, info) {
      if (process.env.NODE_ENV === "development") {
        console.log(error, info);
      }
    }

    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return renderError();
      }
      return this.props.children;
    }
  }

  return (Component) => (props) => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );
};

/**
 * HOC
 * Do not render component, if customer has not selected LPG service.
 */
export const withBottledGasMandatory = compose(
  connect((state) => ({
    hasBottledGas: getHasBottledGas(state),
  })),
  branch(({ hasBottledGas }) => !hasBottledGas, renderNothing)
);

/**
 * HOC
 * Do not render component, if customer has not selected broadband service.
 */
export const withBroadbandMandatory = compose(
  connect((state) => ({
    hasBroadband: getHasBroadband(state),
  })),
  branch(({ hasBroadband }) => !hasBroadband, renderNothing)
);

/**
 * HOC
 * Injects hasReticulatedGas prop.
 */
export const withHasReticulatedGas = connect((state) => ({
  hasReticulatedGas: getHasReticulatedGas(state),
}));

/**
 * HOC
 * Do not render component, if not a south island customer with piped gas selected
 */
export const withReticulatedGasMandatory = compose(
  withHasReticulatedGas,
  branch(({ hasReticulatedGas }) => !hasReticulatedGas, renderNothing)
);
