// @flow
import { ApiError } from "../Config/Constants";

export type ServiceResponse = {
  ok: boolean,
  problem: ?string,
  data: any,
  status: ?number,
  headers?: Object,
  config?: Object,
  duration?: Object,
};

type ApiErrorType = {|
  statusCode: ?number,
  serviceResponse?: ?ServiceResponse,
|};

export const apiResolveError = (
  { statusCode, serviceResponse }: ApiErrorType,
  operation: string = "",
  state = null
) => {
  let data = null;
  if (serviceResponse) {
    data = serviceResponse.data || serviceResponse.problem;
  }

  if (statusCode && data) {
    switch (true) {
      case statusCode === 400:
        return "badRequestError";
      case statusCode === 401:
        return "unauthorizedError";
      case statusCode > 401 && statusCode < 500:
        return "clientError";
      case statusCode === 500 &&
        state &&
        data?.error?.includes(
          state.Customer?.DriversLicense?.Number?.toUpperCase()
        ):
        return "dlExistError";
      case statusCode > 499:
        return `${operation}ServerError`;
      default:
        return "clientError";
    }
  } else if (statusCode === null && !data) {
    return "dataError";
  } else {
    return "apiTimeoutError";
  }
};

// Sharan: We can update the message as we work on future tickets.
export const convertApiErrorToText = (error: string) => {
  switch (error) {
    case "badRequestError":
      return {
        blurb1: ApiError.errorTitle,
        blurb2: ApiError.errorMessage,
      };
    case "clientError":
      return {
        blurb1: ApiError.errorTitle,
        blurb2: ApiError.errorMessage,
      };
    case "ServerError":
      return {
        blurb1: ApiError.errorTitle,
        blurb2: ApiError.serverErrorMessage,
      };
    case "bpServerError":
      return {
        blurb1: ApiError.errorTitle,
        blurb2: ApiError.bpServerErrorMessage,
      };
    case "ccServerError":
      return {
        blurb1: ApiError.errorTitle,
        blurb2: ApiError.ccServerErrorMessage,
      };
    case "dataError":
      return {
        blurb1: ApiError.errorTitle,
        blurb2: ApiError.errorMessage,
      };
    case "apiTimeoutError":
      return {
        blurb1: ApiError.errorTitle,
        blurb2: ApiError.errorMessage,
      };
    default:
      return {
        blurb1: ApiError.errorTitle,
        blurb2: ApiError.errorMessage,
      };
  }
};
