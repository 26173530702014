import { servicesFullName } from "../Config/Constants";

export const saveForLaterCustomerList = (data: any) => {
  // For now we will always have only one customer. In the future state we will have multiple customers
  try {
    const customerList = [];

    let servicesAndPlansList = [];

    if (data && data.result) {
      if (data?.result.state?.Services?.length > 0) {
        servicesAndPlansList = data.result.state.Services.map((service) => {
          return servicesFullName[service];
        });
        servicesAndPlansList.push(
          data.result.state.PlanInfo.SelectedPlan?.Name
        );
        servicesAndPlansList.push(
          data.result.state.PlanInfo.SelectedBBPlan?.Name
        );
      } else {
        servicesAndPlansList.push("No services or plan selected yet");
      }

      const filteredServicesAndPlans = servicesAndPlansList.filter(
        (arrayItem) => arrayItem !== null && arrayItem !== undefined
      );

      customerList.push({
        firstName: data.result.firstName,
        lastName: data.result.lastName,
        serviceAddress: data.result.serviceAddress,
        emailAddress: data.result.emailAddress,
        services: filteredServicesAndPlans.join(", "),
      });
    }
    return customerList;
  } catch (e) {
    console.log("Exception ", e);
  }
};
