import * as actionTypes from "../Actions/actionTypes";

const initialState = {
  sessionID: "",
  deleteAfter: 0,
  hasError: false,
  isCallingAPI: false,
  submitted: false,
  redirectFromExpress: false,
};

const updateAuth = (state, auth) => {
  return { ...state, ...auth };
};

const callAPI = (state) => {
  return { ...state, isCallingAPI: true, hasError: false };
};

const setError = (state) => {
  return { ...state, isCallingAPI: false, hasError: true };
};

const setSuccess = (state) => {
  return { ...state, isCallingAPI: false, hasError: false };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_AUTH:
      return updateAuth(state, action.auth);

    case actionTypes.CALL_API:
      return callAPI(state);

    case actionTypes.API_ERROR:
      return setError(state);

    case actionTypes.API_SUCCESS:
      return setSuccess(state);

    case actionTypes.API_SUBMIT_SUCCESS:
      return { ...setSuccess(state), submitted: true };

    default:
      return state;
  }
};

export default reducer;
