import { SERVICES_NAMES as ServiceNamesConstants } from "../Config/Constants";

/**
 * Replaces %variableName% occurrence with it's value for all keys in data object.
 * @param str
 * @param data {object}
 * @returns {string}
 */
export function replaceKeys(str: string, data: object): string {
  if (!str || !data || typeof data !== "object") return str;
  return Object.keys(data).reduce((acc, key) => {
    return acc.replace("%" + key + "%", data[key]);
  }, str);
}

/**
 * Takes array of string and joins it's items with comma and `and` for last item.
 * @param arr
 * @returns {string}
 */
export function readableJoin(arr: [string]): string {
  if (!arr || !arr.length) return "";
  const items = arr.slice(0, arr.length - 1);
  const last = arr[arr.length - 1];
  if (!items.length) {
    return last;
  }
  return items.join(", ") + " and " + last;
}

/**
 * Returns the name of the service.
 * @param serviceId
 * @returns {string}
 */
export function serviceName(serviceId: string): string {
  return ServiceNamesConstants[serviceId] || "";
}
