import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateProp } from "../Redux/Actions/iJoin/MyDetailsActions";

/**
 * Returns a value and it's setter.
 * The value will be overridden by specified prop whenever it changes.
 *
 * @param prop
 * @param defaultValue
 * @returns {*[]}
 */
export function useStateFromProp(prop: any, defaultValue?: any) {
  const [value, setValue] = useState(
    typeof prop !== "undefined" ? prop : defaultValue
  );

  useEffect(() => {
    setValue(typeof prop !== "undefined" ? prop : defaultValue);
  }, [prop, defaultValue]);

  return [value, setValue];
}

/**
 * Returns a value, it's setter and a save function.
 * The value will be initialized from Redux store and updated whenever it changes in Redux store.
 * Save function returned will update the Redux store with the current value.
 *
 * @param propName Array of keys - value path in the Redux store.
 * @param defaultValue
 * @returns {*[]}
 */
export function useStateFromReduxStore(propName: [string], defaultValue?: any) {
  const prop = useSelector((state) =>
    propName.reduce((acc, key) => {
      return acc && acc[key];
    }, state)
  );

  const [value, setValue] = useStateFromProp(prop, defaultValue);

  const dispatch = useDispatch();
  const save = () => {
    dispatch(updateProp(propName, value));
  };

  return [value, setValue, save];
}

/**
 * Performs async fn call and returns [isLoading, data and hasError] value.
 * @param fn
 * @param args
 * @return {[{boolean, any, boolean}]}
 */
export function useWaitUntil(fn: (void) => Promise, ...args) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const result = useRef(null);

  useEffect(() => {
    fn(...args)
      .then((data) => {
        result.current = data;
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return [isLoading, result.current, hasError];
}
