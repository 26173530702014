import { getAgentToken } from "../../../Redux/Actions/ApiCalls";
import * as actionTypes from "../actionTypes";

export const updateAgentToken = (token) => ({
  type: actionTypes.UPDATE_AGENT_TOKEN,
  payload: token,
});

export const getToken = (grantToken, redirectUrl, userAuth) => (dispatch) =>
  getAgentToken(grantToken, redirectUrl, userAuth).then((response) =>
    dispatch(updateAgentToken(response.data.token))
  );
