// @flow
import React, { useState, useEffect, useCallback, Fragment } from "react";
import { connect } from "react-redux";
import {
  ArrowDown,
  Checkbox,
  ExpandableCard,
  Input,
  DateOfBirthInput,
  Button,
  Tick,
  Loading,
  Dropdown,
} from "react-components";
import { AboutYou as Constants, Titles } from "../../../../Config/Constants";
import { useStateFromProp } from "../../../../Utils/hooks";
import { saveCustomerData } from "../../../../Redux/Actions/CSRAgent/JoinAction";
import {
  updateBPFetching,
  updateBPFetched,
  updateBPError,
} from "../../../../Redux/Actions/CSRAgent/AboutYouAction";
import {
  checkIdentity,
  updateCreateBP,
} from "../../../../Redux/Actions/ApiCalls";
import "../../../../Containers/AboutYou/AboutYou.scss";
import { convertApiErrorToText } from "../../../../Utils/ApiUtils";
import {
  triggerAgentPageView,
  triggerDriverLicense,
  triggerBPCreate,
  useTracking,
} from "../../../../Utils/analytics";
import { useDriverLicense } from "./useDriverLicense";

type Props = {
  title?: string,
  firstName?: string,
  middleName?: string,
  lastName?: string,
  dateOfBirth?: string,
  emailAddress?: string,
  phoneNumber?: string,
  driverLicence?: string,
  cardNumber?: string,
  receiveNewsAndOffers?: boolean,
  isCurrentCardOpen?: boolean,
  isCardDisabled?: boolean,
  validate?: boolean,
  saveCustomerData?: () => void,
  onCardClick?: () => void,
  onClick?: () => void,
  triggerFirstinteraction: () => void,
  setValid: () => {},
};

export const AboutYou = (props: Props) => {
  const [title, setTitle] = useStateFromProp(props.title, "");
  const [titleDropdownHasError, setTitleDropdownHasError] = useState(false);

  const [titleIndex, setTitleIndex] = useState(0);
  const [firstName, setFirstName] = useStateFromProp(props.firstName, "");
  const [firstNameHasError, setFirstNameHasError] = useState(false);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState(
    Constants.firstNameRequiredMsg
  );

  const [middleName, setMiddleName] = useStateFromProp(props.middleName, "");
  const [middleNameHasError, setMiddleNameHasError] = useState(false);

  const [lastName, setLastName] = useStateFromProp(props.lastName, "");
  const [lastNameHasError, setLastNameHasError] = useState(false);
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState(
    Constants.lastNameRequiredMsg
  );

  const [dateOfBirth, setDateOfBirth] = useStateFromProp(props.dateOfBirth, "");
  const [dateOfBirthHasError, setDateOfBirthHasError] = useState(false);
  const [dateOfBirthErrorMessage, setDateOfBirthErrorMessage] = useState("");
  const [dateOfBirthObject, setDateOfBirthObject] = useState({
    day: "",
    month: "",
    year: "",
  });

  const [emailAddress, setEmailAddress] = useStateFromProp(
    props.emailAddress,
    ""
  );
  const [emailAddressHasError, setEmailAddressHasError] = useState(false);

  const [phoneNumber, setPhoneNumber] = useStateFromProp(props.phoneNumber, "");
  const [phoneNumberHasError, setPhoneNumberHasError] = useState(false);

  /* Driver License */
  const {
    hasDriverLicence,
    setHasDriverLicence,
    driverLicence,
    setDriverLicence,
    driverLicenceHasError,
    setDriverLicenceHasError,
    cardNumber,
    setCardNumber,
    cardNumberHasError,
    setCardNumberHasError,
  } = useDriverLicense({
    hasDriverLicence: props.hasDriverLicence,
    driverLicence: {
      value: props.driverLicence,
      error: false,
    },
    cardNumber: {
      value: props.cardNumber,
      error: false,
    },
  });

  const [
    driverLicenceInvalidMessage,
    setDriverLicenceInvalidMessage,
  ] = useState(Constants.driverInvalidMsg);

  const [licenceValid, setLicenceValid] = useState(true);
  const [licenceChanged, setLicenceChanged] = useState(false);
  /* /Driver License */

  const [receiveNewsAndOffers, setReceiveNewsAndOffers] = useStateFromProp(
    props.receiveNewsAndOffers,
    false
  );

  const [cardOpen, setCardOpen] = useState(true);

  const [validateItself, setValidateItself] = useState(false);

  // const [invalidFields, setInvalidFields] = useState([]);

  const [customerChanging, setCustomerChanging] = useState(false);

  // eslint-disable-next-line
  const [bp, setBp] = useStateFromProp(props.businessPartnerNumber, "");

  const trackBPCreate = useTracking(triggerBPCreate, [bp]);

  const [focusedInput, setFocusedInput] = useState("");

  const cardFieldsCompleted =
    firstName &&
    lastName &&
    dateOfBirth &&
    !dateOfBirthHasError &&
    phoneNumber &&
    !phoneNumberHasError &&
    emailAddress &&
    !emailAddressHasError &&
    (!hasDriverLicence ||
      (hasDriverLicence &&
        driverLicence &&
        cardNumber &&
        cardNumber.length === 3));

  const buttonCreateDisabled =
    !firstName ||
    !lastName ||
    !dateOfBirth ||
    dateOfBirthHasError ||
    !phoneNumber ||
    phoneNumberHasError ||
    !emailAddress ||
    emailAddressHasError ||
    (hasDriverLicence &&
      (!driverLicence ||
        driverLicenceHasError ||
        !cardNumber ||
        cardNumberHasError ||
        !licenceValid));

  useEffect(() => {
    trackBPCreate();
    // eslint-disable-next-line
  }, [bp]);

  useEffect(() => {
    props.updateBPFetched(false);
    triggerAgentPageView();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.validate) {
      setValidateItself(true);
      setCardOpen(true);
    }
    // eslint-disable-next-line
  }, [props.validate]);

  useEffect(() => {
    if (cardFieldsCompleted) {
      props.setValid("AboutYou", true);
    } else {
      props.setValid("AboutYou", false);
    }
    // eslint-disable-next-line
  }, [cardFieldsCompleted]);

  useEffect(() => {
    if (dateOfBirth) {
      const trimDate = dateOfBirth.replace(/\//g, "");
      const date = {
        day: trimDate.slice(0, 2),
        month: trimDate.slice(2, 4),
        year: trimDate.slice(4),
      };
      setDateOfBirthObject(date);
    }
  }, [dateOfBirth, setDateOfBirthObject]);

  useEffect(() => {
    if (!hasDriverLicence) {
      setDriverLicence("");
      setCardNumber("");
    }
  }, [hasDriverLicence, setDriverLicence, setCardNumber]);

  useEffect(() => {
    if (title) {
      saveFields(null, true);
    }
    // eslint-disable-next-line
  }, [title]);

  useEffect(() => {
    if (driverLicence && cardNumber && licenceValid) {
      saveFields(null, "driverLicence");
    }
    // eslint-disable-next-line
  }, [driverLicence, cardNumber, licenceValid]);

  useEffect(() => {
    if (
      driverLicence &&
      cardNumber &&
      cardNumber.length === 3 &&
      dateOfBirth &&
      !dateOfBirthHasError &&
      firstName &&
      lastName &&
      dateOfBirthObject?.year &&
      dateOfBirthObject?.month &&
      dateOfBirthObject?.day
    ) {
      const date = dateOfBirthObject;

      // We mark this as changed here instead of the callback so that an update
      // to the BP call can save the newly entered DL details.
      // The BP call checks this "setLicenceChanged" boolean as a condition to
      // send `null` or the details.
      setLicenceChanged(true);

      checkIdentity({
        firstName,
        middleName,
        lastName,
        dateOfBirth: date.year + "-" + date.month + "-" + date.day,
        driverLicenceNumber: driverLicence,
        driverLicenceVersion: cardNumber,
      }).then((response) => {
        if (response) {
          triggerDriverLicense(response.data);
          // setLicenceChanged(true);
          if (!response.data.verified) {
            const invalidFields =
              response.data.missingFields || response.data.invalidFields;
            if (invalidFields) {
              if (invalidFields.includes("driverLicenceVersion")) {
                setDriverLicenceHasError(true);
                setCardNumberHasError(true);
                setDriverLicenceInvalidMessage(Constants.licenceNotMatchMsg);
                setLicenceValid(false);
              } else {
                setLicenceValid(false);
                invalidFields.forEach((field) => {
                  if (field === "firstName") {
                    setFirstNameHasError(true);
                    setFirstNameErrorMessage(Constants.nameNotMatchMsg);
                  }
                  if (field === "middleName") {
                    setMiddleNameHasError(true);
                  }
                  if (field === "lastName") {
                    setLastNameHasError(true);
                    setLastNameErrorMessage(Constants.nameNotMatchMsg);
                  }
                  if (field === "dateOfBirth") {
                    setDateOfBirthHasError(true);
                  }
                });
              }
            }
          } else {
            setLicenceValid(true);
            setDriverLicenceHasError(false);
            setCardNumberHasError(false);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [
    driverLicence,
    cardNumber,
    dateOfBirth,
    firstName,
    lastName,
    dateOfBirthObject,
  ]);

  const setDOB = (dateObject) => {
    if (dateObject) {
      setDateOfBirthObject(dateObject);
      if (
        dateObject.day &&
        dateObject.day.length === 2 &&
        dateObject.month &&
        dateObject.month.length === 2 &&
        dateObject.year &&
        dateObject.year.length === 4
      ) {
        setDateOfBirth(
          dateObject.day + "/" + dateObject.month + "/" + dateObject.year
        );
      } else {
        setDateOfBirth("");
      }
    }
  };

  const validateDOB = () => {
    const { day, month, year } = dateOfBirthObject;
    const isNull = !parseInt(day) && !parseInt(month) && !parseInt(year);
    const date = new Date(year + "/" + month + "/" + day);
    const now = new Date();
    if (dateOfBirth) {
      if (date.getFullYear() > now.getFullYear()) {
        setDateOfBirthHasError(true);
        setDateOfBirthErrorMessage(Constants.invalidDateMsg);
      } else if (
        date.getFullYear() <
        now.getFullYear() - Constants.dateMaxAge
      ) {
        setDateOfBirthHasError(true);
        setDateOfBirthErrorMessage(Constants.dateMaxAgeErrorMsg);
      } else if (
        date.getFullYear() >
        now.getFullYear() - Constants.dateMinAge
      ) {
        setDateOfBirthHasError(true);
        setDateOfBirthErrorMessage(Constants.dateMinAgeErrorMsg);
      }
    } else if (isNull) {
      setDateOfBirthHasError(true);
      setDateOfBirthErrorMessage(Constants.dateRequiredMsg);
    } else {
      setDateOfBirthHasError(true);
      setDateOfBirthErrorMessage(Constants.invalidDateMsg);
    }
  };

  const saveFields = (input, checkbox, value) => {
    const noDriverLicence = !driverLicence && !cardNumber;
    if (noDriverLicence) {
      setDriverLicence("");
      setCardNumber("");
    }
    const noErrors =
      !firstNameHasError &&
      !lastNameHasError &&
      !phoneNumberHasError &&
      !emailAddressHasError &&
      !driverLicenceHasError &&
      !cardNumberHasError &&
      !props.isBPError;
    const addressDetails = props.addressDetails;
    if (checkbox === "hasDriverLicence" && !value) {
      setValidEssentialFields();
    }
    const license =
      (checkbox === "hasDriverLicence" && !value) || !hasDriverLicence
        ? {}
        : {
            Number: driverLicence,
            Version: cardNumber,
          };
    if (
      (input === focusedInput || checkbox || input === "dateOfBirth") &&
      noErrors
    ) {
      props.saveData({
        title: title ? title : Constants.unknownTitle,
        firstName,
        middleName,
        lastName,
        dateOfBirth,
        emailAddress,
        phoneNumber,
        addressDetails,
        receiveNewsAndOffers:
          checkbox === "receiveNewsAndOffers" ? value : receiveNewsAndOffers,
        hasDriverLicence:
          checkbox === "hasDriverLicence" ? value : hasDriverLicence,
        driverLicenceHasError,
        cardNumberHasError,
        driversLicense: license,
      });
    }
    if (input === "dateOfBirth") {
      setChangesStartAction(true);
    }
  };

  const createUpdateCustomer = () => {
    const a = props.addressDetails;
    const dob = dateOfBirth.replace(/\//g, "");
    const formattedDob =
      dob.substr(4) + "/" + dob.substr(2, 2) + "/" + dob.substr(0, 2);
    const getLicence = () => {
      if (licenceChanged) {
        if (driverLicence) {
          setLicenceChanged(false);
          return {
            number: driverLicence,
            version: cardNumber,
            validTo: "9999/12/31",
          };
        } else {
          return {};
        }
      }
      return null;
    };
    if (a && props.token) {
      props.updateBPFetching(true);
      props.updateBPFetched(false);
      props.createBP({
        title: title ? title : Constants.unknownTitle,
        firstName,
        lastName,
        middleName,
        phoneNumber: {
          type: "mobile",
          number: phoneNumber,
        },
        address: {
          pxid: a.pxid, // NOTE: only available for Address Finder matches, not manual entries
          number: a.number,
          street: a.street,
          postCode: a.postcode,
          city: a.city,
          country: "NZ",
        },
        email: emailAddress,
        isAllowedToSendMarketingEmails: receiveNewsAndOffers,
        driversLicence: getLicence(),
        dateOfBirth: formattedDob,
        sex: "X",
        businessPartner: bp,
      });
      setCustomerChanging(false);
      triggerDriverLicense(null);
    } else {
      props.updateBPError("dataError");
    }
  };

  const getButtonState = () => {
    if (bp && !customerChanging) {
      return <Tick />;
    }
    if (!bp) {
      return Constants.buttonCreateAgent;
    }
    if (customerChanging) {
      return Constants.buttonUpdateAgent;
    }
  };

  const renderErrorMessage = () => {
    return (
      <div className="joinDetails_errorContainer">
        <div className="joinDetails_errorContainer_blurb1">
          {convertApiErrorToText(props.BPErrorMessage).blurb1}
        </div>
        <span className="joinDetails_errorContainer_blurb2">
          {props.BPErrorMessage === "dlExistError" ? (
            <div>
              <div>{props.BPErrorMessageDetails}</div>
              <div>{Constants.sapContinue}</div>
            </div>
          ) : (
            convertApiErrorToText(props.BPErrorMessage).blurb2
          )}
        </span>
      </div>
    );
  };

  const setNewTitle = useCallback(
    (value) => {
      if (value !== Constants.selectTitle) {
        setTitle(value);
        setCustomerChanging(true);
        setTitleDropdownHasError(false);
      }
    },
    // eslint-disable-next-line
    [title]
  );

  const setChangesStartAction = () => {
    setCustomerChanging(true);
  };

  const setValidEssentialFields = () => {
    if (firstName) {
      setFirstNameHasError(false);
    }
    if (lastName) {
      setLastNameHasError(false);
    }
    if (dateOfBirth) {
      setDateOfBirthHasError(false);
    }
    if (middleName) {
      setMiddleNameHasError(false);
    }
  };

  const onChangeLicenceFields = () => {
    setChangesStartAction(true);
    setValidEssentialFields();
  };

  const titles = [Constants.selectTitle, ...Titles];

  const getContent = (
    <div className="aboutYou">
      <div className="field aboutYou_half">
        <Dropdown
          items={titles}
          selectedItem={title || Constants.selectTitle}
          selectedItemIndex={titleIndex}
          handleSelectedItem={setNewTitle}
          handleSelectedItemIndex={setTitleIndex}
          labelText={Constants.labelTitle}
          handleError={setTitleDropdownHasError}
          errorMessage={Constants.errorTitle}
          hasError={titleDropdownHasError}
        />
      </div>
      <div className="row">
        <div className="field">
          <Input
            name="firstName"
            handleChange={(value) => setFirstName(value)}
            value={firstName}
            labelText={Constants.firstNameLabel}
            placeholder={Constants.firstNamePlaceholder}
            maxLength={Constants.firstNameMaxLength}
            handleError={setFirstNameHasError}
            errorMessage={firstNameErrorMessage}
            hasError={firstNameHasError}
            validationType="name"
            required
            onBlur={() => saveFields("firstName")}
            onKeyDown={setChangesStartAction}
            inputProps={{ onFocus: () => setFocusedInput("firstName") }}
            validateItself={validateItself}
          />
        </div>
        <div className="field">
          <Input
            name="middleName"
            handleChange={(value) => setMiddleName(value)}
            value={middleName}
            labelText={Constants.middleNameLabel}
            placeholder={Constants.middleNamePlaceholder}
            maxLength={Constants.middleNameMaxLength}
            handleError={setMiddleNameHasError}
            errorMessage={Constants.nameNotMatchMsg}
            hasError={middleNameHasError}
            validationType="name"
            onBlur={() => saveFields("middleName")}
            onKeyDown={setChangesStartAction}
            inputProps={{ onFocus: () => setFocusedInput("middleName") }}
            validateItself={validateItself}
          />
        </div>
        <div className="field">
          <Input
            name="lastName"
            handleChange={(value) => setLastName(value)}
            value={lastName}
            labelText={Constants.lastNameLabel}
            placeholder={Constants.lastNamePlaceholder}
            maxLength={Constants.lastNameMaxLength}
            handleError={setLastNameHasError}
            errorMessage={lastNameErrorMessage}
            hasError={lastNameHasError}
            validationType="name"
            required
            onBlur={() => saveFields("lastName")}
            onKeyDown={setChangesStartAction}
            inputProps={{ onFocus: () => setFocusedInput("lastName") }}
            validateItself={validateItself}
          />
        </div>
      </div>
      <div className="row">
        <div className="field">
          <DateOfBirthInput
            name="dateOfBirth"
            handleChange={(value) => setDOB(value)}
            value={dateOfBirthObject}
            labelText={Constants.dateLabel}
            placeholder={Constants.datePlaceholder}
            handleError={setDateOfBirthHasError}
            hasError={dateOfBirthHasError}
            validationType="dateOfBirth"
            errorMessage={dateOfBirthErrorMessage}
            minErrorMessage={Constants.dateMaxAgeErrorMsg}
            maxErrorMessage={Constants.dateMinAgeErrorMsg}
            required
            maxLength={10}
            inputProps={{
              onBlur: () => {
                saveFields("dateOfBirth");
                validateDOB();
              },
            }}
            validateItself={validateItself}
          />
        </div>
        <div className="field">
          <Input
            name="phone"
            handleChange={(value) => setPhoneNumber(value)}
            value={phoneNumber}
            labelText={Constants.phoneLabel}
            placeholder={Constants.phonePlaceholder}
            maxLength={Constants.phoneMaxLength}
            handleError={setPhoneNumberHasError}
            errorMessage={Constants.phoneRequiredMsg}
            invalidMessage={Constants.phoneInvalidMsg}
            hasError={phoneNumberHasError}
            validationType="phoneNumber"
            required
            onBlur={() => saveFields("phoneNumber")}
            onKeyDown={setChangesStartAction}
            inputProps={{ onFocus: () => setFocusedInput("phoneNumber") }}
            validateItself={validateItself}
          />
        </div>
      </div>
      <div className="field">
        <Input
          name="email"
          handleChange={(value) => setEmailAddress(value)}
          value={emailAddress}
          labelText={Constants.emailLabel}
          placeholder={Constants.emailPlaceholder}
          maxLength={Constants.emailMaxLength}
          handleError={setEmailAddressHasError}
          errorMessage={Constants.emailRequiredMsg}
          invalidMessage={Constants.emailInvalidMsg}
          hasError={emailAddressHasError}
          validationType="email"
          showSuccessIcon
          required
          onBlur={() => saveFields("email")}
          onKeyDown={setChangesStartAction}
          inputProps={{ onFocus: () => setFocusedInput("email") }}
          validateItself={validateItself}
        />
      </div>
      <div className="aboutYou_checkbox">
        <Checkbox
          name="receiveNewsAndOffers"
          className="news-check-checkbox"
          label={Constants.newsCheckboxLabelAgent}
          checked={receiveNewsAndOffers}
          onChange={() => {
            setReceiveNewsAndOffers(!receiveNewsAndOffers);
            saveFields(null, "receiveNewsAndOffers", !receiveNewsAndOffers);
          }}
        />
      </div>
      <div className="joinDetails_subtitle">{Constants.driverLicenceTitle}</div>
      <div className="aboutYou_checkbox">
        <Checkbox
          name="hasDriverLicence"
          className="driverLicence"
          label={Constants.hasDriverLicenceAgent}
          checked={hasDriverLicence}
          onChange={() => {
            setHasDriverLicence(!hasDriverLicence);
            saveFields(null, "hasDriverLicence", !hasDriverLicence);
            setChangesStartAction();
          }}
        />
      </div>
      {hasDriverLicence && (
        <div className="row subItem">
          <div className="aboutYou_card">
            <Input
              name="driverLicence"
              handleChange={(value) => setDriverLicence(value)}
              value={driverLicence}
              labelText={Constants.driverLabel}
              placeholder={Constants.driverPlaceholder}
              maxLength={Constants.driverMaxLength}
              handleError={setDriverLicenceHasError}
              errorMessage={Constants.driverRequiredMsg}
              invalidMessage={driverLicence && driverLicenceInvalidMessage}
              hasError={driverLicenceHasError}
              validationType="driverLicence"
              required={hasDriverLicence}
              onKeyDown={onChangeLicenceFields}
              onBlur={saveFields}
              inputProps={{ onFocus: () => setFocusedInput("driverLicence") }}
              validateItself={validateItself}
            />
          </div>
          <div className="aboutYou_card">
            <Input
              name="cardNumber"
              handleChange={(value) => setCardNumber(value)}
              value={cardNumber}
              labelText={Constants.cardNumberLabel}
              placeholder={Constants.cardNumberPlaceholder}
              maxLength={Constants.cardNumberMaxLength}
              handleError={setCardNumberHasError}
              errorMessage={Constants.cardNumberRequiredMsg}
              invalidMessage={Constants.cardNumberInvalidMsg}
              hasError={cardNumberHasError}
              validationType="cardVersionNumber"
              required={hasDriverLicence}
              onKeyDown={onChangeLicenceFields}
              onBlur={saveFields}
              inputProps={{ onFocus: () => setFocusedInput("cardNumber") }}
              validateItself={validateItself}
            />
          </div>
        </div>
      )}
      <div className="joinDetails_buttonContainer">
        <Button
          name="aboutYouContinueButton"
          className="joinDetails_button-create"
          type="button"
          text={getButtonState()}
          handleClick={createUpdateCustomer}
          handleValidationClick={() => {}}
          primaryOnLight
          disabled={buttonCreateDisabled || props.isBPFetching}
        />
        {props.isBPError && renderErrorMessage()}
        {bp &&
          !props.isBPFetched &&
          !props.isBPFetching &&
          !customerChanging &&
          !props.isBPError && (
            <div className="joinDetails_buttonContainer_text">
              <div className="joinDetails_buttonContainer_text_header">
                {Constants.customerCreated}
              </div>
              <div onClick={setChangesStartAction}>
                <span className="joinDetails_buttonContainer_text_link">
                  {Constants.makeChanges}
                </span>
              </div>
            </div>
          )}
        {bp &&
          props.isBPFetched &&
          !props.isBPFetching &&
          !customerChanging &&
          !props.isBPError && (
            <div className="joinDetails_buttonContainer_text">
              <div className="joinDetails_buttonContainer_text_header">
                {Constants.customerUpdated}
              </div>
              <div onClick={setChangesStartAction}>
                <span className="joinDetails_buttonContainer_text_link">
                  {Constants.makeChanges}
                </span>
              </div>
            </div>
          )}
        {customerChanging && !props.isBPError && (
          <div className="joinDetails_errorContainer">
            <div className="joinDetails_errorContainer_blurb1">
              {Constants.customerChanged}
            </div>
            <div>
              <span className="joinDetails_errorContainer_blurb2">
                {Constants.pressUpdate}
              </span>
            </div>
          </div>
        )}
      </div>
      {props.isBPFetching && !props.isBPError && (
        <Loading
          customstyle={{
            minWidth: "100%",
            minHeight: "150px",
            marginBottom: "-15px",
          }}
        />
      )}
      {!props.isBPFetching && !props.isBPError && bp && (
        <div className="joinDetails_container">
          <div className="joinDetails_subtitle">
            {Constants.customerNumberTitle}
          </div>
          <Input
            name="customerNumber"
            handleChange={() => {}}
            value={bp}
            labelText={Constants.customerNumberLabel}
            placeholder=""
            disabled
          />
          <Button
            name="aboutYouCopyButton"
            className="joinDetails_button-copy"
            type="button"
            text="Copy"
            handleClick={() => {
              navigator.clipboard.writeText(bp);
            }}
            handleValidationClick={() => {}}
            primaryOnLight
            customStyle={{
              position: "absolute",
              right: 0,
              top: "55px",
            }}
          />
        </div>
      )}
    </div>
  );

  const getHeader = {
    cardTitle: Constants.cardTitleAgent,
    cardContent: "",
    cardIcon: <ArrowDown />,
    cardIsSelected: false,
    cardLink: true,
  };

  return (
    <Fragment>
      <div id="AboutYou" className="cardId" />
      <ExpandableCard
        name="aboutYouCard"
        headerContent={getHeader}
        content={getContent}
        isOpen={cardOpen}
        disabled={props.isCardDisabled}
        handleClick={() => setCardOpen(!cardOpen)}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    title:
      state.Customer.CustomerInfo?.Title !== Constants.unknownTitle
        ? state.Customer.CustomerInfo?.Title
        : "",
    firstName: state.Customer.CustomerInfo?.FirstName,
    middleName: state.Customer.CustomerInfo?.MiddleName,
    lastName: state.Customer.CustomerInfo?.LastName,
    serviceAddress: state.Customer.CustomerInfo?.ServiceAddress,
    emailAddress: state.Customer.CustomerInfo?.EmailAddress,
    phoneNumber: state.Customer.CustomerInfo?.PhoneNumber,
    dateOfBirth: state.Customer.CustomerInfo?.DateOfBirth,
    addressDetails: state.Property.PropertyInfo?.AddressDetails,
    receiveNewsAndOffers: state.Confirmation?.ReceiveNewsAndOffers,
    businessPartnerNumber: state.Customer.CustomerInfo.BusinessPartnerNumber,
    token: state.Login.token,
    hasDriverLicence: state.Customer.HasDriversLicense,
    driverLicence: state.Customer.DriversLicense.Number,
    cardNumber: state.Customer.DriversLicense.Version,
    isBPFetched: state.AboutYou?.isFetched,
    isBPFetching: state.AboutYou?.isFetching,
    isBPError: state.AboutYou?.isError,
    BPErrorMessage: state.AboutYou?.errorMessage,
    BPErrorMessageDetails: state.AboutYou?.errorDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createBP: (data) => {
      dispatch(updateCreateBP(data));
    },
    updateBPError: (message) => {
      dispatch(updateBPError(message));
    },
    saveData: (values) => {
      const details = {
        Customer: {
          CustomerInfo: {
            Title: values.title,
            FirstName: values.firstName,
            MiddleName: values.middleName,
            LastName: values.lastName,
            DateOfBirth: values.dateOfBirth,
            PhoneNumber: values.phoneNumber,
            EmailAddress: values.emailAddress,
            Moniker: values.addressDetails?.id,
          },
          HasDriversLicense: values.hasDriverLicence,
          DriversLicense: values.driversLicense,
        },
        Confirmation: {
          ReceiveNewsAndOffers: values.receiveNewsAndOffers,
        },
      };
      dispatch(saveCustomerData(details));
    },
    updateBPFetching: (isFetching) => dispatch(updateBPFetching(isFetching)),
    updateBPFetched: (isFetching) => dispatch(updateBPFetched(isFetching)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutYou);
