import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  LayoutRow,
  LayoutColumn,
  Card,
  StepList,
  HTML,
  DateUtil,
  Button,
  Modal,
} from "react-components";

import "./Success.scss";

import { Success as Constants } from "../../../Config/Constants";
import { AgentJoin as AgentConstants } from "../../../Config/Constants";
import { replaceKeys, readableJoin, serviceName } from "../../../Utils";
import {
  triggerAgentPageView,
  triggerAgentPurchase,
} from "../../../Utils/analytics";
import {
  getHasSession,
  getHasError,
  getHasElectricity,
  getHasPipedGas,
  getHasBottledGas,
  getHasBroadband,
  getServicesStartDate,
  getIsSwitchingSupplier,
  getHasSmartMeter,
  getIsPaymentPrepay,
  getHasOrderBottledGas,
  getServices,
  getServiceAddress,
} from "../../../Utils/selectors";

import { redirect } from "../../../Redux/Actions/Redirect";
import { deleteStateApiSessionId } from "../../../Redux/Actions/ApiCalls";
import Sidebar from "../Sidebar";

type Props = {
  hasSession: boolean,
  hasError: boolean,
  firstName: string,
  services: Array<string>,
  hasElectricity: boolean,
  hasPipedGas: boolean,
  hasBottledGas: boolean,
  hasBroadband: boolean,
  startDate: string,
  isSwitching: boolean,
  hasSmartMeter: boolean,
  isPaymentPrepay: boolean,
  hasOrderBottledGas: boolean,
  redirectFromExpress: boolean,
  deleteStateApiSessionId?: () => void,
  redirectOnError?: () => void,
  address: string,
};

export const Success = (props: Props) => {
  document.title = Constants.pageTitle;

  const [isClearSessionModalActive, setClearSessionModalActive] = useState(
    false
  );

  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
    triggerAgentPageView("/join-success");
  }, []);

  useState(() => {
    if (props.plan) {
      triggerAgentPurchase(props.plan, props.promoCode, props.icp);
    }
  }, [props.plan, props.promoCode, props.icp]);

  // Redirect on error
  useEffect(() => {
    if (props.hasError || props.isNotLastPage) {
      // props.redirectOnError();
    }
    // eslint-disable-next-line
  }, [props.hasError, props.isNotLastPage]);

  const clearSession = () => {
    triggerAgentPageView("/delete-session-modal");
    localStorage.removeItem("session");
    props.clearRedirect();
  };

  const data = {
    firstName: props.firstName,
    services: readableJoin(
      props.services.map(serviceName).map((service) => `<b>${service}</b>`)
    ),
    startDate: DateUtil.convertDateInstanceToFormat(
      DateUtil.getJavascriptDateFormat(
        props.startDate,
        Constants.apiDateFormat
      ),
      Constants.displayDateFormat
    ),
    address: props.address,
    isSwitching: props.isSwitching,
    hasSmartMeter: props.hasSmartMeter,
    isPaymentPrepay: props.isPaymentPrepay,
    hasOrderBottledGas: props.hasOrderBottledGas,
    hasElectricity: props.hasElectricity,
    hasPipedGas: props.hasPipedGas,
    hasBottledGas: props.hasBottledGas,
    hasBroadband: props.hasBroadband,
  };

  const steps = Constants.steps
    // filter by required
    .filter(
      ({ required }) =>
        !required || required.every((variable) => Boolean(data[variable]))
    )
    // filter by restricted
    .filter(
      ({ restricted }) =>
        !restricted || !restricted.find((variable) => Boolean(data[variable]))
    );

  return (
    <>
      <Modal
        showClose
        clearSessionStyle
        isActive={isClearSessionModalActive}
        buttonTextPrimary={
          AgentConstants.clearSessionModal.primaryButtonContent
        }
        handlePrimaryClick={() => {
          setClearSessionModalActive(!isClearSessionModalActive);
        }}
        buttonTextSecondary={
          AgentConstants.clearSessionModal.secondaryButtonContent
        }
        handleSecondaryClick={() => {
          clearSession();
        }}
        content={<HTML html={AgentConstants.clearSessionModal.html} />}
        contentStyle={{
          marginLeft: "20px",
          marginRight: "20px",
          marginTop: "60px",
          marginBottom: "60px",
          textAlign: "center",
        }}
      />
      <LayoutRow
        reverseRow
        left={
          <div>
            <Card name="successCard" className="successCard">
              <div className="success">
                <h1 name="successGreeting" className="success_greeting">
                  {replaceKeys(Constants.greeting, data)}
                  <small>
                    {replaceKeys(
                      props.redirectFromExpress
                        ? Constants.greetingExpressSubtitle
                        : Constants.greetingSubtitle,
                      data
                    )}
                  </small>
                </h1>
                <h2 className="success_nextSteps_title">
                  {Constants.nextStepsTitle}
                </h2>
                <StepList className="success__stepList">
                  {steps.map(({ title, icon, html, successHtml }, key) => (
                    <StepList.Item
                      key={key}
                      title={title}
                      icon={icon}
                      name={key}
                    >
                      <HTML html={replaceKeys(html, data)} />
                      {!props.redirectFromExpress && (
                        <HTML html={replaceKeys(successHtml, data)} />
                      )}
                    </StepList.Item>
                  ))}
                </StepList>
                <div className="success_belowNextStepsNote">
                  <HTML html={Constants.belowNextStepsNote} />
                </div>
              </div>
            </Card>
            <div className="success_buttonContainer">
              <Button
                name="startNewSessionButton"
                type="button"
                className="continue-button"
                text={AgentConstants.newSessionAgentButton}
                handleClick={() => setClearSessionModalActive(true)}
                primaryOnLight
                handleValidationClick={() => {}}
              />
            </div>
          </div>
        }
        right={
          <LayoutColumn
            hideBottom
            top={<Sidebar constants={AgentConstants} disableNotes />}
          />
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  hasSession: getHasSession(state),
  hasError: getHasError(state),
  firstName: state.Customer.CustomerInfo.FirstName,
  services: getServices(state),
  hasElectricity: getHasElectricity(state),
  hasPipedGas: getHasPipedGas(state),
  hasBottledGas: getHasBottledGas(state),
  hasBroadband: getHasBroadband(state),
  startDate: getServicesStartDate(state),
  address: getServiceAddress(state),
  isSwitching: getIsSwitchingSupplier(state),
  hasSmartMeter: getHasSmartMeter(state),
  isPaymentPrepay: getIsPaymentPrepay(state),
  hasOrderBottledGas: getHasOrderBottledGas(state),
  redirectFromExpress: state.Auth.redirectFromExpress,
  isNotLastPage: !(
    state.Confirmation.AcceptPlanTermsAndConditions ||
    state.Confirmation.AcceptPipedGasTermsAndConditions ||
    state.Confirmation.AcceptBottledGasTermsAndConditions
  ),
  plan: state.PlanInfo.SelectedPlan,
  promoCode: state.Payment?.PromoCode || "",
  icp: state.Property?.PropertyInfo?.ElectricityICP,
});

const mapDispatchToProps = (dispatch) => ({
  deleteStateApiSessionId: () => dispatch(deleteStateApiSessionId()),
  clearRedirect: () => {
    if (AgentConstants.clearSessionRedirectUrl) {
      dispatch(redirect(AgentConstants.clearSessionRedirectUrl));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Success);
