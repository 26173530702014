import React, { useState, useEffect, Fragment, useMemo } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { createBusinessAccountNumber } from "../../../Redux/Actions/ApiCalls";
import {
  HTML,
  LayoutRow,
  LayoutColumn,
  Modal,
  Tabs,
  Button,
  Loading,
} from "react-components";
import { Redirect } from "react-router-dom";
import { redirect } from "../../../Redux/Actions/Redirect";
import { triggerAgentPageView } from "../../../Utils/analytics";
import {
  getState,
  saveAuthData,
  updateCreditCheck,
  saveCustomerData,
  saveForLaterRequest,
  saveCustomerInfoForSaveForLater,
} from "../../../Redux/Actions/CSRAgent/JoinAction";
import { AgentJoin as Constants } from "../../../Config/Constants";
import { updateSubmittingJourney } from "../../../Redux/Actions/CSRAgent/JoinDetailsAction";
import { updateAgentToken } from "../../../Redux/Actions/CSRAgent/LoginActions";
import Sidebar from "../Sidebar";
import SaveForLater from "../../../Components/SaveForLater/SaveForLater";
import GoBack from "../../../Components/GoBack";
import AboutYou from "./CustomerDetails/AboutYou";
import MedicalCreditCheck from "./CustomerDetails/MedicalCreditCheck";
import AddPerson from "./CustomerDetails/AddPerson";
import Property from "./CustomerDetails/Property";
import BottledGas from "./CustomerDetails/BottledGas";
import BroadbandOrder from "./CustomerDetails/BroadbandOrder";
import Payment from "./CustomerDetails/Payment";
import FinalStep from "./CustomerDetails/FinalStep";
import "./JoinDetails.scss";
import { useStateFromProp } from "../../../Utils/hooks";

const modalRoot = document.getElementById("modalRoot");
type Props = {};

const ABOUT_YOU = "AboutYou";
const MEDICAL_CHECK = "MedicalCreditCheck";
const ADD_PERSON = "AddPerson";
const PROPERTY = "Property";
const BOTTLED_GAS = "BottledGas";
const BROADBAND_ORDER = "BroadbandOrder";
const PAYMENT = "Payment";
const FINAL_STEP = "FinalStep";

const sections = [
  { id: ABOUT_YOU, Component: AboutYou },
  { id: MEDICAL_CHECK, Component: MedicalCreditCheck },
  { id: ADD_PERSON, Component: AddPerson },
  { id: PROPERTY, Component: Property },
  { id: BOTTLED_GAS, Component: BottledGas },
  { id: BROADBAND_ORDER, Component: BroadbandOrder },
  { id: PAYMENT, Component: Payment },
  { id: FINAL_STEP, Component: FinalStep },
];

export const JoinDetails = (props: Props) => {
  const { isCustomerRehydrated } = props;

  const [goBackAndSave, setGoBackAndSave] = useState(false);
  const [cardsClosed, setCardsClosed] = useState([]);
  const [token, setToken] = useState("");
  const [finalStepValid, setFinalStepValid] = useState(false);
  const [paymentValid, setPaymentValid] = useState(false);
  const [medicalCreditValid, setMedicalCreditValid] = useState(false);
  const [addPersonValid, setAddPersonValid] = useState(false);
  const [propertyValid, setPropertyValid] = useState(false);
  const [aboutYouValid, setAboutYouValid] = useState(false);
  const [lpgValid, setLpgValid] = useState(false);
  const [bbValid, setBbValid] = useState(false);
  const [baCreated, setBaCreated] = useState(false);

  const [needValidation, setNeedValidation] = useState("");
  const [isClearSessionModalActive, setClearSessionModalActive] = useState(
    false
  );
  const [sendCustomerSummary, setSendCustomerSummary] = useState(false);
  const [validateItself, setValidateItself] = useState(false);
  const [emailAddress, setEmailAddress] = useStateFromProp(
    props.emailAddress,
    ""
  );
  const [receiveNewsAndOffers, setReceiveNewsAndOffers] = useStateFromProp(
    props.receiveNewsAndOffers,
    false
  );

  const isInList = (name, list) => {
    if (list.length && name) {
      return list?.includes(name);
    }
  };

  const hasBroadband = isInList("BB", props.services);
  const hasBottledGas = isInList("LPG", props.services);
  const isBottledGasOnly =
    isInList("LPG", props.services) && props.services.length === 1;

  let continueDisabled = true;

  if (isBottledGasOnly) {
    continueDisabled =
      !aboutYouValid ||
      !medicalCreditValid ||
      !propertyValid ||
      (hasBottledGas && !lpgValid) ||
      (hasBroadband && !bbValid) ||
      !finalStepValid ||
      props.submittingJourney ||
      baCreated;
  } else {
    continueDisabled =
      !aboutYouValid ||
      !medicalCreditValid ||
      !propertyValid ||
      (hasBottledGas && !lpgValid) ||
      (hasBroadband && !bbValid) ||
      !paymentValid ||
      !finalStepValid ||
      props.submittingJourney ||
      baCreated;
  }

  useEffect(() => {
    setGoBackAndSave(false);
    const session = localStorage.getItem("session");
    const sessionId = JSON.parse(session)?.sessionID;
    const deleteAfter = JSON.parse(session)?.deleteAfter;
    const auth = {
      sessionID: sessionId,
      deleteAfter: deleteAfter,
    };
    props.saveSessionID(auth);
    const tokenObject = localStorage.getItem("grantToken");
    const token = JSON.parse(tokenObject)?.token;
    const deleteInPast =
      JSON.parse(tokenObject)?.deleteAfter < new Date().getTime();
    if (!deleteInPast && token) {
      setToken(token);
      props.updateToken(token);
    } else if (deleteInPast || !token) {
      localStorage.removeItem("grantToken");
      setCardsClosed();
      setGoBackAndSave(true);
    }
    props.updateCreditCheck();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.sessionId) {
      props.getSessionState();
    }
    // eslint-disable-next-line
  }, [props.sessionId]);

  useEffect(() => {
    setBaCreated(false);
    // eslint-disable-next-line
  }, [props.hasError]);

  useEffect(() => {
    if (props.ba && props.accountNumber) {
      props.updateSubmittingJourney(true);
      props.saveCustomerData();
    }
    // eslint-disable-next-line
  }, [props.ba, props.accountNumber]);

  useEffect(() => {
    setSendCustomerSummary(false);
  }, [props.isSaveForLaterSuccess, setSendCustomerSummary]);

  const cards = useMemo(() => {
    return [
      isCustomerRehydrated && ABOUT_YOU,
      MEDICAL_CHECK,
      ADD_PERSON,
      PROPERTY,
      hasBottledGas && BOTTLED_GAS,
      hasBroadband && BROADBAND_ORDER,
      !isBottledGasOnly && PAYMENT,
      FINAL_STEP,
    ].filter(Boolean);
  }, [hasBottledGas, hasBroadband, isBottledGasOnly, isCustomerRehydrated]);

  const clearSession = () => {
    triggerAgentPageView("/delete-session-modal");
    localStorage.removeItem("session");
    props.clearRedirect();
  };

  const scrollToElement = (name) => {
    setTimeout(() => {
      const element = document.getElementById(name);
      setNeedValidation(name);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 600);
  };

  const toggleCard = (id) => {
    if (cardsClosed?.includes(id)) {
      const newCards = cardsClosed.filter((item) => item !== id);
      setCardsClosed(newCards);
    } else {
      const newCards = [...cardsClosed, id];
      setCardsClosed(newCards);
    }
  };

  const joinContactHandler = () => {
    if (!continueDisabled) {
      setBaCreated(true);
      if (props.bp && !baCreated) {
        props.createBA({
          businessPartner: props.bp,
          BankID: props.BankId ? props.BankId : "",
          incomingPaymentMethod: props.paymentMethod ? "D" : "",
          invoiceDeliveryMethod: props.invoiceDeliveryMethod
            ? Constants.mailPaperless
            : Constants.mailPaper,
          correspondenceDeliveryMethod: props.invoiceDeliveryMethod
            ? Constants.mailPaperless
            : Constants.mailPaper,
        });
      }
    } else {
      if (!aboutYouValid) {
        scrollToElement(ABOUT_YOU);
      } else if (!medicalCreditValid) {
        scrollToElement(MEDICAL_CHECK);
      } else if (!addPersonValid) {
        scrollToElement(ADD_PERSON);
      } else if (!propertyValid) {
        scrollToElement(PROPERTY);
      } else if (!lpgValid && hasBottledGas) {
        scrollToElement(BOTTLED_GAS);
      } else if (!bbValid && hasBroadband) {
        scrollToElement(BROADBAND_ORDER);
      } else if (!paymentValid) {
        scrollToElement(PAYMENT);
      } else if (!finalStepValid) {
        scrollToElement(FINAL_STEP);
      }
    }
  };

  const renderLeftSide = () => (
    <div className="joinDetails">
      <div className="joinDetails_title">
        <div className="join_title_bold">{Constants.title}</div>
        <div
          className="join_title_clear"
          onClick={() => setClearSessionModalActive(true)}
        >
          {Constants.clearSession}
        </div>
      </div>
      <div>
        <GoBack
          backToJourneyUrl={Constants.backToJourneyUrl}
          onClick={saveDataAndGoBack}
        />
        <Tabs tabList={staticTabList} withOverflow />
        <div className="joinDetails_joinButtonContainer">
          <Button
            name="continueButton"
            type="button"
            className="joinDetails_joinButtonContainer_button"
            text={Constants.continueButtonLabel}
            handleClick={joinContactHandler}
            primaryOnLight
            handleValidationClick={() => {}}
            customStyle={{
              background:
                continueDisabled || props.submittingJourney
                  ? "#ccc"
                  : "#dd353f",
              color:
                continueDisabled || props.submittingJourney ? "grey" : "#fff",
            }}
          />
          <div
            className="joinDetails_joinButtonContainer_send"
            onClick={() => setSendCustomerSummary(true)}
          >
            {Constants.sendSummary}
          </div>
        </div>
      </div>
    </div>
  );

  const saveDataAndGoBack = () => {
    setGoBackAndSave(true);
  };

  const handleSendCustomerSummary = async () => {
    props.saveForLaterRequest();
    props.saveDataForSaveForLater();
  };

  const setCardValid = (name, isValid) => {
    if (name === FINAL_STEP) {
      setFinalStepValid(isValid);
    } else if (name === PAYMENT) {
      setPaymentValid(isValid);
    } else if (name === MEDICAL_CHECK) {
      setMedicalCreditValid(isValid);
    } else if (name === ADD_PERSON) {
      setAddPersonValid(isValid);
    } else if (name === PROPERTY) {
      setPropertyValid(isValid);
    } else if (name === ABOUT_YOU) {
      setAboutYouValid(isValid);
    } else if (name === BOTTLED_GAS) {
      setLpgValid(isValid);
    } else if (name === BROADBAND_ORDER) {
      setBbValid(isValid);
    }
  };

  const getCards = sections
    .filter(({ id }) => cards?.includes(id))
    .map(({ id, Component }) => (
      <Component
        key={id}
        onClick={() => (id === FINAL_STEP ? null : toggleCard(id))}
        isCurrentCardOpen={!cardsClosed?.includes(id)}
        onCardClick={() => (id === FINAL_STEP ? null : toggleCard(id))}
        setValid={(name, isValid) => setCardValid(name, isValid)}
        token={token}
        validate={id === needValidation}
      />
    ));

  const staticTabList = [{ header: Constants.header, content: getCards }];

  return (
    <Fragment>
      {goBackAndSave && <Redirect to="/residential/agent/find-a-plan" />}
      {props.submitted && <Redirect to="/residential/agent/success" />}
      <Modal
        showClose
        clearSessionStyle
        isActive={isClearSessionModalActive}
        buttonTextPrimary={Constants.clearSessionModal.primaryButtonContent}
        handlePrimaryClick={() => {
          setClearSessionModalActive(!isClearSessionModalActive);
        }}
        buttonTextSecondary={Constants.clearSessionModal.secondaryButtonContent}
        handleSecondaryClick={() => {
          clearSession();
        }}
        content={<HTML html={Constants.clearSessionModal.html} />}
        contentStyle={{
          marginLeft: "20px",
          marginRight: "20px",
          marginTop: "60px",
          marginBottom: "60px",
          textAlign: "center",
        }}
      />
      <SaveForLater
        sendCustomerSummary={sendCustomerSummary}
        setEmailAddress={setEmailAddress}
        emailAddress={emailAddress}
        setReceiveNewsAndOffers={setReceiveNewsAndOffers}
        receiveNewsAndOffers={receiveNewsAndOffers}
        handlePrimaryClick={() => setSendCustomerSummary(false)}
        handleSecondaryClick={() => handleSendCustomerSummary()}
        handleValidationClick={() => setValidateItself(true)}
        validateItself={validateItself}
        sendCustomerSummaryError={props.saveForLaterError}
        isSaveForLaterSubmitting={props.isSaveForLaterSubmitting}
      />
      {props.submittingJourney &&
        ReactDOM.createPortal(
          <div className="joinDetails_submitLoadingContainer">
            <Loading />
          </div>,
          modalRoot
        )}
      <LayoutRow
        reverseRow
        left={renderLeftSide()}
        right={
          <LayoutColumn
            fullHeight
            hideColumn
            top={<Sidebar constants={Constants} isJoinDetails />}
          />
        }
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isCustomerRehydrated: state.Customer.isRehydrated,
  sessionId: state.Auth?.sessionID,
  services: state.Services,
  submitted: state.Auth.submitted,
  hasError: state.Auth.hasError,
  emailAddress: state.Customer.CustomerInfo?.EmailAddress,
  receiveNewsAndOffers: state.Confirmation?.ReceiveNewsAndOffers,
  bp: state.Customer?.CustomerInfo.BusinessPartnerNumber,
  ba: state.Customer?.CustomerInfo.AccountNumber,
  bankAccountNumber: state.Payment?.DirectDebitDetails?.BankAccountNumber,
  invoiceDeliveryMethod: state.Payment?.PaperlessDiscount,
  paymentMethod: state.Payment?.JoinDirectDebit,
  BankId: state.Customer?.CustomerInfo?.BankId,
  submittingJourney: state.JoinDetails?.isSubmitting,
  accountNumber: state.Customer.CustomerInfo.AccountNumber,
  isSaveForLaterSubmitting: state.Join?.isSubmitting,
  saveForLaterError: state.Join?.error,
  isSaveForLaterSuccess: state.Join?.success,
});

const mapDispatchToProps = (dispatch) => ({
  clearRedirect: () => {
    if (Constants.clearSessionRedirectUrl) {
      dispatch(redirect(Constants.clearSessionRedirectUrl));
    }
  },
  updateToken: (token) => {
    dispatch(updateAgentToken(token));
  },
  getSessionState: () => {
    dispatch(getState());
  },
  saveSessionID: (auth) => {
    dispatch(saveAuthData(auth));
  },
  updateCreditCheck: () => {
    dispatch(updateCreditCheck());
  },
  createBA: (data) => {
    dispatch(createBusinessAccountNumber(data));
  },
  saveCustomerData: () => {
    dispatch(saveCustomerData({}, true));
  },
  saveDataForSaveForLater: () => {
    dispatch(saveCustomerInfoForSaveForLater({}));
  },
  updateSubmittingJourney: (isSubmitting) =>
    dispatch(updateSubmittingJourney(isSubmitting)),
  saveForLaterRequest: () => {
    dispatch(saveForLaterRequest());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(JoinDetails);
