// @flow
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { compose } from "recompose";

import { withReduxProvider } from "../Utils/enhancers";
import { injectGtm } from "../Utils/gtm";
import { Gtm } from "../Config/Constants";

import csrAgentStore from "../Redux/Stores/csrAgentStore";

// import { fetchMyDetailsData } from "../Redux/Actions/iJoin/MyDetailsActions";

import Join from "./CSRJouney";
import JoinDetails from "./CSRJouney/JoinDetails";
import WithLogin from "./CSRJouney/WithLogin";
import Success from "./CSRJouney/Success";
import withLayout from "../Components/Layout";

// type Props = {
//   fetchMyDetailsData?: () => void,
// };

const AgentApp = (props: Props) => {
  useEffect(() => {
    injectGtm(Gtm.reactAgentAppId);
  }, []);

  useEffect(() => {
    // props.fetchMyDetailsData();
    // eslint-disable-next-line
  }, []);

  return (
    <Switch>
      <Route
        path="/residential/agent/success"
        component={withLayout(Success)}
      />
      <Route
        path="/residential/agent/find-a-plan/:grantToken?"
        component={WithLogin(withLayout(Join))}
      />
      <Route
        path="/residential/agent/customer-details"
        component={withLayout(JoinDetails)}
      />
      <Route
        path="/auth/cognito/callback/:code?"
        component={WithLogin(withLayout(Join))}
      />
      <Route
        path="/"
        component={() => {
          console.log("NOT FOUND");
          window.location.href =
            process.env.REACT_APP_EXPRESS_BASE_URL + "/404-not-found";
          return null;
        }}
      />
    </Switch>
  );
};

const mapDispatchToProps = (dispatch) => ({
  // fetchMyDetailsData: () => dispatch(fetchMyDetailsData()),
});

export default compose(
  withReduxProvider(csrAgentStore),
  connect(null, mapDispatchToProps)
)(AgentApp);
