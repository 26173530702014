import * as actionTypes from "../../Actions/actionTypes";

const initialState = {
  BroadbandId: "",
  BroadbandFullName: "",
  HasBroadbandProvider: false,
  BroadbandProvider: "",
  BroadbandProviderOther: null,
  AccountHolderName: "",
  AccountNumber: "",
  ModemDeliveredToSameAddress: true,
  DeliveryAddress: "",
  Moniker: "",
  AddPhoneLine: false,
  HomePhoneNumber: "",
  PhoneLineOptions: [],
};

const updateBroadband = (state, broadband) => {
  return { ...state, ...broadband };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_BROADBAND:
      return updateBroadband(state, action.broadband);

    default:
      return state;
  }
};

export default reducer;
