import React from "react";
import { Icon, Tick } from "react-components";

import { Login as Constants } from "../../Config/Constants";

import move from "../../Assets/Images/move.jpg";

const Sidebar = () => (
  <div className="login_sidebar">
    <h2 className="login_sidebar_title">{Constants.sidebarTitle}</h2>
    {Constants.sideBarItems.map((item, index) => (
      <div className="login_sidebar_item" key={index}>
        <Icon className="login_sidebar_icon" icon={<Tick />} />
        <span className="login_sidebar_text">{item}</span>
      </div>
    ))}
    <p className="login_sidebar_description">{Constants.sideBarDescription}</p>
    <img className="login_sidebar_image" src={move} alt="move" />
  </div>
);

export default Sidebar;
