import React, { useCallback, useEffect, useReducer, useState } from "react";
import {
  ArrowDown,
  ExpandableCard,
  ServiceCardField,
  ServiceModal,
} from "react-components";
import { useStateFromProp } from "../../../Utils/hooks";
import v from "voca";
import { AgentJoin, FindPlan } from "../../../Config/Constants";
import {
  getBroadbandAddress,
  getBroadbandInfo,
} from "../../../Redux/Actions/ApiCalls";

export type BroadbandOffer = {
  Id: string,
  OriginalPrice: string,
  BundlePrice: string,
  Name: string,
  DownloadSpeed: string,
  UploadSpeed: string,
  Label: string,
};

const BroadbandConstants = AgentJoin.planServiceDetails.serviceButtons.find(
  (button) => button.code === "BB"
);
const BroadbandServiceConstants =
  AgentJoin.planServiceDetails.services.broadband;

function formatAddress({ streetNumber, streetName, suburbOrTown, region }) {
  const parts = [];

  if (streetNumber && streetName) {
    parts.push(`${streetNumber} ${streetName}`);
  } else {
    if (streetNumber) parts.push(streetNumber);
    if (streetName) parts.push(streetName);
  }

  if (suburbOrTown) parts.push(suburbOrTown);
  if (region) parts.push(region);

  return parts.join(", ");
}

/**
 * BroadbandCard
 * Inputs:
 *  - pxid: string
 *  - address: string
 *
 * Operations:
 *  - fetch address via pxid
 *  - fetch address via partial address string => pick address => pxid
 *  - fetch provider data with pxid data object
 * */

const transformResponseToMap = (data) => {
  return data.reduce((acc, address) => {
    return {
      ...acc,
      [address.address_id]: address,
    };
  }, {});
};

const initialState = {
  results: {},
  selection: null,
  query: null,
  providerDetails: null,
};
const searchReducer = (state: typeof initialState, action) => {
  switch (action.type) {
    case "query":
      return {
        ...state,
        query: action.payload,
        results: {},
        selection: null,
      };
    case "results":
      return {
        ...state,
        results: transformResponseToMap(action.payload),
        selection: null,
      };
    case "select":
      return {
        ...state,
        results: {},
        selection: state.results[action.payload] ?? null,
      };
    case "reset":
      return {
        ...state,
        results: {},
        selection: null,
      };
    case "setProviderDetails":
      return {
        ...state,
        providerDetails: action.payload,
      };
    default:
      throw new Error(
        `SearchReducer :: Could not find action handler for ${action.type}.`
      );
  }
};

type BroadbandCardProps = {
  address?: string,
  pxid?: string,
  isChecked: boolean,
  onActivate: () => void,
  onReceiveOffers: (offerings: BroadbandOffer[]) => void,
  onAddressSelect: (tlc: string, address: string) => void,
  selectedPlanId?: string,
  selectedServicesList?: string[],
};
const BroadbandCard = ({
  pxid,
  address,
  isChecked,
  onActivate,
  onAddressSelect,
  onReceiveOffers,
  selectedPlanId,
  selectedServicesList,
  isCardOpen,
  ...props
}: BroadbandCardProps) => {
  // Card
  const [isOpen, setIsOpen] = useStateFromProp(isCardOpen, false);

  // Modal
  const [isModalOpen, setModalOpen] = useState(false);

  // Search results handling
  const [{ query, results, selection, providerDetails }, dispatch] = useReducer(
    searchReducer,
    initialState
  );
  const handleSearchQuery = useCallback(
    (searchQuery) =>
      dispatch({
        type: "query",
        payload: searchQuery,
      }),
    [dispatch]
  );
  const handleSearchResults = useCallback(
    (data: Array) => dispatch({ type: "results", payload: data }),
    [dispatch]
  );
  const handleSelection = useCallback(
    (addressId: string) =>
      dispatch({
        type: "select",
        payload: addressId,
      }),
    [dispatch]
  );

  // Provider Details

  /* Search based on the parent props (pxid or address) */
  useEffect(() => {
    if (pxid) {
      handleSearchQuery({ pxid });
    } else if (address) {
      handleSearchQuery({ address: address });
    }
  }, [pxid, address, handleSearchQuery]);

  /* Search with the address selected from typeahead address component */
  const handleAddressSearch = (addressObject) => {
    setModalOpen(false);
    handleSearchQuery({ address: addressObject });
  };

  const handleManualChange = (address, details: AddressDetails) => {
    if (details) {
      formatAddress(details)
    }
  };

  /* Trigger the API request whenever the query changes */
  useEffect(() => {
    if (isChecked && query) {
      getBroadbandAddress(query).then(({ data }) => handleSearchResults(data));
    }
  }, [query, isChecked, handleSearchResults]);

  /* Sync Selection into parent data */
  useEffect(() => {
    onAddressSelect(providerDetails?.tlcId, providerDetails?.Address);
  }, [providerDetails, onAddressSelect]);

  /* Trigger API request for Provider details whenever the active selection changes */
  useEffect(() => {
    if (isChecked && selection) {
      getBroadbandInfo(
        {
          planId: selectedPlanId,
          selectedServices: selectedServicesList,
        },
        selection
      ).then((response) => {
        dispatch({ type: "setProviderDetails", payload: response });
        onReceiveOffers(response.Offerings);
      });
    } else {
      //set details to null, which sets selectedBBAddress to null
      dispatch({ type: "setProviderDetails", payload: null });
      dispatch({ type: "reset", payload: undefined });
    }
  }, [
    selection,
    selectedPlanId,
    selectedServicesList,
    isChecked,
    onReceiveOffers,
  ]);

  /* Reset provider details whenever a new query is made */
  useEffect(() => {
    if (!selection) {
      dispatch({ type: "setProviderDetails", payload: null });
    }
  }, [selection]);

  const cardContent = (
    <>
      <ServiceCardField
        fieldType="address"
        addressList={Object.entries(results).map(([addressId, address]) => ({
          name: address.full_address,
          value: addressId,
        }))}
        onIcpSelect={handleSelection}
        setShowAddressModal={() => setModalOpen(true)}
        title="Address"
        value={selection?.full_address ?? ""}
      />
      <ServiceCardField
        title="ONT
        Status"
        fieldType="standard"
        value={providerDetails?.ontStatus ?? "-"}
      />
      <ServiceCardField
        title="Fibre on street"
        fieldType="standard"
        value={providerDetails?.fibreAvailabilityStatus ?? "-"}
      />
      <ServiceCardField
        title="Serial number"
        fieldType="standard"
        value={providerDetails?.serialNumber ?? "-"}
      />
      <ServiceCardField
        title="Consent"
        fieldType="standard"
        value={providerDetails?.consent ?? "-"}
      />
      <ServiceCardField
        title="Network"
        fieldType="link"
        value={providerDetails?.network ?? "-"}
        buttonTitle={BroadbandServiceConstants.networkLinkLabel}
        href={BroadbandServiceConstants.networkLinkURL}
        target="_blank"
      />
    </>
  );

  return (
    <>
      <ExpandableCard
        name={BroadbandConstants.name}
        handleClick={() => setIsOpen((isOpen) => !isOpen)} /* Card click */
        onCheckboxChange={(e) => {
          e.stopPropagation();
          onActivate();
        }} /* Checkbox click */
        isOpen={isOpen}
        checked={isChecked}
        headerContent={{
          cardTitle: v.titleCase(BroadbandConstants.name),
          cardIcon: <ArrowDown />,
          mainIcon: BroadbandConstants.icon,
          withCheckbox: true,
          disabled: false,
        }}
        classes={{
          infoContainer: {
            "expandableCard_info-bar": true,
          },
        }}
        content={cardContent}
      />
      {/* Modals */}
      <ServiceModal
        api={{
          baseUrl: process.env.REACT_APP_BASE_URL,
          key: process.env.REACT_APP_X_API_KEY,
        }}
        name="broadband-address-search"
        label={FindPlan.serviceAddress.broadbandAddressInputLabel}
        errorMessage={FindPlan.serviceAddress.addressRequiredMsg}
        modalType="address"
        serviceType="broadband"
        isActive={isModalOpen}
        handlePrimaryClick={() => setModalOpen(false)}
        handleSecondaryClick={handleAddressSearch}
        handleManualChange={handleManualChange}
      />
    </>
  );
};

export { BroadbandCard };
