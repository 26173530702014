// @flow
import React from "react";

import { Header as Constants } from "../../Config/Constants";
import "./SecondaryNavBar.scss";

type Props = {};

const SecondaryNavBar = (props: Props) => {
  const handleSupportMenuClick = () => {
    window.location.href = Constants.support.url;
  };

  return (
    <nav className="secondaryNavBar">
      <div className="secondaryNavBar_left" />
      <div className="secondaryNavBar_right">
        <div
          className="secondaryNavBar_navItem"
          onClick={handleSupportMenuClick}
        >
          <a name="support" href={Constants.support.url}>
            {Constants.support.label}
          </a>
        </div>
      </div>
    </nav>
  );
};

export default SecondaryNavBar;
