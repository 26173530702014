// @flow
import React, { useState, Fragment, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import {
  ArrowDown,
  Checkbox,
  ExpandableCard,
  Input,
  Dropdown,
  DateOfBirthInput,
} from "react-components";
import { useStateFromProp } from "../../../../Utils/hooks";
import {
  triggerAgentPageView,
  triggerPersonAdded,
} from "../../../../Utils/analytics";

import "../JoinDetails.scss";

import { AboutYou as Constants, Titles } from "../../../../Config/Constants";
import { saveCustomerData } from "../../../../Redux/Actions/CSRAgent/JoinAction";
type Props = {
  title?: string,
  firstName?: string,
  middleName?: string,
  lastName?: string,
  dateOfBirth?: string,
  emailAddress?: string,
  phoneNumber?: string,
  isCurrentCardOpen?: boolean,
  isCardDisabled?: boolean,
  validate?: boolean,
  saveCustomerData?: () => void,
  onCardClick?: () => void,
  onClick?: () => void,
  triggerFirstinteraction: () => void,
  setValid: () => {},
};

export const AddPerson = (props: Props) => {
  const [title, setTitle] = useStateFromProp(props.title, "");
  const [titleDropdownHasError, setTitleDropdownHasError] = useState(false);
  const [firstName, setFirstName] = useStateFromProp(props.firstName, "");
  const [firstNameHasError, setFirstNameHasError] = useState(false);

  const [lastName, setLastName] = useStateFromProp(props.lastName, "");
  const [lastNameHasError, setLastNameHasError] = useState(false);

  const [dateOfBirth, setDateOfBirth] = useStateFromProp(props.dateOfBirth, "");
  const [dateOfBirthHasError, setDateOfBirthHasError] = useState(false);
  const [dateOfBirthErrorMessage, setDateOfBirthErrorMessage] = useState("");
  const [dateOfBirthObject, setDateOfBirthObject] = useState({
    day: "",
    month: "",
    year: "",
  });

  const [emailAddress, setEmailAddress] = useStateFromProp(
    props.emailAddress,
    ""
  );

  const [emailAddressHasError, setEmailAddressHasError] = useState(false);

  const [phoneNumber, setPhoneNumber] = useStateFromProp(props.phoneNumber, "");
  const [phoneNumberHasError, setPhoneNumberHasError] = useState(false);

  const [validateItself, setValidateItself] = useState(false);

  const [focusedInput, setFocusedInput] = useState("");

  const [addAccount, setAddAccount] = useState(null);

  const [cardOpen, setCardOpen] = useState(true);

  //authorised person
  const [addAuthPerson, setAddAuthPerson] = useState(null);
  const [authFirstName, setAuthFirstName] = useStateFromProp(
    props.authFirstName,
    ""
  );
  const [authFirstNameHasError, setAuthFirstNameHasError] = useState(false);
  const [authLastName, setAuthLastName] = useStateFromProp(
    props.authLastName,
    ""
  );
  const [authLastNameHasError, setAuthLastNameHasError] = useState(false);

  const [pageViewed, setPageViewed] = useState(false);

  const cardFieldsCompleted =
    (!addAccount ||
      (addAccount &&
        firstName &&
        !firstNameHasError &&
        lastName &&
        !lastNameHasError &&
        dateOfBirth &&
        !dateOfBirthHasError &&
        phoneNumber &&
        !phoneNumberHasError)) &&
    (!addAuthPerson ||
      (addAuthPerson &&
        authFirstName &&
        !authFirstNameHasError &&
        authLastName &&
        !authLastNameHasError));

  useEffect(() => {
    setAddAccount(addAccount || props.addAccount);
    setTitle(title || props.title);
    setAddAuthPerson(addAuthPerson || props.addAuthPerson);
    // eslint-disable-next-line
  }, [props]);

  useEffect(() => {
    if (props.validate) {
      setValidateItself(true);
      setCardOpen(true);
    }
    // eslint-disable-next-line
  }, [props.validate]);

  useEffect(() => {
    if (dateOfBirth) {
      const trimDate = dateOfBirth.replace(/\//g, "");
      const date = {
        day: trimDate.slice(0, 2),
        month: trimDate.slice(2, 4),
        year: trimDate.slice(4),
      };
      setDateOfBirthObject(date);
    }
  }, [dateOfBirth]);

  useEffect(() => {
    if (cardFieldsCompleted) {
      props.setValid("AddPerson", true);
    } else {
      props.setValid("AddPerson", false);
    }
    // eslint-disable-next-line
  }, [cardFieldsCompleted]);

  const validateDOB = () => {
    const { day, month, year } = dateOfBirthObject;
    const isNull = !parseInt(day) && !parseInt(month) && !parseInt(year);
    const date = new Date(year + "/" + month + "/" + day);
    const now = new Date();
    if (dateOfBirth) {
      if (date.getFullYear() > now.getFullYear()) {
        setDateOfBirthHasError(true);
        setDateOfBirthErrorMessage(Constants.invalidDateMsg);
      } else if (
        date.getFullYear() <
        now.getFullYear() - Constants.dateMaxAge
      ) {
        setDateOfBirthHasError(true);
        setDateOfBirthErrorMessage(Constants.dateMaxAgeErrorMsg);
      } else if (
        date.getFullYear() >
        now.getFullYear() - Constants.dateMinAge
      ) {
        setDateOfBirthHasError(true);
        setDateOfBirthErrorMessage(Constants.dateMinAgeErrorMsg);
      }
    } else if (isNull) {
      setDateOfBirthHasError(true);
      setDateOfBirthErrorMessage(Constants.dateRequiredMsg);
    } else {
      setDateOfBirthHasError(true);
      setDateOfBirthErrorMessage(Constants.invalidDateMsg);
    }
  };

  const setDOB = (dateObject) => {
    if (dateObject) {
      setDateOfBirthObject(dateObject);
      if (
        dateObject.day &&
        dateObject.day.length === 2 &&
        dateObject.month &&
        dateObject.month.length === 2 &&
        dateObject.year &&
        dateObject.year.length === 4
      ) {
        setDateOfBirth(
          dateObject.day + "/" + dateObject.month + "/" + dateObject.year
        );
      } else {
        setDateOfBirth("");
      }
    }
  };

  const saveFields = (input, checkbox, value) => {
    const noErrors =
      !firstNameHasError &&
      !lastNameHasError &&
      !phoneNumberHasError &&
      !emailAddressHasError &&
      !dateOfBirthHasError;
    const validInput = input === focusedInput || input === "title";
    const getIsAuth = () => {
      if (input === "addAuthPerson") {
        return value;
      } else {
        return addAuthPerson;
      }
    };
    if ((validInput || checkbox) && noErrors) {
      props.saveData({
        title:
          input === "title" ? value : title ? title : Constants.unknownTitle,
        firstName,
        lastName,
        dateOfBirth,
        emailAddress,
        phoneNumber,
        addAccount: input === "addAccount" ? value : addAccount,
        addAuthPerson: getIsAuth(),
        authFirstName,
        authLastName,
      });
    }
  };

  const titles = [Constants.selectTitle, ...Titles];

  const setNewTitle = useCallback(
    (value) => {
      if (value !== Constants.selectTitle) {
        setTitle(value);
        setTitleDropdownHasError(false);
        saveFields("title", null, value);
      }
    },
    // eslint-disable-next-line
    [title]
  );

  const getPersonDetailsFields = () => (
    <div>
      <div className="field aboutYou_half">
        <Dropdown
          items={titles}
          selectedItem={title || Constants.selectTitle}
          handleSelectedItem={setNewTitle}
          labelText={Constants.labelTitle}
          handleError={setTitleDropdownHasError}
          errorMessage={Constants.errorTitle}
          hasError={titleDropdownHasError}
          validateItself={validateItself}
          required
        />
      </div>
      <div className="row">
        <div className="field">
          <Input
            name="jointAccountHolderFirstName"
            handleChange={(value) => setFirstName(value)}
            value={firstName}
            labelText={Constants.firstNameLabel}
            placeholder={Constants.firstNamePlaceholder}
            maxLength={Constants.firstNameMaxLength}
            handleError={setFirstNameHasError}
            errorMessage={Constants.firstNameRequiredMsg}
            hasError={firstNameHasError}
            validationType="name"
            required
            onBlur={() => saveFields("firstName")}
            inputProps={{
              onFocus: () => setFocusedInput("firstName"),
            }}
            validateItself={validateItself}
          />
        </div>
        <div className="field">
          <Input
            name="jointAccountHolderLastName"
            handleChange={(value) => setLastName(value)}
            value={lastName}
            labelText={Constants.lastNameLabel}
            placeholder={Constants.lastNamePlaceholder}
            maxLength={Constants.lastNameMaxLength}
            handleError={setLastNameHasError}
            errorMessage={Constants.lastNameRequiredMsg}
            hasError={lastNameHasError}
            validationType="name"
            required
            onBlur={() => saveFields("lastName")}
            inputProps={{
              onFocus: () => setFocusedInput("lastName"),
            }}
            validateItself={validateItself}
          />
        </div>
      </div>
      <div className="row">
        <div className="field">
          <DateOfBirthInput
            name="dateOfBirth"
            handleChange={(value) => setDOB(value)}
            value={dateOfBirthObject}
            labelText={Constants.dateLabel}
            placeholder={Constants.datePlaceholder}
            handleError={setDateOfBirthHasError}
            hasError={dateOfBirthHasError}
            validationType="dateOfBirth"
            errorMessage={dateOfBirthErrorMessage}
            minErrorMessage={Constants.dateMaxAgeErrorMsg}
            maxErrorMessage={Constants.dateMinAgeErrorMsg}
            required
            maxLength={10}
            inputProps={{ onBlur: validateDOB }}
            onBlur={() => saveFields("dateOfBirth")}
            validateItself={validateItself}
          />
        </div>
        <div className="field">
          <Input
            name="phone"
            handleChange={(value) => setPhoneNumber(value)}
            value={phoneNumber}
            labelText={Constants.phoneLabel}
            placeholder={Constants.phonePlaceholder}
            maxLength={Constants.phoneMaxLength}
            handleError={setPhoneNumberHasError}
            errorMessage={Constants.phoneRequiredMsg}
            invalidMessage={Constants.phoneInvalidMsg}
            hasError={phoneNumberHasError}
            validationType="phoneNumber"
            required
            onBlur={() => saveFields("phoneNumber")}
            inputProps={{
              onFocus: () => setFocusedInput("phoneNumber"),
            }}
            validateItself={validateItself}
          />
        </div>
      </div>
      <div className="field">
        <Input
          name="email"
          handleChange={setEmailAddress}
          value={emailAddress}
          labelText={Constants.emailLabel}
          placeholder={Constants.emailPlaceholder}
          maxLength={Constants.emailMaxLength}
          handleError={setEmailAddressHasError}
          invalidMessage={Constants.emailInvalidMsg}
          hasError={emailAddressHasError}
          validationType="email"
          onBlur={() => saveFields("email")}
          inputProps={{ onFocus: () => setFocusedInput("email") }}
          showSuccessIcon
        />
      </div>
    </div>
  );

  const getAuthPersonDetailsFields = () => (
    <div>
      <div className="row">
        <div className="field">
          <Input
            name="authFirstName"
            handleChange={(value) => setAuthFirstName(value)}
            value={authFirstName}
            labelText={Constants.firstNameLabel}
            placeholder={Constants.firstNamePlaceholder}
            maxLength={Constants.firstNameMaxLength}
            handleError={setAuthFirstNameHasError}
            errorMessage={Constants.firstNameRequiredMsg}
            hasError={authFirstNameHasError}
            validationType="name"
            required
            onBlur={() => saveFields("authFirstName")}
            inputProps={{
              onFocus: () => setFocusedInput("authFirstName"),
            }}
            validateItself={validateItself}
          />
        </div>
        <div className="field">
          <Input
            name="authLastName"
            handleChange={(value) => setAuthLastName(value)}
            value={authLastName}
            labelText={Constants.lastNameLabel}
            placeholder={Constants.lastNamePlaceholder}
            maxLength={Constants.lastNameMaxLength}
            handleError={setAuthLastNameHasError}
            errorMessage={Constants.lastNameRequiredMsg}
            hasError={authLastNameHasError}
            validationType="name"
            required
            onBlur={() => saveFields("authLastName")}
            inputProps={{
              onFocus: () => setFocusedInput("authLastName"),
            }}
            validateItself={validateItself}
          />
        </div>
      </div>
    </div>
  );

  const setJointAccount = (value) => {
    if (!value) {
      setTitle("");
      setFirstName("");
      setLastName("");
      setDateOfBirth("");
      setEmailAddress("");
      setPhoneNumber("");
    } else {
      triggerPersonAdded(1, 0);
    }
    if (!pageViewed) {
      triggerAgentPageView("/add-someone-to-account");
      setPageViewed(true);
    }
    setAddAccount(value);
    saveFields("addAccount", true, value);
  };

  const setAuthAccount = (value) => {
    if (!value) {
      setAuthFirstName("");
      setAuthLastName("");
    } else {
      triggerPersonAdded(0, 1);
    }
    if (!pageViewed) {
      triggerAgentPageView("/add-someone-to-account");
      setPageViewed(true);
    }
    saveFields("addAuthPerson", true, value);
    setAddAuthPerson(value);
  };

  const getContent = (
    <div className="addPerson">
      <div className="aboutYou_checkbox">
        <Checkbox
          name="addAccount"
          className="checkbox"
          label={Constants.addPersonCheckbox}
          checked={addAccount}
          onChange={() => setJointAccount(!addAccount)}
        />
      </div>
      {addAccount && getPersonDetailsFields()}
      <div className="aboutYou_checkbox">
        <Checkbox
          name="addAuthPerson"
          className="checkbox"
          label={Constants.addAuthPersonCheckbox}
          checked={addAuthPerson}
          onChange={() => setAuthAccount(!addAuthPerson)}
        />
      </div>
      {addAuthPerson && getAuthPersonDetailsFields()}
    </div>
  );

  const getHeader = {
    cardTitle: Constants.cardTitleAddPerson,
    cardContent: "",
    cardIcon: <ArrowDown />,
    cardIsSelected: false,
    cardLink: true,
  };

  return (
    <Fragment>
      <div id="AddPerson" className="cardId" />
      <ExpandableCard
        name="addPersonCard"
        headerContent={getHeader}
        content={getContent}
        isOpen={cardOpen}
        disabled={props.isCardDisabled}
        handleClick={() => setCardOpen(!cardOpen)}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  addAccount: state.JointAccountHolderInfo[0]?.IsJointAccount,
  title:
    state.JointAccountHolderInfo[0]?.Title !== Constants.unknownTitle
      ? state.JointAccountHolderInfo[0]?.Title
      : "",
  firstName: state.JointAccountHolderInfo[0]?.FirstName,
  lastName: state.JointAccountHolderInfo[0]?.LastName,
  emailAddress: state.JointAccountHolderInfo[0]?.EmailAddress,
  phoneNumber: state.JointAccountHolderInfo[0]?.PhoneNumber,
  dateOfBirth: state.JointAccountHolderInfo[0]?.DateOfBirth,
  addAuthPerson: state.AuthorizedPersonInfo?.IsAuthorizedPerson,
  authFirstName: state.AuthorizedPersonInfo?.FirstName,
  authLastName: state.AuthorizedPersonInfo?.LastName,
});

const mapDispatchToProps = (dispatch) => ({
  saveData: (values) => {
    const jointAccount = values.addAccount
      ? {
          IsJointAccount: values.addAccount,
          Title: values.title,
          FirstName: values.firstName,
          LastName: values.lastName,
          DateOfBirth: values.dateOfBirth,
          PhoneNumber: values.phoneNumber,
          EmailAddress: values.emailAddress,
        }
      : {
          IsJointAccount: values.addAccount,
          Title: "",
          FirstName: "",
          LastName: "",
          DateOfBirth: "",
          PhoneNumber: "",
          EmailAddress: "",
        };
    const authAccount = values.addAuthPerson
      ? {
          IsAuthorizedPerson: values.addAuthPerson,
          FirstName: values.authFirstName,
          LastName: values.authLastName,
        }
      : {
          IsAuthorizedPerson: values.addAuthPerson,
          FirstName: "",
          LastName: "",
        };
    const details = {
      JointAccountHolderInfo: jointAccount,
      AuthorizedPersonInfo: authAccount,
    };
    dispatch(saveCustomerData(details));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPerson);
