// @flow
import React, { useState, Fragment, useEffect, useMemo, useRef } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Property as Constants } from "../../../../Config/Constants";
import { useStateFromProp } from "../../../../Utils/hooks";
import isEmpty from "lodash/isEmpty";
import {
  ExpandableCard,
  Input,
  ArrowDown,
  Checkbox,
  Button,
  Loading,
} from "react-components";
import { updateBPFetched } from "../../../../Redux/Actions/CSRAgent/AboutYouAction";
import CreditCheckResult, {
  CreditCheckResultType,
} from "../CreditCheckResult/";
import {
  triggerAgentPageView,
  triggerCCResult,
} from "../../../../Utils/analytics";
import { saveCustomerData } from "../../../../Redux/Actions/CSRAgent/JoinAction";
import {
  updateCreditCheckFetching,
  updateCreditCheckError,
} from "../../../../Redux/Actions/CSRAgent/CreditCheckAction";
import {
  checkCredit,
  getBillinEligibility,
  getIcpDetails,
} from "../../../../Redux/Actions/ApiCalls";
import { convertApiErrorToText } from "../../../../Utils/ApiUtils";

type Props = {
  handleError: () => {},
  setValid: () => {},
  validateItself: boolean,
  creditCheckResult: {
    result: CreditCheckResultType,
    bond: string,
    message: string,
  },
};

export const MedicalCreditCheck = (props: Props) => {
  const { handleError } = props;

  const [validateItself, setValidateItself] = useState(false);

  const supplierRef = useRef(null);

  // Vulnerable Person
  const [hasVulnerablePerson, setHasVulnerablePerson] = useStateFromProp(
    props.hasVulnerablePerson,
    false
  );

  // Credit check consent
  const [hasCreditCheckConsent, setHasCreditCheckConsent] = useState(false);

  // Medical Dependant

  const [hasMedicalDependant, setHasMedicalDependant] = useStateFromProp(
    props.hasMedicalDependant,
    false
  );
  const [
    medicalDependantDifferentAsAccountHolder,
    setMedicalDependantDifferentAsAccountHolder,
  ] = useState(true);
  const [
    medicalDependantFirstName,
    setMedicalDependantFirstName,
  ] = useStateFromProp(props.medicalDependantFirstName, "");
  const [
    medicalDependantFirstNameHasError,
    setMedicalDependantFirstNameHasError,
  ] = useState(false);
  const [
    medicalDependantLastName,
    setMedicalDependantLastName,
  ] = useStateFromProp(props.medicalDependantLastName, "");
  const [
    medicalDependantLastNameHasError,
    setMedicalDependantLastNameHasError,
  ] = useState(false);
  const [medicalDependantPhone, setMedicalDependantPhone] = useStateFromProp(
    props.medicalDependantPhone,
    ""
  );
  const [
    medicalDependantPhoneHasError,
    setMedicalDependantPhoneHasError,
  ] = useState(false);
  const [
    medicalDependantEMailAddress,
    setMedicalDependantEMailAddress,
  ] = useStateFromProp(props.medicalDependantEMailAddress, "");
  const [
    medicalDependantEMailAddressHasError,
    setMedicalDependantEMailAddressHasError,
  ] = useState(false);
  const [
    criticalMedicalEquipment,
    setCriticalMedicalEquipment,
  ] = useStateFromProp(props.criticalMedicalEquipment, "");
  const [
    criticalMedicalEquipmentHasError,
    setCriticalMedicalEquipmentHasError,
  ] = useState(false);
  const [
    contactHealthPractitioner,
    setContactHealthPractitioner,
  ] = useStateFromProp(props.contactHealthPractitioner, true);
  const [practiceName, setPracticeName] = useStateFromProp(
    props.practiceName,
    ""
  );
  const [practiceNameHasError, setPracticeNameHasError] = useState(false);
  const [practitionerName, setPractitionerName] = useStateFromProp(
    props.practitionerName,
    ""
  );
  const [practitionerNameHasError, setPractitionerNameHasError] = useState(
    false
  );
  const [practicePhone, setPracticePhone] = useStateFromProp(
    props.practicePhone,
    ""
  );
  const [practicePhoneHasError, setPracticePhoneHasError] = useState(false);
  const [cardOpen, setCardOpen] = useState(true);
  const [focusedInput, setFocusedInput] = useState("");
  const [creditCheckResult, setCreditCheckResult] = useState({});
  const [updatedCCResult, setUpdatedCCResult] = useState(false);
  const [dollarBond, setDollarBond] = useState(null);
  const [prepayEligible, setPrepayEligible] = useState(false);
  const [firstChange, setFirstChange] = useState(false);
  const [ccAnalyticsTriggered, setCcAnalyticsTriggered] = useState(false);
  const buttonEnabled = useMemo(() => {
    if (
      (!creditCheckResult.result ||
        creditCheckResult.result !== "Green" ||
        props.bPFetched) &&
      props.businessPartnerNumber &&
      !props.creditCheckFetching &&
      !props.bPFetching &&
      hasCreditCheckConsent
    ) {
      return true;
    } else {
      return false;
    }
  }, [
    creditCheckResult,
    props.businessPartnerNumber,
    props.creditCheckFetching,
    props.bPFetching,
    props.bPFetched,
    hasCreditCheckConsent,
  ]);

  const [
    medicalDiffValueManuallyChanged,
    setMedicalDiffValueManuallyChanged,
  ] = useState(false);

  useEffect(() => {
    const invalidMedicalDependantPart =
      hasMedicalDependant &&
      ((!medicalDependantDifferentAsAccountHolder &&
        (!medicalDependantFirstName ||
          medicalDependantFirstNameHasError ||
          !medicalDependantLastName ||
          medicalDependantLastNameHasError ||
          !medicalDependantEMailAddress ||
          medicalDependantEMailAddressHasError ||
          !medicalDependantPhone ||
          medicalDependantPhoneHasError)) ||
        !criticalMedicalEquipment ||
        criticalMedicalEquipmentHasError ||
        (contactHealthPractitioner &&
          (!practiceName ||
            practiceNameHasError ||
            !practitionerName ||
            practitionerNameHasError ||
            !practicePhone ||
            practicePhoneHasError)));
    if (invalidMedicalDependantPart) {
      props.setValid("MedicalCreditCheck", false);
    } else {
      props.setValid("MedicalCreditCheck", true);
    }
    // eslint-disable-next-line
  }, [
    hasMedicalDependant,
    medicalDependantDifferentAsAccountHolder,
    medicalDependantFirstName,
    medicalDependantLastName,
    medicalDependantPhone,
    medicalDependantEMailAddress,
    criticalMedicalEquipment,
    contactHealthPractitioner,
    practiceName,
    handleError,
    practitionerName,
    practicePhone,
    medicalDependantFirstNameHasError,
    medicalDependantLastNameHasError,
    medicalDependantEMailAddressHasError,
    medicalDependantPhoneHasError,
    criticalMedicalEquipmentHasError,
    practiceNameHasError,
    practitionerNameHasError,
    practicePhoneHasError,
  ]);

  useMemo(() => {
    if (!medicalDiffValueManuallyChanged) {
      setMedicalDependantDifferentAsAccountHolder(
        !props.medicalDependantDifferentAsAccountHolder
      );
    }
    // eslint-disable-next-line
  }, [props.medicalDependantDifferentAsAccountHolder]);

  useEffect(() => {
    if (!isEmpty(props.creditCheckResult?.data)) {
      const creditCheckData = {
        result:
          props.creditCheckResult.data.ragStatus ||
          props.creditCheckResult.data.Result,
        paymentAmount:
          props.creditCheckResult.data.Bond ||
          props.creditCheckResult.data.paymentAmount,
        message:
          props.creditCheckResult.data.Message ||
          props.creditCheckResult.data.message,
      };
      setCreditCheckResult(creditCheckData);
      if (!ccAnalyticsTriggered && creditCheckData.result) {
        triggerCCResult(creditCheckData.result);
        setCcAnalyticsTriggered(true);
      }
    }
    // eslint-disable-next-line
  }, [props.creditCheckResult]);

  useEffect(() => {
    if (
      props.icp &&
      props.services.includes("ELEC") &&
      props.services.length === 1
    ) {
      Promise.all([
        getIcpDetails({ icp: props.icp }),
        getIcpDetails({ icp: props.icp, format: "websphere" }),
      ]).then(([icpResponse, websphereIcpResponse]) => {
        const icpDetails = icpResponse.data?.icp;
        supplierRef.current = icpDetails?.trader ?? null;

        const data = websphereIcpResponse.data.icp;
        const elegibilityPayload = {
          Services: props.services,
          ElectricityICP: {
            AnzsicCode: data.AnzsicCode,
            Meters: data.Meters,
            NetworkParticipantCode: data.NetworkParticipantCode,
            PriceCategoryCode: data.PriceCategoryCode,
            Unm: data.Unm,
            IcpNumber: data.IcpNumber,
            Pp: data.Pp,
            Mep: data.Mer,
            GxpNumber: data.GxpNumber,
          },
        };
        getBillinEligibility(elegibilityPayload).then((response) => {
          setPrepayEligible(false);
          const prepay = response.data.filter((item) =>
            item.name.includes(Constants.prepayName)
          );
          const eligibleForWF = response.data.filter((item) =>
            item.name.includes(Constants.paymentCycleWF)
          );
          if (prepay[0].eligible) {
            setPrepayEligible(true);
          }
          props.savePrepayData({
            prepayEligible: prepay[0]?.eligible,
            wfEligible: eligibleForWF[0]?.eligible,
          });
        });
      });
    } else {
      // If the call is not made, i.e. other services selected, ensure the
      // redux data is no longer caching the previous results
      setPrepayEligible(false);

      props.savePrepayData({
        prepayEligible: undefined,
        wfEligible: undefined,
      });
    }
    // eslint-disable-next-line
  }, [props.icp, props.services]);

  useEffect(() => {
    if (props.validate) {
      setValidateItself(true);
      setCardOpen(true);
    }
  }, [props.validate]);

  useEffect(() => {
    if (dollarBond !== null) {
      saveFields("bond", true);
    }
    // eslint-disable-next-line
  }, [dollarBond]);

  useEffect(() => {
    if (prepayEligible && firstChange) {
      saveFields("prepay", true);
    }
    // eslint-disable-next-line
  }, [prepayEligible]);

  useEffect(() => {
    if (creditCheckResult?.result && updatedCCResult) {
      saveFields(null, true);
    }
    // eslint-disable-next-line
  }, [creditCheckResult?.result, props.icp]);

  const saveFields = (input, checkbox, value) => {
    if (input === focusedInput || checkbox) {
      props.saveData({
        hasMedicalDependant:
          checkbox === "hasMedicalDependant" ? value : hasMedicalDependant,
        medicalDependantDifferentAsAccountHolder:
          checkbox === "medicalDependantDifferentAsAccountHolder"
            ? value
            : medicalDependantDifferentAsAccountHolder,
        medicalDependantFirstName,
        medicalDependantLastName,
        medicalDependantPhone,
        medicalDependantEMailAddress,
        criticalMedicalEquipment,
        contactHealthPractitioner:
          checkbox === "contactHealthPractitioner"
            ? value
            : contactHealthPractitioner,
        practiceName,
        practitionerName,
        practicePhone,
        hasVulnerablePerson:
          checkbox === "hasVulnerablePerson" ? value : hasVulnerablePerson,
        creditCheckResult,
        dollarBond,
      });
      if (input !== "bond") {
        props.updateBPFetched(true);
      }
    }
  };

  const setBond = (isChecked) => {
    if (creditCheckResult.result === Constants.amber) {
      if (isChecked) {
        setDollarBond(Constants.amberBond);
      } else {
        setDollarBond(Constants.defaultBond);
      }
    }
  };

  const hasMedicalDependantCheckboxChangedHandler = () => {
    if (hasMedicalDependant) {
      clearMedicalDependant();
      setMedicalDependantDifferentAsAccountHolder(false);
      setMedicalDiffValueManuallyChanged(true);
      clearCriticalMedicalEquipment();
      clearContactHealthPractitioner();
      setContactHealthPractitioner(false);
    }
    saveFields(null, "hasMedicalDependant", !hasMedicalDependant);
    setHasMedicalDependant(!hasMedicalDependant);
  };

  const medicalDependantDifferentAsAccountHolderCheckboxChangedHandler = () => {
    if (medicalDependantDifferentAsAccountHolder) {
      clearMedicalDependant();
    }
    setMedicalDiffValueManuallyChanged(true);
    saveFields(
      null,
      "medicalDependantDifferentAsAccountHolder",
      !medicalDependantDifferentAsAccountHolder
    );
    setMedicalDependantDifferentAsAccountHolder(
      !medicalDependantDifferentAsAccountHolder
    );
  };

  const contactHealthPractitionerCheckboxChangedHandler = () => {
    if (contactHealthPractitioner) {
      clearContactHealthPractitioner();
    }
    saveFields(null, "contactHealthPractitioner", !contactHealthPractitioner);
    setContactHealthPractitioner(!contactHealthPractitioner);
  };

  const vulnerableChecboxHandler = () => {
    saveFields(null, "hasVulnerablePerson", !hasVulnerablePerson);
    setHasVulnerablePerson(!hasVulnerablePerson);
  };

  const clearMedicalDependant = () => {
    setMedicalDependantFirstName("");
    setMedicalDependantFirstNameHasError("");
    setMedicalDependantLastName("");
    setMedicalDependantLastNameHasError("");
    setMedicalDependantEMailAddress("");
    setMedicalDependantEMailAddressHasError("");
    setMedicalDependantPhone("");
    setMedicalDependantPhoneHasError("");
  };

  const clearCriticalMedicalEquipment = () => {
    setCriticalMedicalEquipmentHasError("");
    setCriticalMedicalEquipment("");
  };

  const clearContactHealthPractitioner = () => {
    setPractitionerName("");
    setPractitionerNameHasError("");
    setPracticeName("");
    setPracticeNameHasError("");
    setPracticePhone("");
    setPracticePhoneHasError("");
  };

  const runCreditCheck = () => {
    const a = props.addressDetails;
    let service = "";
    const isSouthIsland = props.address.slice(-4) >= 7000;
    if (props.services.includes("ELEC") && props.services.length >= 1) {
      service = Constants.ccServiceElec;
    } else if (
      (props.services.includes("LPG") || props.services.includes("GAS")) &&
      props.services.length === 1
    ) {
      service = isSouthIsland
        ? Constants.ccServiceGasSI
        : Constants.ccServiceGasNI;
    }
    if (props.token && a) {
      props.creditCheck(
        {
          address: {
            number: a.number,
            street: a.street,
            suburb: a.suburb,
            postCode: a.postcode,
            city: a.city,
            country: "NZ",
          },
          service: service,
          businessPartner: props.businessPartnerNumber,
          isSuppliedByContact: supplierRef.current === "CTCT", // CTCT = Contact, hence if we have this value, we are the supplier
          hasMedicalDependant: hasMedicalDependant,
          contractStartDate: moment(new Date()).format("YYYY/MM/DD"),
        },
        props.token
      );
      setCcAnalyticsTriggered(false);
      props.updateBPFetched(false);
      props.updateCreditCheckFetching(true);
      setUpdatedCCResult(true);
      setFirstChange(true);
      if (!firstChange) {
        triggerAgentPageView("/medical-dependency-credit-check");
      }
    } else {
      props.updateCreditCheckError("dataError");
    }
  };

  const renderErrorMessage = () => (
    <div className="joinDetails_errorContainer">
      <div className="joinDetails_errorContainer_blurb1">
        {convertApiErrorToText(props.creditCheckError).blurb1}
      </div>
      <span className="joinDetails_errorContainer_blurb2">
        {convertApiErrorToText(props.creditCheckError).blurb2}
      </span>
    </div>
  );

  const getHeader = {
    cardTitle: Constants.cardTitleAgent,
    cardContent: "",
    cardIcon: <ArrowDown />,
    cardIsSelected: false,
    cardLink: true,
  };

  const getContent = (
    <div className="customerDetails">
      <div className="field">
        <Checkbox
          name="hasVulnerablePerson"
          label={Constants.hasVulnerablePersonLabel}
          subLabel={Constants.hasVulnerablePersonSubLabel}
          checked={hasVulnerablePerson}
          onChange={vulnerableChecboxHandler}
        />
      </div>
      <div className="field">
        <Checkbox
          name="hasMedicalDependant"
          label={Constants.hasMedicalDependantLabel}
          subLabel={Constants.hasMedicalDependantSubLabel}
          checked={hasMedicalDependant}
          onChange={hasMedicalDependantCheckboxChangedHandler}
        />
      </div>
      {hasMedicalDependant && (
        <Fragment>
          <div className="field subItem">
            <Checkbox
              name="medicalDependantDifferentAsAccountHolder"
              label={Constants.medicalDependantDifferentAsAccountHolderLabel}
              checked={!medicalDependantDifferentAsAccountHolder}
              onChange={
                medicalDependantDifferentAsAccountHolderCheckboxChangedHandler
              }
            />

            {!medicalDependantDifferentAsAccountHolder && (
              <Fragment>
                <div className="row subItem">
                  <div className="field">
                    <Input
                      name="medicalDependantFirstName"
                      handleChange={(value) =>
                        setMedicalDependantFirstName(value)
                      }
                      value={medicalDependantFirstName}
                      labelText={Constants.medicalDependantFirstNameLabel}
                      maxLength={Constants.medicalDependantFirstNameMaxLength}
                      handleError={setMedicalDependantFirstNameHasError}
                      errorMessage={
                        Constants.medicalDependantFirstNameRequiredMsg
                      }
                      hasError={medicalDependantFirstNameHasError}
                      validationType="name"
                      required={hasMedicalDependant}
                      validateItself={validateItself}
                      inputProps={{
                        onFocus: () =>
                          setFocusedInput("medicalDependantFirstName"),
                      }}
                      onBlur={() => saveFields("medicalDependantFirstName")}
                    />
                  </div>
                  <div className="field">
                    <Input
                      name="medicalDependantLastName"
                      handleChange={(value) =>
                        setMedicalDependantLastName(value)
                      }
                      value={medicalDependantLastName}
                      labelText={Constants.medicalDependantLastNameLabel}
                      maxLength={Constants.medicalDependantLastNameMaxLength}
                      handleError={setMedicalDependantLastNameHasError}
                      errorMessage={
                        Constants.medicalDependantLastNameRequiredMsg
                      }
                      hasError={medicalDependantLastNameHasError}
                      validationType="name"
                      required={hasMedicalDependant}
                      validateItself={validateItself}
                      inputProps={{
                        onFocus: () =>
                          setFocusedInput("medicalDependantLastName"),
                      }}
                      onBlur={() => saveFields("medicalDependantLastName")}
                    />
                  </div>
                </div>
                <div className="row subItem">
                  <div className="field">
                    <Input
                      name="medicalDependantPhone"
                      handleChange={(value) => setMedicalDependantPhone(value)}
                      value={medicalDependantPhone}
                      labelText={Constants.medicalDependantPhoneLabel}
                      placeholder={Constants.medicalDependantPhonePlaceholder}
                      maxLength={Constants.medicalDependantPhoneMaxLength}
                      handleError={setMedicalDependantPhoneHasError}
                      errorMessage={Constants.medicalDependantPhoneRequiredMsg}
                      invalidMessage={Constants.medicalDependantPhoneInvalidMsg}
                      hasError={medicalDependantPhoneHasError}
                      validationType="phoneNumber"
                      required
                      validateItself={validateItself}
                      inputProps={{
                        onFocus: () => setFocusedInput("medicalDependantPhone"),
                      }}
                      onBlur={() => saveFields("medicalDependantPhone")}
                    />
                  </div>
                  <div className="field">
                    <Input
                      name="medicalDependantEMailAddress"
                      handleChange={(value) =>
                        setMedicalDependantEMailAddress(value)
                      }
                      value={medicalDependantEMailAddress}
                      labelText={Constants.medicalDependantEMailAddressLabel}
                      maxLength={
                        Constants.medicalDependantEMailAddressMaxLength
                      }
                      handleError={setMedicalDependantEMailAddressHasError}
                      errorMessage={
                        Constants.medicalDependantEMailAddressRequiredMsg
                      }
                      invalidMessage={
                        Constants.medicalDependantEMailAddressInvalidMsg
                      }
                      hasError={medicalDependantEMailAddressHasError}
                      validationType="email"
                      required
                      validateItself={validateItself}
                      inputProps={{
                        onFocus: () =>
                          setFocusedInput("medicalDependantEMailAddress"),
                      }}
                      onBlur={() => saveFields("medicalDependantEMailAddress")}
                    />
                  </div>
                </div>
              </Fragment>
            )}
          </div>

          <div className="field subItem">
            <Input
              name="criticalMedicalEquipment"
              handleChange={(value) => setCriticalMedicalEquipment(value)}
              value={criticalMedicalEquipment}
              labelText={Constants.criticalMedicalEquipmentLabel}
              handleError={setCriticalMedicalEquipmentHasError}
              errorMessage={Constants.criticalMedicalEquipmentRequiredMsg}
              hasError={criticalMedicalEquipmentHasError}
              required={hasMedicalDependant}
              maxLength={Constants.criticalMedicalEquipmentMaxLength}
              validateItself={validateItself}
              inputProps={{
                onFocus: () => setFocusedInput("criticalMedicalEquipment"),
              }}
              onBlur={() => saveFields("criticalMedicalEquipment")}
            />
          </div>

          <div className="field subItem">
            <Checkbox
              name="contactHealthPractitioner"
              label={Constants.contactHealthPractitionerLabelAgent}
              subLabel={Constants.contactHealthPractitionerSubLabel}
              checked={contactHealthPractitioner}
              onChange={contactHealthPractitionerCheckboxChangedHandler}
              required
              validateItself={validateItself}
            />
            {contactHealthPractitioner && (
              <Fragment>
                <div className="row subItem">
                  <div className="field">
                    <Input
                      name="practiceName"
                      handleChange={(value) => setPracticeName(value)}
                      value={practiceName}
                      labelText={Constants.practiceNameLabel}
                      maxLength={Constants.practiceNameMaxLength}
                      handleError={setPracticeNameHasError}
                      errorMessage={Constants.practiceNameRequiredMsg}
                      hasError={practiceNameHasError}
                      required={
                        hasMedicalDependant && contactHealthPractitioner
                      }
                      validateItself={validateItself}
                      inputProps={{
                        onFocus: () => setFocusedInput("practiceName"),
                      }}
                      onBlur={() => saveFields("practiceName")}
                    />
                  </div>
                </div>
                <div className="row subItem">
                  <div className="field">
                    <Input
                      name="practitionerName"
                      handleChange={(value) => setPractitionerName(value)}
                      value={practitionerName}
                      labelText={Constants.practitionerNameLabel}
                      maxLength={Constants.practitionerNameMaxLength}
                      handleError={setPractitionerNameHasError}
                      errorMessage={Constants.practitionerNameRequiredMsg}
                      hasError={practitionerNameHasError}
                      validationType="name"
                      required={
                        hasMedicalDependant && contactHealthPractitioner
                      }
                      validateItself={validateItself}
                      inputProps={{
                        onFocus: () => setFocusedInput("practitionerName"),
                      }}
                      onBlur={() => saveFields("practitionerName")}
                    />
                  </div>
                  <div className="field">
                    <Input
                      name="practicePhone"
                      handleChange={(value) => setPracticePhone(value)}
                      value={practicePhone}
                      labelText={Constants.practicePhoneLabel}
                      placeholder={Constants.practicePhonePlaceholder}
                      maxLength={Constants.practicePhoneMaxLength}
                      handleError={setPracticePhoneHasError}
                      errorMessage={Constants.practicePhoneRequiredMsg}
                      invalidMessage={Constants.practicePhoneInvalidMsg}
                      hasError={practicePhoneHasError}
                      validationType="phoneNumber"
                      required={
                        hasMedicalDependant && contactHealthPractitioner
                      }
                      validateItself={validateItself}
                      inputProps={{
                        onFocus: () => setFocusedInput("practicePhone"),
                      }}
                      onBlur={() => saveFields("practicePhone")}
                    />
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </Fragment>
      )}
      <div className="field">
        <Checkbox
          name="hasCreditCheckConsent"
          label={Constants.creditCheckConsent}
          checked={hasCreditCheckConsent}
          onChange={() => setHasCreditCheckConsent(!hasCreditCheckConsent)}
        />
      </div>
      <div className="joinDetails_buttonContainer">
        <Button
          name="creditCheckButton"
          className="joinDetails_button-create"
          type="button"
          text={Constants.buttonCreditCheck}
          handleClick={runCreditCheck}
          handleValidationClick={() => {}}
          primaryOnLight
          disabled={!buttonEnabled}
        />
      </div>
      {creditCheckResult.result &&
        !props.creditCheckFetching &&
        !props.hasCreditCheckError && (
          <CreditCheckResult
            resultType={creditCheckResult}
            onCheckboxChange={setBond}
            checked={creditCheckResult.paymentAmount === Constants.amberBond}
            prepayEligible={prepayEligible}
          />
        )}
      {props.creditCheckFetching && (
        <Loading
          customstyle={{
            minWidth: "100%",
            minHeight: "150px",
            marginBottom: "-15px",
          }}
        />
      )}
      {!props.creditCheckFetching &&
        props.hasCreditCheckError &&
        renderErrorMessage()}
    </div>
  );
  return (
    <Fragment>
      <div id="MedicalCreditCheck" className="cardId" />
      <ExpandableCard
        name="cutomerDetailsCard"
        headerContent={getHeader}
        content={getContent}
        isOpen={cardOpen}
        handleClick={() => setCardOpen(!cardOpen)}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  hasMedicalDependant: state.Property.MedicalInfo.HasMedicalDependant,
  hasVulnerablePerson: state.Property.MedicalInfo.HasVulnerablePerson,
  medicalDependantFirstName:
    state.Property.MedicalInfo.MedicalDependantFirstName || "",
  medicalDependantLastName:
    state.Property.MedicalInfo.MedicalDependantLastName || "",
  medicalDependantDifferentAsAccountHolder: !state.Property.MedicalInfo
    .MedicalDependantSameAsAccountHolder,
  medicalDependantPhone: state.Property.MedicalInfo.MedicalDependantPhone || "",
  medicalDependantEMailAddress:
    state.Property.MedicalInfo.MedicalDependantEMailAddress || "",
  criticalMedicalEquipment:
    state.Property.MedicalInfo.CriticalMedicalEquipment || "",
  contactHealthPractitioner:
    state.Property.MedicalInfo.ContactHealthPractitioner,
  practiceName: state.Property.MedicalInfo.PracticeName || "",
  practitionerName: state.Property.MedicalInfo.PractitionerName || "",
  practicePhone: state.Property.MedicalInfo.PracticePhone || "",
  token: state.Login.token,
  address: state.Property.PropertyInfo?.Address,
  addressDetails: state.Property.PropertyInfo?.AddressDetails,
  businessPartnerNumber: state.Customer.CustomerInfo.BusinessPartnerNumber,
  services: state.Services,
  creditCheckResult: state.CreditCheck,
  creditCheckStatus:
    state.CreditCheck?.data?.ragStatus || state.CreditCheck?.data?.Result,
  creditCheckFetching: state.CreditCheck?.isFetching,
  hasCreditCheckError: state.CreditCheck?.isError,
  creditCheckError: state.CreditCheck?.errorMessage,
  bPFetching: state.AboutYou?.isFetching,
  bPFetched: state.AboutYou?.isFetched,
  icp: state.Property?.PropertyInfo?.ElectricityICP,
  isCallingAPI: state.Auth.isCallingAPI,
});

const mapDispatchToProps = (dispatch) => {
  return {
    creditCheck: (data, token) => {
      dispatch(checkCredit(data, token));
    },
    updateCreditCheckError: (message) => {
      dispatch(updateCreditCheckError(message));
    },
    updateBPFetched: (isFetching) => dispatch(updateBPFetched(isFetching)),
    savePrepayData: (values) => {
      const details = {
        Customer: {
          CustomerInfo: {
            PrepayEligible: values.prepayEligible,
            WfEligible: values.wfEligible,
          },
        },
      };
      dispatch(saveCustomerData(details));
    },
    saveData: (values) => {
      const details = {
        Property: {
          MedicalInfo: {
            HasMedicalDependant: values.hasMedicalDependant,
            MedicalDependantSameAsAccountHolder: values.hasMedicalDependant
              ? values.medicalDependantDifferentAsAccountHolder
              : "",
            MedicalDependantFirstName: values.medicalDependantFirstName || "",
            MedicalDependantLastName: values.medicalDependantLastName || "",
            MedicalDependantPhone: values.medicalDependantPhone || "",
            MedicalDependantEMailAddress:
              values.medicalDependantEMailAddress || "",
            CriticalMedicalEquipment: values.criticalMedicalEquipment || "",
            ContactHealthPractitioner:
              values.contactHealthPractitioner || false,
            PracticeName: values.practiceName || "",
            PractitionerName: values.practitionerName || "",
            PracticePhone: values.practicePhone || "",
            HasVulnerablePerson: values.hasVulnerablePerson,
          },
        },
        CreditCheck: {
          Result: values.creditCheckResult?.result,
          Bond: values.dollarBond
            ? values.dollarBond
            : values.creditCheckResult?.paymentAmount,
          Message: values.creditCheckResult?.message,
        },
      };
      dispatch(saveCustomerData(details));
    },
    updateCreditCheckFetching: (isFetching) =>
      dispatch(updateCreditCheckFetching(isFetching)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicalCreditCheck);
