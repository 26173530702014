import React, { useState, useEffect } from "react";
import { Address, DatePicker, IcpSelect, DateUtil } from "react-components";

import { Express } from "../../../Config/Constants";
import {
  getAddressDetails,
  getElectricityDetails,
} from "../../../Redux/Actions/ApiCalls";

import "../Express.scss";

const constants = Express.moveInAddress;

type Props = {
  date?: Date,
  moveOutAddress: String,
  moveInAddress: String,
  handleDateChange: () => void,
  handleAddressChange: () => void,
  onIcpSelect: () => void,
  validateItself: boolean,
  handleError: () => void,
  minDate: Date,
  setHasNoIcp: () => void,
};

export const MoveInAddress = (props: Props) => {
  const [addressId, setAddressId] = useState("");
  const [addressDetails, setAddressDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [addressHasError, setAddressHasError] = useState(false);
  const [dateHasError, setDateHasError] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState(
    constants.addressRequiredMsg
  );
  const { handleError, moveOutAddress, moveInAddress } = props;

  useEffect(() => {
    handleError && handleError(dateHasError || addressHasError);
  }, [dateHasError, addressHasError, handleError]);

  const validateMoveInAddress = () => {
    if (moveInAddress && moveInAddress === moveOutAddress) {
      setAddressHasError(true);
      setInvalidMessage(constants.addressInvalidMsg);
    } else {
      setAddressHasError(false);
      setInvalidMessage(constants.addressRequiredMsg);
    }
  };

  useEffect(() => {
    validateMoveInAddress();
    // eslint-disable-next-line
  }, [moveOutAddress, moveInAddress]);

  useEffect(() => {
    if (addressId) {
      setIsLoading(true);
      getAddressDetails(addressId).then((response) =>
        setAddressDetails(response.data)
      );
    }
  }, [addressId]);

  return (
    <div className="express_address">
      <Address
        api={{
          baseUrl: process.env.REACT_APP_BASE_URL,
          key: process.env.REACT_APP_X_API_KEY,
        }}
        labelText={constants.addressInputLabel}
        value={props.moveInAddress}
        handleChange={(address) => {
          if (address !== moveInAddress) {
            props.onIcpSelect("");
            props.setHasNoIcp(false);
            props.handleAddressChange(address);
          }
        }}
        handleMonikerChange={setAddressId}
        hasError={addressHasError}
        handleError={setAddressHasError}
        errorMessage={constants.addressRequiredMsg}
        invalidMessage={invalidMessage}
        rightContent={
          <div className="express_address_datepicker">
            <DatePicker
              labelText={constants.dateInputLabel}
              placeholder={constants.datePlaceholder}
              value={props.date}
              handleChange={props.handleDateChange}
              minDate={
                props.minDate
                  ? props.minDate
                  : new Date(
                      DateUtil.getDateMinusXMonths(constants.dateMaxMonths)
                    )
              }
              minErrorMessage={constants.dateMaxMonthsErrorMsg}
              hasError={dateHasError}
              handleError={setDateHasError}
              errorMessage={constants.dateRequiredMsg}
              validateItself={props.validateItself}
              required
            />
          </div>
        }
        classes={{ container: "express_address_input" }}
        isLoading={isLoading}
        validateItself={props.validateItself}
        required
        onInputBlur={validateMoveInAddress}
      >
        <IcpSelect
          addressDetails={addressDetails}
          request={getElectricityDetails}
          onSelect={props.onIcpSelect}
          onSuccess={(addresses) => {
            props.setHasNoIcp(addresses.length === 0);
            setIsLoading(false);
          }}
        />
      </Address>
    </div>
  );
};

export default MoveInAddress;
