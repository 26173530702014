// @flow
import React, { useState, useEffect } from "react";
import "./Footer.scss";
import { Footer as Constants } from "../../Config/Constants";
import _debounce from "lodash/debounce";
import logo from "../../Assets/Images/contact-logo.svg";
import logoMobile from "../../Assets/Images/contact-logo-mobile.svg";

type Props = {};

const Footer = (props: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const getWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  const debounceWindowWidth = _debounce(getWindowWidth, 150);

  useEffect(() => {
    window.addEventListener("resize", debounceWindowWidth);
    return () => {
      window.removeEventListener("resize", debounceWindowWidth);
    };
  });
  return (
    <div className="footer">
      <div className="footer_image">
        <img
          name="footerLogo"
          src={windowWidth > 640 ? logo : logoMobile}
          alt={Constants.contactLogoAlt}
        />
      </div>
      <div className="footer_blocks">
        <div className="footer_blocks_links">
          {Constants.links.map(({ title, href }, index) => (
            <a
              name={"link" + index}
              href={href}
              rel="noopener noreferrer"
              target="_blank"
              key={href}
            >
              <span>{title}</span>
            </a>
          ))}
        </div>
        <div>{Constants.copyright}</div>
      </div>
    </div>
  );
};

export default Footer;
