// @flow
import React, { useState, useEffect, useCallback, Fragment } from "react";
import v from "voca";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import {
  ExpandableCard,
  ArrowDown,
  Loading,
  PlanCarousel,
  BroadbandCarousel,
  PromoCode,
  ServiceButtons,
  Close,
  ServiceCard,
} from "react-components";
import differenceWith from "lodash/differenceWith";
import { USAGE_TYPE_STANDARD, Tracking } from "../../../Config/Constants";
import {
  getGasRates,
  getSitcorePlans,
  getDefaultPlans,
  validatePromoCode,
  getIcpDetails,
  getElectricityDetailsManualSearch,
  getGasAddress,
  getGasIcp,
  getElecRegion,
} from "../../../Redux/Actions/ApiCalls";
import { setPlanValue } from "../../../Utils/generalFunctions";
import { useStateFromProp } from "../../../Utils/hooks";
import Rates from "../Rates";
import ServiceAddress from "../../FindPlan/Address";
import classNames from "classnames";
import closeButton from "../../../Assets/Images/icon-close.png";
import "./PlanServiceDetails.scss";
import type { BroadbandOffer } from "./BroadbandCard";
import { BroadbandCard } from "./BroadbandCard";
import { PipedGasServiceCard } from "./PipedGasServiceCard";
import {
  triggerServiceToggle,
  triggerServiceSelect,
  triggerServiceEnter,
  triggerPlanSelect,
  triggerPromoCodeSubmitted,
  triggerAgentPageView,
  triggerEPValues,
} from "../../../Utils/analytics";

type Props = {
  constants: Object,
  serviceAddress: string,
  serviceAddressIcp: string | null,
  setServiceAddressIcp: (icp: string) => void,
  icp: string,
  gasIcp: string,
  hasNoIcp: boolean,
  isAddressEmpty: boolean,
  setServiceAddress: () => {},
  setAddressDetails: () => {},
  setSelectedPlan: () => {},
  setSelectedPlanIndex: () => {},
  setSelectedBBPlan: () => {},
  setSelectedBBPlanIndex: () => {},
  setContinueButton: () => {},
  setIsStandard: () => {},
  isStandard: boolean,
  setGasRate: () => {},
  setPromoCode: () => {},
  setSelectedPlanId: () => {},
  setPlanServiceComplete: () => {},
  setIcp: () => {},
  setGasUsageList: Function,
  gasUsageList: [],
  setGasIcp: Function,
  onBroadbandAddressSelect: (tlcId?: string, address?: string) => void,
};

export const PlanServiceDetails = (props: Props) => {
  const {
    constants,
    icp,
    setIcp,
    serviceAddressIcp,
    setServiceAddressIcp,
    setIsStandard,
    isStandard,
    gasIcp,
    setGasIcp,
    onBroadbandAddressSelect,
  } = props;

  //Plan
  const [planCardOpen, setPlanCardOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [hasNoIcp, setHasNoIcp] = useStateFromProp(props.hasNoIcp, false);
  const [gasRate, setGasRate] = useState([]);

  const [selectedPlan, setSelectedPlan] = useStateFromProp(
    props.selectedPlan,
    null
  );
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(null);

  const [planOffers, setPlanOffers] = useState(null);
  const [plansData, setPlansData] = useState([]);
  const [ratesContent, setRatesContent] = useState(null);

  const [selectedBBPlan, setSelectedBBPlan] = useStateFromProp(
    props.selectedBBPlan,
    null
  );
  const [selectedBBPlanIndex, setSelectedBBPlanIndex] = useState(null);

  const [bbOffers, setBBOffers] = useState([]);
  const [isDiscount, setIsDiscount] = useState(false);

  const [promoCode, setPromoCode] = useStateFromProp(props.promoCode, "");
  const [isCodeValidating, setIsCodeValidating] = useState(false);
  const [codeHasError, setCodeHasError] = useState(false);
  const [codeErrorMessage, setCodeErrorMessage] = useState("");
  const [codeSubmitted, setCodeSumbitted] = useState(false);
  const [codeSuccessMessage, setCodeSuccessMessage] = useState("");

  const [notApplicable, setNotApplicable] = useState(false);
  const [isSouthIsland, setIsSouthIsland] = useState(false);

  const [allPlanOffers, setAllPlanOffers] = useState([]);

  //Service
  const [serviceCardOpen, setServiceCardOpen] = useState(true);
  const [serviceAddress, setServiceAddress] = useStateFromProp(
    props.serviceAddress,
    ""
  );
  const [serviceAddressHasError, setServiceAddressHasError] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [isManualAddress, setIsManualAddress] = useState(false);
  const [addressDetails, setAddressDetails] = useStateFromProp(
    props.addressDetails,
    null
  );

  const [hover, setHover] = useState(null);
  const [gasUsageList, setGasUsageList] = useState([]);
  const [selectedServiceList, setSelectedServiceList] = useState(["ELEC"]);

  const onlyLPGOrGAS =
    selectedServiceList.length === 1 &&
    constants.excludedPromo.services.find(
      (service) => service === selectedServiceList[0]
    );
  const [noteMessageList, setNoteMessageList] = useState([]);

  const [gasIcpDetails, setGasDetails] = useState(null);
  const [gasAddressesList, setGasAddressesList] = useState([]);

  const [pageViewed, setPageViewed] = useState(false);

  const isInList = (name, list): boolean => {
    if (Array.isArray(list) && name) {
      return list.includes(name);
    }
    return false;
  };

  const handleServiceCheck = useCallback((service, list) => {
    if (service && list) {
      return isInList(service, list);
    }
  }, []);

  const [lpgOnly, setLpgOnly] = useState(false);
  const gasOnly =
    isInList("GAS", selectedServiceList) && selectedServiceList.length === 1;

  // Ensure that if the service address is changed, it overrides the Electricity ICP again
  useEffect(() => {
    setIcp(serviceAddressIcp);
  }, [serviceAddressIcp, setIcp]);

  useEffect(() => {
    getDefaultPlans().then((response) => {
      setPlansData(response);
    });
    getPlans();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.gasUsageList) {
      setGasUsageList(props.gasUsageList);
    }
    // eslint-disable-next-line
  }, [props.gasUsageList]);

  useEffect(() => {
    if (gasUsageList) {
      props.setGasUsageList(gasUsageList);
    }
    // eslint-disable-next-line
  }, [gasUsageList]);

  useEffect(() => {
    if (props.selectedServiceList.length) {
      setSelectedServiceList(props.selectedServiceList);
    }
  }, [props.selectedServiceList]);

  useEffect(() => {
    if (selectedPlan) {
      triggerPlanSelect(selectedPlan);
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (
      !selectedServiceList.length ||
      !serviceAddress ||
      isCodeValidating ||
      (isInList("ELEC", selectedServiceList) && !selectedPlan) ||
      (isInList("BB", selectedServiceList) && !selectedBBPlan?.Id) ||
      (isInList("LPG", selectedServiceList) && !gasUsageList?.length) ||
      (isInList("GAS", selectedServiceList) &&
        (!gasRate || !selectedPlan) &&
        !isSouthIsland) ||
      (gasUsageList.length === 1 && isInList("cooking", gasUsageList))
    ) {
      props.setPlanServiceComplete(false);
    } else {
      props.setPlanServiceComplete(true);
    }
    // eslint-disable-next-line
  }, [
    selectedPlan,
    selectedBBPlan,
    gasUsageList,
    gasRate,
    icp,
    isManualAddress,
    hasNoIcp,
    selectedServiceList,
    isCodeValidating,
  ]);

  useEffect(() => {
    if (plansData) {
      if (gasOnly) {
        if (isSouthIsland) {
          setPlanOffers(null);
          setSelectedPlan(null);
          setNoteList(constants.onlyGasSANote, false);
        } else {
          if (allPlanOffers.length) {
            const filteredPlans = allPlanOffers.filter(
              (plan) =>
                plan.fields.ExistingPlan.fields.ApiPlanId.value ===
                constants.basicPlan
            );
            const selectedPlan = plansData.find(
              (plan) =>
                plan.Id ===
                filteredPlans[0].fields.ExistingPlan.fields.ApiPlanId.value
            );
            if (selectedPlan) {
              setSelectedPlan(selectedPlan);
              setSelectedPlanIndex(0);
              setPlanOffers(filteredPlans);
            }
          }
        }
      } else {
        setPlanOffers(allPlanOffers);
      }
    }
    // eslint-disable-next-line
  }, [gasOnly, plansData, serviceAddress, allPlanOffers]);

  useEffect(() => {
    if (props.serviceAddress) {
      setServiceAddress(props.serviceAddress);
      const code = props.serviceAddress.slice(-4);
      setIsSouthIsland(code >= 7000);
    }
    // eslint-disable-next-line
  }, [props.serviceAddress]);

  useEffect(() => {
    if (selectedServiceList) {
      const lpg =
        isInList("LPG", selectedServiceList) &&
        selectedServiceList.length === 1;
      if (lpg) {
        setLpgOnly(lpg);
        props.setSelectedPlanId(constants.lpgOnlyPlan);
        props.setSelectedPlan(null);
      }
      if (selectedPlan?.Services) {
        const mandatoryServices = [];
        selectedPlan.Services.forEach((service) => {
          if (service.mandatory) {
            mandatoryServices.push(service.id);
          }
        });
        const differenceInPlans = differenceWith(
          mandatoryServices,
          selectedServiceList
        );
        if (differenceInPlans.length) {
          setSelectedPlan(null);
          setSelectedPlanIndex(null);
          if (differenceInPlans.includes("GAS")) {
            setNoteList(constants.ppRemoved, true);
            setGasRate([]);
          }
        }
      }
    }
    if (!selectedServiceList.length) {
      setNoteList(constants.serviceEmptyNote, true);
    } else {
      const newArray = noteMessageList.filter(
        (item) => item.text !== constants.serviceEmptyNote
      );
      setNoteMessageList(newArray);
    }
    // eslint-disable-next-line
    if (selectedServiceList) {
      props.setSelectedServiceList(selectedServiceList);
    }
    if (!isInList("BB", selectedServiceList)) {
      setSelectedBBPlan(null);
    }
    // eslint-disable-next-line
  }, [selectedServiceList]);

  useEffect(() => {
    if (gasUsageList.length === 1) {
      if (isInList("cooking", gasUsageList)) {
        setNoteList(constants.cookingGasNote, true);
      }
    } else {
      const newArray = noteMessageList.filter(
        (item) => item.text !== constants.cookingGasNote
      );
      setNoteMessageList(newArray);
    }
    // eslint-disable-next-line
  }, [gasUsageList]);

  useEffect(() => {
    if (selectedPlan && planOffers?.length) {
      planOffers.forEach((plan, index) => {
        if (
          plan.fields?.ExistingPlan?.fields.ApiPlanId.value === selectedPlan.Id
        ) {
          setSelectedPlanIndex(index);
        }
      });
    }
    // eslint-disable-next-line
  }, [selectedPlan, planOffers]);

  useEffect(() => {
    if (selectedPlan?.Services) {
      selectedPlan.Services.forEach((service) => {
        if (service.mandatory && !isInList(service.id, selectedServiceList)) {
          addValue(service.id, selectedServiceList, setSelectedServiceList);
          if (service.id === "BB") {
            setNoteList(constants.bbAddNote, true);
          }
          if (service.id === "GAS") {
            setNoteList(constants.gasAddNote, true);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [selectedPlan]);

  useEffect(() => {
    if (selectedBBPlan && bbOffers?.length) {
      bbOffers.forEach((plan, index) => {
        if (plan.Id === selectedBBPlan.Id) {
          setSelectedBBPlanIndex(index);
        }
      });
    }
  }, [selectedBBPlan, bbOffers]);

  useEffect(() => {
    if (selectedPlan) {
      props.setSelectedPlan(selectedPlan);
    }
    // eslint-disable-next-line
  }, [selectedPlan]);

  useEffect(() => {
    if (selectedBBPlan) {
      props.setSelectedBBPlan(selectedBBPlan);
    }
    // eslint-disable-next-line
  }, [selectedBBPlan]);

  useEffect(() => {
    if (selectedPlanIndex) {
      props.setSelectedPlanIndex(selectedPlanIndex);
    }
    // eslint-disable-next-line
  }, [selectedPlanIndex]);

  useEffect(() => {
    if (selectedBBPlanIndex) {
      props.setSelectedBBPlanIndex(selectedBBPlanIndex);
    }
    // eslint-disable-next-line
  }, [selectedBBPlanIndex]);

  useEffect(() => {
    if (props.isAddressEmpty) {
      scrollToElement("serviceAddress");
    } else if (props.isGasUsageEmpty) {
      scrollToElement("gasUsageList");
    } else if (props.isPlanEmpty) {
      scrollToElement("planCarousel");
    } else if (props.isBBEmpty) {
      scrollToElement("broadbandCarousel");
    }
    // eslint-disable-next-line
  }, [props.isAddressEmpty]);

  useEffect(() => {
    if (onlyLPGOrGAS && props.plan) {
      setNotApplicable(
        !!onlyLPGOrGAS ||
          constants.excludedPromo.plansId.find(
            (planId) => planId === selectedPlan.Id
          )
      );
    }
    // eslint-disable-next-line
  }, [onlyLPGOrGAS, selectedPlan]);

  useEffect(() => {
    props.setAddressDetails(addressDetails);
    setIcp("");
    setGasIcp("");
    setGasDetails(null);
    // eslint-disable-next-line
  }, [addressDetails]);

  useEffect(() => {
    if (codeSubmitted) {
      setCodeSumbitted(!codeSubmitted);
      setCodeSuccessMessage("");
    }
    props.setPromoCode(promoCode);
    // eslint-disable-next-line
  }, [promoCode]);

  useEffect(() => {
    if (selectedBBPlanIndex) {
      props.setSelectedBBPlanIndex(selectedBBPlanIndex);
    }
    // eslint-disable-next-line
  }, [selectedBBPlanIndex]);

  const gasInServiceList = isInList("GAS", selectedServiceList);

  useEffect(() => {
    if (!isEmpty(addressDetails) && icp) {
      if (gasInServiceList && (gasIcp || icp)) {
        if (!gasIcpDetails?.fullAddress && !gasIcp) {
          getGasData({ pxid: addressDetails.pxid });
        }
        setIsLoading(true);

        if (gasIcp && !isEmpty(selectedPlan)) {
          getGasRates(gasIcp, selectedPlan).then((response) => {
            if (!isEmpty(response)) {
              setIsLoading(false);
              setGasRate(response);
            }
          });
        }
      } else {
        setGasRate([]);
      }
    }
    // eslint-disable-next-line
  }, [addressDetails, gasInServiceList, gasIcp, icp, selectedPlan]);

  useEffect(() => {
    if (selectedPlan) {
      if (
        selectedPlan?.Id === constants.bbBundle ||
        selectedPlan?.Id === constants.rockGas
      ) {
        setIsDiscount(true);
      } else {
        setIsDiscount(false);
      }
    }
    // eslint-disable-next-line
  }, [selectedPlan]);

  const getPlanAndIndex = () => {
    const data = isSouthIsland
      ? allPlanOffers.filter(
          (plan) =>
            plan.fields.ExistingPlan.fields.ApiPlanId.value !==
            constants.simplicityPlan
        )
      : allPlanOffers;
    const plan = data.find(
      (plan) =>
        plan.fields.ExistingPlan.fields.ApiPlanId.value === constants.basicPlan
    );
    const index = data.findIndex(
      (plan) =>
        plan.fields.ExistingPlan.fields.ApiPlanId.value === constants.basicPlan
    );
    onPlanSelect(plan, index);
    return plan;
  };

  useEffect(() => {
    // Sets electricity plan to basic by default if address entered and no plan selected
    if (serviceAddress && allPlanOffers && !selectedPlan) {
      getPlanAndIndex();
    }
    // eslint-disable-next-line
  }, [serviceAddress, allPlanOffers, selectedPlan]);

  const trackEPValues = useCallback(
    (icp, rates) => {
      if (addressDetails && selectedPlan.Id) {
        getElecRegion({
          Address: addressDetails.a,
          AddressIdentifier: addressDetails.id,
          CustomerSelectedICPNumber: icp,
          PPD: 0,
          AddressFinderAddress: addressDetails,
          PlanId: selectedPlan.Id,
        }).then((region) => triggerEPValues(icp, rates.length !== 0, region));
      }
    },
    [addressDetails, selectedPlan]
  );

  const onPlanSelect = (data, index) => {
    if (!pageViewed) {
      triggerAgentPageView("/plan");
    }
    setPageViewed(true);
    if (!isEmpty(data)) {
      const selectedPlan = plansData.find(
        (plan) => plan.Id === data.fields?.ExistingPlan?.fields.ApiPlanId.value
      );

      setSelectedPlan(selectedPlan);
      props.setSelectedPlan(selectedPlan);
      setSelectedPlanIndex(index);
    } else {
      setSelectedPlan(null);
      props.setSelectedPlan(null);
      setSelectedPlanIndex(null);
    }
  };

  const getGasData = (addressDetails) => {
    getGasAddress(addressDetails).then((response) => {
      if (response.data?.length === 1) {
        getGasIcpData(response.data[0].icp);
      } else if (response.data?.length > 1) {
        setGasAddressesList(response.data);
      } else {
        setGasAddressesList([]);
        setIsLoading(false);
        setGasRate([]);
      }
    });
  };

  const isManualGasEntry = (elecIcp, gasIcp) => {
    return elecIcp !== gasIcp;
  };

  const getGasIcpData = (icpGas) => {
    if (icpGas && isManualGasEntry(icp, icpGas)) {
      setGasIcp(icpGas);
      getGasIcp({ icp: icpGas }).then((response) => {
        if (response.data) {
          setGasDetails(response.data);
          triggerServiceEnter(Tracking.GAS, icpGas, response.data);
        }
      });
    }
  };

  const trackIcpDetails = (icp, data) => {
    if (!isEmpty(data)) {
      triggerServiceEnter(Tracking.ELEC, icp, data);
    }
  };

  const setNoteList = (text, isWarning) => {
    const existingText = noteMessageList.filter((value) => value.text === text);
    if (!existingText.length) {
      setNoteMessageList([
        ...noteMessageList,
        {
          text: text,
          isWarning: isWarning,
        },
      ]);
    }
  };

  const removeNote = (index) => {
    const newArray = noteMessageList.filter(
      (item, itemIndex) => itemIndex !== index
    );
    setNoteMessageList(newArray);
  };

  const processBroadbandOffers = useCallback(
    (offerings: BroadbandOffer[]) => {
      // Set a selected offer if we're rehydrating
      if (props.selectedBBPlan) {
        const currentIndex = offerings.findIndex(
          (offer) => offer.Id === props.selectedBBPlan?.Id
        );
        if (currentIndex > 0) setSelectedBBPlanIndex(currentIndex);
      }

      // Set offer list to render UI
      setBBOffers(offerings);
    },
    [props.selectedBBPlan, setSelectedBBPlanIndex, setBBOffers]
  );

  const onBBPlanSelect = (data, index) => {
    if (!isEmpty(data)) {
      setSelectedBBPlan(data);
      setSelectedBBPlanIndex(index);
      props.setSelectedBBPlan(data);
      triggerServiceEnter(Tracking.BB, null, {
        Address: serviceAddress,
        ...data,
      });
      props.setSelectedBBPlanIndex(index);
    } else {
      setSelectedBBPlan(null);
      setSelectedBBPlanIndex(null);
      props.setSelectedBBPlan(null);
      props.setSelectedBBPlanIndex(null);
    }
  };

  const scrollToElement = (name) => {
    setTimeout(() => {
      const element = document.getElementById(name);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 400);
  };

  const getPlans = async () => {
    await getSitcorePlans(true).then((response) => {
      if (response.data?.sitecore) {
        const placeHolders = response.data.sitecore.route.placeholders[
          "jss-main"
        ].find((item) => item.componentName === "PlansAndServices")
          .placeholders["plans-filter-placeholder"];
        const ratesContent = placeHolders.find(
          (item) => item.componentName === "EnergyRates"
        );
        const continueButton = response.data.sitecore.route.placeholders[
          "jss-main"
        ].find((item) => item.componentName === "ContinueButton").fields;
        const plans = placeHolders.filter(
          (item) => item.componentName === "PlanFilter"
        );
        const plansOffers = plans[0].placeholders["plans-placeholder"];
        setRatesContent(ratesContent.fields);
        setPlanOffers(plansOffers);
        setAllPlanOffers(plansOffers);
        props.setContinueButton(continueButton);
      }
    });
  };

  const getPlanHeader = {
    cardTitle: constants.titlePlans,
    cardContent: "",
    cardIcon: <ArrowDown />,
    cardIsSelected: false,
    cardLink: true,
  };
  const getServiceHeader = {
    cardTitle: constants.title,
    cardContent: "",
    cardIcon: <ArrowDown />,
    cardIsSelected: false,
    cardLink: true,
  };

  const applyPromoCode = async () => {
    setIsCodeValidating(true);
    setCodeErrorMessage("");
    if (notApplicable) {
      setIsCodeValidating(false);
      setCodeHasError(true);
      setCodeErrorMessage(constants.promoCodeNotApplicable);
    } else {
      await validatePromoCode(promoCode).then((response) => {
        triggerPromoCodeSubmitted(response, promoCode);
        setIsCodeValidating(false);
        if (!response?.IsValid) {
          setCodeHasError(true);
          setCodeErrorMessage(response.InvalidMessage);
        } else {
          setCodeSumbitted(!codeSubmitted);
          setCodeSuccessMessage(response.Description);
        }
      });
    }
  };

  const addValue = (service, list, setList) => {
    triggerServiceSelect(service);
    setPlanValue(
      service,
      list,
      setList,
      setSelectedPlan,
      setSelectedPlanIndex,
      setNoteList,
      setSelectedBBPlan,
      setGasUsageList,
      isInList,
      selectedPlan,
      constants,
      selectedServiceList
    );
  };

  const handleAddressChange = (address) => {
    const code = address.slice(-4);
    setIsSouthIsland(code >= 7000);
    if (code >= 7000) {
      if (selectedPlan && selectedPlan.Id === constants.simplicityPlan) {
        setSelectedServiceList(
          selectedServiceList.filter((service) => service !== "GAS")
        );
        setSelectedPlan(null);
        setSelectedPlanIndex(null);
      }

      if (selectedPlan && selectedPlan.GeographicalLocation === "NI") {
        setSelectedPlan(null);
        setSelectedPlanIndex(null);
        setNoteList(constants.southRemoveNote, true);
      }
    }

    setServiceAddress(address);
    props.setServiceAddress(address);
    setIsManualAddress(false);
  };

  const handleManualChange = (address) => {
    setIcp("");
    setAddressId("");
    setServiceAddress(address);
    props.setServiceAddress(address);
    setIsManualAddress(true);
  };

  const getPlanContent = (
    <div className="planDetails">
      {planOffers &&
        (icp || hasNoIcp) &&
        serviceAddress &&
        selectedServiceList &&
        selectedPlan && (
          <div>
            {isLoading ? (
              <Loading
                customstyle={{
                  minWidth: "100%",
                  minHeight: "150px",
                  marginBottom: "-15px",
                }}
              />
            ) : (
              <Rates
                icp={icp}
                planId={selectedPlan.Id}
                hasNoIcp={hasNoIcp}
                isLowUser={!isStandard}
                setIsStandard={setIsStandard}
                gasUsageType={
                  USAGE_TYPE_STANDARD
                } /* TODO: This is hardcoded temporarily as part of CSRT-559 */
                setGasUsageType={props.setConsumptionBand}
                isNarrow={false}
                constants={constants.joinRates}
                isOpenByDefault={false}
                gasRate={
                  isInList("GAS", selectedServiceList)
                    ? gasIcp
                      ? gasRate
                      : []
                    : null
                }
                managedContent={ratesContent}
                gasOnly={gasOnly}
                trackingRequest={trackEPValues}
              />
            )}
          </div>
        )}
      {planOffers ? (
        <div
          id="planCarousel"
          className={classNames(
            props.isPlanEmpty ? "serviceDetails_shake" : ""
          )}
        >
          <PlanCarousel
            planIndex={selectedPlanIndex}
            data={
              isSouthIsland
                ? planOffers.filter(
                    (plan) =>
                      plan.fields.ExistingPlan.fields.ApiPlanId.value !==
                      constants.simplicityPlan
                  )
                : planOffers
            }
            onPress={onPlanSelect}
            constants={constants.planCarouselConstants}
            url={process.env.REACT_APP_EXPRESS_BASE_URL}
            arrowsCustomStyle={{
              width: "105%",
              marginLeft: "-40px",
            }}
          />
        </div>
      ) : isSouthIsland ? null : (
        <Loading
          customstyle={{
            minWidth: "100%",
            minHeight: "150px",
            marginBottom: "-15px",
          }}
        />
      )}
      {selectedServiceList?.includes("BB") ? (
        bbOffers.length ? (
          <div
            id="broadbandCarousel"
            className={classNames(
              "broadbandCarousel",
              props.isBBEmpty ? "serviceDetails_shake" : ""
            )}
          >
            <BroadbandCarousel
              planIndex={selectedBBPlanIndex}
              data={bbOffers}
              onPress={onBBPlanSelect}
              constants={constants.bbCarouselConstants}
              isDiscount={isDiscount}
              arrowsCustomStyle={{
                width: "105%",
                marginLeft: "-40px",
              }}
            />
          </div>
        ) : (
          <Loading customstyle={{ minWidth: "100%", minHeight: "200px" }} />
        )
      ) : null}
      <div className="planDetails_promo-code">
        <div className="planDetails_promo-code_title">
          {constants.promoCodeTitle}
        </div>
        <PromoCode
          placeholder={constants.promoCodePlaceholder}
          buttonText={constants.promoCodeButtonText}
          buttonTextHover={constants.promoCodeButtonTextHover}
          buttonTextApplied={constants.promoCodeButtonTextApplied}
          buttonDisabled={!promoCode}
          maxLength={10}
          code={promoCode}
          setCode={setPromoCode}
          setCodeSumbitted={applyPromoCode}
          codeSubmitted={codeSubmitted}
          isCodeValidating={isCodeValidating}
          hasError={codeHasError}
          handleError={setCodeHasError}
          errorMessage={codeErrorMessage}
          successMessage={codeSuccessMessage}
        />
      </div>
    </div>
  );

  const handleIcpSelect = (icp) => {
    setServiceAddressIcp(icp);
    if (!icp) {
      setIcp("");
    }
  };
  const getServiceContent = (
    <div className="serviceDetails">
      <div
        className={classNames(
          props.isAddressEmpty ? "serviceDetails_shake" : ""
        )}
        id="serviceAddress"
      >
        <ServiceAddress
          serviceAddress={serviceAddress}
          handleAddressChange={handleAddressChange}
          handleManualChange={handleManualChange}
          onIcpSelect={handleIcpSelect}
          icp={serviceAddressIcp ?? icp}
          setHasNoIcp={setHasNoIcp}
          hasError={serviceAddressHasError && !isManualAddress}
          handleError={setServiceAddressHasError}
          suburbRequired
          addressId={addressId}
          setAddressDetails={setAddressDetails}
          loaderCustomStyle={{
            background: "#fff",
          }}
          newBuildUrl={constants.newBuildUrl}
        />
      </div>
      <div className="serviceDetails_title">{constants.serviceTitle}</div>
      <ServiceCard
        serviceType="ELEC"
        canOpen
        addValue={addValue}
        isInList={isInList}
        list={selectedServiceList}
        setlist={setSelectedServiceList}
        icp={
          isInList("ELEC", selectedServiceList)
            ? icp || serviceAddressIcp
            : undefined
        }
        persistIcp={setIcp}
        getIcpDetails={getIcpDetails}
        getManualSearch={getElectricityDetailsManualSearch}
        address={serviceAddress}
        setAddress={setServiceAddress}
        onReceiveIcpDetails={trackIcpDetails}
        setModalOpened={(value) => {
          const url = value
            ? value === "AddressSearch"
              ? "/about-property/manual-details-electricity-modal"
              : "/about-property/anual-electricity-icp-modal"
            : null;
          if (url) {
            triggerAgentPageView(url);
          }
        }}
        classes={{
          infoContainer: {
            "expandableCard_info-bar": true,
          },
        }}
        setIsOpen={(isOpen) => triggerServiceToggle(isOpen, Tracking.ELEC)}
        isOpen={serviceAddress && isInList("ELEC", selectedServiceList)}
      />

      <PipedGasServiceCard
        isInList={isInList}
        list={selectedServiceList}
        icpDetails={gasIcpDetails}
        serviceType="GAS"
        address={serviceAddress}
        constants={constants}
        island={isSouthIsland ? "South" : "North"}
        onCheckboxChange={(e) => {
          e.stopPropagation();
          if (handleServiceCheck("GAS", selectedServiceList)) {
            setGasIcp(""); //empty ui if gas checkbox unclicked, will pass empty gas icp to parent
            setGasDetails(null);
            props.setConsumptionBand("");
          }
          addValue("GAS", selectedServiceList, setSelectedServiceList);
        }}
        getGasData={getGasData}
        addressesList={gasAddressesList}
        setGasIcp={getGasIcpData}
        gasIcp={gasIcp}
        setPipedGasUsage={props.setPipedGasUsage}
        consumptionBand={props.consumptionBand}
        setConsumptionBand={props.setConsumptionBand}
        setModalOpened={(value) => {
          const url = value
            ? value === "ADDRESS"
              ? "/about-property/manual-details-piped-gas-modal"
              : "/about-property/manual-gas-icp-modal"
            : null;
          if (url) {
            triggerAgentPageView(url);
          }
        }}
      />

      <BroadbandCard
        isChecked={isInList("BB", selectedServiceList)}
        onAddressSelect={onBroadbandAddressSelect}
        onReceiveOffers={processBroadbandOffers}
        onActivate={() => {
          addValue("BB", selectedServiceList, setSelectedServiceList);
        }}
        selectedPlanId={selectedPlan?.Id}
        selectedServicesList={selectedServiceList}
        address={props.bbAddress ?? serviceAddress}
        pxid={props.bbAddress ? undefined : addressDetails?.pxid} //ignore pxid if bb address was chosen manually
        isCardOpen={isInList("BB", selectedServiceList)}
      />

      {/* At this point only LPG remains in here... */}
      {constants.serviceButtons
        .filter((b) => b.code === "LPG")
        .map((button) => (
          <ExpandableCard
            key={button.code}
            name={button.name}
            isOpen={
              button.code === "LPG" &&
              isInList(button.code, selectedServiceList)
            }
            headerContent={{
              cardTitle: v.titleCase(button.name),
              mainIcon: button.icon,
              withCheckbox: true,
              disabled: button.code !== "LPG",
            }}
            classes={{
              infoContainer: {
                "expandableCard_info-bar": true,
              },
            }}
            content={
              button.code === "LPG" ? (
                <div
                  className={classNames(
                    "serviceDetails_service-buttons",
                    props.isAddressEmpty ? "serviceDetails_shake" : ""
                  )}
                >
                  <div
                    className="serviceDetails_title"
                    style={{ marginTop: 20 }}
                  >
                    {constants.gasUsageTitle}
                  </div>
                  <ServiceButtons
                    constants={constants.serviceDetails}
                    addValue={addValue}
                    isInList={isInList}
                    hover={hover}
                    setHover={setHover}
                    list={gasUsageList}
                    buttons={constants.gasButtons}
                    setlist={setGasUsageList}
                    className="findPlan_section_buttonHolder_service-button--gas"
                    buttonCustomStyle={{
                      marginBottom: "24px",
                    }}
                    servicesContent={[]}
                    narrow
                  />
                </div>
              ) : null
            }
            onCheckboxChange={(e) => {
              e.stopPropagation();
              addValue(
                button.code,
                selectedServiceList,
                setSelectedServiceList
              );
            }}
            checked={isInList(button.code, selectedServiceList)}
          />
        ))}
      {!!noteMessageList.length &&
        noteMessageList.map((note, index) => (
          <div
            className={classNames(
              "findPlan_section_note",
              note.isWarning ? "findPlan_section_note_warning" : ""
            )}
            key={index}
          >
            <div
              className={classNames(
                "findPlan_section_note_text",
                note.isWarning ? "findPlan_section_note_text_warning" : ""
              )}
            >
              {note.text}
            </div>
            <div
              className="findPlan_section_note_close"
              onClick={() => removeNote(index)}
            >
              {!note.isWarning && (
                <img
                  name="closeButton"
                  src={closeButton}
                  alt={constants.contactLogoAlt}
                />
              )}
              {note.isWarning && (
                <div className="findPlan_section_note_close_warning">
                  <Close />
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
  return (
    <Fragment>
      <div id="planDetails" className="cardId" />
      <ExpandableCard
        name="ServiceCard"
        headerContent={getServiceHeader}
        content={getServiceContent}
        isOpen={serviceCardOpen}
        handleClick={() => setServiceCardOpen(!serviceCardOpen)}
      />
      {!lpgOnly && (
        <ExpandableCard
          name="planDetailsCard"
          headerContent={getPlanHeader}
          content={getPlanContent}
          isOpen={planCardOpen}
          handleClick={() => setPlanCardOpen(!planCardOpen)}
        />
      )}
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getGasAddress: (queryParams) => {
    dispatch(getGasAddress(queryParams));
  },
  getGasIcp: (queryParams) => {
    dispatch(getGasIcp(queryParams));
  },
});

const mapStateToProps = (state) => ({
  selectedPlan: state.PlanInfo?.SelectedPlan,
  selectedBBPlan: state.BroadbandInfo,
  serviceAddress: state.Property.PropertyInfo?.Address,
  bbAddress: state.BroadbandInfo?.DevoliAddress,
  gasUsageList: state.LpgInfo?.BottledGasUsage,
  selectedServiceList: state.Services,
  addressDetails: state.Property.PropertyInfo?.AddressDetails,
  promoCode: state.Payment.PromoCode,
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanServiceDetails);
