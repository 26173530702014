// @flow
import React, { useEffect, useState } from "react";
import _debounce from "lodash/debounce";

import { Header as Constants } from "../../Config/Constants";

import "./PrimaryNavBar.scss";
import logoDesktop from "../../Assets/Images/header-logo-desktop.svg";
import logoMobile from "../../Assets/Images/header-logo-mobile.svg";

const PrimaryNavBar = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const getWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  const debounceWindowWidth = _debounce(getWindowWidth, 150);

  useEffect(() => {
    window.addEventListener("resize", debounceWindowWidth);
    return () => {
      window.removeEventListener("resize", debounceWindowWidth);
    };
  });

  const handleLogoClick = () => {
    window.location.href = Constants.logo.redirectURL;
  };

  return (
    <nav className="primaryNavBar">
      <div className="primaryNavBar_logo" onClick={handleLogoClick}>
        <img
          name="headerLogo"
          src={windowWidth > 640 ? logoDesktop : logoMobile}
          alt={Constants.logo.alt}
        />
      </div>
    </nav>
  );
};

export default PrimaryNavBar;
