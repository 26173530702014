// @flow
import React, { useState, Fragment } from "react";
import {
  ExpandableCard,
  Input,
  ArrowDown,
  DateOfBirthInput,
  Checkbox,
  Button,
} from "react-components";
import "./CustomerDetails.scss";

type Props = {
  constants: Object,
  firstName: string,
  lastName: string,
  spokenToUsBefore: boolean,
  phoneNumber: string,
  emailAddress: string,
  dateOfBirthObject: Object,
  isLoading: boolean,
  bp: object,
  isCustomerInfoError: boolean,
  renderErrorMessage: () => {},
  setFirstName: () => {},
  setLastName: () => {},
  setSpokenToUsBefore: () => {},
  setPhoneNumber: () => {},
  setDOB: () => {},
  setEmailAddress: () => {},
  setBP: () => {},
  handleFindButtonClick: () => {},
};

export const CustomerDetails = (props: Props) => {
  const {
    constants,
    firstName,
    lastName,
    spokenToUsBefore,
    phoneNumber,
    bp,
    isCustomerInfoError,
    emailAddress,
    dateOfBirthObject,
    isLoading,
    setFirstName,
    setLastName,
    setSpokenToUsBefore,
    setPhoneNumber,
    setDOB,
    setEmailAddress,
    setBP,
    handleFindButtonClick,
    renderErrorMessage,
  } = props;
  const [cardOpen, setCardOpen] = useState(true);
  const getHeader = {
    cardTitle: constants.title,
    cardContent: "",
    cardIcon: <ArrowDown />,
    cardIsSelected: false,
    cardLink: true,
  };

  const getContent = (
    <div className="customerDetails">
      <div className="customerDetails_fields">
        <div className="field">
          <Input
            name="firstName"
            handleChange={setFirstName}
            value={firstName}
            labelText={constants.firstNameLabel}
            placeholder={constants.firstNamePlaceholder}
            maxLength={constants.firstNameMaxLength}
            handleError={() => {}}
            hasError={false}
            validationType="name"
            required
          />
        </div>
        <div className="field">
          <Input
            name="lastName"
            handleChange={setLastName}
            value={lastName}
            labelText={constants.lastNameLabel}
            placeholder={constants.lastNamePlaceholder}
            maxLength={constants.lastNameMaxLength}
            handleError={() => {}}
            hasError={false}
            validationType="name"
          />
        </div>
        <div className="field">
          <Checkbox
            name="spokenToUsBefore"
            className="spoken-to-us-before-checkbox"
            label={constants.spokenToUsBeforeLabel}
            checked={spokenToUsBefore}
            onChange={() => setSpokenToUsBefore(!spokenToUsBefore)}
          />
        </div>
        {spokenToUsBefore && (
          <>
            <div className="field">
              <Input
                name="phone"
                handleChange={setPhoneNumber}
                value={phoneNumber}
                labelText={constants.phoneLabel}
                placeholder={constants.phonePlaceholder}
                maxLength={constants.phoneMaxLength}
                invalidMessage={constants.phoneInvalidMsg}
                validationType="phoneNumber"
              />
            </div>
            <div className="field">
              <DateOfBirthInput
                name="dateOfBirth"
                handleChange={setDOB}
                value={dateOfBirthObject}
                labelText={constants.dateLabel}
                placeholder={constants.datePlaceholder}
                validationType="dateOfBirth"
              />
            </div>
            <div className="field">
              <Input
                name="email"
                handleChange={setEmailAddress}
                value={emailAddress}
                labelText={constants.emailLabel}
                placeholder={constants.emailPlaceholder}
                maxLength={constants.emailMaxLength}
                invalidMessage={constants.emailInvalidMsg}
                validationType="email"
                showSuccessIcon
              />
            </div>
            <div className="field">
              <Input
                name="customerNumber"
                handleChange={setBP}
                value={bp}
                labelText={constants.customerNumberLabel}
                placeholder=""
              />
            </div>
            <div className="field">
              <Button
                name="findButton"
                type="button"
                text={constants.findButton}
                handleClick={handleFindButtonClick}
                primaryOnLight
                isLoading={isLoading}
              />
              {isCustomerInfoError && renderErrorMessage}
            </div>
          </>
        )}
      </div>
    </div>
  );
  return (
    <Fragment>
      <div id="Details" className="cardId" />
      <ExpandableCard
        name="cutomerDetailsCard"
        headerContent={getHeader}
        content={getContent}
        isOpen={cardOpen}
        handleClick={() => setCardOpen(!cardOpen)}
      />
    </Fragment>
  );
};

export default CustomerDetails;
