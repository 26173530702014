import * as actionTypes from "../actionTypes";
import { getUserProfile, getUserAccount } from "../ApiCalls";

export const updateUserProfile = (userProfile) => {
  return {
    type: actionTypes.UPDATE_USER_PROFILE,
    userProfile,
  };
};

export const getAccountDetails = (accountId, token, services) => (dispatch) => {
  getUserAccount(accountId, token).then((response) => {
    const newServices = [...services, updateProperties(response.data)];
    response.data.customer.serviceTypes = newServices;
    dispatch(updateUserProfile(response.data));
  });
};

const updateProperties = (data) => {
  const contracts = {
    accountId: data.customer.account.id,
    serviceTypes: [],
  };
  data.customer.account.contracts.forEach((contract) => {
    contracts.serviceTypes.push(contract.contractTypeLabel);
  });
  return contracts;
};

export const saveUserProfile = (token) => (dispatch) => {
  getUserProfile(token).then((response) => {
    const types = updateProperties(response.data);
    response.data.customer.serviceTypes = [types];
    dispatch(updateUserProfile(response.data));
  });
};
