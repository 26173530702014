import * as actionTypes from "../actionTypes";

export const updateJointAccountHolder = (JointAccountHolderInfo) => {
  return {
    type: actionTypes.UPDATE_JOINT_ACCOUNT_HOLDER,
    JointAccountHolderInfo,
  };
};

export const updateAuthorizedPerson = (AuthorizedPersonInfo) => {
  return {
    type: actionTypes.UPDATE_AUTHORISED_PERSON,
    AuthorizedPersonInfo,
  };
};
