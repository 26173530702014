import * as actionTypes from "../../Actions/actionTypes";

const initialState = {
  Customer: {
    CustomerInfo: {
      Title: "",
      FirstName: "",
      MiddleName: "",
      LastName: "",
      EmailAddress: "",
      PhoneNumber: "",
      DateOfBirth: null,
      AccountNumber: "",
      PostalAddress: "",
      CurrentAddress: "",
      IsStandard: true,
      InteractionCategory: "",
      InteractionNotes: "",
      BPUpdated: false,
    },
    HasDriversLicense: false,
    DriversLicense: {
      Number: "",
      Version: "",
    },
  },
  Property: {
    PropertyInfo: {
      Address: null,
      QASAddress: null,
      AddressSameAsPostalAddress: true,
      Moniker: null,
      ElectricityMeterType: null,
      ElectricityICP: null,
      GasICP: null,
      ElectricityUserType: "",
      Hazards: [],
      HazardsOther: null,
      HasDogs: false,
      WithAnotherSupplier: false,
      OwnsProperty: false,
      IsBach: false,
      HaveFinalMeterReading: false,
      FinalElectricityMeterRead: null,
      FinalGasMeterRead: null,
      SendMeterReader: false,
    },
    MedicalInfo: {
      HasMedicalDependant: false,
      CriticalMedicalEquipment: "",
      MedicalDependantFirstName: "",
      MedicalDependantLastName: "",
      MedicalDependantEMailAddress: "",
      MedicalDependantPhone: "",
      MedicalDependantSameAsAccountHolder: false,
      ContactHealthPractitioner: false,
      PracticeName: "",
      PracticePhone: "",
      PractitionerName: "",
      HasVulnerablePerson: false,
    },
    MoveInfo: {
      IsMovingHouse: false,
      AccountStart: null,
      MoveInDate: null,
      MoveOutDate: null,
      MoveFromAddress: null,
      Situation: null,
      SituationOtherDetails: "",
    },
  },
  BroadBandInfo: {
    BroadbandId: "",
    BroadbandFullName: "",
    HasBroadbandProvider: false,
    BroadbandProvider: "",
    BroadbandProviderOther: null,
    AccountHolderName: "",
    AccountNumber: "",
    ModemDeliveredToSameAddress: true,
    DeliveryAddress: "",
    Moniker: "",
    AddPhoneLine: false,
    HomePhoneNumber: "",
    PhoneLineOptions: [],
  },
  LPGInfo: {
    OrderBottledGas: false,
    NumberOfBottles: 0,
    DeliveryAddress: "",
    DeliveryInstructions: "",
    PaperlessStatements: false,
    BottledGasUsage: [],
  },
  PaymentInfo: {
    PaymentMethod: null,
    PrePayMobileNumber: null,
    PaperlessDiscount: false,
    JoinDirectDebit: false,
    DirectDebitDetails: null,
    AaSmartFuel: null,
    HasAaSmartFuel: false,
    PromoCode: null,
    BillingCycle: null,
    BillStartDate: null,
  },
  error: null,
  isSubmitting: false,
  success: false,
  successResponse: null,
  successMessage: null,
  state: null,
};

const updateCustomerData = (state, customerData) => {
  return { ...state, ...customerData };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CUSTOMER_DATA:
      return updateCustomerData(state, action.customerData);
    case actionTypes.SAVE_FOR_LATER_SUBMITTING:
      return {
        ...state,
        error: null,
        isSubmitting: true,
        success: false,
        successResponse: null,
        successMessage: null,
        state: null,
      };
    case actionTypes.SAVE_FOR_LATER_SUCCESS:
      return {
        ...state,
        error: null,
        isSubmitting: false,
        success: true,
        successResponse: action.successResponse,
        successMessage: action.successMessage,
        state: action.state,
      };
    case actionTypes.SAVE_FOR_LATER_ERROR:
      return {
        ...state,
        error: action.error,
        isSubmitting: false,
        success: false,
        successResponse: null,
        successMessage: null,
        state: null,
      };
    case actionTypes.SAVE_FOR_LATER_INITIAL_STATE:
      return {
        ...state,
        error: null,
        isSubmitting: false,
        success: false,
        successResponse: null,
        successMessage: null,
        state: null,
      };
    default:
      return state;
  }
};

export default reducer;
