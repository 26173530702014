// @flow
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import {
  ArrowDown,
  Checkbox,
  ExpandableCard,
  Input,
  CheckboxList,
  AutoCompleteInput,
} from "react-components";
import "../../../../Containers/BroadbandOrder/BroadbandOrder.scss";

import ServiceAddress from "../../../../Containers/FindPlan/Address";

import { Broadband as Constants } from "../../../../Config/Constants";
import { saveCustomerData } from "../../../../Redux/Actions/CSRAgent/JoinAction";
import { useStateFromProp } from "../../../../Utils/hooks";
import { getPropertyAddress } from "../../../../Utils/selectors";
import { triggerAgentPageView } from "../../../../Utils/analytics";

type Props = {
  hasBroadbandProvider?: boolean,
  broadbandProvider?: string,
  accountHolderName?: string,
  accountNumber?: string,
  modemDeliveredToSameAddress?: boolean,
  deliveryAddress?: string,
  addPhoneLine?: boolean,
  homePhoneNumber?: string,
  selectedPhoneLineOptions?: array,
  moniker?: string,
  isCurrentCardOpen?: boolean,
  isCardDisabled?: boolean,
  validate?: boolean,
  saveBroadbandData?: () => void,
  onCardClick?: () => void,
  onClick: () => void,
  setValid: () => {},
};

export const BroadbandOrder = (props: Props) => {
  const [hasBroadbandProvider, setHasBroadbandProvider] = useStateFromProp(
    props.hasBroadbandProvider,
    false
  );
  const [broadbandProvider, setBroadbandProvider] = useStateFromProp(
    props.broadbandProvider,
    ""
  );
  const [broadbandProviderHasError, setBroadbandProviderHasError] = useState(
    false
  );
  const [accountHolderName, setAccountHolderName] = useStateFromProp(
    props.accountHolderName,
    ""
  );
  const [accountHolderNameHasError, setAccountHolderNameHasError] = useState(
    false
  );
  const [accountNumber, setAccountNumber] = useStateFromProp(
    props.accountNumber,
    ""
  );
  const [accountNumberHasError, setAccountNumberHasError] = useState(false);
  const [
    modemDeliveredToSameAddress,
    setModemDeliveredToSameAddress,
  ] = useStateFromProp(props.modemDeliveredToSameAddress, true);
  const [currentDeliveryAddress, setCurrentDeliveryAddress] = useStateFromProp(
    props.currentDeliveryAddress,
    ""
  );
  const [
    currentDeliveryAddressHasError,
    setCurrentDeliveryAddressHasError,
  ] = useState(false);
  const [moniker, setMoniker] = useStateFromProp(props.moniker, "");
  const [addPhoneLine, setAddPhoneLine] = useStateFromProp(
    props.addPhoneLine,
    false
  );
  const [homePhoneNumber, setHomePhoneNumber] = useStateFromProp(
    props.homePhoneNumber,
    ""
  );
  const [homePhoneNumberHasError, setHomePhoneNumberHasError] = useState(false);
  const [phoneLineOptions, setPhoneLineOptions] = useState(
    Constants.addonsOptions.map((o) => ({ ...o, checked: false }))
  );
  const [validateItself, setValidateItself] = useState(false);

  const providerOptions = Constants.providerList.map((provider) => ({
    name: provider,
  }));

  const [cardOpen, setCardOpen] = useState(true);

  const isValidDeliveryAddress =
    currentDeliveryAddress && !currentDeliveryAddressHasError;
  const invalidBroadbandProvider =
    hasBroadbandProvider &&
    (!broadbandProvider ||
      broadbandProviderHasError ||
      !accountHolderName ||
      accountHolderNameHasError ||
      !accountNumber ||
      accountNumberHasError);
  const invalidModemDelivery =
    !modemDeliveredToSameAddress && !isValidDeliveryAddress;

  const [checkboxCheckStarted, setCheckboxCheckStarted] = useState(false);

  const [focusedInput, setFocusedInput] = useState("");

  const [changeMade, setChangeMade] = useState(false);
  const [pageViewed, setPageViewed] = useState(false);

  useEffect(() => {
    if (props.validate) {
      setValidateItself(true);
      setCardOpen(true);
    }
  }, [props.validate]);

  useEffect(() => {
    const newPhoneLineOptions = Constants.addonsOptions.map((addon) => {
      return {
        ...addon,
        checked: props.selectedPhoneLineOptions.includes(addon.code),
      };
    });
    setPhoneLineOptions(newPhoneLineOptions);
  }, [props.selectedPhoneLineOptions]);

  useEffect(() => {
    if (invalidBroadbandProvider || invalidModemDelivery) {
      props.setValid("BroadbandOrder", false);
    } else {
      props.setValid("BroadbandOrder", true);
    }
    // eslint-disable-next-line
  }, [invalidBroadbandProvider, invalidModemDelivery]);

  useEffect(() => {
    if (
      (broadbandProvider || currentDeliveryAddress) &&
      (changeMade || checkboxCheckStarted)
    ) {
      saveData(null, true);
    }
    // eslint-disable-next-line
  }, [currentDeliveryAddress, broadbandProvider]);

  useEffect(() => {
    if (checkboxCheckStarted) {
      saveData(null, true);
    }
    // eslint-disable-next-line
  }, [hasBroadbandProvider, modemDeliveredToSameAddress, addPhoneLine]);

  useEffect(() => {
    if (currentDeliveryAddress && !props.deliveryAddress) {
      saveData("deliveryAddress", false);
    }
    // eslint-disable-next-line
  }, [currentDeliveryAddress, props.deliveryAddress]);

  const saveData = (input, checkbox) => {
    if (!pageViewed) {
      triggerAgentPageView("/about-your-broadband-order");
      setPageViewed(true);
    }
    const broadbandId = props.broadbandId;
    const broadbandFullName = props.broadbandFullName;
    if (input === focusedInput || checkbox || input === "deliveryAddress") {
      props.saveCustomerData({
        hasBroadbandProvider,
        broadbandProvider,
        accountHolderName,
        accountNumber,
        modemDeliveredToSameAddress,
        currentDeliveryAddress,
        moniker,
        addPhoneLine,
        homePhoneNumber,
        phoneLineOptions,
        broadbandId,
        broadbandFullName,
      });
    }
  };

  // The following 2 checkboxs are mutually exclusive. (Landline to Mobile call 100 minutes, Landline to Mobile call 200 minutes)
  const phoneLineOptionsChangedHandler = (index) => {
    if (index === 0) {
      const newPhoneLineOptions = [...phoneLineOptions];
      newPhoneLineOptions[0].checked = !newPhoneLineOptions[0].checked;
      setPhoneLineOptions(newPhoneLineOptions);
    } else {
      setPhoneLineOptions(
        phoneLineOptions.map((opt, idx) =>
          idx === 0
            ? opt
            : idx === index
            ? { ...opt, checked: !opt.checked }
            : { ...opt, checked: false }
        )
      );
    }
    setCheckboxCheckStarted(true);
  };

  const hasBroadbandProviderCheckboxChangeHandler = () => {
    setHasBroadbandProvider(!hasBroadbandProvider);
    setBroadbandProvider("");
    setBroadbandProviderHasError(false);
    setAccountHolderName("");
    setAccountHolderNameHasError(false);
    setAccountNumber("");
    setAccountNumberHasError(false);
    setCheckboxCheckStarted(true);
  };

  const modemDeliveredToSameAddressCheckboxChangeHandler = () => {
    setModemDeliveredToSameAddress(!modemDeliveredToSameAddress);
    setCurrentDeliveryAddress("");
    setCurrentDeliveryAddressHasError(false);
    setMoniker(null);
    setCheckboxCheckStarted(true);
  };

  const addPhoneLineCheckboxChangeHandler = () => {
    setAddPhoneLine(!addPhoneLine);
    setHomePhoneNumber("");
    setPhoneLineOptions(
      phoneLineOptions.map((opt) => ({ ...opt, checked: false }))
    );
    setCheckboxCheckStarted(true);
  };

  const handleManualChange = (address) => {
    setCurrentDeliveryAddress(address);
    saveData("deliveryAddress", false);
  };

  const getContent = (
    <div className="broadbandOrder">
      <div className="field">
        <Checkbox
          name="hasBroadbandProvider"
          label={Constants.hasBroadbandProviderLabel}
          checked={hasBroadbandProvider}
          onChange={hasBroadbandProviderCheckboxChangeHandler}
        />
      </div>
      {hasBroadbandProvider && (
        <Fragment>
          <div className="subItem">
            <div className="field">
              <AutoCompleteInput
                name="broadbandProvider"
                handleChange={(value) => {
                  setBroadbandProvider(value);
                  setChangeMade(true);
                }}
                value={broadbandProvider}
                options={providerOptions}
                labelText={Constants.broadbandProviderLabel}
                maxLength={Constants.broadbandProviderMaxLength}
                handleError={setBroadbandProviderHasError}
                errorMessage={Constants.broadbandProviderRequiredMsg}
                hasError={broadbandProviderHasError}
                required
                validateItself={validateItself}
              />
            </div>
          </div>
          <div className="row subItem">
            <div className="field">
              <Input
                name="accountHolderName"
                handleChange={(value) => setAccountHolderName(value)}
                value={accountHolderName}
                labelText={Constants.accountHolderNameLabel}
                maxLength={Constants.accountHolderNameLabelMaxLength}
                handleError={setAccountHolderNameHasError}
                errorMessage={Constants.accountHolderNameRequiredMsg}
                hasError={accountHolderNameHasError}
                required
                validateItself={validateItself}
                inputProps={{
                  onFocus: () => setFocusedInput("accountHolderName"),
                }}
                onBlur={() => saveData("accountHolderName", false)}
              />
            </div>
            <div className="field">
              <Input
                name="accountNumber"
                handleChange={(value) => setAccountNumber(value)}
                value={accountNumber}
                labelText={Constants.accountNumberLabel}
                handleError={setAccountNumberHasError}
                errorMessage={Constants.accountNumberRequiredMsg}
                invalidMessage={Constants.accountNumberInvalidMsg}
                hasError={accountNumberHasError}
                validationType="broadbandAccountNumber"
                required
                validateItself={validateItself}
                maxLength={Constants.accountNumberMaxLength}
                inputProps={{
                  onFocus: () => setFocusedInput("accountNumber"),
                }}
                onBlur={() => saveData("accountNumber", false)}
              />
            </div>
          </div>
        </Fragment>
      )}

      <div className="field">
        <Checkbox
          name="modemDeliveredToSameAddress"
          label={Constants.modemDeliveredToDifferentAddressLabel}
          subLabel={Constants.modemDeliveredToDifferentAddressSubLabel}
          checked={!modemDeliveredToSameAddress}
          onChange={modemDeliveredToSameAddressCheckboxChangeHandler}
        />
      </div>

      {!modemDeliveredToSameAddress && (
        <div className="subItem">
          <div className="field">
            <ServiceAddress
              serviceAddress={currentDeliveryAddress}
              handleAddressChange={setCurrentDeliveryAddress}
              handleManualChange={handleManualChange}
              hasError={currentDeliveryAddressHasError}
              handleError={setCurrentDeliveryAddressHasError}
              loaderCustomStyle={{
                background: "#fff",
              }}
              required
              onIcpSelect={() => {}}
              icp=""
              setHasNoIcp={() => {}}
              setAddressDetails={() => {}}
              setMoniker={setMoniker}
              validateItself={validateItself}
              noIcpNeeded
            />
          </div>
        </div>
      )}

      <div className="field">
        <Checkbox
          name="addPhoneLine"
          label={Constants.addPhoneLineLabel}
          subLabel={Constants.addPhoneLineSubLabel}
          checked={addPhoneLine}
          onChange={addPhoneLineCheckboxChangeHandler}
        />
      </div>
      {addPhoneLine && (
        <Fragment>
          <div className="subItem">
            <div className="field">
              <Input
                name="homePhoneNumber"
                handleChange={(value) => setHomePhoneNumber(value)}
                value={homePhoneNumber}
                placeholder={Constants.homePhoneNumberPlaceholder}
                labelText={Constants.homePhoneNumberLabel}
                handleError={setHomePhoneNumberHasError}
                invalidMessage={Constants.homePhoneNumberInvalidMsg}
                hasError={homePhoneNumberHasError}
                validationType="phoneNumber"
                inputProps={{
                  onFocus: () => setFocusedInput("homePhoneNumber"),
                }}
                onBlur={() => saveData("homePhoneNumber", false)}
              />
            </div>
          </div>
          <div className="subItem">
            <div className="broadbandOrder_checkbox">
              <CheckboxList
                name="phoneLineOptions"
                title={Constants.phoneLineOptionsLabel}
                onChange={phoneLineOptionsChangedHandler}
                options={phoneLineOptions}
              />
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );

  const getPreview = (
    <div>
      {broadbandProvider && (
        <div className="field">
          {Constants.previewBroadbandProvider}{" "}
          <span className="bold">{broadbandProvider}</span>
        </div>
      )}

      {accountHolderName && (
        <div className="field">
          {Constants.previewAccountHolderName}{" "}
          <span className="bold">{accountHolderName}</span>
        </div>
      )}

      {accountNumber && (
        <div className="field">
          {Constants.previewAccountNumber}{" "}
          <span className="bold">{accountNumber}</span>
        </div>
      )}

      <div className="field">
        {Constants.previewDeliveryAddress}
        <span className="bold">
          {isValidDeliveryAddress
            ? currentDeliveryAddress
            : props.customerAddress}
        </span>
      </div>

      {homePhoneNumber && (
        <div className="field">
          {Constants.previewHomePhoneNumber}{" "}
          <span className="bold">{homePhoneNumber}</span>
        </div>
      )}

      {phoneLineOptions &&
        phoneLineOptions.map(
          (opt, index) =>
            opt.checked && (
              <div className="field" key={index}>
                {Constants.previewPhoneLineOptions}{" "}
                <span className="bold">{opt.label}</span>
              </div>
            )
        )}
    </div>
  );

  const getHeader = {
    cardTitle: Constants.cardTitle,
    cardContent: getPreview,
    cardIcon: <ArrowDown />,
    cardIsSelected: false,
    cardLink: true,
  };

  return (
    <Fragment>
      <div id="BroadbandOrder" className="cardId" />
      <ExpandableCard
        name="broadbandOrderCard"
        headerContent={getHeader}
        content={getContent}
        isOpen={cardOpen}
        disabled={props.isCardDisabled}
        handleClick={() => setCardOpen(!cardOpen)}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  hasBroadbandProvider: state.BroadbandInfo.HasBroadbandProvider,
  broadbandProvider: state.BroadbandInfo.BroadbandProvider
    ? state.BroadbandInfo.BroadbandProvider
    : state.BroadbandInfo.BroadbandProviderOther,
  accountHolderName: state.BroadbandInfo.AccountHolderName,
  accountNumber: state.BroadbandInfo.AccountNumber,
  modemDeliveredToSameAddress: state.BroadbandInfo.ModemDeliveredToSameAddress,
  currentDeliveryAddress: state.BroadbandInfo.DeliveryAddress,
  addPhoneLine: state.BroadbandInfo.AddPhoneLine,
  homePhoneNumber: state.BroadbandInfo.HomePhoneNumber,
  selectedPhoneLineOptions: state.BroadbandInfo.PhoneLineOptions,
  customerAddress: getPropertyAddress(state),
  broadbandId: state.BroadbandInfo.Id,
  broadbandFullName: state.BroadbandInfo.Name,
});

const mapDispatchToProps = (dispatch) => {
  return {
    saveCustomerData: (values) => {
      const details = {
        BroadbandInfo: {
          BroadbandId: values.broadbandId,
          BroadbandFullName: values.broadbandFullName,
          HasBroadbandProvider: values.hasBroadbandProvider,
          BroadbandProvider: Constants.providerList.includes(
            values.broadbandProvider
          )
            ? values.broadbandProvider
            : "",
          BroadbandProviderOther: Constants.providerList.includes(
            values.broadbandProvider
          )
            ? ""
            : values.broadbandProvider,
          AccountHolderName: values.accountHolderName,
          AccountNumber: values.accountNumber,
          ModemDeliveredToSameAddress: values.modemDeliveredToSameAddress,
          DeliveryAddress: values.currentDeliveryAddress,
          Moniker: values.moniker,
          AddPhoneLine: values.addPhoneLine,
          HomePhoneNumber: values.homePhoneNumber,
          PhoneLineOptions: values.phoneLineOptions
            .filter((option) => option.checked)
            .map((option) => option.code),
          PhoneLineOptionsFullName: values.phoneLineOptions
            .filter((option) => option.checked)
            .map((option) => option.label),
        },
      };
      dispatch(saveCustomerData(details));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BroadbandOrder);
