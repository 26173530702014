// @flow
import React from "react";
import { withRouter } from "react-router-dom";
import "./GoBack.scss";

type Props = {
  backToText: String,
  onClick: () => {},
};

const GoBack = (props: Props) => {
  return (
    <div className="go-back">
      <div className="go-back_link-item" onClick={props.onClick}>
        {props.backToText ? props.backToText : "GO BACK"}
      </div>
    </div>
  );
};

export default withRouter(GoBack);
