// @flow
import * as actionTypes from "../../Actions/actionTypes";

export const reducerType = "AgentLogin";

const initialState = {
  isLoggedIn: false,
  token: null,
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case actionTypes.UPDATE_AGENT_TOKEN:
      return {
        ...state,
        token: action.payload,
        isLoggedIn: true,
      };
    default:
      return state;
  }
};
