// @flow
import isEmpty from "lodash/isEmpty";
import Cookies from "js-cookie";

export const setPlanValue = (
  service,
  list,
  setList,
  setSelectedPlan,
  setSelectedPlanIndex,
  setNoteList,
  setSelectedBBPlan,
  setGasUsageList,
  isInList,
  selectedPlan,
  constants,
  selectedServiceList
) => {
  let newList = [];
  const dependantPlan = selectedPlan?.Services.find(
    (item) => item.mandatory && item.id === service
  );
  if (list.includes(service)) {
    newList = list.filter((item) => item !== service);
    if (!isEmpty(dependantPlan)) {
      setSelectedPlan(null);
      setSelectedPlanIndex(null);
      if (service === "BB") {
        setNoteList(constants.bbRemoveNote, true);
      } else if (service === "GAS") {
        setNoteList(constants.simplicityRemoveNote, true);
      }
    }
    if (service === "BB") {
      setSelectedBBPlan(null);
    } else if (service === "LPG") {
      setGasUsageList([]);
    } else if (service === "ELEC") {
      const hasBB = newList.includes("BB");
      const hasLPG = newList.includes("LPG");
      if (hasBB) {
        newList = newList.filter((item) => item !== "BB");
        setNoteList(constants.elecNeededNote, true);
      }
      if (hasLPG) {
        setNoteList(constants.onlyLPGNote, false);
      }
      setSelectedPlan(null);
      setSelectedPlanIndex(null);
    }
  } else {
    newList.push(...list, service);
    if (service === "LPG") {
      if (isInList("GAS", selectedServiceList)) {
        newList = newList.filter((item) => item !== "GAS");
        setNoteList(constants.bothGasNote, true);
      }
      if (newList.length === 1) {
        setNoteList(constants.onlyLPGNote, false);
      }
    }
    if (service === "GAS") {
      if (isInList("LPG", selectedServiceList)) {
        newList = newList.filter((item) => item !== "LPG");
        setNoteList(constants.bothGasNote, true);
      }
      newList = newList.filter((item) => item !== "LPG");
      setGasUsageList([]);
    }
    if (service === "BB") {
      const hasElec = newList.includes("ELEC");
      if (!hasElec) {
        newList.push("ELEC");
        setNoteList(constants.elecNeededNote, true);
      }
      setList(newList);
    }
  }
  setList(newList);
};

export const callForSession = () => {
  getSessionASP();
};

const getSessionASP = () => {
  const savedSession = Cookies.get("ASPNETSessionThirdParty");
  if (savedSession) {
    Cookies.set("ASP.NET_SessionId", savedSession);
    return savedSession;
  } else {
    callForSession();
  }
};
