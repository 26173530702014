// @flow
import React, { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { ArrowDown, Checkbox, ExpandableCard, HTML } from "react-components";

import "../../../../Containers/FinalStep/FinalStep.scss";

import { FinalStep as Constants } from "../../../../Config/Constants";
import { saveCustomerData } from "../../../../Redux/Actions/CSRAgent/JoinAction";
import { triggerAgentPageView } from "../../../../Utils/analytics";
import {
  getHasBottledGas,
  getHasBroadband,
  getHasElectricity,
  getHasPipedGas,
  getHasReticulatedGas,
} from "../../../../Utils/selectors";

type Props = {
  hasElectricity: boolean,
  hasPipedGas: boolean,
  hasBottledGas: boolean,
  hasBroadband: boolean,
  hasReticulatedGas: boolean,
  isCurrentCardOpen?: boolean,
  isCardDisabled?: boolean,
  validate?: boolean,
  onCardClick?: () => void,
  setValid: () => {},
};

export const FinalStep = (props: Props) => {
  const {
    hasElectricity,
    hasPipedGas,
    hasBottledGas,
    hasBroadband,
    hasReticulatedGas,
  } = props;

  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(
    false
  );
  const [validateItself, setValidateItself] = useState(false);
  const [pageViewed, setPageViewed] = useState(false);
  let clickingTermAndConditionsLink = false;

  useEffect(() => {
    if (props.validate) {
      setValidateItself(true);
    }
  }, [props.validate]);

  useEffect(() => {
    if (acceptTermsAndConditions) {
      props.setValid("FinalStep", true);
    } else {
      props.setValid("FinalStep", false);
    }
    // eslint-disable-next-line
  }, [acceptTermsAndConditions]);

  useEffect(() => {
    if (!pageViewed) {
      triggerAgentPageView("/final-step");
    }
    setPageViewed(true);
    if (acceptTermsAndConditions) {
      props.saveCustomerData(
        hasElectricity,
        hasPipedGas,
        hasBroadband,
        hasBottledGas,
        hasReticulatedGas
      );
      props.onClick();
    }
    // eslint-disable-next-line
  }, [acceptTermsAndConditions]);

  const termAndConditionsCheckboxOnChange = () => {
    if (!clickingTermAndConditionsLink) {
      setAcceptTermsAndConditions(!acceptTermsAndConditions);
    }
    clickingTermAndConditionsLink = false;
  };

  const getTermsAndConditionsLabel = (termsAndConditions, preview) => (
    <span>
      {termsAndConditions.label}{" "}
      {preview ? (
        termsAndConditions.labelWithLink
      ) : (
        <a
          name={termsAndConditions.label.split(" ").splice(-1)}
          href={
            process.env.REACT_APP_PDF_BASE_URL +
            termsAndConditions.file +
            "?t=" +
            new Date().getTime()
          }
          download
          rel="noopener noreferrer"
          onClick={() => (clickingTermAndConditionsLink = true)}
        >
          {termsAndConditions.labelWithLink}
        </a>
      )}
      {", "}
    </span>
  );

  const fullTermsAndConditionsLabel = (preview) => (
    <Fragment>
      <span>{Constants.termsAndConditionsPrefix}</span>
      {(hasElectricity || (hasPipedGas && !hasReticulatedGas)) &&
        getTermsAndConditionsLabel(
          Constants.generalTermsAndConditions,
          preview
        )}
      {(hasElectricity || (hasPipedGas && !hasReticulatedGas)) &&
        getTermsAndConditionsLabel(Constants.planTermsAndConditions, preview)}
      {hasBroadband &&
        getTermsAndConditionsLabel(
          Constants.broadbandTermsAndConditions,
          preview
        )}
      {hasBottledGas &&
        getTermsAndConditionsLabel(
          Constants.bottledGasTermsAndConditions,
          preview
        )}
      {hasReticulatedGas &&
        getTermsAndConditionsLabel(
          Constants.pipedGasTermsAndConditions,
          preview
        )}
      <span>{Constants.termsAndConditionsSuffix}</span>
    </Fragment>
  );

  const getContent = (
    <div className="finalStep">
      <HTML html={Constants.privacyStatementTitle} />
      <br />
      <HTML html={Constants.privacyStatementContent} />
      <br />
      <HTML html={Constants.termsAndConditionsTitle} />
      <br />
      <div className="finalStep_checkbox">
        <Checkbox
          name="acceptTermsAndConditions"
          className="accept-terms-and-conditions-checkbox"
          label={fullTermsAndConditionsLabel(false)}
          checked={acceptTermsAndConditions}
          onChange={termAndConditionsCheckboxOnChange}
          required
          validateItself={validateItself}
        />
      </div>
      <br />
      <HTML html={Constants.welcomePackContent} />
    </div>
  );

  const getPreview = (
    <div>
      {acceptTermsAndConditions && (
        <div className="field">
          <span className="bold">{fullTermsAndConditionsLabel(true)}</span>
        </div>
      )}
    </div>
  );

  const getHeader = {
    cardTitle: Constants.cardTitle,
    cardContent: getPreview,
    cardIcon: <ArrowDown />,
    cardIsSelected: false,
    cardLink: true,
  };

  return (
    <Fragment>
      <div id="FinalStep" className="cardId" />
      <ExpandableCard
        name="finalStepCard"
        headerContent={getHeader}
        content={getContent}
        isOpen={props.isCurrentCardOpen}
        disabled={props.isCardDisabled}
        handleClick={props.onCardClick}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  hasElectricity: getHasElectricity(state),
  hasPipedGas: getHasPipedGas(state),
  hasBottledGas: getHasBottledGas(state),
  hasBroadband: getHasBroadband(state),
  hasReticulatedGas: getHasReticulatedGas(state),
  customer: state.Customer,
  creditCheckResult: state.CreditCheck?.data?.result,
});

const mapDispatchToProps = (dispatch) => ({
  saveCustomerData: async (
    hasElectricity,
    hasPipedGas,
    hasBroadband,
    hasBottleGas,
    hasReticulatedGas,
    creditCheckResult
  ) => {
    const details = {
      Confirmation: {
        AcceptGeneralTermsAndConditions:
          hasElectricity || (hasPipedGas && !hasReticulatedGas),
        AcceptPlanTermsAndConditions:
          hasElectricity || (hasPipedGas && !hasReticulatedGas),
        AcceptBroadbandTermsAndConditions: hasBroadband,
        AcceptBottledGasTermsAndConditions: hasBottleGas,
        AcceptPipedGasTermsAndConditions: hasReticulatedGas,
        JourneyType: "Join",
        CreditCheck: !!creditCheckResult,
      },
    };
    await dispatch(saveCustomerData(details));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FinalStep);
