// @flow
import { createSelector } from "reselect";
import { getPostcodeFromAddress, isSouthIslandPostcode } from "./address";
import {
  SERVICE_ID_ELECTRICITY,
  SERVICE_ID_PIPED_GAS,
  SERVICE_ID_LPG,
  SERVICE_ID_BROADBAND,
  ELIGIBLE_PERIODIC,
  ELIGIBLE_PREPAY,
  SERVICE_ELEC,
} from "../Config/Constants";
import { isPrepay } from "../Containers/Payment/utils";

/**
 * Returns true, if session id present.
 * @param state
 * @return {boolean}
 */
export const getHasSession = (state): boolean => Boolean(state.Auth.sessionID);

/**
 * Returns true, if there has been an error.
 * @param state
 * @return {boolean}
 */
export const getHasError = (state): boolean => Boolean(state.Auth.hasError);

/**
 * Selector
 * Returns customer's address.
 * @param state
 * @return {string}
 */
export const getPropertyAddress = (state): string =>
  (state.Property?.PropertyInfo && state.Property?.PropertyInfo?.Address) ||
  undefined;

/**
 * Selector
 * Returns customer's postal address.
 * @param state
 * @return {string}
 */
export const getPostalAddress = (state): string =>
  (state.Customer?.CustomerInfo &&
    (state.Customer?.CustomerInfo.PostalAddress ||
      state.Customer?.CustomerInfo.CurrentAddress)) ||
  undefined;

/**
 * Returns true, if customer residential.
 * @todo
 * @param state
 * @return {boolean}
 */
export const getIsResidential = (state): boolean => true;

/**
 * Selector
 * Returns and array of service ids.
 * @param state
 * @return {Array<string>}
 */
export const getServices = (state): Array<string> => state.Services || [];

/**
 * Selector
 * Returns true, if service selected.
 * @param serviceId {string}
 * @return {boolean}
 */
const getHasService = (serviceId: string) =>
  createSelector(
    getServices,
    (services) => services && services.includes(serviceId)
  );
export const getHasElectricity = getHasService(SERVICE_ID_ELECTRICITY);
export const getHasPipedGas = getHasService(SERVICE_ID_PIPED_GAS);
export const getHasBottledGas = getHasService(SERVICE_ID_LPG);
export const getHasBroadband = getHasService(SERVICE_ID_BROADBAND);

export const getIsElectricityOnly = createSelector(
  getServices,
  getHasElectricity,
  (services, hasElectricity) => hasElectricity && services.length === 1
);

export const getIsBottleGasOnly = createSelector(
  getServices,
  getHasBottledGas,
  (services, hasBottledGas) => hasBottledGas && services.length === 1
);

/**
 * Selector
 * Returns customer's postcode.
 * @returns {string}
 */
export const getPostCode = createSelector(
  getPropertyAddress,
  (address) => address && getPostcodeFromAddress(address)
);

/**
 * Selector
 * Returns true, if customer is from south island.
 * @returns {boolean}
 */
export const getIsSouthIsland = createSelector(getPostCode, (postcode) =>
  isSouthIslandPostcode(postcode)
);

/**
 * Selector
 * Returns true, if customer is from south island and has selected piped gas.
 * @returns {boolean}
 */
export const getHasReticulatedGas = createSelector(
  getHasPipedGas,
  getIsSouthIsland,
  (hasPipedGas, isSouthIsland) => hasPipedGas && isSouthIsland
);

/**
 * Selector
 * Returns current plan id
 * @param state
 * @return {string}
 */
export const getPlanId = (state): string =>
  (state.Readonly.PlanInfo && state.Readonly.PlanInfo.PlanId) || undefined;

/**
 * Selector
 * Returns true, if bach plan selected.
 * @returns {boolean}
 */
export const getIsBachPlan = createSelector(
  getPlanId,
  (planId) => planId && planId.toLowerCase() === "bach"
);

/**
 * Returns true, if fuel rewards plan has been chosen.
 * @param state
 * @return {boolean}
 */
export const getIsFuelRewards = (state): boolean =>
  Boolean(
    state.Readonly?.ApiPlan &&
      state.Readonly?.ApiPlan.Filters &&
      state.Readonly?.ApiPlan.Filters.includes("AA")
  );

/**
 * Returns true, if customer eligible for periodic payments.
 * @param state
 * @return {boolean}
 */
export const getIsBillingCycleEligible = (state): boolean =>
  Boolean(
    state.Readonly?.Eligibilities &&
      state.Readonly?.Eligibilities.find(
        ({ eligible, name }) => name === ELIGIBLE_PERIODIC && eligible
      )
  );

/**
 * Returns true, if customer eligible for prepay.
 * @param state
 * @return {boolean}
 */
export const getIsPrepayEligible = (state): boolean =>
  Boolean(
    state.Readonly?.Eligibilities &&
      state.Readonly?.Eligibilities.find(
        ({ eligible, name }) => name === ELIGIBLE_PREPAY && eligible
      )
  );

/**
 * Selector
 * Returns services start date.
 * @param state
 * @return {string}
 */
export const getServicesStartDate = (state): string =>
  (state.Property.MoveInfo && state.Property.MoveInfo.AccountStart) ||
  undefined;

/**
 * Returns true, if a new residential customer.
 * @param state
 * @return {boolean}
 */
export const getIsSwitchingSupplier = (state): boolean =>
  Boolean(
    state.Property.PropertyInfo &&
      state.Property.PropertyInfo.WithAnotherSupplier
  );

// TODO when smart meter for move in address is ready (Success) If Electricity only order and meter type is Smart meter
export const getHasSmartMeter = (state): boolean => false;

/**
 * Selector
 * Returns true, if customer has selected prepay as payment method.
 * @param state
 * @return {boolean}
 */
export const getIsPaymentPrepay = (state): boolean =>
  isPrepay(state.Payment.PaymentMethod);

/**
 * Selector
 * Returns true, if customer has ordered bottled gas.
 * @param state
 * @return {boolean}
 */
export const getHasOrderBottledGas = (state): boolean => {
  return (state.LpgInfo && state.LpgInfo.OrderBottledGas) || false;
};

/**
 * Selector
 * Returns meters object.
 * @param state
 * @return {object}
 */
export const getMeters = (state) => {
  const meters = [];
  state.contracts.forEach((contract) => {
    contract.devices.forEach((device) => {
      device.registers.forEach((register) => {
        meters.push({
          meter: device.id,
          reading: register.PreviousMeterReading,
        });
      });
    });
  });
  return meters;
};

/**
 * Selector
 * Returns bool.
 * @param contracts
 * @return {boolean}
 */
export const getIsDualEnergyCustomer = (services) => {
  return !!services.filter((service) => service !== SERVICE_ELEC).length;
};

/**
 * Selector
 * Returns customer's service address.
 * @param state
 * @return {string}
 */
export const getServiceAddress = (state): string =>
  state.Customer?.CustomerInfo && state.Customer?.CustomerInfo.ServiceAddress;
