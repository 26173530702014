import React, { useState, useEffect, Fragment } from "react";
import { Property as Constants } from "../../Config/Constants";
import { Modal, Checkbox, Input } from "react-components";
// TODO: use medical dependency component for property

type Props = {
  handleChange: () => void,
  handleError: () => {},
  validateItself: boolean,
};

export const MedicalDependency = (props: Props) => {
  const { handleChange, handleError, validateItself } = props;

  // Vulnerable Person
  const [hasVulnerablePerson, setHasVulnerablePerson] = useState(false);
  const [
    hasVulnerablePersonModalActive,
    setHasVulnerablePersonModalActive,
  ] = useState(false);

  // Medical Dependant
  const [hasMedicalDependant, setHasMedicalDependant] = useState(false);
  const [
    medicalDependantDifferentAsAccountHolder,
    setMedicalDependantDifferentAsAccountHolder,
  ] = useState(false);
  const [medicalDependantFirstName, setMedicalDependantFirstName] = useState(
    ""
  );
  const [
    medicalDependantFirstNameHasError,
    setMedicalDependantFirstNameHasError,
  ] = useState(false);
  const [medicalDependantLastName, setMedicalDependantLastName] = useState("");
  const [
    medicalDependantLastNameHasError,
    setMedicalDependantLastNameHasError,
  ] = useState(false);
  const [medicalDependantPhone, setMedicalDependantPhone] = useState("");
  const [
    medicalDependantPhoneHasError,
    setMedicalDependantPhoneHasError,
  ] = useState(false);
  const [
    medicalDependantEMailAddress,
    setMedicalDependantEMailAddress,
  ] = useState("");
  const [
    medicalDependantEMailAddressHasError,
    setMedicalDependantEMailAddressHasError,
  ] = useState(false);
  const [criticalMedicalEquipment, setCriticalMedicalEquipment] = useState("");
  const [
    criticalMedicalEquipmentHasError,
    setCriticalMedicalEquipmentHasError,
  ] = useState(false);
  const [contactHealthPractitioner, setContactHealthPractitioner] = useState(
    false
  );
  const [practiceName, setPracticeName] = useState("");
  const [practiceNameHasError, setPracticeNameHasError] = useState(false);
  const [practitionerName, setPractitionerName] = useState("");
  const [practitionerNameHasError, setPractitionerNameHasError] = useState(
    false
  );
  const [practicePhone, setPracticePhone] = useState("");
  const [practicePhoneHasError, setPracticePhoneHasError] = useState(false);
  const [
    hasMedicalDependantModalActive,
    setHasMedicalDependantModalActive,
  ] = useState(false);

  useEffect(() => {
    const medicalDependency = {
      hasMedicalDependant,
      medicalDependantDifferentAsAccountHolder,
      medicalDependantFirstName,
      medicalDependantLastName,
      medicalDependantPhone,
      medicalDependantEMailAddress,
      criticalMedicalEquipment,
      contactHealthPractitioner,
      practiceName,
      practitionerName,
      practicePhone,
      hasVulnerablePerson,
    };
    handleChange && handleChange(medicalDependency);
  }, [
    hasMedicalDependant,
    medicalDependantDifferentAsAccountHolder,
    medicalDependantFirstName,
    medicalDependantLastName,
    medicalDependantPhone,
    medicalDependantEMailAddress,
    criticalMedicalEquipment,
    contactHealthPractitioner,
    practiceName,
    practitionerName,
    practicePhone,
    hasVulnerablePerson,
    handleChange,
  ]);

  useEffect(() => {
    const invalidMedicalDependantPart =
      hasMedicalDependant &&
      ((medicalDependantDifferentAsAccountHolder &&
        (!medicalDependantFirstName ||
          medicalDependantFirstNameHasError ||
          !medicalDependantLastName ||
          medicalDependantLastNameHasError ||
          !medicalDependantEMailAddress ||
          medicalDependantEMailAddressHasError ||
          !medicalDependantPhone ||
          medicalDependantPhoneHasError)) ||
        !criticalMedicalEquipment ||
        criticalMedicalEquipmentHasError ||
        (contactHealthPractitioner &&
          (!practiceName ||
            practiceNameHasError ||
            !practitionerName ||
            practitionerNameHasError ||
            !practicePhone ||
            practicePhoneHasError)));
    handleError && handleError(invalidMedicalDependantPart);
  }, [
    hasMedicalDependant,
    medicalDependantDifferentAsAccountHolder,
    medicalDependantFirstName,
    medicalDependantLastName,
    medicalDependantPhone,
    medicalDependantEMailAddress,
    criticalMedicalEquipment,
    contactHealthPractitioner,
    practiceName,
    handleError,
    practitionerName,
    practicePhone,
    medicalDependantFirstNameHasError,
    medicalDependantLastNameHasError,
    medicalDependantEMailAddressHasError,
    medicalDependantPhoneHasError,
    criticalMedicalEquipmentHasError,
    practiceNameHasError,
    practitionerNameHasError,
    practicePhoneHasError,
  ]);

  const hasMedicalDependantCheckboxChangedHandler = (value) => {
    if (hasMedicalDependant) {
      clearMedicalDependant();
      setMedicalDependantDifferentAsAccountHolder(false);
      clearCriticalMedicalEquipment();
      clearContactHealthPractitioner();
      setContactHealthPractitioner(false);
    }
    setHasMedicalDependant(!hasMedicalDependant);
  };

  const medicalDependantDifferentAsAccountHolderCheckboxChangedHandler = () => {
    if (medicalDependantDifferentAsAccountHolder) {
      clearMedicalDependant();
    }
    setMedicalDependantDifferentAsAccountHolder(
      !medicalDependantDifferentAsAccountHolder
    );
  };

  const contactHealthPractitionerCheckboxChangedHandler = () => {
    if (contactHealthPractitioner) {
      clearContactHealthPractitioner();
    }
    setContactHealthPractitioner(!contactHealthPractitioner);
  };

  const clearMedicalDependant = () => {
    setMedicalDependantFirstName("");
    setMedicalDependantFirstNameHasError("");
    setMedicalDependantLastName("");
    setMedicalDependantLastNameHasError("");
    setMedicalDependantEMailAddress("");
    setMedicalDependantEMailAddressHasError("");
    setMedicalDependantPhone("");
    setMedicalDependantPhoneHasError("");
  };

  const clearCriticalMedicalEquipment = () => {
    setCriticalMedicalEquipmentHasError("");
    setCriticalMedicalEquipment("");
  };

  const clearContactHealthPractitioner = () => {
    setPractitionerName("");
    setPractitionerNameHasError("");
    setPracticeName("");
    setPracticeNameHasError("");
    setPracticePhone("");
    setPracticePhoneHasError("");
  };

  return (
    <Fragment>
      <Modal
        isActive={hasVulnerablePersonModalActive}
        className="property_modal_container"
        handlePrimaryClick={() =>
          setHasVulnerablePersonModalActive(!hasVulnerablePersonModalActive)
        }
        content={
          <div className="property_modal_content">
            <div className="property_modal_title">
              {Constants.vulnerableQuestionModalContent.title}
            </div>
            <p>{Constants.vulnerableQuestionModalContent.consider}</p>
            <ul className="property_modal_bullet">
              {Constants.vulnerableQuestionModalContent.bulletPoints.map(
                (item, index) => (
                  <li key={index}>{item}</li>
                )
              )}
            </ul>
            <p>{Constants.vulnerableQuestionModalContent.content1}</p>
            <p>{Constants.vulnerableQuestionModalContent.content2}</p>
          </div>
        }
        buttonTextPrimary={Constants.vulnerableQuestionModalContent.buttonLabel}
        journeyStyle={true}
      />
      <Modal
        name="hasMedicalDependantModal"
        isActive={hasMedicalDependantModalActive}
        className="property_modal_container"
        handlePrimaryClick={() =>
          setHasMedicalDependantModalActive(!hasMedicalDependantModalActive)
        }
        content={
          <div className="property_modal_content">
            <div className="property_modal_title">
              {Constants.medicalQuestionModalContent.title1}
            </div>
            <p>{Constants.medicalQuestionModalContent.para1}</p>
            <div className="property_modal_title">
              {Constants.medicalQuestionModalContent.title2}
            </div>
            <p>{Constants.medicalQuestionModalContent.para2}</p>
            <p>{Constants.medicalQuestionModalContent.bulletPointsTitle}</p>
            <ul className="property_modal_bullet">
              {Constants.medicalQuestionModalContent.bulletPoints.map(
                (item, index) => (
                  <li key={index}>{item}</li>
                )
              )}
            </ul>
          </div>
        }
        buttonTextPrimary={Constants.medicalQuestionModalContent.buttonLabel}
        journeyStyle={true}
      />
      <div className="field">
        <Checkbox
          name="hasVulnerablePerson"
          label={Constants.hasVulnerablePersonLabel}
          subLabel={Constants.hasVulnerablePersonSubLabel}
          checked={hasVulnerablePerson}
          onChange={() => setHasVulnerablePerson(!hasVulnerablePerson)}
          hasHelpIcon
          iconClicked={() =>
            setHasVulnerablePersonModalActive(!hasVulnerablePersonModalActive)
          }
        />
      </div>
      <div className="field">
        <Checkbox
          name="hasMedicalDependant"
          label={Constants.hasMedicalDependantLabel}
          subLabel={Constants.hasMedicalDependantSubLabel}
          checked={hasMedicalDependant}
          onChange={(value) => hasMedicalDependantCheckboxChangedHandler(value)}
          hasHelpIcon
          iconClicked={() =>
            setHasMedicalDependantModalActive(!hasMedicalDependantModalActive)
          }
        />
      </div>
      {hasMedicalDependant && (
        <Fragment>
          <div className="field subItem">
            <Checkbox
              name="medicalDependantDifferentAsAccountHolder"
              label={Constants.medicalDependantDifferentAsAccountHolderLabel}
              checked={medicalDependantDifferentAsAccountHolder}
              onChange={
                medicalDependantDifferentAsAccountHolderCheckboxChangedHandler
              }
            />

            {medicalDependantDifferentAsAccountHolder && (
              <Fragment>
                <div className="row subItem">
                  <div className="field">
                    <Input
                      name="medicalDependantFirstName"
                      handleChange={(value) =>
                        setMedicalDependantFirstName(value)
                      }
                      value={medicalDependantFirstName}
                      labelText={Constants.medicalDependantFirstNameLabel}
                      maxLength={Constants.medicalDependantFirstNameMaxLength}
                      handleError={setMedicalDependantFirstNameHasError}
                      errorMessage={
                        Constants.medicalDependantFirstNameRequiredMsg
                      }
                      hasError={medicalDependantFirstNameHasError}
                      validationType="name"
                      required={hasMedicalDependant}
                      validateItself={validateItself}
                    />
                  </div>
                  <div className="field">
                    <Input
                      name="medicalDependantLastName"
                      handleChange={(value) =>
                        setMedicalDependantLastName(value)
                      }
                      value={medicalDependantLastName}
                      labelText={Constants.medicalDependantLastNameLabel}
                      maxLength={Constants.medicalDependantLastNameMaxLength}
                      handleError={setMedicalDependantLastNameHasError}
                      errorMessage={
                        Constants.medicalDependantLastNameRequiredMsg
                      }
                      hasError={medicalDependantLastNameHasError}
                      validationType="name"
                      required={hasMedicalDependant}
                      validateItself={validateItself}
                    />
                  </div>
                </div>
                <div className="row subItem">
                  <div className="field">
                    <Input
                      name="medicalDependantPhone"
                      handleChange={(value) => setMedicalDependantPhone(value)}
                      value={medicalDependantPhone}
                      labelText={Constants.medicalDependantPhoneLabel}
                      placeholder={Constants.medicalDependantPhonePlaceholder}
                      maxLength={Constants.medicalDependantPhoneMaxLength}
                      handleError={setMedicalDependantPhoneHasError}
                      errorMessage={Constants.medicalDependantPhoneRequiredMsg}
                      invalidMessage={Constants.medicalDependantPhoneInvalidMsg}
                      hasError={medicalDependantPhoneHasError}
                      validationType="phoneNumber"
                      required
                      validateItself={validateItself}
                    />
                  </div>
                  <div className="field">
                    <Input
                      name="medicalDependantEMailAddress"
                      handleChange={(value) =>
                        setMedicalDependantEMailAddress(value)
                      }
                      value={medicalDependantEMailAddress}
                      labelText={Constants.medicalDependantEMailAddressLabel}
                      maxLength={
                        Constants.medicalDependantEMailAddressMaxLength
                      }
                      handleError={setMedicalDependantEMailAddressHasError}
                      errorMessage={
                        Constants.medicalDependantEMailAddressRequiredMsg
                      }
                      invalidMessage={
                        Constants.medicalDependantEMailAddressInvalidMsg
                      }
                      hasError={medicalDependantEMailAddressHasError}
                      validationType="email"
                      required
                      validateItself={validateItself}
                    />
                  </div>
                </div>
              </Fragment>
            )}
          </div>

          <div className="field subItem">
            <Input
              name="criticalMedicalEquipment"
              handleChange={(value) => setCriticalMedicalEquipment(value)}
              value={criticalMedicalEquipment}
              labelText={Constants.criticalMedicalEquipmentLabel}
              handleError={setCriticalMedicalEquipmentHasError}
              errorMessage={Constants.criticalMedicalEquipmentRequiredMsg}
              hasError={criticalMedicalEquipmentHasError}
              required={hasMedicalDependant}
              maxLength={Constants.criticalMedicalEquipmentMaxLength}
              validateItself={validateItself}
            />
          </div>

          <div className="field subItem">
            <Checkbox
              name="contactHealthPractitioner"
              label={Constants.contactHealthPractitionerLabel}
              subLabel={Constants.contactHealthPractitionerSubLabel}
              checked={contactHealthPractitioner}
              onChange={contactHealthPractitionerCheckboxChangedHandler}
            />
            {contactHealthPractitioner && (
              <Fragment>
                <div className="row subItem">
                  <div className="field">
                    <Input
                      name="practiceName"
                      handleChange={(value) => setPracticeName(value)}
                      value={practiceName}
                      labelText={Constants.practiceNameLabel}
                      maxLength={Constants.practiceNameMaxLength}
                      handleError={setPracticeNameHasError}
                      errorMessage={Constants.practiceNameRequiredMsg}
                      hasError={practiceNameHasError}
                      required={
                        hasMedicalDependant && contactHealthPractitioner
                      }
                      validateItself={validateItself}
                    />
                  </div>
                </div>
                <div className="row subItem">
                  <div className="field">
                    <Input
                      name="practitionerName"
                      handleChange={(value) => setPractitionerName(value)}
                      value={practitionerName}
                      labelText={Constants.practitionerNameLabel}
                      maxLength={Constants.practitionerNameMaxLength}
                      handleError={setPractitionerNameHasError}
                      errorMessage={Constants.practitionerNameRequiredMsg}
                      hasError={practitionerNameHasError}
                      validationType="name"
                      required={
                        hasMedicalDependant && contactHealthPractitioner
                      }
                      validateItself={validateItself}
                    />
                  </div>
                  <div className="field">
                    <Input
                      name="practicePhone"
                      handleChange={(value) => setPracticePhone(value)}
                      value={practicePhone}
                      labelText={Constants.practicePhoneLabel}
                      placeholder={Constants.practicePhonePlaceholder}
                      maxLength={Constants.practicePhoneMaxLength}
                      handleError={setPracticePhoneHasError}
                      errorMessage={Constants.practicePhoneRequiredMsg}
                      invalidMessage={Constants.practicePhoneInvalidMsg}
                      hasError={practicePhoneHasError}
                      validationType="phoneNumber"
                      required={
                        hasMedicalDependant && contactHealthPractitioner
                      }
                      validateItself={validateItself}
                    />
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
