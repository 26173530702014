import React, { Fragment, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Card, RadioButton, Input, Button, LayoutRow } from "react-components";
import Sidebar from "./Sidebar";
import "./Login.scss";
import {
  login,
  updateLoginProp,
} from "../../Redux/Actions/express/LoginActions";
import { REDUCER_PROPS } from "../../Redux/Reducers/express/LoginRedux";
import { triggerExpressAnalytics } from "../../Redux/Actions/iJoin/AnalyticsActions";
import {
  getIsFetching,
  getHasError,
  getIsLoggedIn,
} from "../../Redux/Reducers/express/LoginSelectors";
import { Login as Constants } from "../../Config/Constants";
import * as Analytics from "../../Utils/analytics";

type Props = {
  isFetching: boolean,
  error: {
    username?: string,
    password?: string,
  },
  isLoggedIn: boolean,
  login: () => Promise<any>,
};

export const Login = (props: Props) => {
  document.title = Constants.pageTitle;

  const [EXISTING, NONE, NEW] = Constants.radioButtonTypes;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [accountType, setAccountType] = useState(EXISTING);
  const [validateItself, setValidateItself] = useState(false);
  const [hasUsernameError, setHasUsernameError] = useState(false);
  const [hasPasswordError, setHasPasswordError] = useState(false);
  const buttonRef = useRef(null);

  const triggerExpressAnalytics = props.triggerExpressAnalytics;
  useEffect(() => {
    triggerExpressAnalytics && triggerExpressAnalytics();
  }, [triggerExpressAnalytics]);

  useEffect(() => {
    Analytics.triggerPageView(accountType);
  }, [accountType]);

  if (props.isLoggedIn) {
    return <Redirect to={Constants.redirectToExpressUrl} />;
  }

  const mapUsernameErrorMessage = () =>
    props.hasError
      ? Constants.errorMessage
      : Constants.usernameRequiredErrorMessage;

  const mapPasswordErrorMessage = () => {
    if (props.hasError) return "";
    if (!password.length) return Constants.passwordRequiredErrorMessage;

    return Constants.invalidPasswordErrorMessage;
  };

  const existingAccount = (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        buttonRef.current.onclick();
      }}
      noValidate
      id={Constants.automationIds.existingAccount}
      className="login_existingAccount"
    >
      <div className="row">
        <div className="field">
          <Input
            name={Constants.automationIds.usernameInput}
            placeholder={Constants.usernamePlaceholder}
            value={username}
            handleChange={(username) => {
              props.updateLoginProp(REDUCER_PROPS.HAS_ERROR, false);
              setUsername(username);
            }}
            handleError={setHasUsernameError}
            hasError={props.hasError || hasUsernameError}
            errorMessage={mapUsernameErrorMessage()}
            validateItself={validateItself}
            required
          />
        </div>
        <div className="field">
          <Input
            name={Constants.automationIds.passwordInput}
            placeholder={Constants.passwordPlaceholder}
            inputProps={{ type: "password" }}
            hint={
              <a className="login_link" href={Constants.forgotPasswordLink}>
                {Constants.passwordHint}
              </a>
            }
            value={password}
            errorMessage={mapPasswordErrorMessage()}
            handleError={setHasPasswordError}
            handleChange={(password) => {
              props.updateLoginProp(REDUCER_PROPS.HAS_ERROR, false);
              setPassword(password);
            }}
            hasError={props.hasError || hasPasswordError}
            validateItself={validateItself}
            validationType="password"
            required
          />
        </div>
      </div>
      <Button
        id={Constants.automationIds.loginButton}
        className="login_button login_submit_button"
        primaryOnLight
        text={Constants.loginButtonText}
        disabled={!username || !password || props.isFetching}
        isLoading={props.isFetching}
        type="submit"
        handleClick={() =>
          !hasPasswordError &&
          !hasUsernameError &&
          props.login({ username, password })
        }
        handleValidationClick={() => setValidateItself(true)}
        buttonRef={buttonRef}
      />
    </form>
  );

  const noAccount = (
    <div id={Constants.automationIds.noAccount} className="login_noAccount">
      <Button
        className="login_noAccount_button"
        href={Constants.makeChangesLink}
        text={Constants.continueButtonText}
        handleClick={Analytics.triggerGuestCheckout}
        primaryOnLight
      />
      <div className="login_noAccount_link">
        {Constants.registerText1}
        <a
          className="login_link"
          onClick={Analytics.triggerClickToRegister}
          href={Constants.registerLink}
        >
          {Constants.registerText2}
        </a>
      </div>
    </div>
  );

  const newToContact = (
    <div className="row" id={Constants.automationIds.newToContact}>
      <Button
        className="login_button"
        href={Constants.findAPlanButtonLink}
        text={Constants.findAPlanButtonText}
        handleClick={Analytics.triggerClicktostartNew}
        primaryOnLight
      />
    </div>
  );

  const radioButtons = {
    [EXISTING]: {
      label: Constants.existingAccountLabel,
      content: existingAccount,
    },
    [NONE]: { label: Constants.noAccountLabel, content: noAccount },
    [NEW]: { label: Constants.newToContactLabel, content: newToContact },
  };

  return (
    <LayoutRow
      left={
        <Card className="login">
          <h1 className="login_greeting">{Constants.cardTitle}</h1>
          {Constants.radioButtonTypes.map((type) => {
            const { label, content } = radioButtons[type];

            return (
              <Fragment key={type}>
                <RadioButton
                  name={type}
                  className="login_radio"
                  label={label}
                  checked={type === accountType}
                  value={type}
                  onChange={setAccountType}
                />
                {type === accountType && content}
              </Fragment>
            );
          })}
        </Card>
      }
      right={<Sidebar />}
      reverseRow
    />
  );
};

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  hasError: getHasError(state),
  isLoggedIn: getIsLoggedIn(state),
});

const mapDispatchToProps = { login, updateLoginProp, triggerExpressAnalytics };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
