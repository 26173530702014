import _merge from "lodash/merge";

export function reducerPropUpdater(name: string): function {
  return (state, propName, value) => {
    const [reducerName, ...keys] = propName;
    if (reducerName !== name) return state;

    const partialReduxState = keys.reverse().reduce(
      (acc, key) => ({
        [key]: acc,
      }),
      value
    );
    return _merge({}, state, partialReduxState);
  };
}
