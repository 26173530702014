import React, { useEffect } from "react";
import { connect } from "react-redux";
import { LayoutRow, Card } from "react-components";

import "./NpsAsync.scss";
import NPSSurvey from "./NpsAsyncSurvey/NpsAsyncSurvey";

type Props = {};

export const NpsAsync = (props: Props) => {
  useEffect(() => {}, []);

  return (
    <LayoutRow
      reverseRow
      left={
        <Card name="successCard" className="successCard">
          <div className="success">
            <div className="success_rateYourExperience">
              <NPSSurvey />
            </div>
          </div>
        </Card>
      }
      right={null}
    />
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NpsAsync);
