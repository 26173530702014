import * as actionTypes from "../../Actions/actionTypes";

const initialState = {
  data: {},
  isFetching: false,
  isError: false,
  errorMessage: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CREDIT_CHECK_RESULT:
      return {
        ...state,
        data: action.data,
        isError: false,
        errorMessage: "",
      };
    case actionTypes.SAVE_CREDIT_CHECK_RESULT:
      return { ...state, data: action.data };
    case actionTypes.UPDATE_CREDIT_CHECK_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
        isError: false,
        errorMessage: "",
      };
    case actionTypes.UPDATE_CREDIT_CHECK_ERROR:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.errorMessage,
        data: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
