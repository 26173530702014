import * as actionTypes from "../../Actions/actionTypes";

const initialState = {
  IsAuthorizedPerson: false,
  FirstName: "",
  LastName: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_AUTHORISED_PERSON:
      const newData = { ...state, ...action.AuthorizedPersonInfo };
      return newData;
    default:
      return state;
  }
};

export default reducer;
