import * as actionTypes from "../actionTypes";
import { updateCustomer } from "../iJoin/CustomerActions";
import { updateServices } from "../iJoin/ServicesActions";
import { updateConfirmation } from "../iJoin/ConfirmationActions";
import { updateProperty } from "../iJoin/PropertyActions";
import { createApiState } from "../ApiCalls";
import { updatePayment } from "../iJoin/PaymentActions";

const updateAuth = (auth) => {
  return {
    type: actionTypes.UPDATE_AUTH,
    auth: auth,
  };
};

export const submitExpressMove = (
  customer,
  service,
  confirmation,
  property,
  submit
) => {
  const auth = {
    redirectFromExpress: true,
    sessionID: Math.random().toString(36).substring(2),
    deleteAfter: new Date().getTime() / 1000 + 300,
  };
  return (dispatch) => {
    dispatch(updateCustomer(customer));
    dispatch(updateAuth(auth));
    dispatch(updateConfirmation(confirmation));
    dispatch(updateServices(service));
    dispatch(updateProperty(property));
    dispatch(updatePayment(submit.Promotion));
    if (!process.env.REACT_APP_USE_FIXTURES) {
      dispatch(createApiState(submit));
    }
  };
};
