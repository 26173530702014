// @flow
import * as actionTypes from "../../Actions/actionTypes";
import { reducerPropUpdater } from "../../utils";
import { Payment as Constants } from "../../../Config/Constants";

type State = {
  +PaymentMethod: string,
  +PrePayMobileNumber: string,
  +PaperlessDiscount: boolean,
  +JoinDirectDebit: boolean,
  +DirectDebitDetails: {
    +BankName: string,
    +BankAccountNumber: string,
    +IsSoleSignatory: boolean,
    +AgreeWithTermsAndConditions: boolean,
  },
  +AaSmartFuel: string,
  +HasAaSmartFuel: boolean,
  +BillingCycle: string,
  +BillStartDate: string,
  +PromoCode: string,
};

const initialState: State = {
  PaymentMethod: Constants.paymentMethodDefault,
  PrePayMobileNumber: null,
  PaperlessDiscount: Constants.paperlessDiscountDefault,
  JoinDirectDebit: false,
  DirectDebitDetails: {
    BankName: null,
    BankAccountNumber: null,
    IsSoleSignatory: true,
    AgreeWithTermsAndConditions: false,
  },
  AaSmartFuel: null,
  HasAaSmartFuel: false,
  BillingCycle: Constants.billingCycleDefault,
  BillStartDate: null,
  PromoCode: "",
};

const updateProp = reducerPropUpdater("Payment");

const updateConfirmation = (state, confirmation) => {
  return { ...state, ...confirmation };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PROP:
      return updateProp(state, action.propName, action.value);

    case actionTypes.UPDATE_PAYMENT:
      return updateConfirmation(state, action.payment);

    default:
      return state;
  }
};

export default reducer;
