import React, { useState } from "react";
import { Modal, Input, Checkbox } from "react-components";
import { convertApiErrorToText } from "../../Utils/ApiUtils";
import { SaveForLater as Constants } from "../../Config/Constants";
import "./SaveForLater.scss";

type Props = {
  sendCustomerSummary: boolean,
  sendCustomerSummaryError: string,
  emailAddress: string,
  handlePrimaryClick: () => {},
  handleSecondaryClick: () => {},
  handleValidationClick: () => {},
  setEmailAddress: () => {},
  setReceiveNewsAndOffers: () => {},
  receiveNewsAndOffers: boolean,
  validateItself: boolean,
};

export const SaveForLater = (props: Props) => {
  const [emailAddressHasError, setEmailAddressHasError] = useState(false);

  const renderErrorMessage = () => {
    return (
      <div className="save-for-later_errorContainer">
        <div className="save-for-later_errorContainer_blurb1">
          {convertApiErrorToText(props.sendCustomerSummaryError).blurb1}
        </div>
        <span className="save-for-later_errorContainer_blurb2">
          {convertApiErrorToText(props.sendCustomerSummaryError).blurb2}
        </span>
      </div>
    );
  };

  const renderSendCustomerSummaryContent = () => (
    <div className="save-for-later_modal_content_container">
      <br />
      <div className="save-for-later_modal_summary_info">
        {Constants.information}
      </div>
      <br />
      <Input
        name="email"
        handleChange={props.setEmailAddress}
        value={props.emailAddress}
        labelText={Constants.emailLabel}
        placeholder={Constants.emailPlaceholder}
        maxLength={Constants.emailMaxLength}
        handleError={setEmailAddressHasError}
        errorMessage={Constants.emailRequiredMsg}
        invalidMessage={Constants.emailInvalidMsg}
        hasError={emailAddressHasError}
        validationType="email"
        showSuccessIcon
        required
        validateItself={props.validateItself}
      />
      <br />
      <Checkbox
        name="receiveMarketingAndOfferEmails"
        label={Constants.marketingOffersCheckboxLabel}
        checked={props.receiveNewsAndOffers}
        onChange={() =>
          props.setReceiveNewsAndOffers(!props.receiveNewsAndOffers)
        }
        required
        validateItself={props.validateItself}
      />
      {props.sendCustomerSummaryError && renderErrorMessage()}
    </div>
  );

  return (
    <Modal
      showClose
      clearSessionStyle
      isActive={props.sendCustomerSummary}
      title={Constants.title}
      className="save-for-later_modal_container"
      buttonTextPrimary={Constants.primaryButtonContent}
      handlePrimaryClick={props.handlePrimaryClick}
      buttonTextSecondary={Constants.secondaryButtonContent}
      handleSecondaryClick={props.handleSecondaryClick}
      buttonDisabled={!props.emailAddress || !props.receiveNewsAndOffers}
      handleValidationClick={props.handleValidationClick}
      content={renderSendCustomerSummaryContent()}
    />
  );
};

export default SaveForLater;
