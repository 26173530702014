import * as actionTypes from "../actionTypes";
import { getApiState } from "../ApiCalls";
import { MyDetailsFixture } from "../../Fixtures/iJoin/MyDetailsResponse";
import { updateCustomer } from "./CustomerActions";
import { updateProperty } from "./PropertyActions";
import { updateBottledGas } from "./BottledGasActions";
import { updatePipedGas } from "./GasActions";
import { updatePayment } from "./PaymentActions";
import { updateBroadband } from "./BroadbandActions";
import { updateServices } from "./ServicesActions";
import { updateConfirmation } from "./ConfirmationActions";
import { updateReadonly } from "./ReadonlyActions";
import { updatePlan } from "./PlanActions";
import { saveCreditCheckResult } from "../CSRAgent/CreditCheckAction";
import {
  updateJointAccountHolder,
  updateAuthorizedPerson,
} from "../CSRAgent/JointAccountAction";
import { triggerIJoinAnalytics } from "./AnalyticsActions";

export const updateAuth = (sessionID, deleteAfter) => {
  return {
    type: actionTypes.UPDATE_AUTH,
    auth: { sessionID: sessionID, deleteAfter: deleteAfter },
  };
};

export const updateProp = (propName: [string], value) => {
  return {
    type: actionTypes.UPDATE_PROP,
    propName,
    value,
  };
};

export const updateMyDetails = (apiData) => {
  const {
    sessionID,
    deleteAfter,
    Customer,
    LpgInfo,
    PipedGasInfo,
    Payment,
    BroadbandInfo,
    Services,
    Property,
    PropertyInfo,
    PlanInfo,
    MedicalInfo,
    CreditCheckInfo,
    CreditCheck,
    MoveInfo,
    JointAccountHolderInfo,
    AuthorizedPersonInfo,
    Confirmation,
    ...rest
  } = apiData;
  return [
    updateAuth(sessionID, deleteAfter),
    updateReadonly(rest),
    Customer && updateCustomer(Customer),
    LpgInfo && updateBottledGas(LpgInfo),
    PipedGasInfo && updatePipedGas(PipedGasInfo),
    Payment && updatePayment(Payment),
    BroadbandInfo && updateBroadband(BroadbandInfo),
    CreditCheckInfo && saveCreditCheckResult(CreditCheckInfo),
    CreditCheck && saveCreditCheckResult(CreditCheck.data),
    Services && updateServices(Services),
    Property
      ? updateProperty(Property)
      : updateProperty({
          PropertyInfo: PropertyInfo,
          MedicalInfo: MedicalInfo,
          MoveInfo: MoveInfo,
        }),
    PlanInfo && updatePlan(PlanInfo),
    JointAccountHolderInfo && updateJointAccountHolder(JointAccountHolderInfo),
    Confirmation && updateConfirmation(Confirmation),
    AuthorizedPersonInfo && updateAuthorizedPerson(AuthorizedPersonInfo),
    // do this last so that data are populated in the store
    triggerIJoinAnalytics(),
  ];
};

export const fetchMyDetailsData = () => {
  return process.env.REACT_APP_USE_FIXTURES
    ? updateMyDetails(MyDetailsFixture)
    : getApiState();
};
