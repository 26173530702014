import _now from "lodash/now";

import * as actionTypes from "../actionTypes";
import { REDUCER_TYPE, REDUCER_PROPS } from "../../Reducers/express/LoginRedux";
import { loginUser } from "../ApiCalls";

const MINS_TO_EXPIRY = 20;
const MILLIS_PER_MIN = 60000;

export const updateLoginProp = (prop, payload) => ({
  type: actionTypes.UPDATE_PROP,
  propName: [REDUCER_TYPE, prop],
  payload,
});

export const login = (data) => (dispatch) => {
  dispatch(updateLoginProp(REDUCER_PROPS.IS_FETCHING, true));
  dispatch(updateLoginProp(REDUCER_PROPS.HAS_ERROR, false));

  return loginUser(data)
    .then((response) => {
      dispatch({
        type: actionTypes.PERSIST_LOCAL_DATA,
        payload: {
          [REDUCER_TYPE]: {
            token: response.data.token,
            expiry: _now() + MINS_TO_EXPIRY * MILLIS_PER_MIN,
          },
        },
      });
      dispatch(updateLoginProp(REDUCER_PROPS.TOKEN, response.data.token));
      dispatch(updateLoginProp(REDUCER_PROPS.IS_LOGGED_IN, true));
    })
    .catch((error) => dispatch(updateLoginProp(REDUCER_PROPS.HAS_ERROR, true)))
    .finally(() => dispatch(updateLoginProp(REDUCER_PROPS.IS_FETCHING, false)));
};
