import { combineReducers } from "redux";
import Join from "../CSRAgent/JoinRedux";
import Login from "../CSRAgent/LoginRedux";
import CreditCheck from "../CSRAgent/CreditCheckRedux";
import AboutYou from "../CSRAgent/AboutYouRedux";
import Price from "../CSRAgent/PriceRedux";
import JoinDetails from "../CSRAgent/JoinDetailsRedux";
import Auth from "../AuthRedux";
import Customer from "../iJoin/CustomerRedux";
import LpgInfo from "../iJoin/BottledGasRedux";
import PipedGasInfo from "../iJoin/GasRedux";
import Payment from "../iJoin/PaymentRedux";
import Services from "../iJoin/ServicesRedux";
import Property from "../iJoin/PropertyRedux";
import Confirmation from "../iJoin/ConfirmationRedux";
import BroadbandInfo from "../iJoin/BroadbandRedux";
import PlanInfo from "../CSRAgent/PlanRedux";
import Readonly from "../iJoin/ReadonlyRedux";
import JointAccountHolderInfo from "../CSRAgent/JointAccountRedux";
import AuthorizedPersonInfo from "../CSRAgent/AddAuthorizedPersonRedux";

export default combineReducers({
  Join,
  CreditCheck,
  AboutYou,
  Price,
  JoinDetails,
  Auth,
  Customer,
  LpgInfo,
  PipedGasInfo,
  Payment,
  Services,
  Property,
  Confirmation,
  BroadbandInfo,
  PlanInfo,
  Readonly,
  Login,
  JointAccountHolderInfo,
  AuthorizedPersonInfo,
});
