import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  saveUserProfile,
  getAccountDetails,
} from "../../Redux/Actions/express/UserProfileAction";
import { submitExpressMove } from "../../Redux/Actions/express/ExpressAction";
import { Express as Constants } from "../../Config/Constants";
import { SERVICES_SHORT_NAMES as Service } from "../../Config/Constants";
import { Redirect } from "react-router-dom";
import { redirect } from "../../Redux/Actions/Redirect";
import {
  PlanCard,
  // PromoCode,
  Modal,
  Button,
  CheckboxList,
  Input,
  Checkbox,
  HTML,
  Loading,
  DateUtil,
} from "react-components";
import { getHasError, getIsDualEnergyCustomer } from "../../Utils/selectors";
import MoveOutAddress from "./MoveOutAddress";
import MoveInAddress from "./MoveInAddress";
import Rates from "../Rates";
import { MedicalDependency } from "../MedicalDependency/MedicalDependency";
import { getMeters } from "../../Utils/selectors";
import * as Analytics from "../../Utils/analytics";
import "./Express.scss";

type Props = {
  token: string,
  firstName: string,
  properties: Array<object>,
  contracts: Array<object>,
  serviceTypes: Array<object>,
  saveUserProfile: () => void,
  submitExpressMove: () => void,
  getAccountDetails: () => void,
};

const mapPropertyToAddress = ({ premises }) => {
  const [premise] = premises;
  return premise.address;
};

export const Express = (props: Props) => {
  document.title = Constants.pageTitle;

  const [moveOutAddress, setMoveOutAddress] = useState("");
  const [moveOutDate, setMoveOutDate] = useState(null);
  const [moveOutHasError, setMoveOutHasError] = useState(false);
  const [moveInAddress, setMoveInAddress] = useState("");
  const [moveInDate, setMoveInDate] = useState(null);
  const [moveInHasError, setMoveInHasError] = useState(false);
  const [icp, setIcp] = useState("");
  const [hasNoIcp, setHasNoIcp] = useState(false);
  const [isStandard, setIsStandard] = useState(true);
  const [dualEnergyCustomer, setDualEnergyCustomer] = useState(false);

  const [tcClicked, setTcClicked] = useState(false);

  const [propertyIndex, setPropertyIndex] = useState(0);

  const {
    firstName,
    fullName,
    middleName,
    lastName,
    emailAddress,
    meterContracts,
  } = props;

  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(
    false
  );

  const [medicalDependency, setMedicalDependency] = useState({});
  const [medicalDependencyHasError, setMedicalDependencyHasError] = useState(
    false
  );
  const [hazards, setHazards] = useState([]);
  const [hazardsOther, setHazardsOther] = useState("");
  const [hazardsOtherSelected, setHazardsOtherSelected] = useState(false);
  const [hazardsConstants, setHazardsConstants] = useState({});
  const [hazardsCheckboxOptions, setHazardsCheckboxOptions] = useState([]);
  const [hazardsOtherHasError, setHazardsOtherHasError] = useState(false);
  const [validateItself, setValidateItself] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);
  // const [code, setCode] = useState('');
  const code = "";
  const [codeSubmitted, setCodeSumbitted] = useState(false);

  const [accountNumber, setAccountNumber] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");

  let clickingTermAndConditionsLink = false;

  useEffect(() => {
    props.saveUserProfile(props.token);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsModalActive(props.hasError);
  }, [props.hasError]);

  useEffect(() => {
    if (props.properties) {
      setMoveOutAddress(props.properties[propertyIndex].premises[0].address);
    }
  }, [props.properties, propertyIndex]);

  useEffect(() => {
    if (props.properties) {
      Analytics.triggerSuccessLogin(
        props.properties[0].contractAccountId,
        props.businessPartnerId
      );
      Analytics.triggerMoveExpressJourney();
      Analytics.triggerAddToCartExpressJourney(
        props.emailAddress,
        props.properties[0].premises[0].address
      );
      Analytics.triggerCheckoutStepExpressJourney();
    }
  }, [props.properties, props.emailAddress, props.businessPartnerId]);

  useEffect(() => {
    if (codeSubmitted) {
      Analytics.triggerPromoCodeSubmitted(code);
    }
    // eslint-disable-next-line
  }, [codeSubmitted]);

  useEffect(() => {
    const hazards = hazardsCheckboxOptions
      .filter((option) => option.checked)
      .map((option) => option.label);
    setHazards(hazards);
  }, [hazardsCheckboxOptions]);

  useEffect(() => {
    if (!code) {
      setCodeSumbitted(false);
    }
  }, [code]);

  useEffect(() => {
    if (props.phoneNumber) {
      setPhoneNumber(props.phoneNumber[0].phoneNumber);
    }
  }, [props.phoneNumber]);

  useEffect(() => {
    if (props.properties) {
      setAccountNumber(props.properties[propertyIndex].contractAccountId);
    }
  }, [props.properties, propertyIndex, setAccountNumber]);

  useEffect(() => {
    if (accountNumber && props.contracts) {
      const isAccountExist = props.serviceTypes.filter(
        (element) => element.accountId === accountNumber
      );
      if (!isAccountExist.length) {
        props.getAccountDetails(
          accountNumber,
          props.token,
          props.serviceTypes || []
        );
      } else {
        const currentAccountServices = props.serviceTypes.filter(
          (contract) => contract.accountId === accountNumber
        );
        const isDual = getIsDualEnergyCustomer(
          currentAccountServices[0].serviceTypes
        );
        setDualEnergyCustomer(isDual);
      }
    }
  }, [accountNumber, props]);

  useEffect(() => {
    setIsDisabled(
      medicalDependencyHasError ||
        (hazardsOtherSelected && !hazardsOther) ||
        hazardsOtherHasError ||
        hazards.length === 0 ||
        !acceptTermsAndConditions ||
        !moveOutAddress ||
        !moveOutDate ||
        moveOutHasError ||
        !moveInAddress ||
        !moveInDate ||
        moveInHasError
    );
  }, [
    hazardsOtherSelected,
    hazardsOtherHasError,
    hazardsOther,
    hazards,
    medicalDependencyHasError,
    acceptTermsAndConditions,
    moveOutHasError,
    moveOutAddress,
    moveOutDate,
    moveInHasError,
    moveInAddress,
    moveInDate,
  ]);

  useEffect(() => {
    const mappedHazard = Constants.hazards;

    setHazardsConstants(mappedHazard);

    const hazardsOptions = mappedHazard.options.map((option) => {
      const checked = hazards.includes(option);
      return {
        checked: checked,
        label: option,
      };
    });

    setHazardsCheckboxOptions(hazardsOptions);

    setHazardsOtherSelected(hazards.includes(hazardsConstants.optionsOther));
    // eslint-disable-next-line
  }, []);

  const termAndConditionsCheckboxOnChange = () => {
    if (!clickingTermAndConditionsLink) {
      setAcceptTermsAndConditions(!acceptTermsAndConditions);
    }
    clickingTermAndConditionsLink = false;
  };
  const clickedHandler = () => {
    setIsLoading(true);
    props.submitExpressMove({
      firstName,
      middleName,
      lastName,
      fullName,
      emailAddress,
      phoneNumber,
      accountNumber,
      medicalDependency,
      code: codeSubmitted ? code : "",
      moveInDate,
      moveInAddress,
      hazards,
      moveOutDate,
      moveOutAddress,
      acceptTermsAndConditions,
      hazardsOther,
      meterContracts,
      icp,
      isStandard,
    });
  };

  const setContent = (
    <Fragment>
      <div className="express_card_plan">{Constants.planCard.content.plan}</div>
      <div className="express_card_header">
        {Constants.planCard.content.header}
      </div>
      <div className="express_card_title">
        {Constants.planCard.content.title}
      </div>
      <div className="express_card_description">
        {Constants.planCard.content.description}
      </div>
    </Fragment>
  );

  const hazardsCheckboxListChangedHandler = (index) => {
    if (hazardsCheckboxOptions[index].label === hazardsConstants.optionsOther) {
      if (hazardsCheckboxOptions[index].checked) {
        setHazardsOther("");
      }
      setHazardsOtherSelected(!hazardsCheckboxOptions[index].checked);
    }

    let updatedHazardsCheckboxOptions;
    if (
      hazardsCheckboxOptions[index].label === hazardsConstants.optionsNoHazards
    ) {
      updatedHazardsCheckboxOptions = hazardsCheckboxOptions.map((opt) => {
        return opt.label === hazardsConstants.optionsNoHazards
          ? { ...opt, checked: !opt.checked }
          : { ...opt, checked: false };
      });
      setHazardsOther("");
      setHazardsOtherSelected(false);
      setHazardsOtherHasError(false);
    } else {
      updatedHazardsCheckboxOptions = hazardsCheckboxOptions.map((opt, idx) => {
        return idx === index
          ? { ...opt, checked: !opt.checked }
          : opt.label === hazardsConstants.optionsNoHazards
          ? { ...opt, checked: false }
          : opt;
      });
    }

    setHazardsCheckboxOptions(updatedHazardsCheckboxOptions);
  };

  const closeModal = () => {
    setTcClicked(false);
  };

  const getPropertyContent = (
    <Fragment>
      <div className="express_module">
        <div className="express_module_title">{Constants.property.title}</div>
        <MedicalDependency
          handleChange={setMedicalDependency}
          handleError={setMedicalDependencyHasError}
          validateItself={validateItself}
        />
      </div>
      <div className="express_module">
        <div className="express_module_title">{Constants.hazards.label}</div>
        <CheckboxList
          name="hazardsCheckboxOptions"
          onChange={hazardsCheckboxListChangedHandler}
          options={hazardsCheckboxOptions}
          columns={3}
          description={Constants.hazards.helpText}
          required
          validateItself={validateItself}
        />
        {hazardsOtherSelected && (
          <Input
            name="hazardsOther"
            handleChange={setHazardsOther}
            value={hazardsOther}
            labelText={Constants.hazards.otherHazards}
            placeholder={Constants.hazards.otherHazardsPlaceholder}
            handleError={setHazardsOtherHasError}
            errorMessage={Constants.hazards.otherHazardsRequiredMsg}
            hasError={hazardsOtherHasError}
            required
            validateItself={validateItself}
          />
        )}
      </div>
    </Fragment>
  );

  if (props.submitted) {
    return <Redirect to={"/residential/express-move/success"} />;
  }
  return (
    <div className="express">
      {props.hasError && (
        <Modal
          isActive={isModalActive}
          handlePrimaryClick={() => {
            props.redirectOnError();
            setIsModalActive(!isModalActive);
          }}
          content={<HTML html={Constants.errorModalContent.content} />}
          buttonTextPrimary={Constants.errorModalContent.buttonLabel}
        />
      )}
      <div className="express_account">
        {Constants.accountNumber}
        {accountNumber}
      </div>
      <div className="express_module">
        <h1 className="express_title">{Constants.title}</h1>
        {props.properties ? (
          <Fragment>
            <MoveOutAddress
              addressValue={moveOutAddress}
              addressIndex={propertyIndex}
              addresses={(props.properties || []).map(mapPropertyToAddress)}
              handleAddressChange={setMoveOutAddress}
              handleAddressIndexChange={setPropertyIndex}
              date={moveOutDate}
              handleDateChange={setMoveOutDate}
              handleError={setMoveOutHasError}
              validateItself={validateItself}
            />
            <MoveInAddress
              date={moveInDate}
              moveOutAddress={moveOutAddress}
              moveInAddress={moveInAddress}
              handleDateChange={setMoveInDate}
              handleAddressChange={setMoveInAddress}
              onIcpSelect={setIcp}
              setHasNoIcp={setHasNoIcp}
              handleError={setMoveInHasError}
              validateItself={validateItself}
            />
            {dualEnergyCustomer && (
              <div className="express_disclaimer-alert">
                {Constants.dualCustomerMessage}
              </div>
            )}
          </Fragment>
        ) : (
          <div className="express_loader">
            <Loading />
          </div>
        )}
      </div>
      <div className="express_module">
        <Rates icp={icp} hasNoIcp={hasNoIcp} setIsStandard={setIsStandard} />
      </div>
      <div className="express_module">
        <div className="express_plan">
          <div className="express_card">
            <PlanCard
              banner={Constants.planCard.banner}
              tag={Constants.planCard.tag}
              content={setContent}
            />
          </div>
          <div className="express_details">
            <div className="express_details_title">
              {Constants.detailsTitle}
            </div>
            <div className="express_details_description">
              {Constants.description}
            </div>
            <div className="express_details_includes-title">
              {Constants.includeTitle}
            </div>
            <div className="express_details_includes">
              {Constants.icons.map((card, index) => (
                <div className="express_details_includes_row" key={index}>
                  <div className="express_details_includes_row_image">
                    <img src={card.image} alt={card.text} />
                  </div>
                  <span>{card.text}</span>
                </div>
              ))}
            </div>
            <div className="express_row">
              <Button
                className="express_termsButton"
                name="termsButton"
                type="button"
                text={Constants.tc.termsButtonLabel}
                handleClick={setTcClicked}
                primaryBlack
              />
              <Modal
                isActive={tcClicked}
                className="express_modal_container"
                handlePrimaryClick={closeModal}
                content={
                  <div className="express_modal_content">
                    <div className="express_modal_content_title">
                      {Constants.tc.title}
                    </div>
                    <ul>
                      {Constants.tc.bulletPoints.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                    <a
                      href={Constants.tc.file}
                      rel="noopener noreferrer"
                      download
                    >
                      <Button
                        name="moduleButton"
                        type="button"
                        className="express_modal_content_module-button"
                        text={Constants.tc.moduleButtonLabel}
                        primaryBlack
                      >
                        {Constants.tc.moduleButtonLabel}
                      </Button>
                    </a>
                  </div>
                }
                buttonTextPrimary={Constants.termsButtonLabel}
                journeyStyle={true}
              />
              {/* <div className='express_row_promo'> //save as we might come back to using it later
                <PromoCode
                  placeholder={Constants.promoCode.title}
                  buttonText={Constants.promoCode.buttonText}
                  maxLength={10}
                  code={code}
                  setCode={setCode}
                  setCodeSumbitted={setCodeSumbitted}
                  codeSubmitted={codeSubmitted}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="express_divide" />
      <div className="express_module">{getPropertyContent}</div>
      <div className="express_module">
        <div className="express_terms_checkbox">
          <Checkbox
            name="acceptTermsAndConditions"
            className="express_terms_accept-terms-and-conditions-checkbox"
            label={<HTML html={Constants.tcCheckbox.label} />}
            subLabel={Constants.tcCheckbox.subLabel}
            checked={acceptTermsAndConditions}
            onChange={termAndConditionsCheckboxOnChange}
            required
            validateItself={validateItself}
          />
        </div>
      </div>
      <div className="express_module">
        <Button // todo create  proper button, have it now only to add validation
          name="espressMove"
          id="espressMove"
          className="continue-button"
          type="button"
          text={Constants.submitButtonText}
          secondaryText={Constants.submitButtonSecondaryText}
          handleClick={clickedHandler}
          handleValidationClick={() => setValidateItself(true)}
          primaryOnLight
          disabled={isDisabled || isLoading}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.Login.token,
  properties: state.UserProfile.customer.properties,
  serviceTypes: state.UserProfile.customer.serviceTypes,
  contracts: state.UserProfile.customer.account
    ? state.UserProfile.customer.account.contracts
    : null,
  firstName: state.UserProfile.customer.firstName,
  fullName: state.UserProfile.customer.fullName,
  middleName: state.UserProfile.customer.middleName,
  lastName: state.UserProfile.customer.lastName,
  phoneNumber: state.UserProfile.customer.phones,
  emailAddress: state.UserProfile.customer.email,
  submitted: state.Auth.submitted,
  meterContracts: state.UserProfile.customer.account,
  hasError: getHasError(state),
  businessPartnerId: state.UserProfile.customer.id,
});

const mapDispatchToProps = (dispatch) => ({
  saveUserProfile: (token) => {
    dispatch(saveUserProfile(token));
  },
  getAccountDetails: (accountId, token, services) => {
    dispatch(getAccountDetails(accountId, token, services));
  },
  redirectOnError: () => {
    if (Constants.redirectOnErrorUrl) {
      dispatch(redirect(Constants.redirectOnErrorUrl));
    }
  },
  submitExpressMove: (values) => {
    const meters = getMeters(values.meterContracts);
    const customer = {
      CustomerInfo: {
        FirstName: values.firstName,
        FullName: values.fullName,
        MiddleName: values.middleName,
        LastName: values.lastName,
        EmailAddress: values.emailAddress,
        AccountNumber: values.accountNumber,
        PhoneNumber: values.phoneNumber,
      },
    };
    const property = {
      MoveInfo: {
        AccountStart: DateUtil.convertDateInstanceToFormat(
          values.moveInDate,
          Constants.dateFormat
        ),
        MoveOutDate: DateUtil.convertDateInstanceToFormat(
          values.moveOutDate,
          Constants.dateFormat
        ),
        OldAddress: values.moveOutAddress,
        MoveInDate: DateUtil.convertDateInstanceToFormat(
          values.moveInDate,
          Constants.dateFormat
        ),
        NewAddress: values.moveInAddress,
        HasOldMeterRead: meters.length > 0,
        HasNewMeterRead: false,
        OldMeterRead: meters,
      },
      PropertyInfo: {
        HasHazards: values.hazards[0] !== "No hazards",
        Hazards: values.hazards.map((hazard) => hazard),
        HazardsOther: values.hazardsOther,
        ElectricityICP: values.icp,
        ElectricityUserType: values.isStandard ? "Standard" : "Low User",
      },
    };
    const medicalInfo = {
      HasMedicalDependant: values.medicalDependency.hasMedicalDependant,
      CriticalMedicalEquipment:
        values.medicalDependency.criticalMedicalEquipment,
      MedicalDependantFirstName:
        values.medicalDependency.medicalDependantFirstName,
      MedicalDependantLastName:
        values.medicalDependency.medicalDependantLastName,
      MedicalDependantEmailAddress:
        values.medicalDependency.medicalDependantEMailAddress,
      MedicalDependantPhone: values.medicalDependency.medicalDependantPhone,
      MedicalDependantSameAsAccountHolder: values.medicalDependency
        .hasMedicalDependant
        ? !values.medicalDependency.medicalDependantDifferentAsAccountHolder
        : null,
      ContactHealthPractitioner: values.medicalDependency.hasMedicalDependant
        ? values.medicalDependency.contactHealthPractitioner
        : null,
      PracticeName: values.medicalDependency.practiceName,
      PracticePhone: values.medicalDependency.practicePhone,
      PractitionerName: values.medicalDependency.practitionerName,
      HasVulnerablePerson: values.medicalDependency.hasVulnerablePerson,
    };
    const planInfo = {
      PlanName: Constants.planInfo.planName,
      PlanId: Constants.planInfo.planId,
      CampaignId: Constants.planInfo.campaignId,
    };
    const discounts = {
      PromoCode: values.code,
    };

    const confirmation = {
      AcceptPlanTermsAndConditions: values.acceptTermsAndConditions,
      JourneyType: Constants.expressJourneyType,
    };

    const submitExpress = {
      Customer: customer,
      Services: [Service.elec],
      PlanInfo: planInfo,
      PropertyInfo: property.PropertyInfo,
      MedicalInfo: medicalInfo,
      MoveInfo: property.MoveInfo,
      Promotion: discounts,
      Confirmation: confirmation,
    };

    dispatch(
      submitExpressMove(
        customer,
        [Service.elec],
        confirmation,
        property,
        submitExpress
      )
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Express);
