import React from "react";
import { connect } from "react-redux";
import { useStateFromProp } from "../../Utils/hooks";
import { Icon, ExternalLink, HTML } from "react-components";
import { saveCustomerData } from "../../Redux/Actions/CSRAgent/JoinAction";
import "./Join.scss";

type Props = {
  constants: Object,
  disableNotes?: boolean,
  isJoin?: boolean,
  isJoinDetails?: boolean,
};

const Sidebar = (props: Props) => {
  const { constants, disableNotes, isJoin, isJoinDetails } = props;
  const [note, setNote] = useStateFromProp(props.note, "");

  const getNote = (e) => {
    const note = e.target.value;
    setNote(note);
  };

  const saveNote = () => {
    setNote(note);
    props.saveData({ note });
  };

  const renderLinks = (links) => (
    <div className="join_sidebar_links">
      {links.map((link, index) => (
        <div key={index}>
          <a
            className="join_sidebar_links_link"
            href={link.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Icon
              customStyle={{ width: "24px", height: "24px" }}
              className="join_sidebar_links_icon"
              icon={<ExternalLink />}
            />
            {link.title}
          </a>
        </div>
      ))}
    </div>
  );

  return (
    <div className="join_sidebar">
      <div className="join_sidebar_title">{constants.linksTitle}</div>
      {renderLinks([
        {
          url: constants.linkHelp.url,
          title: constants.linkHelp.title,
        },
        {
          url: constants.linkQuote.url,
          title: constants.linkQuote.title,
        },
        {
          url: constants.linkFaults.url,
          title: constants.linkFaults.title,
        },
        {
          url: constants.linkGoogleMap.url,
          title: constants.linkGoogleMap.title,
        },
        {
          url: constants.linkPostcodeFinder.url,
          title: constants.linkPostcodeFinder.title,
        },
      ])}
      <div className="join_sidebar_sub-title">{constants.category1}</div>
      {renderLinks([
        {
          url: constants.linkElec.url,
          title: constants.linkElec.title,
        },
        { url: constants.linkPG.url, title: constants.linkPG.title },
      ])}
      <div className="join_sidebar_sub-title">{constants.category2}</div>
      {renderLinks([
        {
          url: constants.linkVumedaPortal.url,
          title: constants.linkVumedaPortal.title,
        },
        {
          url: constants.linkDevoliSupport.url,
          title: constants.linkDevoliSupport.title,
        },
        { url: constants.linkMap.url, title: constants.linkMap.title },
      ])}
      <div className="join_sidebar_sub-title">{constants.category3}</div>
      {renderLinks([
        {
          url: constants.linkBottleGasMap.url,
          title: constants.linkBottleGasMap.title,
        },
        {
          url: constants.linkBottleGasPricing.url,
          title: constants.linkBottleGasPricing.title,
        },
      ])}
      <div className="join_sidebar_title">{constants.notesTitle}</div>
      <div>
        <textarea
          className="join_sidebar_textarea"
          onChange={(e) => getNote(e)}
          maxLength={constants.noteMaxLength}
          value={note}
          disabled={disableNotes}
          onBlur={() => saveNote(note)}
        />
      </div>
      {/* There is another ticket to display summary section which will replace questions section in the customer details page */}
      {isJoin && (
        <>
          <div className="join_sidebar_title">{constants.questionsTitle}</div>
          <ul className="join_sidebar_list">
            {constants.findAPlanQuestions.map((question, index) => (
              <li key={index}>{question}</li>
            ))}
          </ul>
        </>
      )}
      {isJoinDetails && (
        <>
          <div className="join_sidebar_title">{constants.summaryTitle}</div>
          <ul className="join_sidebar_customer_list">
            {constants.customerDetailsQuestions.map((question, index) => (
              <>
                <HTML html={question.title} />
                <HTML html={question.content} />
              </>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.Customer?.CustomerInfo?.InteractionNotes,
});

const mapDispatchToProps = (dispatch) => ({
  saveData: (values) => {
    const details = {
      Customer: {
        CustomerInfo: {
          InteractionCategory: "7000",
          InteractionNotes: values.note,
        },
      },
    };
    dispatch(saveCustomerData(details));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
