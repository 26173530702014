import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { compose } from "recompose";

import { withReduxProvider } from "../Utils/enhancers";
import expressStore from "../Redux/Stores/expressStore";
import { injectGtm } from "../Utils/gtm";
import { Gtm } from "../Config/Constants";

import NpsAsync from "./NpsAsync/NpsAsync";
import withLayout from "../Components/Layout";

const ExpressApp = () => {
  useEffect(() => {
    injectGtm(Gtm.reactAppId);
  }, []);

  return (
    <Switch>
      <Route path="/asyncsurvey" component={NpsAsync} />
      <Route
        path="/"
        component={() => {
          window.location.href =
            process.env.REACT_APP_EXPRESS_BASE_URL + "/404-not-found";
          return null;
        }}
      />
    </Switch>
  );
};

export default compose(withReduxProvider(expressStore), withLayout)(ExpressApp);
