import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _debounce from "lodash/debounce";
import {
  LayoutRow,
  LayoutColumn,
  Card,
  StepList,
  HTML,
  CardsRow,
  DateUtil,
} from "react-components";

import "./Success.scss";

import {
  triggerIJoinSuccessAnalytics,
  triggerPurchaseExpress,
} from "../../Redux/Actions/iJoin/AnalyticsActions";
import { Success as Constants } from "../../Config/Constants";
import { replaceKeys, readableJoin, serviceName } from "../../Utils";
import {
  getHasSession,
  getHasError,
  getHasElectricity,
  getHasPipedGas,
  getHasBottledGas,
  getHasBroadband,
  getServicesStartDate,
  getIsSwitchingSupplier,
  getHasSmartMeter,
  getIsPaymentPrepay,
  getHasOrderBottledGas,
  getServices,
} from "../../Utils/selectors";
import NPSSurvey from "./NPSSurvey/NPSSurvey";

import viewSupport from "../../Assets/Images/view-support-card.jpg";
import viewSupportRetina from "../../Assets/Images/view-support-card@2x.jpg";
import flexiblePayment from "../../Assets/Images/flexible-payment-card.jpg";
import flexiblePaymentRetina from "../../Assets/Images/flexible-payment-card@2x.jpg";
import { redirect } from "../../Redux/Actions/Redirect";
import { deleteStateApiSessionId } from "../../Redux/Actions/ApiCalls";

type Props = {
  hasSession: boolean,
  hasError: boolean,
  firstName: string,
  services: Array<string>,
  hasElectricity: boolean,
  hasPipedGas: boolean,
  hasBottledGas: boolean,
  hasBroadband: boolean,
  startDate: string,
  isSwitching: boolean,
  hasSmartMeter: boolean,
  isPaymentPrepay: boolean,
  hasOrderBottledGas: boolean,
  redirectFromExpress: boolean,
  deleteStateApiSessionId?: () => void,
  redirectOnError?: () => void,
  triggerIJoinSuccessAnalytics?: () => void,
};

export const Success = (props: Props) => {
  document.title = Constants.pageTitle;

  useEffect(() => {
    // delete state api session id
    props.deleteStateApiSessionId();
    // scroll to top
    window.scrollTo(0, 0);

    // eslint-disable-next-line
  }, []);

  const { triggerIJoinSuccessAnalytics, triggerPurchaseExpress } = props;
  useEffect(() => {
    // analytics'
    if (props.redirectFromExpress) {
      triggerPurchaseExpress && triggerPurchaseExpress();
    } else {
      triggerIJoinSuccessAnalytics && triggerIJoinSuccessAnalytics();
    }
  }, [
    triggerIJoinSuccessAnalytics,
    triggerPurchaseExpress,
    props.redirectFromExpress,
  ]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const getWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  const isMobile = windowWidth <= 640;
  const isTablet = windowWidth <= 860 && windowWidth >= 640;

  const debounceWindowWidth = _debounce(getWindowWidth, 150);

  useEffect(() => {
    window.addEventListener("resize", debounceWindowWidth);
    return () => {
      window.removeEventListener("resize", debounceWindowWidth);
    };
  });

  // Redirect on error
  useEffect(() => {
    if (props.hasError || props.isNotLastPage) {
      props.redirectOnError();
    }
    // eslint-disable-next-line
  }, [props.hasError, props.isNotLastPage]);

  const tipsCards = [
    {
      image: {
        desktop: viewSupport,
        retina: viewSupportRetina,
      },
      label: Constants.tipsLabel1,
      link: Constants.tipsLink1,
    },
    {
      label: Constants.tipsLabel2,
      link: Constants.tipsLink2,
      background: "#31102E",
    },
    {
      image: {
        desktop: flexiblePayment,
        retina: flexiblePaymentRetina,
      },
      label: Constants.tipsLabel3,
      link: Constants.tipsLink3,
    },
  ];

  const getRightColumn = (
    <div className="success_tipsContainer">
      <CardsRow
        cards={tipsCards}
        layout={isTablet ? "horizontal" : "vertical"}
        header={Constants.tipsTitle}
      />
    </div>
  );
  const data = {
    firstName: props.firstName,
    services: readableJoin(
      props.services.map(serviceName).map((service) => `<b>${service}</b>`)
    ),
    startDate: DateUtil.convertDateInstanceToFormat(
      DateUtil.getJavascriptDateFormat(
        props.startDate,
        Constants.apiDateFormat
      ),
      Constants.displayDateFormat
    ),
    isSwitching: props.isSwitching,
    hasSmartMeter: props.hasSmartMeter,
    isPaymentPrepay: props.isPaymentPrepay,
    hasOrderBottledGas: props.hasOrderBottledGas,
    hasElectricity: props.hasElectricity,
    hasPipedGas: props.hasPipedGas,
    hasBottledGas: props.hasBottledGas,
    hasBroadband: props.hasBroadband,
  };

  const steps = Constants.steps
    // filter by required
    .filter(
      ({ required }) =>
        !required || required.every((variable) => Boolean(data[variable]))
    )
    // filter by restricted
    .filter(
      ({ restricted }) =>
        !restricted || !restricted.find((variable) => Boolean(data[variable]))
    );

  return (
    <>
      <LayoutRow
        reverseRow
        left={
          <Card name="successCard" className="successCard">
            <div className="success">
              <h1 name="successGreeting" className="success_greeting">
                {replaceKeys(Constants.greeting, data)}
                <small>
                  {replaceKeys(
                    props.redirectFromExpress
                      ? Constants.greetingExpressSubtitle
                      : Constants.greetingSubtitle,
                    data
                  )}
                </small>
              </h1>
              <h2 className="success_nextSteps_title">
                {Constants.nextStepsTitle}
              </h2>
              <StepList className="success__stepList">
                {steps.map(({ title, icon, html, successHtml }, key) => (
                  <StepList.Item key={key} title={title} icon={icon} name={key}>
                    <HTML html={replaceKeys(html, data)} />
                    {!props.redirectFromExpress && (
                      <HTML html={replaceKeys(successHtml, data)} />
                    )}
                  </StepList.Item>
                ))}
              </StepList>
              <div className="success_belowNextStepsNote">
                <HTML html={Constants.belowNextStepsNote} />
              </div>
              {(isMobile || isTablet) && getRightColumn}
              <div className="success_rateYourExperience">
                <NPSSurvey />
              </div>
            </div>
          </Card>
        }
        right={
          isMobile || isTablet ? null : (
            <LayoutColumn hideBottom top={getRightColumn} />
          )
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  hasSession: getHasSession(state),
  hasError: getHasError(state),
  firstName: state.Customer.CustomerInfo.FirstName,
  services: getServices(state),
  hasElectricity: getHasElectricity(state),
  hasPipedGas: getHasPipedGas(state),
  hasBottledGas: getHasBottledGas(state),
  hasBroadband: getHasBroadband(state),
  startDate: getServicesStartDate(state),
  isSwitching: getIsSwitchingSupplier(state),
  hasSmartMeter: getHasSmartMeter(state),
  isPaymentPrepay: getIsPaymentPrepay(state),
  hasOrderBottledGas: getHasOrderBottledGas(state),
  redirectFromExpress: state.Auth.redirectFromExpress,
  isNotLastPage: !(
    state.Confirmation.AcceptPlanTermsAndConditions ||
    state.Confirmation.AcceptPipedGasTermsAndConditions ||
    state.Confirmation.AcceptBottledGasTermsAndConditions
  ),
});

const mapDispatchToProps = (dispatch) => ({
  deleteStateApiSessionId: () => dispatch(deleteStateApiSessionId()),
  redirectOnError: () => {
    if (Constants.redirectOnErrorUrl) {
      dispatch(redirect(Constants.redirectOnErrorUrl));
    }
  },
  triggerIJoinSuccessAnalytics: () => {
    dispatch(triggerIJoinSuccessAnalytics());
  },
  triggerPurchaseExpress: () => {
    dispatch(triggerPurchaseExpress());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Success);
