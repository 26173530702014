import React, { useState, useEffect, Fragment, useCallback } from "react";
import { connect } from "react-redux";
import uuidv4 from "uuid/v4";
import { withRouter } from "react-router-dom";
import {
  LayoutRow,
  LayoutColumn,
  Button,
  HTML,
  Modal,
  Loading,
} from "react-components";
import { Redirect } from "react-router-dom";
import { useStateFromProp } from "../../Utils/hooks";
import SaveForLater from "../../Components/SaveForLater/SaveForLater";
import {
  AgentJoin as Constants,
  ApiError,
  SaveForLater as SaveForLaterConstants,
} from "../../Config/Constants";
import CustomerDetails from "./CustomerDetails";
import PlanServiceDetails from "./PlanServiceDetails";
import {
  saveCustomerData,
  createState,
  saveAuthData,
  getState,
  saveForLaterReset,
  saveForLaterRequest,
  saveCustomerInfoForSaveForLater,
} from "../../Redux/Actions/CSRAgent/JoinAction";
import { updateAgentToken } from "../../Redux/Actions/CSRAgent/LoginActions";
import Sidebar from "./Sidebar";
import {
  triggerCsrEvent,
  triggerAgentPageView,
  triggerBeginCheckout,
} from "../../Utils/analytics";
import { getCustomerInfo } from "../../Redux/Actions/ApiCalls";
import { updateMyDetails } from "../../Redux/Actions/iJoin/MyDetailsActions";

export const Join = (props) => {
  const [firstName, setFirstName] = useStateFromProp(props.firstName, "");
  const [lastName, setLastName] = useStateFromProp(props.lastName, "");
  const [serviceAddress, setServiceAddress] = useStateFromProp(
    props.serviceAddress,
    ""
  );
  const [selectedServiceList, setSelectedServiceList] = useState([]);
  const [gasUsageList, setGasUsageList] = useState([]);
  const [addressDetails, setAddressDetails] = useState(null);

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(null);
  const [selectedBBPlan, setSelectedBBPlan] = useState(null);
  const [selectedBBPlanIndex, setSelectedBBPlanIndex] = useState(null);
  const [selectedBBAddress, setSelectedBBAddress] = useState();
  const handleBBAddressSelect = useCallback(
    (tlcId, address) => {
      if (tlcId && address) {
        setSelectedBBAddress({ tlcId, address });
      } else {
        setSelectedBBAddress(undefined); // clearing it
      }
    },
    [setSelectedBBAddress]
  );

  const [serviceAddressIcp, setServiceAddressIcp] = useState(
    props.serviceAddressIcp
  ); // address search icp
  const [icp, setIcp] = useStateFromProp(props.icp, ""); // electricity icp
  const [gasIcp, setGasIcp] = useStateFromProp(props.gasIcp, ""); // gas icp
  const [hasNoIcp, setHasNoIcp] = useState(false);

  const [isStandard, setIsStandard] = useStateFromProp(props.isStandard, true);
  const [hover, setHover] = useState(null);

  const [continueButton, setContinueButton] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const [promoCode, setPromoCode] = useState("");
  // eslint-disable-next-line
  const [noteList, setNoteList] = useState([]);

  const [selectedPlanId, setSelectedPlanId] = useState("");

  const [isAddressEmpty, setIsAddressEmpty] = useState(false);
  const [isPlanEmpty, setIsPlanEmpty] = useState(false);
  const [isBBEmpty, setIsBBEmpty] = useState(false);
  const [isGasUsageEmpty, setIsGasUsageEmpty] = useState(false);

  const [continueEnabled, setContinueEnabled] = useState(false);
  const [isClearSessionModalActive, setClearSessionModalActive] = useState(
    false
  );
  const [isNeedRedirect, setIsNeedRedirect] = useState(false);
  const [spokenToUsBefore, setSpokenToUsBefore] = useStateFromProp(
    props.spokenToUsBefore,
    false
  );
  const [phoneNumber, setPhoneNumber] = useStateFromProp(props.phoneNumber, "");
  const [dateOfBirth, setDateOfBirth] = useStateFromProp(props.dateOfBirth, "");
  const [dateOfBirthObject, setDateOfBirthObject] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [bp, setBP] = useStateFromProp(props.businessPartnerNumber, "");
  const [isSearchModalVisible, setSearchModal] = useState(false);
  const [sendCustomerSummary, setSendCustomerSummary] = useState(false);
  const [validateItself, setValidateItself] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [receiveNewsAndOffers, setReceiveNewsAndOffers] = useStateFromProp(
    props.receiveNewsAndOffers,
    false
  );

  // const [meterType, setMeterType] = useState('');

  // API requires values to be LCR_01, LCR_02, LCR_03 hence using the constants to find the value so that value repopulation works
  const [consumptionBand, setConsumptionBand] = useStateFromProp(
    Constants.planServiceDetails.consumptionBands.filter((band) =>
      band.includes(props.consumptionBand)
    )[0],
    ""
  );

  const buttonDisabled = !continueEnabled || props.isPriceFetching;

  const isInList = (name, list) => {
    if (list.length && name) {
      return list.includes(name);
    }
  };

  useEffect(() => {
    const token = props.match?.params?.grantToken;
    if (token && !props.token) {
      props.updateToken(token);
    }
    checkSession();
    triggerCsrEvent();
    if (window.location.pathname === "/residential/agent/find-a-plan") {
      triggerAgentPageView();
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.isLoggedIn && props.token) {
      if (props.match.url !== "/residential/agent/find-a-plan") {
        setIsNeedRedirect(true);
        const grantToken = {
          token: props.token,
          deleteAfter: new Date().getTime() + 60 * 60 * 1000,
        };
        localStorage.setItem("grantToken", JSON.stringify(grantToken));
      }
    }
  }, [props]);

  useEffect(() => {
    if (props.sessionId) {
      props.getSessionState();
    }
    // eslint-disable-next-line
  }, [props.sessionId]);

  useEffect(() => {
    setSendCustomerSummary(false);
    if (props.isSaveForLaterSuccess && props.saveForLaterMessage) {
      setSearchModal(true);
    }
  }, [
    props.isSaveForLaterSuccess,
    props.saveForLaterMessage,
    setSendCustomerSummary,
    setSearchModal,
  ]);

  useEffect(() => {
    if (selectedPlan?.Id) {
      setSelectedPlanId(selectedPlan.Id);
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (selectedServiceList.length) {
      if (!isInList("ELEC", selectedServiceList)) {
        setSelectedPlan(null);
      }
      if (!isInList("BB", selectedServiceList)) {
        setSelectedBBPlan(null);
      }
    }
  }, [selectedServiceList]);

  useEffect(() => {
    if (dateOfBirth) {
      const trimDate = dateOfBirth.replace(/\//g, "");
      const date = {
        day: trimDate.slice(0, 2),
        month: trimDate.slice(2, 4),
        year: trimDate.slice(4),
      };
      setDateOfBirthObject(date);
    }
  }, [dateOfBirth]);

  const setDOB = (dateObject) => {
    if (dateObject) {
      setDateOfBirthObject(dateObject);
      if (
        dateObject.day &&
        dateObject.day.length === 2 &&
        dateObject.month &&
        dateObject.month.length === 2 &&
        dateObject.year &&
        dateObject.year.length === 4
      ) {
        setDateOfBirth(
          dateObject.day + "/" + dateObject.month + "/" + dateObject.year
        );
      } else {
        setDateOfBirth("");
      }
    }
  };

  const continueButtonClicked = async () => {
    if (!serviceAddress) {
      setIsAddressEmpty(true);
      setTimeout(() => {
        setIsAddressEmpty(false);
      }, 2000);
      return;
    } else if (isInList("LPG", selectedServiceList) && !gasUsageList.length) {
      setIsGasUsageEmpty(true);
      setTimeout(() => {
        setIsGasUsageEmpty(false);
      }, 1000);
    } else if (isInList("ELEC", selectedServiceList) && !selectedPlan) {
      setIsPlanEmpty(true);
      setTimeout(() => {
        setIsPlanEmpty(false);
      }, 1000);
    } else if (isInList("BB", selectedServiceList) && !selectedBBPlan.Id) {
      setIsBBEmpty(true);
      setTimeout(() => {
        setIsBBEmpty(false);
      }, 1000);
    } else {
      if (!submitted) {
        triggerBeginCheckout(selectedPlan, promoCode);
        await props.saveData({
          isStandard,
          firstName,
          lastName,
          serviceAddress,
          serviceAddressIcp,
          selectedServiceList,
          gasUsageList,
          selectedPlan,
          selectedPlanIndex,
          selectedBBPlan,
          selectedBBPlanIndex,
          promoCode,
          addressDetails,
          selectedPlanId,
          icp,
          gasIcp,
          // meterType,
          consumptionBand,
          selectedBBAddress,
        });
        setSubmitted(true);
        window.scrollTo(0, 0);
      }
    }
  };

  const emptyFields = async () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const checkSession = () => {
    const session = localStorage.getItem("session");
    const storageSessionId = JSON.parse(session)?.sessionID;
    const deleteInPast =
      new Date(JSON.parse(session)?.deleteAfter) < new Date().getTime();

    if (storageSessionId && !deleteInPast) {
      props.saveSessionID(JSON.parse(session));
    } else if (!storageSessionId || deleteInPast) {
      const newSessionId = uuidv4();
      const deleteAfter = new Date().getTime() + 60 * 60 * 1000;
      const auth = {
        sessionID: newSessionId,
        deleteAfter: deleteAfter,
      };
      props.saveSessionID(auth);
      localStorage.setItem("session", JSON.stringify(auth));
      props.createSession();
    }
  };

  const clearSession = () => {
    emptyFields();
    triggerAgentPageView("/delete-session-modal");
    localStorage.removeItem("session");
    checkSession();
  };

  const renderCustomerList = () => {
    const { saveForLaterCustomerList } = props;

    return saveForLaterCustomerList.map((list) => (
      <>
        <span className="join_modal_customer_details">
          {list.firstName} {list.lastName}
          {list.lastName && ","} {list.serviceAddress}
          {list.serviceAddress && ","} {list.emailAddress}
        </span>
        <br />
        <span className="join_modal_services_selected">{list.services}</span>
      </>
    ));
  };

  const renderCustomerListErrorMessage = () => {
    const { saveForLaterMessage } = props;

    if (saveForLaterMessage && saveForLaterMessage.includes("No results")) {
      return (
        <>
          <span className="join_modal_customer_details_error">
            {SaveForLaterConstants.errorMessageBlurb1}
          </span>
          <br />
          <span className="join_modal_services_selected_error">
            {SaveForLaterConstants.errorMessageBlurb2}
          </span>
        </>
      );
    }
    if (
      saveForLaterMessage &&
      saveForLaterMessage.includes("several results")
    ) {
      return (
        <span className="join_modal_customer_details_error">
          {SaveForLaterConstants.errorMessageMultipleResults}
        </span>
      );
    }
  };

  const renderCustomerListContent = () => (
    <>
      <p>{SaveForLaterConstants.resultsModalSubtext}</p>
      <br />
      {props.saveForLaterCustomerList &&
      props.saveForLaterCustomerList.length > 0 ? (
        <div className="join_modal_customer_list_container">
          {renderCustomerList()}
        </div>
      ) : (
        <div className="join_modal_customer_list_error_container">
          {renderCustomerListErrorMessage()}
        </div>
      )}
      <br />
      <br />
      <div className="join_modal_no_customer_found">
        <span>{SaveForLaterConstants.resultsModalFooter1}</span>
        <span className="join_link" onClick={() => setSearchModal(false)}>
          {SaveForLaterConstants.resultsModalFooter2}
        </span>
      </div>
    </>
  );

  const handleFindButtonClick = () => {
    props.getCustomer({
      firstName,
      lastName,
      phoneNumber,
      dateOfBirth: dateOfBirthObject,
      emailAddress,
      bp,
    });
  };

  const handleSendCustomerSummary = async () => {
    props.saveForLaterRequest();
    await props.saveDataForSaveForLater({
      isStandard,
      firstName,
      lastName,
      emailAddress,
      serviceAddress,
      serviceAddressIcp,
      selectedServiceList,
      gasUsageList,
      selectedPlan,
      selectedPlanIndex,
      selectedBBPlan,
      selectedBBPlanIndex,
      promoCode,
      addressDetails,
      selectedPlanId,
      icp,
      gasIcp,
      consumptionBand,
      selectedBBAddress,
    });
  };

  const handleLoadProfileClick = () => {
    if (
      props.saveForLaterCustomerList &&
      props.saveForLaterCustomerList.length > 0 &&
      props.saveForLaterState
    ) {
      const auth = {
        sessionID: props.saveForLaterState.Customer?.CustomerInfo?.EmailAddress,
        deleteAfter: props.saveForLaterState.deleteAfter,
      };
      const saveForLaterData = {
        ...props.saveForLaterState,
        sessionID: props.saveForLaterState.Customer?.CustomerInfo?.EmailAddress,
      };
      props.saveSessionID(auth);
      props.loadSaveForLaterProfile(saveForLaterData);
      setSearchModal(false);
      props.saveForLaterReset();
    } else {
      setSearchModal(false);
    }
  };

  const renderErrorMessage = () => {
    return (
      <div className="save-for-later_errorContainer">
        <div className="save-for-later_errorContainer_blurb1">
          {ApiError.errorTitle}
        </div>
        <span className="save-for-later_errorContainer_blurb2">
          {ApiError.errorMessage}
        </span>
      </div>
    );
  };

  const renderLeftSide = () => (
    <div className="join">
      <div className="join_title">
        <div className="join_title_bold">{Constants.title}</div>
        <div
          className="join_title_clear"
          onClick={() => setClearSessionModalActive(true)}
        >
          {Constants.clearSession}
        </div>
      </div>
      <CustomerDetails
        constants={Constants.customerDetails}
        firstName={firstName}
        lastName={lastName}
        spokenToUsBefore={spokenToUsBefore}
        phoneNumber={phoneNumber}
        bp={bp}
        dateOfBirthObject={dateOfBirthObject}
        emailAddress={emailAddress}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setSpokenToUsBefore={setSpokenToUsBefore}
        setPhoneNumber={setPhoneNumber}
        setDOB={setDOB}
        setEmailAddress={setEmailAddress}
        setBP={setBP}
        isCustomerInfoError={props.isSaveForLaterError}
        renderErrorMessage={renderErrorMessage()}
        handleFindButtonClick={() => handleFindButtonClick()}
        isLoading={props.isSaveForLaterSubmitting}
      />
      <PlanServiceDetails
        constants={Constants.planServiceDetails}
        addressDetails={addressDetails}
        serviceAddressProp={serviceAddress}
        setServiceAddress={setServiceAddress}
        setAddressDetails={setAddressDetails}
        setGasUsageList={setGasUsageList}
        gasUsageList={gasUsageList}
        setSelectedServiceList={setSelectedServiceList}
        icp={icp}
        gasIcp={gasIcp}
        serviceAddressIcp={serviceAddressIcp ?? icp}
        setServiceAddressIcp={setServiceAddressIcp}
        hasNoIcp={hasNoIcp}
        setSelectedPlan={setSelectedPlan}
        setContinueButton={setContinueButton}
        isStandard={isStandard}
        setIsStandard={setIsStandard}
        setSelectedBBPlan={setSelectedBBPlan}
        setSelectedPlanIndex={setSelectedPlanIndex}
        setSelectedBBPlanIndex={setSelectedBBPlanIndex}
        setPromoCode={setPromoCode}
        setIcp={setIcp}
        setHasNoIcp={setHasNoIcp}
        setSelectedPlanId={setSelectedPlanId}
        isAddressEmpty={isAddressEmpty}
        isPlanEmpty={isPlanEmpty}
        isBBEmpty={isBBEmpty}
        isGasUsageEmpty={isGasUsageEmpty}
        setPlanServiceComplete={setContinueEnabled}
        setConsumptionBand={setConsumptionBand}
        consumptionBand={consumptionBand}
        setGasIcp={setGasIcp}
        onBroadbandAddressSelect={handleBBAddressSelect}
      />
      <div className="join_button-container">
        <Button
          name="continue"
          id="continue"
          type="button"
          text={<HTML html={continueButton.ContinueTitle?.value} />}
          secondaryText={<HTML html={continueButton.ContinueSubTitle?.value} />}
          handleClick={continueButtonClicked}
          handleValidationClick={() => {}}
          disabled={buttonDisabled}
          className={
            buttonDisabled
              ? "button--primaryOnLight--disabled"
              : "button--primaryOnLight"
          }
          onMouseEnter={() => {
            setHover("continue");
          }}
          onMouseLeave={() => setHover(null)}
          customStyle={{
            background: !buttonDisabled
              ? hover === "continue"
                ? continueButton.HoverBackgroundColor?.value
                : continueButton.BackgroundColor?.value
              : "",
            color: !buttonDisabled
              ? hover === "continue"
                ? continueButton.HoverColor?.value
                : continueButton.Color?.value
              : "",
            border:
              !buttonDisabled &&
              `1px solid ${continueButton.BackgroundColor?.value}`,
            borderRadius: "5px",
          }}
        />
        <div
          className="join_button-container_send"
          onClick={() => setSendCustomerSummary(true)}
        >
          {Constants.sendSummary}
        </div>
      </div>
    </div>
  );

  return (
    <Fragment>
      {submitted && <Redirect to="/residential/agent/customer-details" />}
      {isNeedRedirect && <Redirect to="/residential/agent/find-a-plan" />}
      <Modal
        showClose={false}
        clearSessionStyle
        isActive={!props.isLoggedIn}
        journeyStyle={false}
        content={
          <Loading
            customstyle={{
              minWidth: "100%",
              minHeight: "150px",
              marginBottom: "-15px",
            }}
          />
        }
        className="join_modal_loading_container"
        hideButtonContainer
      />
      <Modal
        showClose
        clearSessionStyle
        isActive={isClearSessionModalActive}
        buttonTextPrimary={Constants.clearSessionModal.primaryButtonContent}
        handlePrimaryClick={() => {
          setClearSessionModalActive(!isClearSessionModalActive);
        }}
        buttonTextSecondary={Constants.clearSessionModal.secondaryButtonContent}
        handleSecondaryClick={() => {
          clearSession();
        }}
        content={<HTML html={Constants.clearSessionModal.html} />}
        contentStyle={{
          marginLeft: "20px",
          marginRight: "20px",
          marginTop: "60px",
          marginBottom: "60px",
          textAlign: "center",
        }}
      />
      <SaveForLater
        sendCustomerSummary={sendCustomerSummary}
        setEmailAddress={setEmailAddress}
        emailAddress={emailAddress}
        setReceiveNewsAndOffers={setReceiveNewsAndOffers}
        receiveNewsAndOffers={receiveNewsAndOffers}
        handlePrimaryClick={() => setSendCustomerSummary(false)}
        handleSecondaryClick={() => handleSendCustomerSummary()}
        handleValidationClick={() => setValidateItself(true)}
        validateItself={validateItself}
        isSendCustomerSummaryError={props.isSaveForLaterError}
        isSaveForLaterSubmitting={props.isSaveForLaterSubmitting}
      />
      <Modal
        showClose
        clearSessionStyle
        isActive={isSearchModalVisible}
        title={Constants.customerDetails.spokenToUsBeforeLabel}
        className="join_modal_container"
        buttonTextPrimary={SaveForLaterConstants.primaryButtonContent}
        handlePrimaryClick={() => setSearchModal(false)}
        buttonTextSecondary={
          props.saveForLaterCustomerList &&
          props.saveForLaterCustomerList.length > 0
            ? SaveForLaterConstants.secondaryButtonLoadContent
            : SaveForLaterConstants.errorMessageButton
        }
        handleSecondaryClick={() => handleLoadProfileClick()}
        content={renderCustomerListContent()}
      />
      <LayoutRow
        reverseRow
        left={renderLeftSide()}
        right={
          <LayoutColumn
            fullHeight
            hideColumn
            top={<Sidebar constants={Constants} isJoin />}
          />
        }
      />
    </Fragment>
  );
};

const getDetails = (values, isSaveForLater = false) => {
  let customerInfo = {
    FirstName: values.firstName,
    LastName: values.lastName,
    ServiceAddress: values.serviceAddress,
    Moniker: values.addressDetails?.pxid || values.addressId,
  };
  if (isSaveForLater) {
    customerInfo = {
      ...customerInfo,
      EmailAddress: values.emailAddress,
    };
  }

  return {
    Customer: {
      CustomerInfo: {
        ...customerInfo,
      },
    },
    PlanInfo: {
      SelectedPlan: values.selectedPlan,
      SelectedBBPlan: values.selectedBBPlan,
      PlanId: values.selectedPlanId || "",
      PlanName: values.selectedPlan?.Name || "",
    },
    Services: values.selectedServiceList,
    LpgInfo: {
      BottledGasUsage: values.gasUsageList,
    },
    PipedGasInfo: {
      PipedGasConsumptionBand:
        values.consumptionBand && values.consumptionBand.split(" - ")[1],
    },
    Payment: {
      PromoCode: values.promoCode,
    },
    BroadbandInfo: {
      ...(values.selectedBBPlan || {}),
      DevoliAddress: values.selectedBBAddress?.address,
      DevoliTLC: values.selectedBBAddress?.tlcId,
      BroadbandCode: values.selectedBBPlan?.Code,
    },
    Property: {
      PropertyInfo: {
        Address: values.serviceAddress,
        AddressICP: values.serviceAddressIcp,
        ElectricityICP: values.icp,
        GasICP: values.gasIcp,
        // ElectricityMeterType: values.meterType,
        ElectricityUserType: values.isStandard ? "Standard" : "Low User",
        Moniker: values.addressDetails?.pxid || values.addressId,
        QASAddress: values.serviceAddress,
        AddressDetails: values.addressDetails,
      },
    },
    JourneyChannel: Constants.journeyChannel,
    JourneyType: Constants.journeyType,
  };
};

const mapStateToProps = (state) => ({
  title: state.Customer.CustomerInfo?.Title,
  firstName: state.Customer.CustomerInfo?.FirstName,
  lastName: state.Customer.CustomerInfo?.LastName,
  emailAddress: state.Customer.CustomerInfo?.EmailAddress,
  receiveNewsAndOffers: state.Confirmation?.ReceiveNewsAndOffers,
  phoneNumber: state.Customer.CustomerInfo?.PhoneNumber,
  dateOfBirth: state.Customer.CustomerInfo?.DateOfBirth,
  businessPartnerNumber: state.Customer.CustomerInfo?.BusinessPartnerNumber,
  serviceAddress: state.Customer.CustomerInfo?.ServiceAddress,
  sessionId: state.Auth?.sessionID,
  icp: state.Property?.PropertyInfo.ElectricityICP,
  gasIcp: state.Property?.PropertyInfo.GasICP,
  serviceAddressIcp: state.Property?.PropertyInfo.AddressICP,
  isStandard: state.Property?.PropertyInfo.ElectricityUserType === "Standard",
  token: state.Login?.token,
  isLoggedIn: state.Login?.isLoggedIn,
  isPriceFetching: state.Price.isFetching,
  consumptionBand: state.PipedGasInfo?.PipedGasConsumptionBand,
  services: state.Services,
  isSaveForLaterSubmitting: state.Join?.isSubmitting,
  isSaveForLaterError: state.Join?.error,
  isSaveForLaterSuccess: state.Join?.success,
  saveForLaterCustomerList: state.Join?.successResponse,
  saveForLaterMessage: state.Join?.successMessage,
  saveForLaterState: state.Join?.state,
});

const mapDispatchToProps = (dispatch) => ({
  saveSessionID: (auth) => {
    dispatch(saveAuthData(auth));
  },
  createSession: () => {
    dispatch(createState());
  },
  getSessionState: () => {
    dispatch(getState());
  },
  updateToken: (token) => {
    dispatch(updateAgentToken(token));
  },
  saveData: (values) => {
    dispatch(saveCustomerData(getDetails(values)));
  },
  saveDataForSaveForLater: (values) => {
    dispatch(saveCustomerInfoForSaveForLater(getDetails(values, true)));
  },
  saveForLaterReset: () => {
    dispatch(saveForLaterReset());
  },
  saveForLaterRequest: () => {
    dispatch(saveForLaterRequest());
  },
  getCustomer: (customerInfo) => {
    dispatch(getCustomerInfo(customerInfo));
  },
  loadSaveForLaterProfile: (data) => {
    dispatch(updateMyDetails(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Join));
