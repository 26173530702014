import * as actionTypes from "../../Actions/actionTypes";
import { reducerPropUpdater } from "../../utils";

const initialState = {
  CreditCheck: false,
  AcceptGeneralTermsAndConditions: false,
  ReceiveNewsAndOffers: false,
  AcceptPlanTermsAndConditions: false,
  AcceptPricingTermsAndConditions: false,
  AcceptBroadbandTermsAndConditions: false,
  AcceptBottledGasTermsAndConditions: false,
  AcceptPipedGasTermsAndConditions: false,
  JourneyType: "",
};

const updateProp = reducerPropUpdater("Confirmation");

const updateConfirmation = (state, confirmation) => {
  return { ...state, ...confirmation };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PROP:
      return updateProp(state, action.propName, action.value);

    case actionTypes.UPDATE_CONFIRMATION:
      return updateConfirmation(state, action.confirmation);

    default:
      return state;
  }
};

export default reducer;
